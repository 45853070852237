export enum ToastEventTypes {
    Success = 'tr-toast-success',
    Info = 'tr-toast-info',
    Info_Light = 'tr-toast-info-light',
    Warning = 'tr-toast-warning',
    Error = 'tr-toast-error',
    Success_Light = 'tr-toast-success-light'
}
export enum ToastEventIcons {
    Success = 'success',
    Info = 'info-blue',
    Info_Light = 'info-light',
    Warning = 'warning',
    Error = 'error',
    Success_Light = 'lock'
}

export interface ToastEvent {
    type: ToastEventTypes;
    icon:ToastEventIcons;
    title: string;
    message: string;
}
