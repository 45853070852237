import { Component, OnInit } from '@angular/core';
import { StudentDashboardService } from '@components/student-components/student-dashboard/student-dashboard.service';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";

@Component({
  selector: 'tr-student-dashboard-my-students',
  templateUrl: './student-dashboard-my-students.component.html',
  styleUrls: ['./student-dashboard-my-students.component.css']
})
export class StudentDashboardMyStudentsComponent implements OnInit {

  tutors!:any;
  isLoading:boolean = true;

  constructor(private service:StudentDashboardService , private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {

    this.getCurrentTutors();
  }

  getCurrentTutors() {
    this.isLoading = true;
    this.service
      .getCurrentTutors()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          let data = res['data'];
          if(data){
            this.tutors = data;
          } 
         console.log(res);
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
    }
}
