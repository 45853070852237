<div class="row p-0 m-0 py-md-4 px-md-4">
   <div class="col-12 p-0">
      <p class="mb-4 tr-notifications-title"> Recent Notifications</p>
   </div>
   <div class="row p-0 m-0">
      <div class="col-12 tr-notifications-main p-4 mb-3" *ngFor="let notification of userNotifications">
         <div class="tr-notification d-flex align-items-start justify-content-between position-relative">
            <div class="tr-notification-img position-relative float-start">
               <img [src]="notification.userImage" alt="" *ngIf="notification.userImage">
               <img src="assets/images/profile-placeholder.png" alt="profile-placeholder" *ngIf="!notification.userImage">
               <span class="tr-notification-online" *ngIf="notification?.online"></span>
            </div>
            <div class="d-flex justify-content-between tr-w-100">
               <div class="tr-notification-content">
                  <p class="mb-0"> {{ notification.content }}</p>
                  <p class="mb-0 d-flex align-items-center">
                     <span class="tr-notification-time">{{ formatTime(notification.createdAt) }}</span> <!-- <span class="tr-notification-divider"></span><span class="tr-notification-lesson">English Lesson</span> -->
                  </p>
                  <p class="mt-3">
                     <a class="btn btn-primary me-3 tr-btn-{{permission}}" *ngFor="let permission of notification?.premissions" (click)="getLessonAction(permission, notification)"> {{ mapPermission(permission) }} </a>
                     
                  </p>
               </div>
               <div class="tr-notification-tag-div" >
                  <p class="mb-0 tr-notification-tag  d-flex align-items-center justify-content-around flex-row-reverse tr-bg-{{ notification.action }}">
                    {{ notification.action }}
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Button trigger modal -->
<button class="border-0 p-0 m-0 d-none" data-bs-toggle="modal" data-bs-target="#lessonReschedulerModal" #openReschedulerModal></button>
<!-- Modal -->
<ng-container *ngIf="(
    (userLessonsService.lessonAction$ | async) ===  LESSON_PERMISSIONS.RESCHEDULE) 
    || ((userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.REQ_RESCHEDULE)">
<div class="modal fade" id="lessonReschedulerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="reschedulerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen border-0 p-0 m-0">
    <div class="modal-content">
        <div class="tr-lessons-rescheduler-components">
         <tr-lesson-rescheduler></tr-lesson-rescheduler>
       </div>
    </div>
  </div>
</div>
</ng-container>
<tr-confirm-delete-popup></tr-confirm-delete-popup>