import * as moment from 'moment';

export function getFormattedTimeAgo(timestamp: string): string {
  const now = moment();
  const messageTime = moment(timestamp);
  const diffInMinutes = Math.abs(now.diff(messageTime, 'minutes'));
  const diffInHours = Math.abs(now.diff(messageTime, 'hours'));
  const diffInDays = Math.abs(now.diff(messageTime, 'days'));

  if (diffInMinutes < 60) {
    if (diffInMinutes === 1) {
      return `${diffInMinutes} min ago`;
    } else {
      return `${diffInMinutes} mins ago`;
    }
  } else if (diffInHours < 24) {
    if (diffInHours === 1) {
      return `${diffInHours} hour ago`;
    } else {
      return `${diffInHours} hours ago`;
    }
  } else if (diffInDays < 30) {
    if (diffInDays === 1) {
      return `${diffInDays} day ago`;
    } else {
      return `${diffInDays} days ago`;
    }
  } else {
    return `a month ago`;
  }
}
