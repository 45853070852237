import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-live-tutor',
  templateUrl: './live-tutor.component.html',
  styleUrls: ['./live-tutor.component.css']
})
export class LiveTutorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
