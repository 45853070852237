import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'tr-crop-modal',
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.css']
})
export class CropModalComponent implements OnInit {
  @Output() savedImage = new EventEmitter<any>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoaded: boolean = false;
  isCropperDialogClosed: boolean = true;
  saveProfileImage:any = '';
  constructor() { }

  ngOnInit(): void {
  }

  closeCropperDialog() {
    this.croppedImage = '';
    this.isCropperDialogClosed = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  saveImage(){
    const Blob = this.dataURItoBlob(this.croppedImage);
    this.savedImage.emit(Blob);
    this.saveProfileImage = this.croppedImage;

  }

  // Convert image from base64 to file
  dataURItoBlob(dataURI: any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png'
    });
  }

  imageLoaded(image: LoadedImage) {
    this.isCropperDialogClosed = false;
    this.isImageLoaded = true;
    let modal = document.getElementById("openModal");
    if(modal){
      modal.click();
    }
    
  }
  cropperReady() {
  }
  loadImageFailed() {
    this.isImageLoaded = false;
  }

}
