import { Component, OnInit, SkipSelf } from '@angular/core';
import { AuthService } from '@auth/auth.service';
@Component({
  selector: 'tr-class-room',
  templateUrl: './class-room.component.html',
  styleUrls: ['./class-room.component.css']
})
export class ClassRoomComponent implements OnInit {

  constructor(@SkipSelf() public auth: AuthService) { 
    this.auth.isClassRoomActivated$.next(true);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.auth.isClassRoomActivated$.next(false);
  }

}
