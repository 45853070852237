  <div class="tr-bg-white" id="get-height">
    <tr-book-lesson-header></tr-book-lesson-header>
  </div>
  <div class="tr-book-lesson-wrapper col-md-12 col-lg-12">
    <section class="row px-0 tr-py-2 tr-pb-4 m-0">
      <tr-lesson-type *ngIf="(bookingPhase | async) == BOOKING_PHASES.LESSON_TYPE"></tr-lesson-type>
      <tr-book-lesson-type-details *ngIf="(bookingPhase | async) == BOOKING_PHASES.LESSON_TYPE_DETAILS"></tr-book-lesson-type-details>
      <tr-lesson-communication-tool *ngIf="(bookingPhase | async) == BOOKING_PHASES.LESSON_Communication_TOOLS"></tr-lesson-communication-tool>
      <ng-template #tutorSchedulerWrapper></ng-template>
      <tr-lesson-summary *ngIf="(bookingPhase | async) == BOOKING_PHASES.BOOKING_SUMMARY "></tr-lesson-summary>
      <tr-book-lesson-checkout *ngIf="(bookingPhase | async) == BOOKING_PHASES.BOOKING_CHECKOUT "></tr-book-lesson-checkout>
    </section>
  </div>
  <div class="tr-book-lesson-footer" #bookingFooter>
    <tr-book-lesson-footer></tr-book-lesson-footer>
  </div>

 