<section class="tr-filter-wrapper d-flex align-items-center justify-content-between px-4">
  <div class="tr-filter-left-sec d-flex  align-items-center justify-content-between gap-3 gap-xxl-5 mb-w-100" *ngIf="!hideFilter">
    <div class="d-flex align-items-center gap-3">
      <p class="mb-0 tr-filter-label">Filter by</p>
      <span [innerHTML]="getSanitizedSVG()"></span>
    </div>
    <div class="d-flex d-md-none  tr-bg-grey" type="button" data-bs-toggle="modal" data-bs-target="#mobileFilterModal">
      <img src="assets/images/tune.svg" alt="tune-img">
    </div>
    <div class="tr-filter-by-select-btns d-flex gap-3 align-items-center flex-wrap d-none d-md-flex">
      <div *ngIf="hideGridView">
        <div class="position-relative tr-filter-drop-search-div">
          <input type="search" class="form-control tr-filter-drop-search p-2" name="search" placeholder="Name of student" [(ngModel)]="searchText" (keyup)="onSearchKeyUp()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!searchText">
            <path [attr.d]="searchDropdownIcon" fill="#888888" />
          </svg>
        </div>
      </div>
      <tr-custom-dropdown id="{{targetLessonList}}" [isCheckbox]="true" [dropDownMenuData]="lessonStatuses" targetList="{{targetLessonList}}" title="Lesson statuses" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)" menuTitle="Status" *ngIf="!hideGridView"></tr-custom-dropdown>
      <tr-custom-dropdown [id]="'subjectId'" [isCheckbox]="true" [dropDownMenuData]="allSubjects$" targetList="subjectId" title="Subjects" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)" menuTitle="Subjects"></tr-custom-dropdown>
      <tr-custom-dropdown [id]="type" [isCheckbox]="true" [dropDownMenuData]="userData$" targetList="userId" [title]="type" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)" menuTitle="Tutors" *ngIf="!hideGridView"></tr-custom-dropdown>
    </div>
  </div>
  <div class="tr-filter-right-sec d-md-flex d-none">
    <div class="d-flex align-items-center gap-3 left15 " *ngIf="!hideGridView">
      <span class="tr-filter-label ">Display</span>
      <div class="mt-2 right5">
        <span (click)="getLisView('grid')" class="tr-lesson-action-header-display-btn">
          <tr-svg [path]="gridDisplayPath" [ngClass]="{'active': isListView, 'not-active': !isListView }"></tr-svg>
        </span>
      </div>
      <div class="mt-2 right15">
        <span (click)="getLisView('list')" class="tr-lesson-action-header-display-btn">
          <tr-svg [path]="listDisplayPath" [ngClass]="{'active': !isListView , 'not-active': isListView }"></tr-svg>
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3 left15" *ngIf="!hideFilter">
      <p class="mb-0 tr-filter-label" style="margin-top: -5px;">Sort by</p>
      <div class="mt-2">
        <span type="button" id="custom_dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" class="tr-filter-by-svg">
          <tr-svg [path]="sortByFilterIcon"></tr-svg>
        </span>
        <ul class="dropdown-menu tr-sort-by-menu border-0 p-4" aria-labelledby="custom_dropdown">
          <div class="position-relative tr-filter-drop-search-div">
            <div class="">
              <div *ngFor="let item of sortByType">
                <li class="right10 tr-sort-by-li d-flex align-items-center ">
                  <img class="tr-sort-by-icon cursor-pointer" src="assets/icons/{{item.icon}}.svg">
                  <input class="me-2" [(ngModel)]="selectedSortBy" (ngModelChange)="emitFilters(item,true)" [value]="item.name" type="radio" [attr.id]="'sortBy' + item.name" name="sortBy" [checked]="selectedSortBy === item.name ">
                  <label class="tr-sort-by-label cursor-pointer" [attr.for]="'sortBy' + item.name">{{ item.name }}</label>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="mobileFilterModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mobileFilterModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="tr-reg-popup-title p-0 m-0" id="mobileFilterModalLabel">Filters</h5>
        <button type="button" class="border-0 p-0 m-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close">
          <img src="assets/icons/close-circle.svg" alt="close-icon">
        </button>
      </div>
      <div class="modal-body">
        <div class="row p-0 m-0">
          <div class="col-12 p-0 m-0 d-flex flex-column gap-3">
            <tr-custom-dropdown id="{{targetLessonList}}" [isCheckbox]="false" [dropDownMenuData]="lessonStatuses" targetList="{{targetLessonList}}" title="Lesson Statuses" (selectedCheckData)="getSelectedData($event)" menuTitle="Status"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="'subjectId'" [isCheckbox]="false" [dropDownMenuData]="allSubjects$" targetList="subjectId" title="Subjects" (selectedCheckData)="getSelectedData($event)" menuTitle="Subjects"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="type" [isCheckbox]="false" [dropDownMenuData]="userData$" targetList="userId" [title]="type" (selectedCheckData)="getSelectedData($event)" menuTitle="Tutors"></tr-custom-dropdown>
            <div class="d-flex align-items-center gap-3 justify-content-between" *ngIf="!hideFilter">
              <p class="mb-0 tr-filter-label" style="margin-top: -5px;">Sort By</p>
              <div class="mt-2">
                <span type="button" id="custom_dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" class="tr-filter-by-svg">
                  <tr-svg [path]="sortByFilterIcon"></tr-svg>
                </span>
                <ul class="dropdown-menu tr-sort-by-menu border-0 p-4" aria-labelledby="custom_dropdown">
                  <div class="position-relative tr-filter-drop-search-div">
                    <div class="">
                      <div *ngFor="let item of sortByType">
                        <li class="right10 tr-sort-by-li d-flex align-items-center ">
                          <img class="tr-sort-by-icon cursor-pointer" src="assets/icons/{{item.icon}}.svg">
                          <input class="me-2" [(ngModel)]="selectedSortBy" (ngModelChange)="emitFilters(item,true)" [value]="item.name" type="radio" [attr.id]="'sortBy' + item.name" [checked]="selectedSortBy === item.name ">
                          <label class="tr-sort-by-label cursor-pointer" [attr.for]="'sortBy' + item.name">
                          {{ item.name }}
                        </label>
                        </li>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-4 d-flex align-items-center gap-3" *ngIf="targetedFilters && targetedFilters.length > 0">
  <div class="d-flex align-items-center gap-3">
    <p *ngFor="let filter of targetedFilters" class="mb-0 tr-font-12  tr-selected-filter-tag">
      {{ filter.name }}
    </p>
  </div>
</div>