import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormDataService } from 'src/app/core/form-data.service';
import { UntypedFormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { formValidationMessages } from 'src/app/shared/models/validation';

@Component({
  selector: 'tr-add-certificate-popup',
  templateUrl: './add-certificate-popup.component.html',
  styleUrls: ['./add-certificate-popup.component.css']
})
export class AddCertificatePopupComponent implements OnInit {
    @ViewChild('closeModal') closeModal?: ElementRef;
    @Output() onChangeStatus = new EventEmitter();
    file: any;
    subjects: any;
    certificates:any;
    uploadImageStatus:any;
    disable:boolean = false;

  constructor(
    private formDataService: FormDataService, 
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private tutorRegPhasesService: TutorRegPhasesService) 
  { 
    this.subjects = this.formDataService.subjects$; 
    this.certificates = this.formDataService.certificates$; 
  }

  public errorMessages = {
    yearsOfStudyFrom: formValidationMessages['fromDate'],
    yearsOfStudyTo: formValidationMessages['toDate'],
    certificateId: formValidationMessages['certificate'],
    attachment: formValidationMessages['attachment'],
    issuedBy: formValidationMessages['issuedBy'],
  }

  public addCertificateForm = this.fb.group({
    yearsOfStudyFrom: ['', Validators.required],
    yearsOfStudyTo: ['', Validators.required],
    certificateId: ['',Validators.required],
    attachment: [''],
    issuedBy: ['', Validators.required],
  });

  ngOnInit(): void {
  }

  certificateImage(event: any) {
   event.rejectedFiles.forEach((file:any) => {
    if (file.reason === 'type') {
        this.uploadImageStatus = 'File has wrong format ("image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document")';
      } else if (file.reason === 'size') {
        this.uploadImageStatus = 'File too big (max file size is 2mb)';
      } else {
        this.uploadImageStatus = 'File not added';
      }
        this.toastService.showErrorToast('Error!', this.uploadImageStatus);
    });
    this.file = event.addedFiles;
    this.uploadImageStatus = '';
  }

  onRemove(event: any) {
    this.file.splice(this.file.indexOf(event), 1);
  }

  onSelectSubject(event:any){
    let subjectId = event.target.value;
    this.formDataService.getCertificates(subjectId);
  }

  clearForm(){
    this.addCertificateForm.reset();
    this.file = [];
  }


  addCertificate(){
    this.disable = true;
    this.addCertificateForm.get('attachment')?.setValue(this.file[0]);
    this.tutorRegPhasesService.registerCertificate(this.addCertificateForm.value).pipe(finalize(() => this.disable = false)).subscribe(
      (data: any) => {
        if(data.status){
          this.onChangeStatus.emit('success');
          this.closeModal?.nativeElement.click();
          this.toastService.showSuccessToast('Success !', data.message);
        }
      },
      (error: any) => {
          if(error instanceof HttpErrorResponse){
          if(error.status === 422){
            this.toastService.showErrorToast('Error!', error.error['message']);
          }
        }
        else{
           this.toastService.showErrorToast('Error!', error.message);
        }
      }
    )
  }

}
