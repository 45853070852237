import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})
export class NotificationsAlertsService {

  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
     return this.trHttp.fetch(endPoint, params, method);
  }

  getNotifications(perPage:any){
     const formData = new FormData();
     formData.append('perPage', perPage);
     return this.fetchCore('list-user-notification', formData);
  }
}
