import { Component, OnInit } from '@angular/core';
import { BookLessonService } from '../book-lesson.service';
import { Router } from '@angular/router';
import { delay, map, take, takeUntil } from 'rxjs/operators';
import { BOOKING_PHASES, TRObject, delayedExecution } from '@shared/models/shared';
import { of } from 'rxjs/internal/observable/of';
import { Observable, combineLatest, timer } from 'rxjs';
import { ToastService } from '@components/shared-components/toast.service';
@Component({
  selector: 'tr-book-lesson-footer',
  templateUrl: './book-lesson-footer.component.html',
  styleUrls: ['./book-lesson-footer.component.css']
})

export class BookLessonFooterComponent extends TRObject implements OnInit {
  bookingPhase: any;
  selectedTutor: any;
  BOOKING_PHASES: typeof BOOKING_PHASES = BOOKING_PHASES;

  constructor(
    public bookLessonService: BookLessonService, 
    private router: Router, private toastService: ToastService) {
    super();
    this.selectedTutor = this.bookLessonService.selectedTutor$;
    console.log(this.selectedTutor);
  }

  ngOnInit(): void {
    this.bookingPhase = this.bookLessonService.bookingPhase$;
  }

  nextBookingPhase() {
    let nextPhase: string = '';
    let currentPhase: string = this.bookingPhase.getValue();

    switch (currentPhase) {
      case BOOKING_PHASES.LESSON_TYPE_DETAILS:
        nextPhase = BOOKING_PHASES.LESSON_TYPE;
        break;
      case BOOKING_PHASES.LESSON_TYPE:
        nextPhase = BOOKING_PHASES.TIME_SLOTS;
        break;
      case BOOKING_PHASES.TIME_SLOTS:
        nextPhase = BOOKING_PHASES.LESSON_Communication_TOOLS;
        this.bookLessonService.holdSlots().subscribe(
          (data: any) => {
          },
          (error: any) => {
            delayedExecution(() => {
              nextPhase = BOOKING_PHASES.TIME_SLOTS;
              this.bookLessonService.nextBookingPhase(nextPhase);
            }, 2000)
            this.toastService.showErrorToast('Error- select another lesson', error.error.message);

          }
        );
        break;
      case BOOKING_PHASES.LESSON_Communication_TOOLS:
        this.bookLessonService.getLessonPrice().subscribe();
        nextPhase = BOOKING_PHASES.BOOKING_SUMMARY;
        break;
      case BOOKING_PHASES.BOOKING_SUMMARY:
        nextPhase = BOOKING_PHASES.BOOKING_CHECKOUT;
        break;
    }
    this.bookLessonService.nextBookingPhase(nextPhase);
  }

  previousPhase() {
    let nextPhase: string = '';
    let currentPhase: string = this.bookingPhase.getValue();

    switch (currentPhase) {
      case BOOKING_PHASES.BOOKING_CHECKOUT:
        nextPhase = BOOKING_PHASES.BOOKING_SUMMARY;
        break;
      case BOOKING_PHASES.BOOKING_SUMMARY:
        nextPhase = BOOKING_PHASES.LESSON_Communication_TOOLS;
        break;
      case BOOKING_PHASES.LESSON_Communication_TOOLS:
        nextPhase = BOOKING_PHASES.TIME_SLOTS;
        break;
      case BOOKING_PHASES.TIME_SLOTS:
        nextPhase = BOOKING_PHASES.LESSON_TYPE;
        break;
      case BOOKING_PHASES.LESSON_TYPE:
        nextPhase = BOOKING_PHASES.LESSON_TYPE_DETAILS
        break;
    }
    this.bookLessonService.nextBookingPhase(nextPhase);
  }

  validateNextButton(): Observable<boolean> {
    return combineLatest([
      this.bookLessonService.bookLessonTypeDetails$,
      this.bookLessonService.studentBookingSlots$,
      this.bookLessonService.bookingPhase$
    ]).pipe(
      map(([lessonTypeDetails, studentBookingSlots, bookingPhase]) => {
        const isTimeSlotsPhase = bookingPhase === BOOKING_PHASES.TIME_SLOTS;
        const hasSlotsSelected = studentBookingSlots.length > 0;
        return (
          lessonTypeDetails.lessonSubject.id !== null &&
          lessonTypeDetails.lessonSpeciality !== null &&
          lessonTypeDetails.lessonTypeAgeGroupId !== null &&
          (isTimeSlotsPhase ? hasSlotsSelected : true)
        );
      })
    );
  }



}

