 <!-- Modal -->
<div class="modal fade" id="raiseComplaintModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content p-4">
      <div class="modal-body mt-2 d-flex flex-column justify-content-center px-4">
        <img src="assets/icons/raise-complaint.svg" alt="raise-complaint" class="tr-raise-icon">
        <div class="d-flex flex-column align-items-center justify-content-center gap-2 mt-3">
          <span class="tr-sub-title-dark-text tr-font-16">Raise Complaint</span>
          <span class="tr-sub-title-lite-text tr-font-14">Are you sure you want to raise complaint for this tutor ?</span>
        </div>
        <div class="d-flex flex-column mt-4">
          <span class="tr-sub-title-dark-text tr-font-16 mb-3">Note</span>
          <textarea name="note" [(ngModel)]="complaintDescription" id="note" cols="8" rows="5" class="form-control"></textarea>
        </div>
        <div class="d-flex align-items-center gap-2 mt-4">
          <button class="tr-btn-primary-outline px-3 py-2 tr-btn" data-bs-dismiss="modal" aria-label="Close" #closeModal (click)="this.complaintDescription = ''">Discard</button>
          <button class="tr-btn-primary px-3 py-2 tr-btn" [disabled]="!complaintDescription" (click)="raiseComplaint()">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- (click)="this.complaintDescription = ''"
(click)="raiseComplaint()" -->