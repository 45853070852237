import { Component, OnInit, SkipSelf } from '@angular/core';
import { ReschedulerService } from '../rescheduler.service';
import { BehaviorSubject } from 'rxjs';
import { SLOT_DURATIONS, extractLessonDateTime, formatDateTimeAndDuration } from '@shared/models/scheduler';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';

@Component({
  selector: 'tr-lesson-rescheduler-confirmation',
  templateUrl: './lesson-rescheduler-confirmation.component.html',
  styleUrls: ['./lesson-rescheduler-confirmation.component.css']
})
export class LessonReschedulerConfirmationComponent extends TRObject implements OnInit {

  constructor(@SkipSelf() public reschedulerService: ReschedulerService,  
    public schedulerNextService : SchedulerNextService) {
    super();
  }
  lessonData$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  formatDateTimeAndDuration: Function = formatDateTimeAndDuration;

  extractLessonDateTime: Function = extractLessonDateTime;
  rescheduleLesson$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  ngOnInit(): void {
    this.lessonData$.next(this.reschedulerService.lessonSlots$);
    this.schedulerNextService.selectedSlots$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        console.log('data', data);
        this.rescheduleLesson$.next(this.mergeSlotsByLessonKey(data));
      }
    );
  }

  mergeSlotsByLessonKey(slots: any) {
    const mergedSlots: any = [];
    const mergedSlotMap: any = {};
    slots.forEach((slot: any) => {
      const { lessonKey, duration, ...rest } = slot;

      if (!lessonKey || !mergedSlotMap[lessonKey]) {
        mergedSlots.push({ lessonKey, duration: SLOT_DURATIONS.THIRTY_MINS, ...rest });
        mergedSlotMap[lessonKey] = mergedSlots[mergedSlots.length - 1];
      } else {
        mergedSlotMap[lessonKey].duration += SLOT_DURATIONS.THIRTY_MINS;
      }
    });

    return mergedSlots;
  }

  mapPermission(permission: string): string {

    switch (permission) {
      case LESSON_PERMISSIONS.CANCEL:
        return 'Cancel';
      case LESSON_PERMISSIONS.CANCEL_REQ:
        return 'Cancel request';
      case LESSON_PERMISSIONS.RESCHEDULE:
        return 'Reschedule';
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        return 'Request reschedule';
      case LESSON_PERMISSIONS.APPROVE:
        return 'Approve';
      case LESSON_PERMISSIONS.REJECT:
        return 'Reject';
      case LESSON_PERMISSIONS.ACCEPT:
        return 'Accept';      
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        return 'Give new time';
      default:
        return permission;
    }
  }



}