import { Component, OnInit } from '@angular/core';
import { TutorMyStudentsService } from './tutor-my-students.service';
import { ToastService } from "@components/shared-components/toast.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';


@Component({
  selector: 'tr-my-students',
  templateUrl: './my-students.component.html',
  styleUrls: ['./my-students.component.css']
})
export class MyStudentsComponent implements OnInit {
  currentPage: any = 1;
  perPage = 10;
  totalRows!: any;
  firstPage: any;
  lastPage: any
  isLoading:boolean = false;
  students:any;
  subjectsList:any
  savedParams: any = null;

  constructor(private service : TutorMyStudentsService, 
    private messageService:SendMessagePopupService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.getAllStudentsList();
    this.getSubjectsList();
  }

  getAllStudentsList(params?: any) {
    const lessonParams = params ? params : this.getParams();
    this.service
      .getStudentsList(lessonParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          if(res){
            this.students = res['data'];
            this.currentPage = res["meta"]["current_page"];
            this.firstPage = res["meta"]["from"];
            this.lastPage = res["meta"]["last_page"];
            this.totalRows = res["meta"]["total"];
            console.log(this.totalRows);
          }
        },
        (error: any) => {
          this.handleFailureResponse(error);
        }
      );
  }

  getSubjectsList(){
    this.service
      .getSubjects()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          if(res['data']){
             this.subjectsList = res['data'];
          }
        },
        (error: any) => {
          this.handleFailureResponse(error);
        }
      );

  }

  handleSuccessRessponse(res: any) {
    this.toastService.showSuccessToast('Success !', res);
  }

  handleFailureResponse(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422 || error.status === 404 || error.status === 500) {
        this.toastService.showErrorToast('Error!', error['error'].message);
      }
    } else {
      this.toastService.showErrorToast('Error!', error.message);
    }
  }

  changePageSize(data: any): void {
    if(data){
      if (data["target"] === "perPage") {
        this.perPage = data["value"];
        this.savedParams ? (this.savedParams.perPage = this.perPage) : null;
        this.currentPage = 1;
      } 
      else if (data["target"] === "page") {
        this.currentPage = data["value"];
        this.savedParams ? (this.savedParams.page = this.currentPage) : null;
      }
    }
    if (this.savedParams) {
        this.getAllStudentsList(this.savedParams);
    } else {
      this.getAllStudentsList();
    }
  }

  getFilters(filter: any) {
    const keys: any = Object.keys(filter);
    let params: any = this.getParams(filter);

    for (let key of keys) {
      let target = filter[key];
      params[target] = filter.data; //filter.name;
    }
    this.savedParams = this.getParams(params);
    this.getAllStudentsList(this.savedParams);
  }

  sendMessage(id:any , name:any, logo:any){
    let tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    this.messageService.sendUserDetails(tutorData);
  }

  getParams(params?: any): any {
    return (params = {
      perPage: this.perPage,
      page: this.currentPage,
      subjectId: params?.subjectId,
      userId:params?.userId,
      sortByType: params?.sortByType == 'Date DESC' ? 'DESC' : 'ASC',
      searchBy: params?.searchBy,
      lessonStatus: params?.lessonStatus,
      requestType: params?.requestType,
      requestStatus: params?.requestStatus,
    });
  }

}
