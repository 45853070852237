import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { TRObject } from "src/app/shared/models/shared";
import { FindTutorService } from "../find-tutor/find-tutor.service";
import { formatDate, DatePipe } from "@angular/common";
import * as moment from "moment";
import { AuthService } from 'src/app/core/auth/auth.service';
import { morningStart, morningEnd, afternoonStart, afternoonEnd, eveningStart, eveningEnd, nightStart, nightEnd } from "@shared/models/settings";


@Component({
  selector: "tr-tutor-scroll-display",
  templateUrl: "./tutor-scroll-display.component.html",
  styleUrls: ["./tutor-scroll-display.component.css"],
})
export class TutorScrollDisplayComponent extends TRObject implements OnInit {
  @Output() playVideoStatus = new EventEmitter();
  tutorData: any;
  timezoneId: any;
  timezone: any;
  tutorSlots: any;
  startOfWeekDate: any;
  nextSevenDays: any;
  endOfWeekDate: any;
  tutorVideoThumbnail:any;
  recordsByTime: any;
  weekDays: string[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];


  constructor(
    public findTutorService: FindTutorService,
    private datePipe: DatePipe,
    public auth: AuthService
  ) {
    super();
    // this.getCurrentDate();
    this.findTutorService.targetedTutorTile$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.tutorData = data;
        if(this.tutorData){
          this.setVideo(this.tutorData['videoLink']);
          this.timezoneId = this.tutorData['timezoneId'];
          this.tutorSlots = this.tutorData["slots"];
          // this.checkSlots(this.tutorSlots);
        }
      });
  }

  ngOnInit(): void {
    // this.findTutorService.getTimezones().pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((data: any) => {
    //        if(data){
    //          console.log(this.timezoneId, data['data']);
    //             for (const obj of data['data']) {
    //             if (obj.id == this.timezoneId) {
    //               this.timezone = obj;
    //               break; 
    //             }
    //           }
    //        }
    //   });
  }

    setVideo(videoLink:any){
     var link = videoLink;
     var removeEmbed = link.replace('embed/','');
     var videoid = removeEmbed.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        let videoId = videoid[1];
        var url = "https://www.youtube.com/embed/" + videoid[1];
        console.log(url);
        this.tutorVideoThumbnail = "https://img.youtube.com/vi/" + videoid[1] + "/0.jpg";
      }
   
  }



  // checkSlots(data: any) {
  //   if (data?.length > 0) {
  //     const recordsBetweenDates = data.filter((data: any) => {
  //       const recordDateTime = moment(data.dateTime, "YYYY-MM-DD HH:mm");
  //       return recordDateTime.isBetween(
  //         this.startOfWeekDate,
  //         this.endOfWeekDate,
  //         null,
  //         "[]"
  //       );
  //     });
  //     this.getGroupedRecords(recordsBetweenDates);
  //   }
  // }

  // filterRecordsByDay(records: any[], day: string): any[] {
  //   const filteredRecords = records.filter((record) => {
  //     const recordDate = new Date(record.dateTime);
  //     const recordDay = this.datePipe
  //       .transform(recordDate, "EEEE")
  //       ?.toLowerCase();
  //     console.log("recordDay:", recordDay);
  //     console.log("day.toLowerCase():", day.toLowerCase());
  //     return recordDay === day.toLowerCase();
  //   });
  //   console.log(day, filteredRecords);
  //   return filteredRecords;
  // }

  // getCurrentDate(): any {
  //   moment.updateLocale("en", {
  //     week: {
  //       dow: 1, // Monday is the first day of the week
  //     },
  //   });
  //   this.startOfWeekDate = moment().startOf("week");
  //   this.endOfWeekDate = moment().endOf("week");
  // }

  playVideo(){
    this.playVideoStatus.emit(true);
  }

  // getGroupedRecords(data: any) {
  //   // Group records by time range
  //   const groupedRecords: {
  //     morning: any[];
  //     afternoon: any[];
  //     evening: any[];
  //     night: any[];
  //   } = {
  //     morning: [],
  //     afternoon: [],
  //     evening: [],
  //     night: [],
  //   };

  //   // Filter and group records
  //   data.forEach((record: any) => {
  //     const recordDateTime = moment(record.dateTime, "YYYY-MM-DD HH:mm");
  //     const recordTime = recordDateTime.format("HH:mm");
  //     if (this.isTimeBetween(recordTime, morningStart, morningEnd)) {
  //       groupedRecords.morning.push(record);
  //     } else if (this.isTimeBetween(recordTime, afternoonStart, afternoonEnd)) {
  //       groupedRecords.afternoon.push(record);
  //     } else if (this.isTimeBetween(recordTime, eveningStart, eveningEnd)) {
  //       groupedRecords.evening.push(record);
  //     } else if (this.isTimeBetween(recordTime, nightStart, nightEnd)) {
  //       groupedRecords.night.push(record);
  //     }
  //   });
  //   this.recordsByTime = groupedRecords;
  //   this.getRecordsByDay(this.recordsByTime);
  //   console.log(this.recordsByTime);
  // }

  // isTimeBetween(time: string, startTime: string, endTime: string): boolean {
  //   return time >= startTime && time <= endTime;
  // }

  // getRecordsByDay(recordsBySectionAndDay: any) {

  //   // Display the records for each section and day
  //   for (const section in recordsBySectionAndDay) {
  //     if (
  //       Object.prototype.hasOwnProperty.call(recordsBySectionAndDay, section)
  //     ) {
  //       switch (section) {
  //         case "morning":
  //           console.log("Morning Records:");
  //           break;
  //         case "afternoon":
  //           console.log("Afternoon Records:");
  //           break;
  //         case "evening":
  //           console.log("Evening Records:");
  //           break;
  //         case "night":
  //           console.log("Night Records:");
  //           break;
  //         default:
  //           console.log("Invalid section");
  //           break;
  //       }

  //       for (let day = 0; day < 7; day++) {
  //         let dayRecords =
  //           recordsBySectionAndDay[
  //           section as keyof typeof recordsBySectionAndDay
  //           ];
  //         let dayRecordsFiltered = dayRecords.filter(
  //           (record: any) => new Date(record.dateTime).getDay() === day
  //         );

  //         switch (day) {
  //           case 0:
  //             console.log("Sunday Records:");
  //             break;
  //           case 1:
  //             console.log("Monday Records:");
  //             break;
  //           case 2:
  //             console.log("Tuesday Records:");
  //             break;
  //           case 3:
  //             console.log("Wednesday Records:");
  //             break;
  //           case 4:
  //             console.log("Thursday Records:");
  //             break;
  //           case 5:
  //             console.log("Friday Records:");
  //             break;
  //           case 6:
  //             console.log("Saturday Records:");
  //             break;
  //           default:
  //             console.log("Invalid day");
  //             break;
  //         }

  //         if (dayRecordsFiltered.length > 0) {
  //           dayRecordsFiltered.forEach((record: any) => {
  //             console.log(record);
  //           });
  //         } else {
  //           console.log("No records");
  //         }
  //       }
  //     }
  //   }
  // }
}
