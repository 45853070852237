import { Component, OnInit, Inject, Output, EventEmitter, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { timer, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from 'src/app/shared/models/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';
import { ConfirmationServiceService } from './confirmation.service.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-send-confirmation-code-popup',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SpinnerComponent],
  templateUrl: './send-confirmation-code-popup.component.html',
  styleUrls: ['./send-confirmation-code-popup.component.css'],
})

export class SendConfirmationCodePopupComponent extends TRObject implements OnInit{
  @ViewChild('openCodeConfirmationModal')
  openCodeConfirmationModal?: ElementRef;
  @ViewChild('closeCodeConfirmationModal')
  closeCodeConfirmationModal?: ElementRef;
  @Output() sendAgainEvent = new EventEmitter<void>();
  @Output() sendDataEvent = new EventEmitter<any>();
  verifyType: string = 'Email';
  verifyTypeValue: any;
  counter: any;
  tick = 1000;
  countDown!: Subscription;
  isLoading: boolean = false;
  isModalOpen: boolean = false;

  public codeConfirmationForm = this.fb.group({
    confirmCodeOne: ['', Validators.required],
    confirmCodeTwo: ['', Validators.required],
    confirmCodeThree: ['', Validators.required],
    confirmCodeFour: ['', Validators.required],
    confirmCodeFive: [''],
    confirmCodeSix: [''],
  });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(DOCUMENT) private document: Document,
    public service: ConfirmationServiceService,
    private responseHandlerService: ResponseHandlerService,
    private toastService: ToastService
  ) {
    super();
    this.detectTypeChanges();
    this.detectValueChanges();
    this.detectModalChanges();
  }

  ngOnInit(): void {}

  detectTypeChanges() {
    this.service.type$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((type: any) => {
        if (type) {
          this.verifyType = type;
        }
      });
  }

  detectValueChanges() {
    this.service.value$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: any) => {
        if (value) {
          this.verifyTypeValue = value;
        }
      });
  }

  detectModalChanges() {
    this.service.toggleModal$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: any) => {
        if (value) {
          this.toggleModalValue(value);
        }
    });
  }

  //start verification timer
  startVerificationCodeCounter() {
    this.counter = 60;
    this.countDown = timer(0, this.tick)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((t) => {
        --this.counter;
        if (this.counter == 0) {
          this.resetCountdown();
        }
    });
  }

  resetCountdown() {
    this.countDown.unsubscribe();
  }

  //remove data when popup close
  closeModalPopup() {
    this.resetCountdown();
    this.isModalOpen = false;
    this.codeConfirmationForm.reset();
    this.document.body.classList.add('tr-overflow-hidden');
  }

  //trigger when request to sendagain
  onSendAgainClick() {
    this.startVerificationCodeCounter();
    this.service.sendAgain$.next(this.verifyType);
  }

  onFormSubmit() {
    this.isLoading = true;
    if (this.verifyType === 'Email') {
      this.codeConfirmationForm.get('confirmCodeFive')?.setValue('');
      this.codeConfirmationForm.get('confirmCodeSix')?.setValue('');
    }

    if (this.codeConfirmationForm.valid) {
      let code = this.combineConfirmCodes(this.codeConfirmationForm.value);
      if (this.verifyType === 'Email') {
          this.updateEmail(code);
      } else{
          this.updateMobile(code);
      }
    } 
    else {
      this.isLoading = false;
      this.toastService.showErrorToast(
        'Error!',
        'Invalid please fill all Inputs'
      );
    }
  }

  updateEmail(code:any){
    this.service.updateEmail(this.verifyTypeValue, code).pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.codeConfirmationForm.reset();
          this.service.isVerified$.next(true);
          this.closeCodeConfirmationModal?.nativeElement.click();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }  

  updateMobile(code:any){
    this.service.updateMobile(this.verifyTypeValue, code).pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.codeConfirmationForm.reset();
          this.service.isVerified$.next(true);
          this.closeCodeConfirmationModal?.nativeElement.click();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  combineConfirmCodes(confirmCodes: { [key: string]: number }): string {
    const codeArray = Object.values(confirmCodes).map((code: number) =>
      code.toString()
    );
    const combinedCode = codeArray.join('');
    return `${combinedCode}`;
  }

  toggleModalValue(value: string) {

    if (value === 'open' && !this.isModalOpen) {
      this.isModalOpen = true;
      this.openCodeConfirmationModal?.nativeElement.click();
    } 

    else if(value === 'open' && this.isModalOpen){
      this.startVerificationCodeCounter();
    }

    else if (value === 'close') {
      this.isModalOpen = false;
      this.closeCodeConfirmationModal?.nativeElement.click();
    }
  }

  // Counter show double digit 00 instead of single digit 0
  formatCounter(counter: number): string {
    return counter < 10 ? `0${counter}` : counter.toString();
  }

  //goto next input if filled
  keytab(event: any) {
    const inputElement = event.srcElement as HTMLInputElement;
    const element = inputElement.nextElementSibling as HTMLInputElement; // Cast to HTMLInputElement to access the focus method

    if (event.key === 'Backspace') {
      if (inputElement.value.length === 0 && element) {
        // If the backspace key is pressed, and the current input is empty, focus on the previous input (if it exists)
        const previousInput = inputElement.previousElementSibling as HTMLInputElement;
        if (previousInput) {
          previousInput.focus();
        }
      }
    } else {
      const value = inputElement.value;
      if (value.length > 1) {
        // If more than one digit is entered, truncate it to the first digit
        inputElement.value = value.charAt(0);
      }

      if (value && element) {
        // If the current input is not empty, focus on the next input (if it exists)
        element.focus();
      }
    }
  }

}
