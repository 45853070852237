<div class="row p-0 m-0 p-4 tr-bg-highlight">
      <div class="d-flex align-items-center gap-2 p-0 col-3 position-relative tr-blur">
        <tr-skeleton-image></tr-skeleton-image>
        <tr-double-line></tr-double-line>
      </div>
      <div class="col-2 d-flex align-items-center position-relative px-0 justify-content-center tr-blur">
        <tr-double-line></tr-double-line>
      </div>
      <div class="col-2 d-flex align-items-center position-relative px-0 justify-content-center tr-blur">
         <tr-double-line></tr-double-line>
      </div>
      <div class="col-2 d-flex align-items-center position-relative px-0 justify-content-center tr-blur">
         <tr-double-line></tr-double-line>
      </div>
</div>