import { Component, OnInit } from "@angular/core";
import { ToastService } from "@components/shared-components/toast.service";
import { searchDropdownIcon } from "@shared/models/svgIcons";

@Component({
  selector: "tr-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit {
  showConversation:boolean = false;
  userConverstaion:any;
  usersLength:any;
  constructor(private toastService: ToastService) {}

  ngOnInit(): void {}

  toggleConversation($event:any){
   this.showConversation = $event;
  }

  getUsersLength(event:any){
    this.usersLength = event;
  }

}