import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { finalize } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { ResponseHandlerService } from '@components/shared-components/response-handler.service';

@Component({
  selector: 'tr-raise-complaint-popup',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './raise-complaint-popup.component.html',
  styleUrls: ['./raise-complaint-popup.component.css']
})

export class RaiseComplaintPopupComponent implements OnInit {
  complaintDescription:any;
  @ViewChild('closeModal') closeModal?: ElementRef;
  isLoading:boolean = false;

  constructor(private userLessonsService:UserLessonsService, private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {}

  raiseComplaint(){
    let id = this.userLessonsService.complaintLessonId$.value;
    if(id){
      let formData = new FormData();
      formData.append('lessonId', id);
      formData.append('description', this.complaintDescription);
      this.userLessonsService
        .raiseComplaint(formData)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (res: any) => {
            this.userLessonsService.complaintLessonId$.next(null);
            this.responseHandlerService.handleSuccessRessponse(res['message']);
            this.closeModal?.nativeElement.click();
          },
          (error: any) => {
            this.responseHandlerService.handleFailureResponse(error);
          }
        );
    }
  }

}
