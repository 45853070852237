import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  
  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  sendPasswordResetEmail(form:any){
    return this.fetchCore('forgot-password', form, HttpMethod.POST)
  }  

  resetPassword(form:any){
    return this.fetchCore('reset-password', form, HttpMethod.POST)
  }


}
