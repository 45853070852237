import { Component, OnInit } from '@angular/core';
import { TutorProfileService } from './tutor-profile.service';
import { AuthService } from '@core/auth/auth.service';
import { FormDataService } from '@core/form-data.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseHandlerService } from '@components/shared-components/response-handler.service';
import * as bootstrap from 'bootstrap';
import { BookLessonService } from '@components/student-components/student-tutors/book-lesson/book-lesson.service';
import { BOOKING_PHASES } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { TRObject, scrollTop } from '@shared/models/shared';


@Component({
  selector: 'tr-tutor-profile',
  templateUrl: './tutor-profile.component.html',
  styleUrls: ['./tutor-profile.component.css'],
})
export class TutorProfileComponent extends TRObject implements OnInit {
  userRole = this.authService.currentRole$.getValue();
  isLoading: boolean = false;
  timezoneId: any;
  tutorId: any;
  tutor: any;
  showPlayer: boolean = false;

  constructor(
    private tutorProfileService: TutorProfileService,
    private responseHandlerService: ResponseHandlerService,
    private activatedRoute: ActivatedRoute,
    public bookLessonService: BookLessonService,
    private formDataService: FormDataService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      if (id) {
        scrollTop();
        this.tutorId = id;
        this.checkUserLoggedIn();
        console.log(id);
      } else {
      }
    });
    this.detectBookingWindowChanges();
  }

  checkUserLoggedIn() {
    if (this.userRole === 'guest') {
      this.isLoading = true;
      this.formDataService
        .fetchDataAndUseTimezone()
        .then((data) => {
          this.timezoneId = data['id'];
          this.getUserProfile(this.timezoneId);
          this.tutorProfileService.getTutorsFirstLoad(this.timezoneId);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      this.getUserProfile();
      this.tutorProfileService.getTutorsFirstLoad();
    }
  }

  getUserProfile(timezoneId?: any) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('tutorId', this.tutorId);
    if (timezoneId) {
      formData.append('timezoneId', this.timezoneId);
    }
    this.tutorProfileService
      .getTutorProfile(formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          let tutor = res['data']['tutor'][0];
          if (tutor) {
            this.tutor = tutor;
            this.tutorProfileService.selectedTutorIdId$.next(this.tutor['id']);
          }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  detectBookingWindowChanges() {
    this.tutorProfileService.openBookingWindow$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tutor: any) => {
        if (tutor) {
          this.openBookingWindow(tutor);
        }
      });
  }

  playVideo() {
    const modalElement = document.getElementById('videoModal');
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
    this.showPlayer = true;
  }

  openBookingWindow(tutor: any) {
    let user = this.authService.user$.value;
    if (
      user.fname &&
      user.lname &&
      user.isEmailVerified === true &&
      user.isPhoneVerified === true
    ) {
      this.bookLessonService.bookingPhase$.next(
        BOOKING_PHASES.LESSON_TYPE_DETAILS
      );
    } else {
      this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.INCOMPLETE);
      const modalElement = document.getElementById(
        'openCompleteInformationModal'
      );
      if (modalElement) {
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }
    }
    this.bookLessonService.openBookingWindow$.next(true);
    this.bookLessonService.enableDisablePageScroll('hidden');
    this.bookLessonService.selectedTutor$.next(tutor);
  }
}
