// Module
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from '@modules/shared.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TrCoreModule } from '@modules/tr-core.module';
import { StudentTutorsModule } from '@modules/student-tutor.module';
import { CropModule } from '@modules/crop.module';
import { StudentModule } from '@modules/student.module';
import { FormNotificationModule } from '@modules/form-notification.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentSettingsModule } from '@modules/student-settings.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddDetailsPopupModule } from '@modules/add-details-popup.module';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslationHttpLoader } from "@shared/models/translation-http-loader";
import { NgxStripeModule } from 'ngx-stripe';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';


// Components - The below components are loaded before the login
import { AppComponent } from './app.component';
import { LoginComponent } from '@components/app-components/login-registration/login/login.component';
import { LogoutComponent } from '@components/app-components/login-registration/logout/logout.component';
import { LandingPageComponent } from '@components/landing-page/landing-page.component';
import { RegisterWithComponent } from '@components/app-components/login-registration/register-with/register-with.component';
import { TutorRegisterComponent } from '@components/app-components/login-registration/tutor-registration/tutor-register.component'
import { StudentRegisterComponent } from '@components/app-components/login-registration/student-registration/student-register.component';
import { LandingPageHeaderComponent } from '@components/landing-page/landing-page-header/landing-page-header.component'
import { LandingPageFooterComponent } from '@components/landing-page-footer/landing-page-footer.component';
import { LandingPageSectionComponent } from '@components/landing-page/landing-page-section/landing-page-section.component'
import { TutorRegSavePhaseOneComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-one/tutor-reg-save-phase-one.component';
import { TutorRegSavePhaseTwoComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-two/tutor-reg-save-phase-two.component';
import { TutorCodeConfirmationComponent } from '@components/app-components/login-registration/tutor-code-confirmation/tutor-code-confirmation.component';
import { TutorRegSavePhaseThreeComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-three/tutor-reg-save-phase-three.component';
import { TutorRegistrationFormHeaderComponent } from '@components/app-components/login-registration/tutor-registration-form-header/tutor-registration-form-header.component';
import { TutorRegSavePhaseFourComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-four/tutor-reg-save-phase-four.component';
import { TutorRegSavePhaseFiveComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-five/tutor-reg-save-phase-five.component';
import { TutorRegSavePhaseSixComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-six/tutor-reg-save-phase-six.component';
import { TutorRegUnderReviewComponent } from '@components/app-components/login-registration/tutor-reg-under-review/tutor-reg-under-review.component';
import { ForgotPasswordComponent } from '@components/app-components/login-registration/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@components/app-components/login-registration/reset-password/reset-password.component';


//Pipes
import { FormatTimePipe } from '../shared/pipes/format-time.pipe';

//Services
import { TrHttpService } from '../core/tr-http.service';
import { AuthService } from '../core/auth/auth.service';
import { AuthGuard } from '../core/auth/auth.guard';
import { RoleGuard } from '@auth/role.guard';
import { FormDataService } from '../core/form-data.service';
import { AuthInterceptor } from '@auth/auth.interceptor';

// Config
import { Google_Client_ID } from '../shared/models/shared';
import { Stripe_Publishable_Key } from '@shared/models/shared';
//Temp
import { TokenIntercept } from '../core/auth/tokenintercept';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FakeBackendInterceptor } from '../shared/fakebackend';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SchedulerNextModule } from '../components/scheduler-next/scheduler-next.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    LandingPageComponent,
    RegisterWithComponent,
    TutorRegisterComponent,
    StudentRegisterComponent,
    LandingPageHeaderComponent,
    LandingPageFooterComponent,
    LandingPageSectionComponent,
    TutorRegSavePhaseOneComponent,
    TutorRegSavePhaseTwoComponent,
    TutorCodeConfirmationComponent,
    TutorRegSavePhaseFourComponent,
    TutorRegSavePhaseThreeComponent,
    TutorRegSavePhaseFiveComponent,
    TutorRegSavePhaseSixComponent,
    TutorRegUnderReviewComponent,
    TutorRegistrationFormHeaderComponent,
    FormatTimePipe,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    SpinnerComponent,
    HttpClientModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    RouterModule,
    SocialLoginModule,
    TrCoreModule,
    SharedModule,
    CropModule,
    AddDetailsPopupModule,
    FormNotificationModule,
    ImageCropperModule,
    NgxStripeModule.forRoot(Stripe_Publishable_Key),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      isolate: true
    }),
    SchedulerNextModule,
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    AuthService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(Google_Client_ID),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('685629163091540')
          },
        ],
      } as SocialAuthServiceConfig,
    },
    FormDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
