import { Component, OnInit, SkipSelf } from '@angular/core';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { timer, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { TRObject } from 'src/app/shared/models/shared';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Phases } from 'src/app/shared/models/shared';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { AuthService } from '@auth/auth.service';
@Component({
  selector: 'tr-tutor-code-confirmation',
  templateUrl: './tutor-code-confirmation.component.html',
  styleUrls: ['./tutor-code-confirmation.component.css'],
})


export class TutorCodeConfirmationComponent extends TRObject implements OnInit {

  constructor(private tutorRegPhasesService: TutorRegPhasesService, 
    private responseHandlerService: ResponseHandlerService, 
    @SkipSelf() private authService: AuthService, 
    private fb: UntypedFormBuilder) {
    super()
  }
  isLoading:boolean = false;
  counter = 60;
  tick = 1000;
  countDown!: Subscription;
  email:any;

  public codeConfirmationForm = this.fb.group({
    confirmCodeOne: ['', Validators.required],
    confirmCodeTwo: ['', Validators.required],
    confirmCodeThree: ['', Validators.required],
    confirmCodeFour: ['', Validators.required],
  });

  ngOnInit(): void {
    this.startVerificationCodeCounter();
    this.authService.userEmail$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((email: any) => {
        if (email) {
           this.email = email;
        }
    });
  }

  nextPhase() {
    this.isLoading = true;
    let emailCode = this.parseCode(this.codeConfirmationForm.value)
    this.tutorRegPhasesService.confirmCode(this.email ,emailCode).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (success: any) => {
        this.tutorRegPhasesService.getPhaseTwoFormData().subscribe(
          (res: any) => {
            this.tutorRegPhasesService.setPhase(Phases.PHASE_TWO);
            this.tutorRegPhasesService.phaseTwoFormData$.next(res.data)
          }
        )
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

  parseCode(codeForm: any) {
    let code: string = ''
    for (let key in codeForm) {
      code += codeForm[key];
    }
    return code;
  }

  resendCode() {
    this.tutorRegPhasesService.resendCode().subscribe(
      (data: any) => {
        this.responseHandlerService.handleSuccessRessponse(data.message);
      },
    (error:any)=>{
      this.responseHandlerService.handleFailureResponse(error);
    }
      
    )
  }

  startVerificationCodeCounter() {
    this.countDown = timer(0, this.tick).pipe(takeUntil(this.ngUnsubscribe)).subscribe(t => {
        --this.counter;
        if(this.counter == 0){
          this.countDown.unsubscribe();
        }
      });
    }

  keytab(event: any) {
    let element = event.srcElement.nextElementSibling; // get the sibling element
    let value = event.target.value;
    if (value) {
      if (element == null)  // check if its null
        return;
      else
        element.focus();   // focus if not null
    }
  }

}