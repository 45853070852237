<div class="container-fluid p-0 m-0 ">
    <div class="row p-0 m-0 d-flex align-items-center justify-content-center">
      <div class="col-md-12 d-flex flex-column justify-content-center px-4 pt-3 p-0">
        <tr-find-tutor-filter-panel (filters)="getFilters($event)" class="tr-find-tutor-filter-panel "></tr-find-tutor-filter-panel>
           <div class="row p-0 m-0 ">
               <tr-toggle-display [tutorsCount]="allTutorsCount"></tr-toggle-display>
           </div>
           <ng-container *ngIf="isLoading">
            <div class="row p-0 m-0">
                <div class="col-lg-8 d-flex flex-column gap-3">
                    <tr-find-tutor-skeleton-loader></tr-find-tutor-skeleton-loader>
                    <tr-find-tutor-skeleton-loader></tr-find-tutor-skeleton-loader>
                    <tr-find-tutor-skeleton-loader></tr-find-tutor-skeleton-loader>
                </div>
            </div>
           </ng-container>
            <ng-container *ngIf="!isLoading">
            <div class="row p-0 m-0" id="tutorTile" *ngIf="tutors && tutors.length > 0">
                <div class="col-lg-8 px-0" [ngClass]="{'col-lg-12 d-flex flex-wrap justify-content-between': !(isListView|async)}">
                    <ng-container *ngFor="let tutor of tutors; let i = index">
                <tr-tutor-tile
                  #tutorTiles
                  class="tr-tutor-tile"
                  (mouseenter)="getTutorOnHover(tutor, i)"
                  id="trTutorTile_{{tutor.id}}"
                  [tutor]="tutor"
                  [role]="userRole"
                  [ngClass]="isFilterLoading ? 'tr-opacity-true' : 'tr-opacity-false'"
                  [ngClass]="(this.findTutorService.isListView$ | async) ? 'tr-is-list-view' : 'tr-is-grid-view'"
                ></tr-tutor-tile>

                    </ng-container>
                </div>
                <div class="col-lg-4 d-none d-lg-flex position-relative pb-5" [ngClass]="{'d-lg-none': !(isListView|async)}" id="tr-scroll-display">
                    <tr-tutor-scroll-display [ngStyle]="{'min-width.%': 100}" (playVideoStatus)="playVideo()" [ngClass]="isFilterLoading ? 'tr-opacity-true' : 'tr-opacity-false'"></tr-tutor-scroll-display>
                </div> 
                <div class="col-12 px-0 pb-3 d-flex align-items-center justify-content-center" *ngIf="showLoader">
                  <div class="tr-loader-sec">
                     <div class="clearfix">
                      <div class="spinner-border float-end" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>    
                </div>
             </div>
             <div class="row p-0 m-0 tr-bg-highlight py-4 mt-4" *ngIf="tutors && tutors.length == 0">
                <div class="col-md-12 d-flex align-items-center flex-column justify-content-center pt-2">
                    <img src="assets/images/no-tutors-search.svg" alt="saved-tutors-img" class="tr-saved-tutors-img">
                    <p class="tr-no-tutor-title mt-3">
                        Therer’s no Result found
                    </p>
                </div>
            </div>
           </ng-container>
         </div>
      </div>
</div>
<div class="tr-middle-screen " id="trFindTutorCrossScreen"></div>
<ng-container *ngIf="targetedTutor">
    <tr-video-player [videoLink]="targetedTutor.videoLink | safe" [showPlayer]="showPlayer" (onClosePlayer)="this.showPlayer = false"></tr-video-player>
</ng-container>
