<h4 class="tr-sub-title-dark-text tr-font-18 mb-3 p-0">You might also like</h4>
<section class="d-flex gap-18 tr-overflow">
	<div class="tr-tutor p-4 tr-bg-highlight d-flex align-items-center justify-content-center flex-column" *ngFor="let tutor of tutors">
		<div class="d-flex flex-column align-items-center position-relative cursor-pointer" [routerLink]="(auth.currentRole$ | async) !== 'guest' ? '/student/tutor/' + tutor.id : '/find-tutor/tutor/' + tutor.id">
			<img src="assets/images/profile-placeholder.png" alt="placeholder-image" class="tr-tutor-img" *ngIf="!tutor.profilePicture">
			<img [src]="tutor.profilePicture" alt="tutor-image" class="tr-tutor-img" *ngIf="tutor.profilePicture">
			<span class="tr-top-tutor-tag px-2 py-1" *ngIf="tutor?.topTutor">Top tutor</span>
		</div>
		<div class="mt-4">
			<div class="d-flex align-items-center justify-content-center gap-3">
				<span class="tr-tutor-name cursor-pointer" [routerLink]="(auth.currentRole$ | async) !== 'guest' ? '/student/tutor/' + tutor.id : '/find-tutor/tutor/' + tutor.id">{{ tutor.firstName}}.</span>
				<span class="fi fi-{{tutor.flag}}"></span>
			</div>
			<div class="d-flex gap-4 mt-1 align-items-center justify-content-center">
				<span class="tr-sub-title">Certified tutor</span>
				<div class="d-flex align-items-center"> 
                   <img src="assets/icons/rate.svg" alt="rate-star" class="tr-tutor-star me-1"> 
                    <span class="tr-rate"> {{tutor.averageRate}}  </span>
                </div>
			</div>
			<div class="d-flex align-items-center gap-4 mt-1">
				<div class="d-flex align-items-center">
	               <span class="me-0 tr-tutor-book-svg d-flex align-items-center">
	                  <tr-svg [path]="lessonBookPath"></tr-svg>
	               </span>
	               <div class="d-flex align-items-center flex-wrap">
	                  <span class="me-1 tr-blod-text-md">
	                  {{tutor?.subjects[0].subjectName}}
	                  </span>
	               </div>
	            </div>
	            <div class="d-flex align-items-center gap-2">
	            	<span class="tr-sub-title">Speaks : </span>
	            	  <span class="tr-blod-text-md d-flex align-items-center gap-2">{{ tutor?.spokenLanguages[0].languageName }} 
	            	  	<span class="tr-lang-tag">({{ tutor?.spokenLanguages[0].levelName }})</span>
                      </span>
	            </div>
			</div>
			<div class="mt-3">
				 <p class="mb-0 tr-font-12 text-start text-md-center">
			<span class="tr-tutor-name"> <span trCurrency class="tr-tutor-name"></span> {{tutor.pricePerHour}} </span>/ hr</p>
			</div>
			<div class="mt-3 d-flex align-items-center justify-content-center">
				     <button class="tr-btn-primary tr-btn" (click)="openBookingWindow(tutor)">
                          <ng-container *ngIf="(this.auth.user$ | async)?.isTrialAvailable && !tutor?.hadLesson; else elseBlock">
                            Book trial lesson
                          </ng-container>
                          <ng-template #elseBlock>
                            Book a lesson
                          </ng-template>
                        </button>
			</div>
		</div>
	</div>
	
</section>