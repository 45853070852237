import { Component, OnInit, Input } from '@angular/core';
import { gridDisplayPath, listDisplayPath, sortByFilterIcon } from 'src/app/shared/models/svgIcons';
import { FindTutorService } from '../find-tutor/find-tutor.service';
@Component({
  selector: 'tr-toggle-display',
  templateUrl: './toggle-display.component.html',
  styleUrls: ['./toggle-display.component.css']
})
export class ToggleDisplayComponent implements OnInit {
  selectedCheckData: any;
  selectedUnCheckData: any;
  targetList: string = 'sortBy';
  gridDisplayPath: string = gridDisplayPath;
  listDisplayPath: string = listDisplayPath;
  sortByFilterIcon: string = sortByFilterIcon;
  @Input() title!: string
  @Input() tutorsCount: number = 0;
  isListView: any;
  findTutorByFilter: any;

  constructor(private findTutorService: FindTutorService) {
    this.isListView = this.findTutorService.isListView$
  }

  ngOnInit(): void {
    this.setFindTutorByFilterOptions();
  }

  toggleView() {
    this.isListView = !this.isListView;
    this.findTutorService.toggleTutorListDisplay(this.isListView);
  }

  setFindTutorByFilterOptions() {
    this.findTutorByFilter = [{ id: 1, name: 'Top tutors', code: 'topTutors' },
    { id: 2, name: 'Best rating', code: 'rating' },
    { id: 3, name: 'Price ( Highest first )', code: 'priceHigh' },
    { id: 4, name: 'Price ( lowest first )', code: 'priceLow' },
    { id: 5, name: 'Number of reviews', code: 'reviews' }
    ];
  }

  // setSelectedData(data: any, event:any) {
  //   if(event){
  //     let isCheck = event.target['checked'];
  //     let selectedData = { "data": data, "targetList": this.targetList, "type" : isCheck }
  //     if(isCheck){
  //       this.selectedCheckData = selectedData;
  //       console.log(this.selectedCheckData);
  //       this.findTutorService.setSortByFilter(this.selectedCheckData);
  //     }
  //     else{
  //       this.selectedUnCheckData = selectedData;
  //       this.findTutorService.setSortByFilter(this.selectedUnCheckData);
  //     }
  //   }
  // }

}
