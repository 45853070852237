import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'tr-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})

export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // setInterval(() => {
    //   this.router.navigate(['/login']);
    //   this.auth.isLoggedIn.next(false);
    //   this.auth.isLoggedIn.complete();
    // });
  }
}
