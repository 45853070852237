<div class="py-4">
   <section class="d-flex align-items-center justify-content-between pb-3 px-4">
      <h4 class="tr-tile mb-0">Latest messages</h4>
      <div class="d-flex align-items-center gap-3 cursor-pointer tr-navigate-link" routerLink="/student/chat">
         <span class="tr-view-more"> view more</span>
         <img src="assets/icons/arrow-right-black.svg" alt="back-btn-img">
      </div>
   </section>
   <section class="d-flex flex-column">
      <ng-container *ngFor="let message of latestMessages">
         <div class="py-3 px-4 d-flex justify-content-between align-items-start gap-3 tr-hover" (click)="goToChat(message.id)">
            <div class="tr-tutor-details d-flex align-items-start gap-3">
               <img src="{{ message.participants[0]['user']['avatar']}}" alt="tutor-img" *ngIf="message.participants[0]['user']['avatar']">
               <span class="tr-dummy-image-sm d-flex align-items-center justify-content-center"  *ngIf="!message.participants[0]['user']['avatar']"> {{ message.participants[0]['user']['fname'].charAt(0) }}</span>
               <div class="tr-message-details">
                  <p class="mb-1">{{ message.participants[0]['user']['fname']}}</p>
                  <span>{{ message['latest_message']['body']}}</span>
               </div>
            </div>
            <div class="d-flex flex-column gap-1 align-items-center">
               <span class="tr-text-lite"> {{ formatTime( message['created_at']) }}</span>
               <span class="tr-count text-center d-flex align-items-center justify-content-center">{{ message.unread_count }}</span>
            </div>
         </div>
      </ng-container>
      <section *ngIf="latestMessages && latestMessages.length == 0" class="d-flex align-items-center justify-content-center tr-no-chat-sec flex-column gap-2 mt-4">
         <div class="d-flex align-items-center justify-content-center no-user">
            <img src="assets/images/no-chat.svg" alt="no-chat" class="tr-no-chat-img">
         </div>
         <div class="rounded-pill">
            <span class="tr-sub-title-lite-text tr-font-12">There is no Messages yet</span>
         </div>
      </section>
   </section>
</div>