import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { parseJson } from '@shared/models/shared';

@Component({
  selector: 'tr-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.css']
})
export class SavedCardsComponent implements OnInit {
  
  _savedCards: any;
  parseJson: Function = parseJson;
  @Output() cardDetails: EventEmitter<any> = new EventEmitter<any>();
  
  @Input()
  set savedCards(val: any) {
    this._savedCards = val;
  }  

  @Input()
  set cards(val: any) {
    this.toggleCardSelection(val);
  }

  selectedCard: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  get chosenCard(): any {
    return this.selectedCard;
  }

  set chosenCard(value: any) {
    this.selectedCard = value;
  }

  toggleCardSelection(card: any) {
    if (this.selectedCard === card) {
        this.chosenCard = null;
      } else {
        this.selectedCard = card;
      }
    this.cardDetails.emit(this.selectedCard);
  }

}