<div class="btn-group">
  <button class="btn btn-secondary dropdown-toggle tr-filter-bottom-select" type="button" [id]="id" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
    {{ title }}
    <span class="tr-arrow-svg ms-1">
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path [attr.d]="arrowDownPath"/>
        </svg>
    </span> 
  </button>
  <ul class="dropdown-menu tr-country-menu p-4" aria-labelledby="custom_dropdown" [ngSwitch]="type">
    <div *ngSwitchDefault>
      <div class="position-relative tr-filter-drop-search-div">
        <input type="search" class="form-control tr-filter-drop-search " name="search" [(ngModel)]="searchText" placeholder="Search">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!searchText">
          <path [attr.d]="searchDropdownIcon" fill="#444444" />
        </svg>
      </div>

      <div class="mb-4" *ngIf="preferredMenu">
        <p class="mb-3 tr-dropdown-title">Popular Countries</p>
        <div class="tr-dropdown-countries-list d-flex align-items-center justify-content-start flex-wrap">
          <span class="p-2">Egypt</span>
          <span class="p-2">Australia</span>
        </div>
      </div>
      <div class="position-relative tr-dropdown-sub-menu" id="dropDownMenu">
      <div class="tr-dropdown-countries">
        <div class="d-flex align-items-center justify-content-between gap-3 mb-3">
          <p class="mb-0 tr-dropdown-title" *ngIf="menuTitle">{{menuTitle}}</p>
          <span class="tr-clear cursor-pointer" (click)="clearAll(targetList)">clear</span>
        </div>
        <div class="tr-countries-drop-list" id="tr-target-{{targetList}}" #checkboxContainer>
          <ng-container *ngIf="!isCurrency">
            <div class="d-flex align-items-center" *ngFor="let value of dropDownMenuValue | async">
              <div class="form-group" *ngIf="searchText === '' || value.name.toLowerCase().includes(searchText | lowercase)">
                <input type="{{ isCheckbox ? 'checkbox' : 'radio' }}" id="{{id+value.name}}" (change)="setSelectedData(value.id,value.name,$event)" [value]="value.id" name="tr-radio">
                <label for="{{id+value.name}}"> {{ value.name }}</label>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isCurrency">
            <ng-container *ngFor="let value of dropDownMenuValue | async">
              <div class="d-flex align-items-center tr-currency-dropdown position-relative" [ngClass]="{'tr-active': currencyCode === value.code}" (click)="setSelectedData(value.code,'', '')">
                <div *ngIf="searchText === '' || value.code.toLowerCase().includes(searchText | lowercase)" class="d-flex align-items-center">
                  <label class="py-2 px-4"> {{ value.code }}</label>
                  <div>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path [attr.d]="activeCheckIcon" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
        
      </div>
    </div>
    <div class="tr-dropdown-slider" *ngSwitchCase="'slider'">
      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (userChangeEnd)="valueChange(minValue,maxValue)"></ngx-slider>
    </div>
  </ul>
</div>
