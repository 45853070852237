import { Component, OnInit, SkipSelf } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";

@Component({
  selector: 'tr-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
})

export class AppLayoutComponent implements OnInit {
  isLoading:boolean = false;
  constructor(@SkipSelf() public auth: AuthService, 
    private responseHandlerService: ResponseHandlerService,
    private activatedRoute: ActivatedRoute) {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   const token = params['token'];
    //   if (token) {
    //     console.log(token);
    //     this.isLoading = true;
    //       this.auth
    //       .getLoginToken(token)
    //       .pipe(finalize(() => (this.isLoading = false)))
    //       .subscribe(
    //         (res: any) => {
    //           this.responseHandlerService.handleSuccessRessponse('Successful login');
    //         },
    //         (error: any) => {
    //           this.responseHandlerService.handleFailureResponse(error);
    //         }
    //       );
    //     }
    // });
    this.activatedRoute.queryParams.subscribe(params => {
        const token = params['token'];
        if (token) {
          this.isLoading = true;
          this.auth
            .getTokenResponse(token)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
              (res: any) => {
                this.auth.setLoginResponse(res);
                // Get the current URL from the browser
                const currentUrl = window.location.href;
                if (res && res.data.user.active_role === "student") {
                  const updatedUrl = currentUrl.replace(/\/tutor/, '/student');
                  const updatedUrlWithoutToken = updatedUrl.replace(/(\?|\&)token=.*$/, '');
                  // Navigate to the updated URL
                  window.location.href = updatedUrlWithoutToken;
                } else if (res && res.data.user.active_role === "tutor") {
                  const updatedUrl = currentUrl.replace(/\/student/, '/tutor');
                  const updatedUrlWithoutToken = updatedUrl.replace(/(\?|\&)token=.*$/, '');
                  // Navigate to the updated URL
                  window.location.href = updatedUrlWithoutToken;
                }
                this.responseHandlerService.handleSuccessRessponse('Successful login');
              },
              (error: any) => {
                this.responseHandlerService.handleFailureResponse(error);
              }
            );
          }
      });

  }

  ngOnInit(): void {
    let root = document.querySelector<HTMLElement>(':root');
    let fontSize = localStorage.getItem('fontSize');
    if (root) {
      let gcs = getComputedStyle(root);
      let font = gcs.getPropertyValue('--font-size');
      if (fontSize) {
        root.style.setProperty('--font-size', fontSize);
      }
    }

    let theme = localStorage.getItem('theme');
    if (theme) {
      this.changeTheme(theme);
    }
  }

  public changeTheme(theme: string) {
    if (document) {
      localStorage.setItem('theme', theme);
      let body = document.getElementsByTagName('body')[0];
      if (theme == 'dark') {
        body.classList.add(theme);
      } else {
        body.classList.remove('dark');
      }
    }
  }
}
