import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'tr-custom-grid-view',
  templateUrl: './custom-grid-view.component.html',
  styleUrls: ['./custom-grid-view.component.css']
})
export class CustomGridViewComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement!: DataTableDirective;
  
  @Input()
  columnNames!: any; 

  @Input()
  tableData!: any; 

  @Input()
  options!: any; 

  @Input() dtOptions: DataTables.Settings = {};
  
  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
     this.dtTrigger.next();
 }

 ngOnDestroy(): void {
  if (this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the DataTable instance
        dtInstance.destroy();
      });
    }
  }

}



