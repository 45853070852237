import { Component, OnInit, ViewChild } from '@angular/core';
import { validatePassword, passRules, formValidationMessages } from 'src/app/shared/models/validation';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Phases } from '@shared/models/shared';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { FormDataService } from '@core/form-data.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'tr-tutor-reg-save-phase-one',
  templateUrl: './tutor-reg-save-phase-one.component.html',
  styleUrls: ['./tutor-reg-save-phase-one.component.css']
})
export class TutorRegSavePhaseOneComponent implements OnInit {
 isLoading:boolean = false;
 referralCode:any;
  public errorMessages= {
    email :formValidationMessages['email'],
  }

  constructor(private auth: AuthService, 
    private formDataService:FormDataService, 
    private toastService: ToastService, 
    private activatedRoute: ActivatedRoute,
    private tutorRegPhasesService: TutorRegPhasesService) {
      this.activatedRoute.queryParams.subscribe(params => {
        const code = params['ref'];
        if (code) {
          this.referralCode = code;
        }
      });

     }
     phaseOneForm!: UntypedFormGroup;

  @ViewChild('password') password: any;
  passRules: any = passRules;
  isPassValid: boolean = false;
  passValidationStatuses: any;
  showPassword: boolean = false;

  ngOnInit(): void {
    let code = sessionStorage.getItem('refCode');
    if(code){
      this.referralCode = code;
    }
    this.auth.clearData();
    this.initForm();
    validatePassword('')// rest validation by passing empty string
  }

  nextPhase() {
    this.tutorRegPhasesService.setPhase(Phases.CODE_CONFIRMATION);
  }

  getPass(password: string) {
    this.isPassValid = validatePassword(password)['isValid'];
    this.passValidationStatuses = validatePassword(password)['validationStatuses'];
  }

  registerPhaseOne() {
    this.isLoading = true;
    if(this.phaseOneForm.valid){
       this.formDataService.fetchDataAndUseTimezone()
       .then((data) => {
        let timezoneID = data['id'];
          if(this.referralCode){
           this.phaseOneForm.patchValue({ referral_code: this.referralCode }); 
          }
         this.auth.tutorRegisterPhaseOne(this.phaseOneForm.value, timezoneID).pipe(finalize(() => (this.isLoading = false))).subscribe(
          (data: any) => {
            this.handleRegPhaseOneSuccess(data['message']);
          },
          (error: any) => {
            this.handleResponseException(error);
          }
        )
      })
      .catch((error) => {
        this.toastService.showErrorToast('Error!', 'Timezone not found !!');
      });
    }
  }

  handleRegPhaseOneSuccess(res:any) {
    this.nextPhase();
    this.toastService.showSuccessToast('Success !', res);
  }

  handleRegPhaseOneFailure(res:any) {
    this.toastService.showErrorToast('Error!', res);
  }

  private initForm() {
    this.phaseOneForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', Validators.required),
      referral_code: new UntypedFormControl(''),
    });
  }

  public handleResponseException(error:any){
    if(error instanceof HttpErrorResponse){
      if(error.status === 422){
        const validationErrors = error.error['errors'];
          Object.keys(validationErrors).forEach(prop => {
          const formControl = this.phaseOneForm.get(prop);
          this.handleRegPhaseOneFailure(validationErrors[prop]); 
          if (formControl) {
              formControl.setErrors({
              serverError: validationErrors[prop]
            });
          }
        });
      }
    }
    else{
      this.handleRegPhaseOneFailure(error.message); 
    }
  }


}