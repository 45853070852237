<section class="tr-mh-100">
  <div [ngClass]="totalLessons > 0 ? 'p-0' : 'p-4'">
    <section *ngIf="totalLessons == 0 || !totalLessons">
      <h2 class="mb-4 tr-title">Next lesson</h2>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <img src="assets/images/no-lessons.svg" alt="no-lessons">
        <p class="tr-text-lite my-4">There is no booked lessons</p>
        <button class="tr-find-tutor-btn px-3 py-2 tr-btn" routerLink="/student/tutors/find-tutor">Find a tutor</button>
      </div>
    </section>
    <section *ngIf="totalLessons > 0">
      <section>
        <div class="tr-next-lesson-details">
          <div id="carouselExampleControls" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
            <div class="carousel-inner">
              <div class="carousel-item" data-bs-interval="false" *ngFor="let lesson of lessons;let isFirst = first" [class.active]="isFirst">
                  <div class="d-flex align-items-center justify-content-between p-4">
                   <h3 class="tr-title">Next lesson</h3>
                      <p class="tr-time-duration mb-0" *ngIf="getDaysUntilLesson(lesson.lessontime) > 0">
                      after <span>{{ getDaysUntilLesson(lesson.lessontime) }} days</span>
                    </p>
                  </div>
                <section class="row p-0 m-0 justify-content-center mh-500">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="d-flex align-items-center justify-content-center tr-pb-60 tr-center me-4">
                      <div class="tr-date pe-4 py-1 tr-bdb text-center">
                        <div class="tr-booking d-flex flex-column">
                          <span class="tr-booked-month text-center">{{ lesson.lessontime | date : 'MMM'}}</span>
                          <span class="tr-booked-date d-flex align-items-center justify-content-center m-auto">{{ lesson.lessontime | date : 'd'}}</span>
                        </div>
                        <span class="tr-booked-day mt-2">{{ lesson.lessontime | date : 'EEEE'}}</span>
                      </div>
                      <div class="d-flex align-items-start gap-3 ps-4">
                        <img src="assets/icons/schedule.svg" alt="schedule-icon">
                        <div class="tr-booking-time d-flex gap-2 flex-column">
                          <ng-container [ngSwitch]="lesson.lessonPeriod">
                            <ng-container *ngSwitchCase="'30'">
                              <span>{{ lesson.lessontime | date: 'hh:mm a' | lowercase }} - {{ lesson.lessontime | addTime:30 | date: 'hh:mm a' | lowercase}}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'60'">
                              <span>{{ lesson.lessontime | date: 'hh:mm a' | lowercase }} - {{ lesson.lessontime | addTime:60 | date: 'hh:mm a' | lowercase }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'90'">
                              <span>{{ lesson.lessontime | date: 'hh:mm a' | lowercase }} - {{ lesson.lessontime | addTime:90 | date: 'hh:mm a' | lowercase }}</span>
                            </ng-container>
                            <ng-container *ngSwitchDefault>Default case</ng-container>
                          </ng-container>
                          <p class="mb-0"> 
                            {{ lesson.subjectName }}
                            <span>with</span> 
                            <ng-container *ngIf="userType == 'student'">
                              {{ lesson.tutorName | truncate: 10  }} 
                              <img src="assets/icons/mode_comment.svg" alt="comment-icon" class="ms-2 cursor-pointer" (click)="sendMessage(lesson.tutorId, lesson.tutorName, lesson.tutorImage)">
                            </ng-container>                           
                             <ng-container *ngIf="userType == 'tutor'">
                              {{ lesson.studentName | truncate: 10  }} 
                              <img src="assets/icons/mode_comment.svg" alt="comment-icon" class="ms-2 cursor-pointer" (click)="sendMessage(lesson.studentId, lesson.studentName, lesson.studentImage)">
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>
              
                    <button class="tr-bg-none" (click)="navigateLessonBoard(lesson.id)">
                      <img src="assets/icons/arrow-right.svg" alt="">
                    </button>
                  </div>
                </section>
                <div class="row p-0 m-0">
                  <div class="col-12 p-4 m-0 tr-bg-grey d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column gap-2">
                      <span class="tr-text-lite">Communication tool</span>
                      <span class="tr-text-dark">Tutorreach classroom</span>
                    </div>
                    <div>
                      <span class="tr-list-view-svg" type="button" dropdown-toggle data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path [attr.d]="optionsIcon" />
                        </svg>
                         <ul class="dropdown-menu tr-dropdown-menu p-3" aria-labelledby="custom_dropdown">
                          <div class="d-flex flex-column">
                            <ng-container *ngFor="let permission of lesson?.premissions ">
                              <div class="tr-dropdown-options" (click)="getLessonAction(permission,lesson)">
                                <li class="d-flex align-items-center p-2 gap-2" class="{{permission}}">
                                        <ng-container *ngIf="(permission == LESSON_PERMISSIONS.CANCEL || permission == LESSON_PERMISSIONS.CANCEL_REQ)">
                                        <tr-svg [path]="cancelPath" [width]="10" [height]="10" [viewBox]="'0 0 10 10'"></tr-svg>
                                        </ng-container>                      
                                        <ng-container *ngIf="permission == LESSON_PERMISSIONS.RESCHEDULE || permission == LESSON_PERMISSIONS.REQ_RESCHEDULE">
                                         <tr-svg [path]="eventRepeatIcon" [width]="15" [height]="15" [viewBox]="'0 0 14 15'"></tr-svg>
                                        </ng-container>
                                      <span >
                                      {{ mapPermission(permission) }}
                                      </span>
                                  </li>
                              </div>
                            </ng-container>
                          </div>
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</section>