import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGridViewComponent } from '@components/shared-components/custom-grid-view/custom-grid-view.component';
import { DataTablesModule } from "angular-datatables";


@NgModule({
  declarations: [CustomGridViewComponent],
  imports: [
    CommonModule,
    DataTablesModule
  ],
  exports: [
    CustomGridViewComponent
  ]
})
export class CustomGridModule { }
