<button
  class="d-none"
  data-bs-toggle="modal"
  data-bs-target="#sendCodeConfirmationModal"
  #openCodeConfirmationModal
  (click)="startVerificationCodeCounter()"
>
  click
</button>
<!-- Modal -->
<div
  class="modal fade"
  id="sendCodeConfirmationModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-md-4 px-2 py-4">
      <div class="modal-header pt-0 pb-4 position-relative">
        <span
          data-bs-dismiss="modal"
          aria-label="Close"
          class="cursor-pointer tr-close"
          id="closeCodeConfirmationModal"
          (click)="closeModalPopup()"
          #closeCodeConfirmationModal
        >
          <img src="assets/icons/close-circle.svg" alt="close-icon" />
        </span>
      </div>
      <div
        class="modal-body mt-2 d-flex flex-column align-items-center justify-content-center px-0 px-md-5"
      >
        <div class="d-flex flex-column tr-modal-body-heading">
          <h2>Verify {{ verifyType }}</h2>
          <div class="tr-sub-text">
            We’ve sent a code to {{ verifyTypeValue }}
          </div>
        </div>
        <div class="tr-py-2 tr-mx-100">
          <div
            class="tr-tutor-register-code-conf-form d-flex tr-gap-2"
            [formGroup]="codeConfirmationForm"
          >
            <ng-container *ngIf="verifyType === 'Email'; else mobileInputs">
              <!-- Email confirmation code inputs -->
              <input
                type="number"
                autofocus
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeOne"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeTwo"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeThree"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeFour"
                required
              />
            </ng-container>

            <ng-template #mobileInputs>
              <!-- Mobile confirmation code inputs -->
              <input
                type="number"
                autofocus
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeOne"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeTwo"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeThree"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeFour"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeFive"
                required
              />
              <input
                type="number"
                maxlength="1"
                (input)="keytab($event)"
                class="tr-tutor-register-code-conf-input"
                name="code"
                autocomplete="off"
                formControlName="confirmCodeSix"
                required
              />
            </ng-template>
          </div>
        </div>

        <div>
          <div class="text-center tr-tutor-reg-code-timer" *ngIf="counter > 0">
            Remaining
            <span class="time-counter">
              00:{{ formatCounter(counter) }} sec.</span
            >
          </div>
          <div class="text-center" *ngIf="counter == 0">
            <span class="tr-code-expired"
              >Didn't receive mail or expired code?
            </span>
            <span class="tr-resend-code" (click)="onSendAgainClick()">
              Send again</span
            >
          </div>
        </div>
        <div class="tr-pt-2 w-100">
          <button
            class="tr-verify-btn py-2 d-flex align-items-center justify-content-center tr-btn"
            (click)="onFormSubmit()"
          >
            <span *ngIf="!isLoading">Verify</span>
            <tr-spinner *ngIf="isLoading"></tr-spinner>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
