<main [ngClass]="{'tr-loading': loading === true }">
   <div class="tr-padding-2">
      <div>
         <h2 class="tr-title mb-3 tr-mb-header px-4 px-lg-0 mb-4">Overview</h2>
        <!--  <tr-form-notification [message]="notifyMessage" [showAlert]="true" [icon]="notifyMessageIcon" [type]="notifyMessageType"></tr-form-notification> -->
      </div>
      <section class="tr-padding-2 tr-bg-highlight d-flex align-items-center justify-content-between tr-blur flex-wrap gap-4">
         <div class="tr-dashboard-sec d-flex align-items-start justify-content-start gap-3">
            <img src="assets/images/dashboard/wallet.svg" alt="wallet-img">
            <div class="tr-dashboard-icons d-flex flex-column">
               <span class="tr-sub-title">My earnings</span>
               <span class="tr-bold-text my-4"><span class="tr-text-dark" trCurrency></span>{{userData?.earnings}}</span>
               <span class="tr-navigate-link d-flex align-items-center gap-2 cursor-pointer" routerLink="/tutor/wallet/overview">view my wallet 
                  <img src="assets/images/dashboard/arrow-up.svg" alt="arrow-up">
               </span>
            </div>
         </div>
         <div class="tr-dashboard-sec d-flex align-items-start justify-content-sm-center gap-3">
            <img src="assets/images/dashboard/my-students.svg" alt="wallet-img">
            <div class="tr-dashboard-icons d-flex flex-column">
               <span class="tr-sub-title">My students</span>
               <span class="tr-bold-text my-4">{{userData?.totalStudents}}</span>
               <span class="tr-navigate-link d-flex align-items-center gap-2 cursor-pointer" routerLink="/tutor/students">view students 
                  <img src="assets/images/dashboard/arrow-up.svg" alt="arrow-up">
               </span>
            </div>
         </div>
         <div class="tr-dashboard-sec d-flex align-items-start justify-content-sm-center gap-3">
            <img src="assets/images/dashboard/subjects.svg" alt="wallet-img">
            <div class="tr-dashboard-icons d-flex flex-column">
               <span class="tr-sub-title">Total lessons</span>
               <span class="tr-bold-text my-4">{{userData?.totalLessons}}</span>
               <span class="tr-navigate-link d-flex align-items-center gap-2 cursor-pointer" routerLink="/tutor/lessons">view lessons 
                  <img src="assets/images/dashboard/arrow-up.svg" alt="arrow-up">
               </span>
            </div>
         </div>
         <div class="tr-dashboard-sec d-flex justify-content-sm-center align-items-start gap-3">
            <img src="assets/images/dashboard/clock.svg" alt="wallet-img">
            <div class="tr-dashboard-icons d-flex flex-column">
               <span class="tr-sub-title">Total hours</span>
               <span class="tr-bold-text my-4">{{userData?.totalHours}}<span class="tr-bold-lite">hr</span>
               </span>
               <span class="tr-navigate-link tr-vs-hide">view <img src="assets/images/dashboard/arrow-up.svg" alt="arrow-up">
               </span>
            </div>
         </div>
      </section>
      <section class="tr-mt-32 row p-0 mx-0 mb-0">
         <div class="tr-mh-100 col-xl-4 p-0 pb-md-0 pe-xl-3">
            <tr-dashboard-today-lessons></tr-dashboard-today-lessons>
         </div>
         <div class="col-xl-4 tr-mh-100 pt-4 pt-xl-0 p-0 px-xl-3">
            <tr-tutor-dashboard-latest-messages></tr-tutor-dashboard-latest-messages>
         </div>
         <div class="tr-mh-100 col-xl-4 pt-4 pt-xl-0 pb-md-0 p-0 ps-xl-3">
            <tr-tutor-dashboard-recent-review></tr-tutor-dashboard-recent-review>
         </div>
      </section>
      <section class="row p-0 mx-0 mb-0">
<!--          <div class="col-md-6 tr-mh-100 p-0 ps-md-3">
            <tr-tutor-dashboard-recent-articles></tr-tutor-dashboard-recent-articles>
         </div> -->
      </section>
      <section class="tr-mt-32 row p-0 mx-0 mb-0">
         <div class="col-12 p-0 m-0">
               <tr-tutor-dashboard-waiting-my-action></tr-tutor-dashboard-waiting-my-action>
         </div>
      </section>
   </div>
</main>

<!-- Button trigger modal -->
<button class="border-0 p-0 m-0 d-none" data-bs-toggle="modal" data-bs-target="#lessonReschedulerModal" #openReschedulerModal></button>

<!-- Modal -->
<ng-container *ngIf="(
    (userLessonsService.lessonAction$ | async) ===  LESSON_PERMISSIONS.RESCHEDULE) 
    || (userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.REQ_RESCHEDULE || ((userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.GIVE_NEW_TIME)">
<div class="modal fade" id="lessonReschedulerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="reschedulerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen border-0 p-0 m-0">
    <div class="modal-content">
        <div class="tr-lessons-rescheduler-components">
         <tr-lesson-rescheduler></tr-lesson-rescheduler>
       </div>
    </div>
  </div>
</div>
</ng-container>
<tr-send-message-popup></tr-send-message-popup>