import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service'
import { BASE_URL } from '@shared/models/shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.startsWith(BASE_URL)) {
            req = req.clone({
                setHeaders: {
                    // 'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json, text/plain, */*',
                    'Authorization': `Bearer ${this.auth.getToken()}`,
                },
            });

            return next.handle(req);
        }
        // pass on the request
        return next.handle(req);
    }
}