import { Component, OnInit, SkipSelf, ViewChild, ElementRef} from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
@Component({
  selector: 'tr-lesson-rescheduler-footer',
  templateUrl: './lesson-rescheduler-footer.component.html',
  styleUrls: ['./lesson-rescheduler-footer.component.css']
})
export class LessonReschedulerFooterComponent implements OnInit {
  @ViewChild('closeModalBtn') closeModalBtn?: ElementRef;
  constructor(@SkipSelf() public auth: AuthService, 
    private responseHandlerService: ResponseHandlerService,
    private userLessonsService: UserLessonsService,
    private schedulerNextService: SchedulerNextService,
    @SkipSelf() public reschedulerService: ReschedulerService) {
  }

  ngOnInit(): void {
  }


  submitReschedule() {
    let rescheduleSlots: Array<any> = [];
    let selectedSlots: any = this.schedulerNextService.selectedSlots$.getValue();
    let lessonId: any = this.schedulerNextService.lessonTimeAndPeriod$.getValue().id;

    for (let i = 0; i < selectedSlots.length; i++) {
      rescheduleSlots.push({ "recordId": selectedSlots[i].recordId, "dateTime": selectedSlots[i].dateTime +":00" });
    }

    let formData: FormData = new FormData();
    formData.append("lessonId", lessonId);
    formData.append("slots", JSON.stringify(rescheduleSlots));

    this.reschedulerService.rescheduleLesson(formData).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.schedulerNextService.selectedSlots$.next([]);
        this.closeModalBtn?.nativeElement.click();
        this.userLessonsService.lessonAction$.next('');
        this.responseHandlerService.handleSuccessRessponse(data['message']);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }  

  submitRequestReschedule(formData:any) {
     this.reschedulerService.requestRescheduleLesson(formData).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.schedulerNextService.selectedSlots$.next([]);
        this.responseHandlerService.handleSuccessRessponse(data['message']);
        this.closeModalBtn?.nativeElement.click();
        this.userLessonsService.lessonAction$.next('');
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }  


  submitNewTime(formData:any) {
     this.reschedulerService.giveNewTime(formData).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.schedulerNextService.selectedSlots$.next([]);
        this.responseHandlerService.handleSuccessRessponse(data['message']);
        this.closeModalBtn?.nativeElement.click();
        this.userLessonsService.lessonAction$.next('');
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

  handleFormData(actionType:any){
    let rescheduleSlots: Array<any> = [];
    let selectedSlots: any = this.schedulerNextService.selectedSlots$.getValue();
    let lessonId: any = this.reschedulerService.lessonTimeAndPeriod$.getValue().id;
    let lessonRequestId: any = this.reschedulerService.lessonTimeAndPeriod$.getValue().lessonRequestId;
    let reasonId = this.reschedulerService.requestRescheduleData$.value['reasonId'];
    let note = this.reschedulerService.requestRescheduleData$.value['note'];

    for (let i = 0; i < selectedSlots.length; i++) {
      rescheduleSlots.push({ "recordId": selectedSlots[i].recordId, "dateTime": selectedSlots[i].dateTime +":00" });
    }

    let formData: FormData = new FormData();
    formData.append("lessonId", lessonId);
    formData.append("reasonId", reasonId);
    formData.append("note", note);
    formData.append("slots", JSON.stringify(rescheduleSlots));
    if(actionType == 'request_reschedule'){
      this.submitRequestReschedule(formData);
     }
    else if( actionType == 'give_new_time'){
      formData.append("lessonRequestId", lessonRequestId);
      this.submitNewTime(formData);
    }
  }

  handleNextBtnClick() {
    let actionType = this.reschedulerService.lessonAction$.getValue();
    if (this.reschedulerService.phase$.getValue() == 'confirm_resch_phase') {
       if(actionType == 'reschedule'){
          this.submitReschedule()
        }
        else if(actionType == 'request_reschedule' || 'give_new_time'){
          this.handleFormData(actionType)
        }
    } 
    else {
      this.reschedulerService.nextPhase();
    }
  }

  checkValidity(){
    let selectedSlots = this.schedulerNextService.selectedSlots$.value;
    let type = this.reschedulerService.lessonAction$.value;
    if(selectedSlots && selectedSlots.length > 0){
    if(type == 'reschedule'){
       return false;
    }
    else if(type == 'request_reschedule' || type == 'give_new_time'){
      let value = this.reschedulerService.reschedulerFormStatus$.getValue();
      if(value){
          return false;
       }else{
          return true;
        }
      }else{
        return true;
      }
    }
    else{
      return true;
    }
  }

}
