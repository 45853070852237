<section class="tr-mh-100 d-flex align-items-center justify-content-center">
    <div class="tr-login-component container-fluid d-flex justify-content-center p-0 m-0">
        <div class="tr-login-wrapper col-md-8 col-lg-6">
            <div class="row top30 p-0 m-0">
                <div class="tr-login-main-title col-md-12 col-lg-12 text-center">Welcome to Tutor Reach</div>
            </div>

            <div class="row p-0 m-0 top20">
                <div class="tr-login-label col-md-12 col-lg-12 text-center mt-4 mb-40">
                    Login
                </div>
            </div>
   <!--         <div class="mb-40">
                <tr-register-with></tr-register-with>
           </div> -->
<!-- 
            <div class="row p-0 m-0 tr-line-divider">
                <div class="tr-login-splitter-wrapper col-md-10 col-lg-10 text-center px-0">
                    <span>or</span>
                </div>
            </div> -->

            <!-- Login Form - Start -->
            <form [formGroup]="loginForm">
                <div class="row tr-login-input d-flex flex-column gap-2 justify-content-center px-0 pt-0 tr-pb-2 m-0">
                    <div class="col-md-11 px-0 col-lg-12">
                        <label class="col-md-2 col-lg-2 px-0 d-flex justify-content-start" >Email</label>
                    </div>

                    <div class="col-md-11 px-0 col-lg-12 d-flex justify-content-start">
                        <input class="col-md-12 col-lg-12 px-0 col-12 ps-3" placeholder="Email" type="text" formControlName="email"/>
                    </div>
                </div>  

                <div class="row tr-login-input d-flex flex-column gap-2 justify-content-center px-0 pt-0 pb-3 m-0">
                    <div class="col-md-11 px-0 col-lg-12 col-11">
                        <label class="col-md-2 col-lg-2 px-0  d-flex justify-content-start col-12">Password</label>
                    </div>

                    <div class="col-md-11 px-0 col-lg-12  d-flex justify-content-start">
                        <input class="col-md-12 col-lg-12 px-0 col-12 ps-3" placeholder="Password" type="{{ showPassword ? 'text' : 'password' }}" formControlName="password"/>
                        <img src="assets/images/password_icon.svg" alt="password" class="tr-view-pass-img cursor-pointer" (click)="this.showPassword = !this.showPassword">
                    </div>
                </div>

                <div class="row d-flex justify-content-between p-0 m-0">
                    <div class="d-flex col-6 justify-content-start px-0">
                        <div class="d-flex justify-content-start px-1">
                            <input class="tr-login-save-cred me-2" type="checkbox" />
                            <label class="tr-login-save-pass-label text-start">Keep me logged in</label>
                        </div>
                    </div>
                    <div class="col-5 tr-login-forgot-password px-0 d-flex justify-content-end me-1">
                        <label routerLink="/forgot-password">Forgot password?</label>
                    </div>
                </div>

                <div class="row d-flex justify-content-center p-0 m-0">
                    <div class="col-md-11 col-lg-12 d-flex justify-content-start col-11 px-0 tr-pt-2">
                         <button class="tr-login-btn col-md-12 col-lg-12 tr-btn" [disabled]="!loginForm.valid || isLoading" (click)="login()"> 
                             <span *ngIf="!isLoading">Login</span>
                            <tr-spinner *ngIf="isLoading"></tr-spinner>
                         </button>
                    </div>
                </div>
            </form>
            <!-- Login Form - End -->

            <div class="row bottom30 tr-login-footer p-0 m-0">
                <div class="col-md-12 col-lg-12 d-flex justify-content-center px-0 mt-3">
                    <span class="tr-login-l-label">Haven't got an account yet? </span> <span class="left5 tr-login-r-label" routerLink="/register-student">Register now</span>
                </div>
            </div>
        </div>
        
    </div>
    
</section>

<div class="tr-spinner-loader d-flex align-items-center justify-content-center" *ngIf="tokenLoader">
    <span class="tr-bg-highlight tr-spinner-inner-sec d-flex align-items-center justify-content-center">
      <tr-spinner ></tr-spinner>
    </span>
</div>


