<main class="tr-pt-2">
   <section class="comment-sec d-flex align-items-center gap-3 pb-4" [formGroup]="chatForm">
     <div class="d-flex align-items-center gap-2 tr-w-100">
       <img [src]="userLogo" alt="student-image" *ngIf="userLogo">
       <img src="assets/images/profile-placeholder.png" alt="student-image" *ngIf="!userLogo">
       <div class="tr-w-100 d-flex align-items-center position-relative">
         <input type="text" class="form-control" formControlName="body" [(ngModel)]="message" name="comment" 
                (keydown.enter)="sendMessage()" 
                placeholder="add comment here..">
         <img src="assets/icons/attachment.svg" alt="attachment-img" class="tr-attachment" type="button" 
              data-bs-toggle="modal" data-bs-target="#fileUploadModal">
       </div>
     </div>
     <button [disabled]="chatForm.invalid" class="tr-btn" (click)="sendMessage()">Publish</button>
   </section>
   <section  *ngIf="totalComments == 0">
      <div class="tr-no-posts d-flex align-items-center flex-column justify-content-center">
         <img src="assets/images/no-comments.svg" alt="" >
         <p class="tr-comment">Add your first comment here</p>
      </div>
   </section>
   <section class="tr-posts pt-2" *ngIf="totalComments > 0">
      <div class="d-flex flex-column gap-4">
         <ng-container *ngFor="let comment of lessonComments; let isFirst = first; let index = index;">
            <div *ngIf="isFirst || isNewDate(comment['created_at'], lessonComments[index - 1]?.created_at)">
               <h3 class="tr-date" *ngIf="isToday(comment['created_at'])">Today</h3>
               <h3 class="tr-date" *ngIf="!isToday(comment['created_at'])">{{ comment['created_at'] | date:'mediumDate' }}</h3>
            </div>
            <div class="tr-post tr-padding-2 tr-bd">
               <div class="tr-post-user d-flex align-items-center gap-3 pb-3">
                  <img [src]="comment['user']['avatar']" alt="user-image" *ngIf="comment['user']['avatar']">
                  <img src="assets/images/profile-placeholder.png" alt="student-image" *ngIf="!comment['user']['avatar']">
                  <div class="d-flex flex-column gap-0">
                     <p class="tr-post-user-name mb-0">{{ comment['user']['fname']}}</p>
                     <span class="tr-post-name"><span>{{ formatTime(comment['created_at']) }}</span></span>
                  </div>
               </div>
               <p class="mb-0 pb-0">{{ comment.body }}</p>
               <div class="tr-post-attachments d-flex align-items-center gap-3" *ngIf="comment['attachments'] && comment['attachments'].length > 0">
               </div>
            </div>
         </ng-container>
      </div>
   </section>
</main>
<!-- Modal -->
<div class="modal fade" id="fileUploadModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header border-0">
            <h5 class="modal-title" id="staticBackdropLabel">File Upload</h5>
            <button type="button" class="tr-btn-close p-0 border-0" data-bs-dismiss="modal" aria-label="Close" (click)="removeAllFiles()">
            <img src="assets/icons/close-circle.svg" alt="close-icon" />
            </button>
         </div>
         <div class="modal-body">
            <ngx-dropzone (change)="addDocument($event)" style="
            flex-direction: column-reverse;
            height: auto !important;
            min-height: 180px;
            width: 100%;
            justify-content: center;
            border: 1px dashed #bebebe;
            " accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" [maxFileSize]="20000000" [multiple]="true">
            <div class="row">
               <div class="col-md-12 text-center">
                  <div class="row">
                     <div class="col-md-12 col-lg-12" *ngIf="files.length <= 0">
                        <div class="d-flex align-items-center flex-column mb-3">
                           <img src="../../assets/icons/upload-file.svg" class="tr-drop-zone-upload-file mb-3" alt="upload-logo" />
                           <span class="tr-drop-zone-info-label mb-1">Drag attachment here</span>
                           <ngx-dropzone-label class="tr-drop-zone-file-types m-0">(PNG, JPG, PDF max. size 20MB) </ngx-dropzone-label>
                        </div>
                     </div>
                     <div class="col-md-12 col-lg-12">
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                     </div>
                  </div>
               </div>
            </div>
            </ngx-dropzone>
            <div class="col-12 mt-3">
               <button class="tr-btn-primary tr-btn px-3 py-2" (click)="sendMessage()">Send</button>
            </div>
         </div>
      </div>
   </div>
</div>