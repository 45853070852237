import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let currentRole = this.authService.currentRole$.getValue();
    if (currentRole === 'guest') {
      return true; // Allow access to the route
    } 
    else if(currentRole == 'student' || currentRole == 'admin'){
      this.router.navigate(['/' + currentRole + '/dashboard']);
      return false; 
    }
    else if(currentRole == 'tutor'){
     let tutorData = this.authService.user$.getValue();
     if(tutorData['register_phase'] === 'completed'){
        this.router.navigate(['/' + currentRole + '/dashboard']);
        return false; 
     }
     else{
       return true; 
     }
    }
    else{
      return true;
    }
  }
}
