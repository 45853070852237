import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { dayTimeIcon, eveningIcon, midNightIcon } from '@shared/models/svgIcons';

@Component({
  selector: 'tr-lesson-type-dropdown',
  templateUrl: './lesson-type-dropdown.component.html',
  styleUrls: ['./lesson-type-dropdown.component.css']
})
export class LessonTypeDropdownComponent implements OnInit {
  dayTimeIcon:string = dayTimeIcon;
  eveningIcon:string = eveningIcon;
  midNightIcon:string = midNightIcon;
  
  @Output() selectedData = new EventEmitter<any>();
  @Input() title?: string;
  @Input() targetList?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
