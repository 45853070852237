import {
  Component,
  OnInit,
  SkipSelf,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import {
  optionsIcon,
  eventRepeatIcon,
  cancelPath,
} from '@shared/models/svgIcons';
import { LessonDetailsService } from './lesson-details.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ResponseHandlerService } from '@components/shared-components/response-handler.service';
import * as moment from 'moment';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { ConfirmDeletePopupService } from '@components/shared-components/confirm-delete-popup/confirm-delete-popup.service';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { AuthService } from '@core/auth/auth.service';
import { BASE_URL } from '@shared/models/shared';
import * as bootstrap from 'bootstrap';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';


@Component({
  selector: 'tr-lesson-details-overview',
  templateUrl: './lesson-details-overview.component.html',
  styleUrls: ['./lesson-details-overview.component.css'],
})
export class LessonDetailsOverviewComponent implements OnInit {
  optionsIcon: string = optionsIcon;
  baseURL!: string;
  eventRepeatIcon: string = eventRepeatIcon;
  cancelPath: string = cancelPath;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  currentRole: any;
  lessonId: any;
  showInput: boolean = false;
  lessonData: any;
  isLoading: boolean = false;
  buttonDisabled: boolean = true;
  lessonDateTime: any;
  lessonDuration: any;
  timeRemaining: any;
  lessonDescription: any;
  closeModal: boolean = false;
  closeReviewModal: boolean = false;

  constructor(
    private location: Location,
    public authService: AuthService,
    public lessonDetailsService: LessonDetailsService,
    private schedulerNextService: SchedulerNextService,
    private activatedRoute: ActivatedRoute,
    @SkipSelf() public userLessonsService: UserLessonsService,
    @SkipSelf() public reschedulerService: ReschedulerService,
    private deleteService: ConfirmDeletePopupService,
    private responseHandlerService: ResponseHandlerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      if (id) {
        this.lessonId = id;
        this.getLessonDetails();
        this.lessonDetailsService.lessonId$.next(this.lessonId);
        this.currentRole = this.authService.currentRole$.getValue();
      } else {
        this.goBackToPreviousRoute();
      }
    });
  }

  getLessonDetails() {
    this.isLoading = true;
    this.lessonDetailsService
      .getLessonDetails(this.lessonId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.lessonData = res['data'];
          this.baseURL = BASE_URL;
          this.lessonDetailsService.chatId$.next(this.lessonData['chatId']);
          this.lessonDateTime = this.lessonData['lessontime'];
          this.lessonDuration = this.lessonData['lessonPeriod'];
          this.checkLessonValidity();
          this.calculateAndDisplayTimeRemaining();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
          this.location.back();
        }
      );
  }

  goBackToPreviousRoute() {
    this.location.back();
  }

  calculateAndDisplayTimeRemaining() {
    const lessonDate = moment(this.lessonDateTime, 'YYYY-MM-DD HH:mm:ss');
    const currentDate = moment();
    const isLessonToday = lessonDate.isSame(currentDate, 'day');
    const isLessonFuture = lessonDate.isAfter(currentDate);

    if (isLessonToday && isLessonFuture) {
      const duration = moment.duration(lessonDate.diff(currentDate));
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      if (hours > 0) {
        this.timeRemaining = `${hours} hours and ${minutes} minutes`;
      } else if (minutes > 0) {
        this.timeRemaining = `${minutes} minutes and ${seconds} seconds`;
      } else if (seconds > 0) {
        this.timeRemaining = `${seconds} seconds`;

        // Update time remaining after 1 second
        setTimeout(() => {
          this.calculateAndDisplayTimeRemaining();
        }, 1000);
      } else {
        this.timeRemaining = 'started';
        // Check lesson validity after 1 second
        setTimeout(() => {
          this.checkLessonValidity();
        }, 1000);
      }
    } else if (!isLessonToday && lessonDate.isBefore(currentDate)) {
      this.timeRemaining = 'completed';
    } else {
      this.timeRemaining = 'started';
    }
  }

  checkLessonValidity() {
    if (this.lessonDateTime && this.lessonDuration > 0 && this.lessonData['lessonStatus'] === 'APPROVED') {
      const lessonTime = moment(this.lessonDateTime, 'YYYY-MM-DD HH:mm:ss');
      const lessonPeriod = moment.duration(this.lessonDuration, 'minutes');
      const currentTime = moment();
      const lessonEndTime = lessonTime.clone().add(lessonPeriod);
      if (currentTime.isBetween(lessonTime, lessonEndTime)) {
        this.buttonDisabled = false;
        const timeRemaining = lessonEndTime.diff(currentTime);
        if (timeRemaining > 0) {
          setTimeout(() => {
            this.buttonDisabled = true;
          }, timeRemaining);
        }
      } else {
        this.buttonDisabled = true;
      }
    }
  }

  rescheduleLesson(actionName: any, lessonData: any) {
    this.userLessonsService.lessonAction$.next(actionName);
    this.reschedulerService.lessonAction$.next(actionName);
    this.reschedulerService.lessonTimeAndPeriod$.next(lessonData);
    this.schedulerNextService.lessonTimeAndPeriod$.next(lessonData);
  }

  getLessonAction(actionName: any, lesson: any) {
    switch (actionName) {
      case LESSON_PERMISSIONS.CANCEL_REQ:
        this.cancelRequest(lesson.id, lesson.lessonRequestId);
        break;
      case LESSON_PERMISSIONS.CANCEL:
        this.directCancelLesson(lesson.id);
        break;
      case LESSON_PERMISSIONS.RESCHEDULE:
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        this.rescheduleLesson(actionName, lesson);
        break;
    }
  }

  directCancelLesson(lessonId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    this.deleteService.setData('cancel', formData);
  }

  cancelRequest(lessonId: any, lessonRequestId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    formData.append('lessonRequestId', lessonRequestId);
    this.deleteService.setData('request_cancellation', formData);
  }

  raiseComplaint() {
    this.userLessonsService.complaintLessonId$.next(this.lessonData['id']);
    const modalElement = document.getElementById('raiseComplaintModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  addDescription() {
    let formData = new FormData();
    formData.append('lessonId', this.lessonData['id']);
    formData.append('description', this.lessonDescription);
    this.lessonDetailsService
      .addLessonDescription(formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.responseHandlerService.handleSuccessRessponse(res['message']);
          this.showInput = false;
          this.getLessonDetails();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  submitReview(data: any) {
    let formData = new FormData();
    formData.append('lessonId', this.lessonData['id']);
    formData.append('tutorId', this.lessonData['tutorId']);
    formData.append('comment', data['description']);
    formData.append('rating', data['userRating']);
    this.lessonDetailsService
      .submitReview(formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.responseHandlerService.handleSuccessRessponse(res['message']);
          this.closeReviewModal = !this.closeReviewModal;
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  attend() {
    let user = this.authService.user$.value;
    const url =
      this.baseURL +
      'communication/session/attend/' +
      this.lessonData['id'] +
      '/' +
      user['id'] +
      '?redirect=1';
    if (user && user['id']) {
      if (
        user.fname &&
        user.lname &&
        user.isEmailVerified === true &&
        user.isPhoneVerified === true
      ) {
        window.open(url, '_blank');
      } else {
        this.lessonDetailsService.lessonPhase$.next('INCOMPLETEPROFILE');
        this.lessonDetailsService.enableDisablePageScroll('hidden');
        this.lessonDetailsService.lessonUrl$.next(url);
      }
    }
  }
}
