import * as moment from "moment";
import { Days } from '@shared/models/shared';
import * as _ from "lodash";

export const TRIAL_LESSON_ALLOWED_SLOTS = 1;
export enum SLOT_STATE {
    AVAILABLE = 'available',
    SELECTED = 'selected',
    BOOKED = 'booked',
    DEFAULT = 'default',
    TIME_OFF = 'default',
    HELD = 'HOLD', // slot that is being held by a student
}

export const enum SLOT_DURATIONS {
    THIRTY_MINS = 30,
    ONE_HOUR = 60
}

interface TimeSlot {
    id: number;
    state: string;
    date: {
        dateTime: string;
        day: string;
        date: string;
    };
}

interface Scheduler {
    time: {
        value: string;
        period: string;
    };
    slots: TimeSlot[];
}

export interface Slot<T> {
    id: any,
    recordId?: any,
    dateTime: any,
    state: string,
    isRepeated?: boolean,
    timestamp?: boolean,
    isRepeatStateUpdated?: boolean,
}
export function getSchedulerDays(startDate: any = moment().format('YYYY-MM-DD HH:mm')) {
    let startOfWeek: any = moment(startDate).startOf('day');
    let totalDays = Object.keys(Days).length;
    let days: any = [];
    for (let i = 0; i < totalDays; i++) {
        let currentDayDate = moment(startOfWeek).add(i, 'days');
        let currentDayName = currentDayDate.format('ddd');
        days.push({ "name": currentDayName, "date": currentDayDate.format('DD'), 'completeDate': currentDayDate.format('YYYY-MM-DD HH:mm') })
    }
    return days;
}

export function formatDateTimeAndDuration(dateTimeStr: any, durationInMinutes: any) {
    const dateTime = moment(dateTimeStr, 'YYYY-MM-DD HH:mm:ss');
    const formattedDateTime = dateTime.format('ddd DD MMM, hh:mm a');
    const result = `${formattedDateTime}, ${durationInMinutes} min`;

    return result;
}

export function generateSchedulerGridSlots(updatedSlots: any = [], startDate: any = moment().format('YYYY-MM-DD HH:mm'), timeOff: any[] = []) {
    let timezone: string = "Europe/London";
    const slots: Scheduler[] = [];
    const today = moment.tz(startDate, timezone);
    let endDate = moment(today).add(6, 'days');
    let slotIdCounter: number = 0;

    let currentTime = moment.tz('00:30', 'HH:mm', timezone);
    while (currentTime.isSameOrBefore(moment.tz('24:00', 'HH:mm', timezone))) {
        const slotsForTime = [];
        let currentDate = today.clone();
        while (currentDate.isSameOrBefore(endDate, 'day')) {
            slotIdCounter++
            let slotDateTime = currentDate.format('YYYY-MM-DD') + ' ' + currentTime.format('HH:mm');
            let slotState = 'default';
            let isRepeated = false;
            let recordId = null;
            let lessonKey = null;

            for (let i = 0; i < updatedSlots.length; i++) {
                if (slotDateTime == updatedSlots[i].dateTime) {
                    slotState = updatedSlots[i].state;
                    isRepeated = updatedSlots[i].isRepeated;

                    if (updatedSlots[i].hasOwnProperty('recordId')) {
                        recordId = updatedSlots[i].recordId;
                    }
                    if (updatedSlots[i].hasOwnProperty('lessonKey')) {
                        lessonKey = updatedSlots[i].lessonKey;
                    }
                }
            }

            // Check if the slot falls within the timeOff range
            const slotDateTimeMoment = moment.tz(slotDateTime, timezone);
            const isTimeOff = timeOff.some((range: any) => {
                const startDateMoment = moment.tz(range.startDate, timezone);
                const endDateMoment = moment.tz(range.endDate, timezone);
                return slotDateTimeMoment.isBetween(startDateMoment, endDateMoment, undefined, '[]');
            });

            if (isTimeOff) {
                slotState = 'timeOff';
            }

            slotsForTime.push({
                id: slotIdCounter,
                state: slotState,
                isRepeated: isRepeated,
                recordId: recordId,
                lessonKey: lessonKey,
                date: {
                    dateTime: currentDate.format('YYYY-MM-DD') + ' ' + currentTime.format('HH:mm'),
                    day: currentDate.format('ddd'),
                    date: currentDate.format('DD')
                }
            });
            currentDate = currentDate.add(1, 'day');
        }

        slots.push({
            time: {
                value: currentTime.format('HH:mm'),
                period: currentTime.format('a')
            },
            slots: slotsForTime
        });

        currentTime = currentTime.add(SLOT_DURATIONS.THIRTY_MINS, 'minutes');
    }
    return slots;
}

export function convertDatetimeFormat(ranges: any) {
    const convertedRanges = ranges.map((range: any) => {
        const { startDate, endDate } = range;
        const convertedStartDate = moment(startDate).format("YYYY-MM-DD HH:mm");
        const convertedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm");
        return {
            startDate: convertedStartDate,
            endDate: convertedEndDate,
        };
    });

    return convertedRanges;
}


export function isWithinFirstWeek(dateString: any): boolean {
    const date = moment(dateString);
    const today = moment().startOf('day');
    const next7Days = moment().add(6, 'days').endOf('day');
    return date.isBetween(today, next7Days, null, '[]');
}

export function getDayName(dateValue: any) {
    const date = moment(dateValue);
    const dayName = date.format('ddd');
    return dayName;
}

export function getDateAndTime(dateTimeString: any) {
    const [datePart, timePart] = dateTimeString.split(' ');
    const date = datePart;
    const time = timePart;
    const dateTimeObject = {
        date,
        time
    };
    return dateTimeObject;
}

export function getRecurringScheduleSlots(slots: any) {
    let repeatedSlots: Array<any> = [];
    slots.map(
        (obj: any) => {
            if (obj.isRepeated) {
                repeatedSlots.push({
                    day: getDayName(obj.dateTime),
                    recordId: obj?.recordId || null,
                    time: getDateAndTime(obj.dateTime)['time'],
                    state: obj.state
                });
            }
        }
    )

    return repeatedSlots;
}

export function reshapeSlot(data: any | any[]): Slot<any>[] | Slot<any> {
    const slotsArray = Array.isArray(data) ? data : [data];
    const resultArray = slotsArray.map((slot: any) => {
        let id, recordId, state, isRepeated, dateTime, timestamp, lessonKey;

        ({ id=null, timestamp=null, recordId=null, state, isRepeated, lessonKey } = slot);

        //if coming from scheduler
        if (slot.hasOwnProperty('date')) {
            dateTime = slot.date.dateTime;
        } else {
            //if coming from API
            dateTime = slot.dateTime;
        }

        return { id, recordId, lessonKey, state, isRepeated, dateTime, timestamp };
    });
    // return array if the data type is array, otherwise, object
    return Array.isArray(data) ? resultArray : resultArray[0];
}

// add timestamp to slot when user changes its original state to maintain log of changes
export function addTimestamp() {
    return moment().valueOf();
}

export function extractDateTimeForPopup(dateString: string) {
    const dateObject = moment(dateString, "YYYY-MM-DD HH:mm");
    const dayOfWeek = dateObject.format("dddd");
    const month = dateObject.format("MMMM");
    const day = dateObject.format("D");
    const timeStart = dateObject.format("HH:mm");
    const timeEnd = dateObject
        .clone()
        .add(SLOT_DURATIONS.THIRTY_MINS, 'minutes')
        .format("HH:mm");

    const desiredObject = {
        date: `${dayOfWeek}, ${day} ${month}`,
        time: `${timeStart} - ${timeEnd}`
    };

    return desiredObject;
}

export function getSlotByDateTime(dateTime: string, slots: Array<any>) {
    for (const obj of slots) {
        for (const slot of obj.slots) {
            if (slot.date.dateTime === dateTime) {
                return slot;
            }
        }
    }
    return null; // Return null if the slot is not found
}

export function addMins(dateTime: string, mins: number) {
    const newDateTime = moment(dateTime).add(mins, 'minutes').format('YYYY-MM-DD HH:mm');
    return newDateTime;
}

export function getDayTimes() {
    //DATES AND TIMES MUST BE UTC

    var timezone = 'Asia/Beirut'; //TODO: Make it dynamic timezones
    var date = moment().tz(timezone);

    var dayStart = date.clone().startOf('day');
    var dayEnd = date.clone().endOf('day');

    // currently hardcoding the start and end hours of day and night times, need to integrate an API to get the sunrise and sunset based on the timezone
    var morningHoursStart = dayStart.clone().hours(0); // Start of day hours 
    var morningHoursEnd = dayEnd.clone().hours(11); // End of day hours
    var eveningHoursStart = dayStart.clone().hours(12); // Start of night hours
    var eveningHoursEnd = eveningHoursStart.clone().add(12, 'hours'); // End of night hours

    var morningHours = [];
    var temp = morningHoursStart.clone();
    while (temp.isBefore(morningHoursEnd)) {
        morningHours.push(temp.format('HH:mm'));
        temp.add(30, 'minutes');
    }

    var eveningHours = [];
    temp = eveningHoursStart.clone();
    while (temp.isBefore(eveningHoursEnd)) {
        eveningHours.push(temp.format('HH:mm'));
        temp.add(30, 'minutes');
    }
    return [{ "morningHours": morningHours, "eveningHours": eveningHours }]

}

export function toggleSlotBackgroundOnHover(cssRule: string, lessonKey: string, displayHover: boolean = true) {
    const divs: any = document.querySelectorAll(`[data-lesson="${lessonKey}"]`);
    cssRule = displayHover ? cssRule : ''
    divs.forEach((div: any) => {
        div.addEventListener('mouseenter', () => {
            divs.forEach((div: any) => {
                div.style.cssText = cssRule;
            });
        });

        div.addEventListener('mouseleave', () => {
            divs.forEach((div: any) => {
                div.style.cssText = '';
            });
        });
    });
}
export function disableSlotBackgroundOnHover(lessonKey: string) {
    const divs: any = document.querySelectorAll(`[data-lesson="${lessonKey}"]`);

    divs.forEach((div: any) => {
        const clonedDiv = div.cloneNode(true);
        div.parentNode.replaceChild(clonedDiv, div);
    });
}
export function removeDataLessonAttributes(lessonKey: string) {
    const elements = document.querySelectorAll(`[data-lesson="${lessonKey}"]`);
    elements.forEach((element: any) => {
        element.removeAttribute('data-lesson');
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
        element.style.cssText = ''; // Reset or modify the CSS styles
    });

    function handleMouseEnter() {
        return ''
    }

    function handleMouseLeave() {
        return ''
    }
}
export function extractLessonDateTime(dateTime: string) {
    const formattedDateTime = moment(dateTime).format('ddd, DD MMM, HH:mm');
    return formattedDateTime;
}

export function extractDateTime(array: Array<any>) {
    return array.map((obj: any) => obj.dateTime);
}