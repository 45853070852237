import { Component, OnInit } from '@angular/core';
import { FindTutorService } from '@studentComponents/student-tutors/find-tutor/find-tutor.service';
import { BookLessonService } from '../book-lesson.service';
import { BOOKING_PHASES } from '@shared/models/shared';

@Component({
  selector: 'tr-book-lesson-header',
  templateUrl: './book-lesson-header.component.html',
  styleUrls: ['./book-lesson-header.component.css']
})
export class BookLessonHeaderComponent implements OnInit {
  viewTitle = "Select Lesson type";
  bookingPhase: any;
  selectedTutor: any;
  BOOKING_PHASES: any = BOOKING_PHASES;
  constructor(public findTutorService: FindTutorService, public bookLessonService: BookLessonService) {
    this.selectedTutor = this.findTutorService.targetedTutorTile$;
    this.bookingPhase = this.bookLessonService.bookingPhase$
  }


  ngOnInit(): void {
  }

  closeBookingWindow() {
    this.bookLessonService.closeBookingWindow()
  }

  resetBookingConfig() {
    // this.bookLessonService.isSchedulerModuleLoaded$.next(false)
    this.bookLessonService.nextBookingPhase('lessonType'); // reset phases
  }

  getTitleForBookingPhase(bookingPhase:any) {
      switch (bookingPhase) {
        case 'bookLessonTypeDetails':
          return 'Select lesson details';
        case 'lessonType':
          return 'Select lesson type';        
        case 'timeSlots':
          return 'Schedule your lessons';    
        case 'lessonCommunicationTools':
          return 'Select communication tool';
        case 'bookingSummary':
          return 'Booking summary';        
        case 'bookingCheckout':
          return 'Payment';
        default:
          return ''; // Set a default value if needed
      }
    }


}