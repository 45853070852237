import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-tutor-profile-guest',
  templateUrl: './tutor-profile-guest.component.html',
  styleUrls: ['./tutor-profile-guest.component.css']
})
export class TutorProfileGuestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
