<div class="tr-side-panel-wrapper p-0">
	<div class="text-start tr-mb-flex">
		<p class="tr-side-panel-main-title pt-xl-4 pb-xl-2 pt-0 pb-0 ps-4 mb-0">{{sidePanelTitle}}</p>
	</div>
	<div class="top5 tr-side-panel-items-wrapper d-flex d-xl-block align-items-center px-4 px-xl-0">
		<ng-container *ngFor="let item of sidePanelItems">
			<p class="mb-0 tr-border-b px-xl-4 py-xl-3 text-start align-items-start" routerLinkActive="tr-active-link">
				<a class="px-0 tr-tutor-side-panel-item flex-column flex-xl-row d-flex  align-items-center"  [routerLink]="item?.route" [ngClass]="item.svgPath.length>0?'':'tr-no-item-icon'">
					<span>
					<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			           <path [attr.d]=item.svgPath/>
			        </svg>
			        </span>
					<div class="d-flex align-items-center justify-content-between w-100">
					  {{item.name}}
					 <img src="assets/icons/{{toggle_icon}}.svg" alt="arrow_forward_svg" *ngIf="item.subMenu" class="tr-submenu-icon ms-1 me-1 d-none d-xl-block" (click)="toggleMenu()">
					</div>
				</a>
				<a class="tr-tutor-side-panel-item left46 flex-column d-none d-xl-flex" *ngIf="item.subMenu && isMenuOpen">
					<span *ngFor="let subMenu of item.subMenu; let last = last;" class="tr-side-panel-sub-menu" [ngClass]="last ? 'top16' : 'tr-border-b'" [routerLink]="subMenu?.route">
						{{ subMenu.name }} 
					</span>
				</a>
			</p>
		</ng-container>
	</div>
</div>

