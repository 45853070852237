<!-- <tr-landing-page-header></tr-landing-page-header> -->
<tr-tutor-reg-save-phase-one *ngIf="(currentPhase$ | async)=='one'"></tr-tutor-reg-save-phase-one>
<tr-tutor-code-confirmation *ngIf="(currentPhase$ | async)=='codeConfirmation'"></tr-tutor-code-confirmation>
<tr-tutor-reg-save-phase-two *ngIf="(currentPhase$ | async)=='two'"></tr-tutor-reg-save-phase-two>
<tr-tutor-reg-save-phase-three *ngIf="(currentPhase$ | async)=='three'" ></tr-tutor-reg-save-phase-three>
<tr-tutor-reg-save-phase-four *ngIf="(currentPhase$ | async)=='four'"></tr-tutor-reg-save-phase-four>
<tr-tutor-reg-save-phase-five *ngIf="(currentPhase$ | async)=='five'"></tr-tutor-reg-save-phase-five>

<tr-tutor-reg-save-phase-six *ngIf="(currentPhase$ | async)=='six'"></tr-tutor-reg-save-phase-six>
<tr-tutor-reg-under-review *ngIf="(currentPhase$ | async)=='Under-Review'"></tr-tutor-reg-under-review>

<!-- <tr-landing-page-footer></tr-landing-page-footer> -->