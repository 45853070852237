<section class="tr-bg-highlight">
	<div class="position-relative tr-bdr-6">
		<div *ngIf="tutorVideoThumbnail" class="tr-video-sec position-relative" style="background-image:url('{{tutorVideoThumbnail}}');">
	    </div>		
	    <div *ngIf="!tutorVideoThumbnail && tutor.profilePicture" class="tr-video-sec position-relative" style="background-image:url('{{tutor.profilePicture}}');">
	    </div>
      <img src="assets/icons/play-btn.svg" alt="play-btn" class="tr-play-btn cursor-pointer" (click)="playVideo()">
	</div>
	<div class="tr-bg-secondary d-flex justify-content-between align-items-center">
		<div class="tr-rate d-flex flex-column gap-1">
			 £ {{tutor?.pricePerHour}} 
			<span>Start from</span>
		</div>
		<div class="d-flex flex-column gap-2">
			<div class="d-flex gap-1">
				<img src="assets/icons/rate.svg" alt="rate-svg">
				<span class="tr-rating"> {{ tutor?.averageRate }} </span>
			</div>
			<div>
				<p class="mb-0 tr-review">{{ tutor?.countOfReviews }} <span> Reviews </span></p>
			</div>
		</div>
	</div>
	<div class="p-4" *ngIf="tutor.tutorDicount && tutor.tutorDicount.length > 0">
		<h5 class="tr-sub-title-dark-text tr-font-18 mb-0">Discount Packages</h5>
		<div class="pt-4 d-flex flex-column gap-3">
			<div class="tr-package p-3 d-flex align-items-center justify-content-between" *ngFor="let discount of tutor?.tutorDicount">
				<span class="tr-price"><span trCurrency class="tr-price"></span>  20</span>
				 {{discount.numberOfLesson}}Lesson per Week
				 <span class="tr-percent"> Save {{ discount.percentage}}% </span>
			</div>			
		</div>
	</div>
	<div class="tr-py-2 px-4">
		<button class="tr-btn tr-book-lesson-btn tr-btn-primary d-flex w-100 align-items-center justify-content-center cursor-pointer" (click)="openBookingWindow(tutor)"> 
           <ng-container *ngIf="this.user?.isTrialAvailable && !tutor?.hadLesson; else elseBlock">
            Book trial lesson
          </ng-container>
          <ng-template #elseBlock>
            Book a lesson
          </ng-template>
		</button>
		<div class="d-flex gap-3 top10">
			<button class="tr-btn tr-btn-primary-outline flex-grow-1" *ngIf="(authService.currentRole$ | async) === 'student'" (click)="sendMessage(tutor?.id, tutor?.firstName, tutor?.profilePicture)"> Message</button>
			<!-- <button class="tr-btn tr-btn-primary-outline flex-grow-1"> Call</button> -->
		</div>
	</div>
</section>
