import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tr-small-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './small-button.component.html',
  styleUrls: ['./small-button.component.css']
})
export class SmallButtonComponent implements OnInit {
  @Input() name:string = 'View all';

  constructor() { }

  ngOnInit(): void {
  }

}
