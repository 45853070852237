<!-- Bootstrap Modal -->
<div class="modal fade" id="videoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
  <div class="modal-dialog" >
    <div class="modal-content bg-transparent">
      <div class="modal-body p-0 position-relative" *ngIf="showPlayer">
         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="this.onClosePlayer.emit('yes');"></button>
         <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" [src]="videoLink" allowfullscreen  allowscriptaccess="always" frameborder="0" allow="autoplay"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
