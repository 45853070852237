import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { SvgModule } from './svg.module';
import { RouterModule, Routes } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FindTutorSkeletonLoaderComponent } from '@studentComponents/student-tutors/find-tutor-skeleton-loader/find-tutor-skeleton-loader.component';

import { FindTutorComponent } from '@studentComponents/student-tutors/find-tutor/find-tutor.component';
import { VideoPlayerComponent } from '@components/shared-components/video-player/video-player.component';
import { FindTutorFilterPanelComponent } from '@studentComponents/student-tutors/find-tutor/find-tutor-filter-panel/find-tutor-filter-panel.component';
import { SafePipe } from '@shared/pipes/safe-url.pipe';
import { ToggleDisplayComponent } from '@studentComponents/student-tutors/toggle-display/toggle-display.component';
import { TutorTileComponent } from '@studentComponents/student-tutors/tutor-tile/tutor-tile.component';
import { TutorScrollDisplayComponent } from '@studentComponents/student-tutors/tutor-scroll-display/tutor-scroll-display.component';
import { CurrencyDirective } from '@core/currency.directive';

@NgModule({
  declarations: [
    FindTutorComponent,
    TutorTileComponent,
    TutorScrollDisplayComponent,
    ToggleDisplayComponent,
    FindTutorFilterPanelComponent
  ],
  imports: [
    CommonModule,
    SafePipe,
    RouterModule,
    CurrencyDirective,
    FindTutorSkeletonLoaderComponent,
    SharedModule,
    SvgModule,
    VideoPlayerComponent,
  ],
  exports: [
    FindTutorComponent,
    TutorScrollDisplayComponent,
    TutorTileComponent,
    ToggleDisplayComponent,
    FindTutorFilterPanelComponent
  ],
  providers: [DatePipe]
})
export class FindTutorModule { }
