import { Injectable, SkipSelf } from '@angular/core';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from 'src/app/shared/models/shared';
import { validateObjKey } from '@shared/models/validation';


@Injectable({
  providedIn: 'root'
})

export class FindTutorService {
  targetedTutorTile$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  isListView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  tutorsList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  timezones$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  unfavedTutorId$: Subject<any> = new Subject<any>();

  constructor(private trHttp: TrHttpService) {
    console.log(this.targetedTutorTile$)
  }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getTutorsFirstLoad(timezone?:any) {
    const formData = new FormData();
    formData.append('perPage', '10');
    if(timezone){
       formData.append('timezoneId', timezone);
    }
    return this.fetch('find-tutors', formData)
  }

  findTutors(params: any) {
    return this.fetch('find-tutors', this.validateFormDataParams(params))
  }

  getSavedTutors() {
    return this.fetch('user/favourites')
  }  

  getCurrentTutors() {
    const formData = new FormData();
    formData.append('perPage', '10');
    return this.fetch('student-tutors', formData)
  }

  validateFormDataParams(params: any) {
    let formData = new FormData();
    for (let key in params) {
      if (params[key] !== "" && params[key] !== null && params[key] !== undefined && (!Array.isArray(params[key]) || params[key].length > 0)) {
        formData.append(key, params[key]);
      }
    }
    return formData;
  }


  toggleTutorListDisplay(isListView: any) {
    this.isListView$.next(isListView)
  }

  favTutor(id: any) {
    const formData = new FormData();
    formData.append('favId', id);
    return this.fetch('user/favourites', formData, HttpMethod.POST)
  }  

  getTimezones() {
    return this.fetch('timezones/list')
  }

  // Get tutorId to remove the tutor object from the tutors array in the saved-tutors
  getTutorId(id: any, isFav: any) {
    //isFav key doesn't exist in the saved-tutor list
    if (isFav === undefined) {
      this.unfavedTutorId$.next(id);
    }
  }

  unfavorite(id: any, isFav: any) {
    return this.fetch('user-favourite/' + id, '', HttpMethod.DELETE).pipe(
      tap(
        (success: any) => {
          this.getTutorId(id, isFav)
        }
      )
    )
  }  

  toggleTutorFavorite(id: any, isFav: boolean) {
    return isFav === undefined || isFav === true ? this.unfavorite(id, isFav) : this.favTutor(id);
  }
}