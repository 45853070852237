import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'tr-scheduler-next-extend-prompt',
  templateUrl: './scheduler-next-extend-prompt.component.html',
  styleUrls: ['./scheduler-next-extend-prompt.component.css']
})
export class SchedulerNextExtendPromptComponent implements OnInit {
  Extend60Mins: boolean = false;
  Extend90Mins: boolean = false;
  _extendPeriod!: number;
  @Output() extendPeriodValue = new EventEmitter();
  @Output() isExtendCancelled: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input()
  set promptData(data: any) {
    console.log(data);
    this.Extend60Mins = data.Extend60Mins;
    this.Extend90Mins = data.Extend90Mins;
  }


  constructor() { }

  ngOnInit(): void {
  }

  get extendPeriod(): any {
    return this._extendPeriod;
  }

  set extendPeriod(value: any) {
    this._extendPeriod = value;
  }

  cancelExtendClass() {
    this.isExtendCancelled.next(true);
  }

  submitExtend() {
    this.extendPeriodValue.emit(this._extendPeriod);
  }

  checkButton(id:any){
    const input = document.getElementById(id);
    if(input){
      input.click();
    }

  }

}
