<main class="tr-bg-highlight tr-mh-100">
  <section class="tr-py-2">
    <div class="d-flex align-items-center justify-content-between pb-3 tr-px-2">
      <h3 class="tr-title mb-0">Today’s lessons
      </h3>
      <div class="tr-view-btn d-flex align-items-center gap-3 cursor-pointer tr-navigate-link" routerLink="/tutor/lessons">
        <span>view more</span>
        <img src="assets/icons/view_more.svg" alt="view-more">
      </div>
    </div>
    <section class="d-flex flex-column gap-2" *ngIf="!isLoading">
      <div class="text-center py-3 tr-px-2" *ngIf="todayLessons?.length == 0">
        <img src="assets/images/no-today-lessons.svg" alt="">
        <p class="tr-sub-title-lite-text mt-2 tr-font-12">No lessons today</p>
      </div>
      <div class="py-3 tr-px-2 justify-content-between tr-hover row m-0" *ngFor="let lesson of todayLessons; let last = last"
     [ngClass]="{'tr-bdr': !last}">
        <div class="tr-tutor-img col-5 px-0 d-flex align-items-center gap-2">
          <img [src]="lesson.studentImage" alt="student-image" *ngIf="lesson.studentImage">
          <img src="assets/images/profile-placeholder.png" alt="placeholder-image" *ngIf="!lesson.studentImage">
          <div class="mb-0 d-flex flex-column align-items-start gap-2">
            <span class="tr-name">{{ lesson.studentName }}</span>
            <span class="tr-text-dark"> {{ lesson.subjectName }} </span>
          </div>
        </div>
        <div class="d-flex flex-column gap-2 px-2 col-4">
          <span class="tr-text-lite"> Lesson time </span>
          <span class="tr-text-dark"> {{ lesson.lessontime | date:'hh:mm a'}}, {{ lesson.lessonPeriod }}mins </span>
        </div>
        <div class="col-3 justify-content-end pe-0">
          <p class="mb-0 tr-time-span d-flex flex-column gap-2 text-end">
            <ng-container *ngIf="formatTime(lesson['lessontime'], lesson.lessonPeriod) != 'Started' && formatTime(lesson['lessontime'], lesson.lessonPeriod) != 'Completed'">after </ng-container>
            <span class="tr-time-duration text-red">
            {{ formatTime(lesson['lessontime'], lesson.lessonPeriod) }}
          </span>
          </p>
        </div>
      </div>
    </section>
    <section *ngIf="isLoading" class="tr-px-2">
      <div class="row p-0 m-0 mt-4">
        <div class="d-flex align-items-start gap-2 col-8 p-0 position-relative tr-blur">
          <tr-skeleton-image></tr-skeleton-image>
          <tr-double-line></tr-double-line>
        </div>
        <div class="col-4 d-flex align-items-center position-relative px-0 justify-content-start tr-blur">
          <tr-single-line></tr-single-line>
        </div>
      </div>
    </section>
  </section>
</main>