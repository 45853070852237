import { Component, EventEmitter, Input, OnInit, Output, SkipSelf, ViewChild } from '@angular/core';
import { TrHttpService } from '@core/tr-http.service';
import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';
import { Observable, of } from 'rxjs';
import { TrPaymentsService } from '@core/tr-payments.service';
import { TRObject } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@auth/auth.service';
@Component({
  selector: 'tr-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css'],
})
export class StripePaymentComponent extends TRObject implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  @Output() isSaveCard: EventEmitter<boolean> = new EventEmitter(false);
  @Output() isSubmissionSucceeded: EventEmitter<boolean> = new EventEmitter();
  @Input() isForSaveCard: boolean = false;
  _checkoutClientSecret!: string;
  _isSaveCard: boolean = true;
  _isSubmitButtonClicked: boolean = false;

  @Input()
  set isSubmitButtonClicked(isSubmitButtonClicked: any) {
    this._isSubmitButtonClicked = isSubmitButtonClicked;
    if (isSubmitButtonClicked) {
      if (this.isForSaveCard) {
        this.saveCard()
        return;
      }
      this.submitPayment();
    }
  }

  @Input()
  set clientSecret(client_secret: string) {
    this._checkoutClientSecret = client_secret;
    this.elementsOptions.clientSecret = client_secret;
  }

  paying = false;
  saving = false;

  elementsOptions: any = {
    locale: 'en',
  }

  constructor(private stripeService: StripeService, @SkipSelf() private auth: AuthService) {
    super();
  }

  ngOnInit(): void {
  }

  toggleSaveOption() {
    this._isSaveCard = !this._isSaveCard;
    this.isSaveCard.emit(this._isSaveCard)
  }

  saveCard() {
    this.saving = true;
    this.stripeService.confirmSetup({
      elements: this.paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: (this.auth.user$.getValue().fname + " " + this.auth.user$.getValue().lname) || '',
            email: this.auth.user$.getValue().email || '',
            // address: {
            //   line1: this.paymentElementForm.get('address').value || '',
            //   postal_code: this.paymentElementForm.get('zipcode').value || '',
            //   city: this.paymentElementForm.get('city').value || '',
            // }
          }
        }
      },
      redirect: 'if_required'
    }).subscribe((result: any) => {

      this.saving = false;

      console.log('ResultResultResultResultResultResult', result);

      if (result.error) {
        this.isSubmissionSucceeded.emit(result);
        // alert({ success: false, error: result.error.message });
      } else {

        if (result.setupIntent.status === 'succeeded') {
          this.isSubmissionSucceeded.emit(result);
        }
      }

    });
  }

  submitPayment() {
    this.paying = true;
    this.stripeService.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: (this.auth.user$.getValue().fname + " " + this.auth.user$.getValue().lname) || '',
            email: this.auth.user$.getValue().email || '',
            // address: {
            //   line1: this.paymentElementForm.get('address').value || '',
            //   postal_code: this.paymentElementForm.get('zipcode').value || '',
            //   city: this.paymentElementForm.get('city').value || '',
            // }
          }
        }
      },
      redirect: 'if_required'
    }).subscribe((result: any) => {

      this.paying = false;

      console.log('ResultResultResultResultResultResult', result);

      if (result.error) {
        this.isSubmissionSucceeded.emit(result);
        // alert({ success: false, error: result.error.message });
      } else {

        if (result.paymentIntent.status === 'succeeded') {
          this.isSubmissionSucceeded.emit(result);
        }
      }

    });
  }
}
