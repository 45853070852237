import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
@Component({
  selector: 'tr-paypal-checkout',
  templateUrl: './paypal-checkout.component.html',
  styleUrls: ['./paypal-checkout.component.css']
})
export class PaypalCheckoutComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  showSuccess: any;
  showCancel: any;
  showError: any;
  payPalOrder: ICreateOrderRequest = {
    intent: 'CAPTURE',
    purchase_units: [{
      amount: {
        currency_code: 'GBP',
        value: '9.99',
        breakdown: {
          item_total: {
            currency_code: 'GBP',
            value: '9.99'
          }
        }
      },
      items: [{
        name: 'Enterprise Subscription',
        quantity: '1',
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'GBP',
          value: '9.99',
        },
      }]
    }]
  }
  constructor() { }

  ngOnInit(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'GBP',
      clientId: this.getPayPalClientId(),
      createOrderOnClient: (data) => this.payPalOrder,
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details: any) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.showCancel = true;

      },
      onError: err => {
        console.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      }
    };
  }

  getPayPalClientId() {
    return 'AUNyASr1J0_Tx4KVoa53XzuSE_ZFTFQAIHEhpBddNTtmd56oWTNAwcJUoY588lfFBM8CsgLsbsz1AsF9';
  }
}
