<main style="margin-bottom: 4rem;">
  <div class="tr-pb-2 m-0">
    <tr-form-notification></tr-form-notification>
  </div>
  <section class="row p-0 m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-6 col-lg-4 p-4 tr-bg-highlight">
      <section class="d-flex pb-4 tr-bdb">
        <div class="tr-profile-img me-3">
          <img src="{{lessonTutor$.profilePicture}}" alt="" *ngIf="lessonTutor$.profilePicture">
          <img src="assets/images/profile-placeholder.png" alt="" *ngIf="!lessonTutor$.profilePicture">
        </div>
        <div class="tr-profile-info w-100">
          <div class="d-flex align-items-center gap-3">
            <h4 class="tr-tutr-name mb-0 p-0">{{lessonTutor$.firstName}} {{lessonTutor$.lastName}}</h4>
            <span class="fi fi-af"></span>
          </div>
          <span class="tr-lite-text">Certified tutor</span>
          <p class="tr-rate d-flex align-items-center mb-3">
            <img src="assets/icons/rate.svg" alt="rate-icon" class="me-1"> {{lessonTutor$.rate}}
          </p>
          <div class="tr-lesson-type mb-3 d-flex align-items-start gap-3">
            <span> Subject</span>
            <p class="mb-0"> {{bookLessonService.bookLessonTypeDetails$.value.lessonSubject.name}}</p>
          </div>
          <div class="tr-lesson-type mb-3 d-flex align-items-start gap-3">
            <span>Lesson type</span>
            <p class="mb-0">{{bookLessonService.selectedLessonType$.getValue()}} </p>
          </div>
        </div>
      </section>

      <section class="py-4 px-0 tr-bdb">

        <h2 class="tr-main-title mb-4 p-0">Order Cost</h2>

        <p  class="tr-lesson-summary-cost-title">Lesson cost</p>

        <div class="tr-lesson-summary-cost-list">

          <ng-container *ngFor="let lesson of lessonsTimeSummary$ | async">
            <div class="tr-lesson-summary-lits-item d-flex justify-content-between">
              <div>
                <p class="tr-lesson-summary-duration">
                  {{lesson.period}} mins lesson
                </p>
                <p class="tr-lesson-summary-time">
                    {{ lesson.lessonDateTime | date : "EEE dd MMM" }} ,
                    {{ lesson.lessonDateTime | date : "hh:mm a" | lowercase }}
                </p>
              </div>
              <div class="tr-lesson-summary-lesson-price-item top10"><span trCurrency></span> {{lesson.price}}</div>
            </div>
          </ng-container>

        </div>

        <div *ngIf="discount$.getValue() != 0" class="tr-lesson-summary-bottom-labels mb-0 d-flex justify-content-between mt-3">
          <span>Discount</span>
          <p class="mb-0 tr-txt-green"> 
            {{ (discount$ | async) * 100 | number:'1.0-0' }}%
          </p>
        </div>
        <div class="tr-lesson-summary-bottom-labels mb-0 d-flex justify-content-between mt-3">
          <span>Cancellation</span>
          <p class="mb-0 tr-txt-green"> Free </p>
        </div>

      </section>

      <section class="py-4 px-0">
      	<div class="tr-lesson-summary-bottom-labels mb-0 d-flex justify-content-between">
          <span>Sub-total</span>
            <p class="mb-0">  
              <span *ngIf="(discount$ | async) > 0" class="tr-striketrough me-2"> <span trCurrency></span> {{ (totalPrice | async) | number:'1.2-2' || 'N/A' }} </span>
              <span> <span trCurrency></span> {{ (totalDiscountPrice$ | async) | number:'1.2-2' || 'N/A' }} </span>
          </p>      
        </div>
      </section>

    </div>
  </section>
</main>