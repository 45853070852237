<div class="tr-side-nav" id="tr-side-nav">
  <div class="tr-min">
    <div class="tr-side-nav-item displayTable" *ngFor="let item of items" [class]="item.class" (click)="item.function()"  (mouseover)="openSubmenu(item.id)" >
      <div class="displayTableCell" [routerLink]="item?.link" routerLinkActive="active" [id]=item.id>
        <img
          class="tr-side-nav-icon"
          src="assets/icons/sidenav/{{ item.icon }}.svg"
          alt=""
        /><br />
        {{ item.title }}
      </div>
    </div>
  </div>
  <div id="tr-side-menu-sub">
    <div class="tr-sub-menu-div" [ngClass]="sidePanelItemsLength > 0 ? 'visiblee' : 'invisible'">
      <tr-side-panel></tr-side-panel>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="mobileMenuModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mobileMenuModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content w-75">
      <div class="modal-body p-0 m-0">
        <div class="tr-pt-50">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div *ngFor="let item of mobileMenuItems" class="p-3 m-0 d-flex align-items-center gap-2 justify-content-between" [class]="'tr-mb-' + item.title"  [routerLink]="item.link" type="button" data-bs-dismiss="modal" aria-label="Close" > 
              <div class="d-flex align-items-center gap-2">
                  <img
                  class="tr-side-nav-icon"
                  src="assets/icons/sidenav/{{ item.icon }}-dark.svg"
                  alt=""
                />
                 {{ item.title }}             
              </div>
              <img src="assets/icons/right-arrow-black.svg" alt="right-arrow-black">
            </div>
        </div>
      </div>
    </div>
  </div>
</div>