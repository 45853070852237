<main class="row p-0 m-0 tr-flex-gap-2">
  <section class="row p-0 m-0 d-flex align-items-center justify-content-center p-3 p-md-0">
    <section class="col-md-7 px-0 pb-0 d-flex flex-column tr-flex-gap-2 tr-pt-2 col-xl-6">
      <ng-container *ngFor="let lesson of lessonTypes">
        <section class="tr-lesson-type tr-bg-highlight d-flex align-items-center justify-content-between p-4 position-relative" [ngClass]="{'cursor-pointer': lesson.title !== 'Subscription', 'selected': lesson.type === (bookLessonService.selectedLessonType$ | async)}" (click)="selectLessonType(lesson.type)">
          <div class="tr-lesson-type-select d-flex align-items-center justify-content-center">
            <label class="checkbox mt-2">
              <input type="radio" name="lesson_type"
               [disabled]="lesson.title === 'Subscription'"
               [checked]="(bookLessonService.selectedLessonType$ | async) == lesson.type" 
               value="trial" class="tr_checkbox__input" />
              <span class="tr_checkbox__inner"></span>
            </label>
            <div class="tr-lesson-time ms-4">
              <p class="mb-0">{{lesson.title}}</p>
              <span>{{lesson.hint}}</span>
            </div>
          </div>
<!--           <div class="d-flex flex-column">
            <span class="tr-small-text">Starts</span>
            <p class="mb-0 tr-lesson-type-price">{{lesson.price}}</p>
          </div> -->
          <span class="tr-soon-tag" *ngIf="lesson.title === 'Subscription'">Coming soon</span>
        </section>
      </ng-container>
    </section>
    <!-- <section class="col-4 col-lg-3 tr-ps-2 pe-0">
      <div class="lesson-type-right-sec"></div>
    </section> -->
  </section>
  <!-- <section class="row p-0 m-0 d-flex align-items-center justify-content-center">
    <div class="col-12 col-lg-10 p-0 m-0 tr-flex-gap-2 d-flex align-items-center justify-content-end">
      <div class="tr-lesson-type-bottom-sec flex-grow-1"></div>
      <div class="tr-lesson-type-bottom-sec flex-grow-1"></div>
      <div class="tr-lesson-type-bottom-sec flex-grow-1"></div>
      <div class="tr-lesson-type-bottom-sec flex-grow-1"></div>
    </div>
  </section> -->
</main>