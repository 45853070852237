<main class="tr-tutor-register-container container-fluid d-flex justify-content-center">
    <div class="tr-tutor-reg-wrapper col-md-8 col-lg-8 top30 bottom50">
        <tr-tutor-registration-form-header [tabClasses]="['tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-active-tab']" title="6. Tutor Terms of Service"></tr-tutor-registration-form-header>
        <section class="row d-flex justify-content-center top20">
            <div class="col-md-8 col-lg-8 col-10 text-center">
                In general, we want to acknowledge that you are working as a freelance contractor. you are providing services to tutor reach users on your own behalf.
            </div>
        </section>

        <section class="row d-flex justify-content-center top30">
            <div class="col-md-11 col-lg-11 col-11 tr-tutor-reg-policy-wrapper">
                <div class="col-md-4 col-lg-4 top20 bottom20">
                    <label class="tr-tutor-reg-policy-title">Terms & Conditions</label>
                </div>
                <div class="col-md-12 col-lg-12">
                    Tutorreach collects and processes personal data submitted by you upon registration and further editing of your Profile. For the registration purposes, you submit your login credentials (username and passwords), first name, last name and email. You may edit your Profile with additional information at your sole discretion. Additional information may help you to get more from the Services, for instance, finding students. At the same time, you are not strictly required to provide additional information, not specifically necessary for the registration. Therefore, it is your choice whether to include additional data to your profile, or not, such as zip code, country, language skills, education and work experience. <br><br>
                    
                    1. Some personal data, such as users' names, and location that is listed in your user profiles or derived from IP Addresses may be publicly visible to other users on the Services or to the web. <br> <br>
                    
                    2. We may implement a feature that permits you to choose what information is made public by changing your privacy setting in “Edit My Profile” within the Service.<br><br>
                    3. Posting of Informatio We process personal data freely submitted to you on Preply. This may be survey you fill in on Tutorreach or feedback you provide us with. Irrespective of whether you are a registered user or not, this shall also cover situations when you post an article(s) or comment(s) on the Tutorreach Blog. Participants are required to fill in the registration forms to register for the competition and prove they are eligible. We process this information exclusively to organise and conduct the competition, determine the winner(s), and contact them regarding the results and prizes.
                </div>
            </div>
        </section>

        <section class="row top30 d-flex justify-content-center" [formGroup]="phaseFiveForm">
            <div class="col-md-11 col-12 text-left tr-tutor-reg-agree-statment ps-0 d-flex align-items-center cursor-pointer" (click)="toggleAgree()">
                <input type="checkbox" name="agree" class="tr-agree-checkbox me-2" value="agree" formControlName="agree" >
                I agree to the terms & conditions above.
            </div>
             <div class="error-message top10 col-md-11 col-12 text-left ps-0" *ngIf="phaseFiveForm.hasError('required', 'agree') && (phaseFiveForm.controls['agree'].touched)">
                Please Agree terms and conditions above to continue.
              </div>
        </section>

        <section class="row  d-flex justify-content-center top20 bottom30">
            <div class="col-md-11 col-11 d-sm-flex justify-content-center ps-0">
                <button class="col-md-1 col-sm-2 col-lg-1 col-12 tr-tutor-reg-back-btn tr-btn px-3 py-2" (click)="backToPrevPhase()">
                    <span *ngIf="!isLoading">Back</span>
                    <tr-spinner *ngIf="isLoading"></tr-spinner>
                </button>
                <div class="col-md-11 col-lg-11 col-sm-10 col-12 d-sm-flex justify-content-end">
                    <button (click)="submitApplication()" class="col-md-1 col-lg-1 col-sm-4 col-12 tr-tutor-reg-submit-btn left5 tr-btn px-3 py-2" [disabled]="phaseFiveForm.invalid">
                        <span *ngIf="!isLoading">Submit</span>
                       <tr-spinner *ngIf="isLoading"></tr-spinner>
                    </button>
                </div>
            </div>
        </section>

    </div>
</main>