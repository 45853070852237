import { Component, OnInit, Self, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { sideNavItemNames } from 'src/app/shared/models/shared';
import { BookLessonService } from './book-lesson/book-lesson.service';
import { FindTutorService } from './find-tutor/find-tutor.service';

@Component({
  selector: 'tr-student-tutors',
  templateUrl: './student-tutors.component.html',
  styleUrls: ['./student-tutors.component.css'],
  providers: [BookLessonService, FindTutorService]
})

export class StudentTutorsComponent implements OnInit {
  sideNavItemNames = sideNavItemNames;
  isScreenBelow768px: boolean = window.innerWidth < 768;

  constructor(private router: Router, @Self() public bookLessonService: BookLessonService) {
  }

  handleScroll(event: Event) {
    if (this.isScreenBelow768px) {
      const scrollPosition = (event.target as Element).scrollTop;
      const header = document.getElementById('tr-fixed-header-row');
      const slotsSection = document.getElementById('tr-slots-section');
      if (header && slotsSection) {
        let height = slotsSection.offsetHeight;
        height = height + 50;
        if(scrollPosition > 160){
          const topPosition = scrollPosition - height;
          header.style.transform = `translateY(${topPosition}px)`;
        }
        else{
           header.style.transform = `translateY(0px)`;
        }
      }
    }
  }

  ngOnInit(): void {
    this.isScreenBelow768px = window.innerWidth < 768;
  }

}