import { Injectable, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { AuthService } from '@auth/auth.service';
import { validateObjKey } from '@shared/models/validation';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { data } from 'jquery';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UserLessonsService {

  isListView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  lessonAction$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  complaintLessonId$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  isFetching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private trHttp: TrHttpService) {}

  fetch(endPoint: any, params: any, method: HttpMethod = HttpMethod.GET): Observable<any> {
    return this.trHttp.fetch(endPoint, params, method);
  }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  raiseComplaint(formData:any){
    return this.fetchCore("add-complaint", formData, HttpMethod.POST);
  }

  filterLessons(params: any, isMyRequest: boolean = false) {
    this.isFetching$.next(true);
    const endPoint: string = isMyRequest ? 'user-lessons-requests' : 'user-lessons';
    return this.fetch(endPoint, this.getFormData(params)).pipe(
      tap(
        (success: any) => {
          this.isFetching$.next(false)
        },
        (error: any)=>{
          this.isFetching$.next(false)
        }
      )
    )
  }

  getFormData(params: any) {
    let formData = new FormData();
    for (let key in params) {
      if (validateObjKey(key, params)) {
        formData.append(key, params[key]);
      }
    }
    return formData;
  }

  getSubjects() {
    return this.fetchCore('subjects-list-find-tutor')
  }

  directCancelLesson(formData: any) {
    return this.fetch("user-direct-cancel", formData, HttpMethod.POST);
  }

  cancelRequest(formData: any) {
    return this.fetch("user-cancel-request", formData, HttpMethod.POST);
  }

  approveReschedule(formData: any) {
    return this.fetch("user-approve-reschedule", formData, HttpMethod.POST);
  }


}
