import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DOCUMENT } from '@angular/common';
import { BOOKING_PHASES, HttpMethod, LESSON_TYPE } from '@shared/models/shared';
import { TrHttpService } from '@core/tr-http.service';
import { tap } from 'rxjs/operators';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
@Injectable({
  providedIn: 'root'
})
export class BookLessonService {

  constructor(@Inject(DOCUMENT) private document: Document, 
    private trHttp: TrHttpService,public schedulerService:SchedulerNextService) { }

  openBookingWindow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingPhase$: BehaviorSubject<string> = new BehaviorSubject<string>(BOOKING_PHASES.LESSON_TYPE_DETAILS); //LESSON_TYPE_DETAILS
  tutorsAvailabilities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  isSchedulerModuleLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedLessonType$: BehaviorSubject<string> = new BehaviorSubject<string>(LESSON_TYPE.TRIAL);
  bookLessonTypeDetails$: BehaviorSubject<any> = new BehaviorSubject<any>({ lessonSubject: { id: null, name: null }, lessonSpecialityId: null, lessonTypeAgeGroupId: null });
  selectedTutor$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  studentBookingSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  studentBookingLesson$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lessonSummaryPrice$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  calculateLessonPriceFormData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.POST): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  enableDisablePageScroll(prop: string) {
    if(prop === 'scroll'){
        this.document.body.classList.remove('tr-overflow-hidden');
    }
    else{
        this.document.body.classList.add('tr-overflow-hidden');
    }
  }

  nextBookingPhase(phaseName: string) {
    this.bookingPhase$.next(phaseName);
  }

  previousPhase(phaseName: string) {
    this.bookingPhase$.next(phaseName);
  }

  getTutorsAvailabilities(tutor: any) {
    this.tutorsAvailabilities$.next(tutor);
  }

  closeBookingWindow() {
    this.openBookingWindow$.next(false);
    this.schedulerService.selectedSlots$.next([]);
    this.enableDisablePageScroll('scroll');
    this.nextBookingPhase(BOOKING_PHASES.LESSON_TYPE_DETAILS);
    this.bookLessonTypeDetails$.next({ lessonSubject: { id: null, name: null }, lessonSpecialityId: null, lessonTypeAgeGroupId: null })
  }

  bookLesson() {
    let formData = new FormData();
    formData.append("tutorId", this.selectedTutor$.getValue().id);
    formData.append("lessons", JSON.stringify(this.studentBookingLesson$.getValue()));
    formData.append("subjectId", this.bookLessonTypeDetails$.getValue().lessonSubject.id);
    formData.append("specialistId", this.bookLessonTypeDetails$.getValue().lessonSpeciality);
    formData.append("ageId", this.bookLessonTypeDetails$.getValue().lessonTypeAgeGroupId);
    formData.append("type", this.selectedLessonType$.getValue());
    return this.fetch('student-booking', formData);
  }

  holdSlots() {
    const bookedSlots: any = this.studentBookingSlots$.getValue();
    const recordsId: any = [];
    for (let i = 0; i < bookedSlots.length; i++) {
      recordsId.push({ recordId: bookedSlots[i].recordId, dateTime: bookedSlots[i].dateTime })
    }
    let formData = new FormData();
    formData.append("tutorId", this.selectedTutor$.getValue().id);
    formData.append("recordsId", JSON.stringify(recordsId));
    formData.append("type", this.selectedLessonType$.getValue());
    return this.fetch('student-hold-slots', formData)
  }

  getLessonPrice() {

    let formData = new FormData();
    formData.append("tutorId", this.selectedTutor$.getValue().id);
    formData.append("lessons", JSON.stringify(this.studentBookingLesson$.getValue()));
    formData.append("subjectId", this.bookLessonTypeDetails$.getValue().lessonSubject.id);
    formData.append("specialistId", this.bookLessonTypeDetails$.getValue().lessonSpecialityId);
    formData.append("ageId", this.bookLessonTypeDetails$.getValue().lessonTypeAgeGroupId);
    formData.append("type", this.selectedLessonType$.getValue());
    this.calculateLessonPriceFormData$.next(formData); // store the formData in observable to use it later in the checkout page
    return this.fetch('calculate-booking-price', formData).pipe(
      tap(
        (res: any) => {
          this.lessonSummaryPrice$.next(res.data)
        }
      )
    );
  }

  getTutorLessonTypeInfo() {
    const tutorId: any = this.selectedTutor$.getValue().id;
    return this.fetch('lesson-subjects-details?tutorId=' + tutorId, '', HttpMethod.GET);
  }

  getDiscountPrices(){
     let data  = this.bookLessonTypeDetails$.value
     const tutorId: any = this.selectedTutor$.getValue().id;
     let formData = new FormData();
     formData.append("subjectId", data['lessonSubject']['id']);
     formData.append("ageId", data['lessonTypeAgeGroupId']);
     formData.append("specialistId", data['lessonSpecialityId']);
     formData.append("tutorId", tutorId);
     return this.fetch('user-price-for-subject', formData, HttpMethod.GET)
   }  

   getDiscounts(){
     let data  = this.bookLessonTypeDetails$.value
     const tutorId: any = this.selectedTutor$.getValue().id;
     let formData = new FormData();
     formData.append("tutorId", tutorId);
     return this.fetch('discount-lits-by-tutor-id', formData, HttpMethod.GET)
   }
}