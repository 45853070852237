import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalComponent } from '@components/shared-components/payment-components/paypal/paypal.component';
import { PaypalCheckoutComponent } from '@components/shared-components/payment-components/paypal/paypal-checkout/paypal-checkout.component';
import { NgxStripeModule } from 'ngx-stripe';
import { Stripe_Publishable_Key } from '@shared/models/shared';
import { SharedModule } from '@modules/shared.module';
import { FormNotificationModule } from '@modules/form-notification.module';
import { StripePaymentComponent } from '@components/shared-components/payment-components/stripe/stripe-payment/stripe-payment.component';
import { TrPaymentsService } from '@core/tr-payments.service';
import { SavedCardsComponent } from '@components/shared-components/payment-components/saved-cards/saved-cards.component';

@NgModule({
  declarations: [
    PaypalComponent,
    PaypalCheckoutComponent,
    StripePaymentComponent,
    SavedCardsComponent,
  ],
  imports: [
    CommonModule,
    NgxPayPalModule,
    SharedModule,
    FormNotificationModule,
    NgxStripeModule.forRoot(Stripe_Publishable_Key),
  ],
  exports: [
    PaypalComponent,
    PaypalCheckoutComponent,

    StripePaymentComponent,
    SavedCardsComponent,
  ],
  providers:[
    TrPaymentsService
  ]
})

export class PaymentModule { }