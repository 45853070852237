<div class="row p-0 m-0" *ngIf="isLoading">
    <div class="col-lg-8 px-0 py-4">
        <section class="tr-tutor-tile-bg p-0 mb-4 position-relative d-flex flex-column gap-4">
           <tr-find-tutor-skeleton-loader></tr-find-tutor-skeleton-loader>
        </section>
    </div>
</div>
<section *ngIf="!isLoading">
<div class="row p-0 m-0 bgWhite py-4 mt-4" *ngIf="!savedTutors || savedTutorsLength == 0">
	<div class="col-md-12 d-flex align-items-center flex-column justify-content-center pt-2">
		<img src="assets/images/saved-tutors.png" alt="saved-tutors-img" class="tr-saved-tutors-img">
		<p class="tr-save-tutor-des mt-4 mb-3"> Favourite tutor using 
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			    <path [attr.d]="saveSvgPath" fill="red" stroke-width="1.6"/>
			</svg> 
             icon
        </p>
        <p class="tr-save-tutor-sub-des mb-4">
        	Browse tutors on <span>Find a Tutor </span> Page.
        </p>
        <a class="btn btn-primary mt-2 pb-2 tr-btn" routerLink="/student/tutors/find-tutor">Browse tutors</a>
	</div>
</div>
<ng-container *ngIf="savedTutors && savedTutorsLength > 0">
	<div class="row p-0 m-0">
	   <tr-toggle-display [title]="'Favourite tutors'"  [tutorsCount]="savedTutorsLength"></tr-toggle-display>
	</div>
	<div class="row p-0 m-0 " id="tutorTile">
	    <div class="col-lg-8 px-0" [ngClass]="{'col-lg-12 d-flex flex-wrap gap-3': !(isListView|async)}">
	        <ng-container *ngFor="let tutor of savedTutors">
	      		<tr-tutor-tile #tutorTiles class="tr-tutor-tile" id="trTutorTile_{{tutor.id}}" [tutor]="tutor" [role]="'student'"   [ngClass]="(this.findTutorService.isListView$ | async) ? 'tr-is-list-view' : 'tr-is-grid-view'" (mouseover)="getTutorOnHover(tutor)">
	      		</tr-tutor-tile>
	        </ng-container>
	    </div>
	    <div class="col-lg-4 d-none d-lg-flex position-relative" [ngClass]="{'d-lg-none': !(isListView|async)}" id="tr-tutor-scroll-col">
	        <tr-tutor-scroll-display [ngStyle]="{'min-width.%': 100}" (playVideoStatus)="playVideo()"></tr-tutor-scroll-display>
	    </div>
	 </div>
</ng-container>
<ng-container *ngIf="targetedTutor">
	<tr-video-player [videoLink]="targetedTutor.videoLink | safe" [showPlayer]="showPlayer" (onClosePlayer)="this.showPlayer = false"></tr-video-player>
</ng-container>
</section>

<div class="tr-middle-screen " id="trFindTutorCrossScreen"></div>