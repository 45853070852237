<main class="tr-tutor-register-container container-fluid d-flex justify-content-center">
   <div class="tr-tutor-reg-wrapper col-md-8 col-lg-8 top30 bottom50">
      <tr-tutor-registration-form-header [tabClasses]="['tr-reg-comp-tab','tr-reg-active-tab','tr-reg-remain-tab','tr-reg-remain-tab','tr-reg-remain-tab','tr-reg-remain-tab' ]" title="2. Personal Information"></tr-tutor-registration-form-header>
      <form [formGroup]="phaseTwoForm" (ngSubmit)="onSubmitPhaseTwoForm()">
      <section class="row">
         <div class="col-md-12 col-lg-12">
            <div class="row d-flex justify-content-around">
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row  top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">First name</label>
                        <input class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input" formControlName="firstName" type="text" placeholder="First name"/>
                     </div>
                     <div class="col-md-12 top5" *ngFor= "let error of errorMessages.firstName">
                        <ng-container *ngIf="phaseTwoForm.controls['firstName'].hasError(error.type) && (phaseTwoForm.controls['firstName'].touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row  top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Last name</label>
                        <input class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input" formControlName="lastName" type="text" placeholder="Last name" />
                     </div>
                     <div class="col-md-12 top5" *ngFor= "let error of errorMessages.lastName">
                        <ng-container *ngIf="phaseTwoForm.controls['lastName'].hasError(error.type) && (phaseTwoForm.controls['lastName'].touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="row">
         <div class="col-md-12 col-lg-12">
            <div class="row d-flex justify-content-around">
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row  top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12  tr-tutor-reg-label">Living in</label>
                        <select class="col-md-12 col-lg-12  col-12 top5 tr-tutor-reg-input tr-tutor-reg-default-option" type="text" formControlName="countryId">
                           <option value="" hidden>Select Country</option>
                           <option *ngFor="let country of this.countries | async" [value]="country.id">
                           {{country.name}}
                           </option>
                        </select>
                     </div>
                     <div class="col-md-12 top5" *ngFor= "let error of errorMessages.countryId">
                        <ng-container *ngIf="phaseTwoForm.controls['countryId'].hasError(error.type) && (phaseTwoForm.controls['countryId'].touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row  top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Mobile no.</label>
                        <!--                                 <select class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input tr-tutor-reg-default-option" type="text">
                           <option value="" hidden disabled selected>Mobile no.</option>
                           </select> -->
                        <ngx-intl-tel-input
                        [cssClass]="'tr-tutor-reg-input'"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true"
                        [selectedCountryISO]="CountryISO.India"
                        [maxLength]="15"
                        [separateDialCode]="true"
                        name="phone"
                        formControlName="mobileNumber"
                        >
                        </ngx-intl-tel-input>
                     </div>
                      <div class="col-md-12 top5" *ngFor= "let error of errorMessages.mobileNumber">
                        <ng-container *ngIf="phaseTwoForm.controls['mobileNumber'].hasError(error.type) && (phaseTwoForm.controls['mobileNumber'].touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="row">
         <div class="col-md-12 col-lg-12">
            <div class="row d-flex justify-content-around">
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Gender</label>
                        <select class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input tr-tutor-reg-default-option" type="text" formControlName="gender">
                           <option value="" hidden disabled selected>Gender</option>
                           <option value="m">Male</option>
                           <option value="f">Female</option>
                        </select>
                     </div>
                     <div class="col-md-12 top5" *ngFor= "let error of errorMessages.gender">
                        <ng-container *ngIf="phaseTwoForm.controls['gender'].hasError(error.type) && (phaseTwoForm.controls['gender'].touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </div>
               <div class="col-md-5 col-lg-5 col-sm-5 col-10">
                  <div class="row top20">
                     <div class="col-md-12 col-lg-12 col-12">
                        <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Price per hour</label>
                        <div class="col-12">
                         <div class="btn-group">
                           <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              <p class="currency-flag d-flex align-items-center mb-0" [class]="'currency-flag-'+ '' + selectedCurrency | lowercase">
                                 <span class="tr-currency-code">{{selectedCurrency}}</span>
                              </p>
                           </button>
<!--                            <ul class="dropdown-menu">
                              <div>
                                 <input type="search" class="w-100 mb-3 tr-dropdown-search" placeholder="Search Currency " name="search" [(ngModel)]="searchText"  [ngModelOptions]="{standalone: true}">
                                 
                              </div>
                              <div class="tr-dropdown-li">
                              <ng-container *ngFor="let currency of currencies | async" >
                              <li  class="d-flex align-items-center justify-content-center mb-2 cursor-pointer" *ngIf="searchText === '' || currency.code.toLowerCase().includes(searchText | lowercase)" (click)="this.selectedCurrency = currency.code">
                                 <div class="d-flex align-items-center justify-content-between w-50">
                                    <p class="currency-flag d-flex align-items-center cursor-pointer mb-0" [class]="'currency-flag-'+ '' + currency.code | lowercase">
                                    </p>
                                    <span>{{currency.code}}</span>
                                 </div>
                              </li>
                              </ng-container>
                              </div>
                           </ul> -->
                        </div>
                        <input class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input ml-80" formControlName="pricePerHour" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" /> 
                        </div>
                        <div class="col-md-12 top5" *ngFor= "let error of errorMessages.pricePerHour">
                           <ng-container *ngIf="phaseTwoForm.controls['pricePerHour'].hasError(error.type) && (phaseTwoForm.controls['pricePerHour'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="row top20">
         <div class="col-md-12 col-lg-12">
            <div class="row d-flex justify-content-center">
               <div class="col-md-11 col-lg-11 col-sm-11 col-10 " *ngIf="!nationalId">
                  <label class="tr-tutor-reg-label mb-1">National ID</label>
                  <ngx-dropzone (change)="selectNationalIdImage($event)" style="flex-direction: column-reverse;height: auto !important; min-height: 180px;width: 100%; justify-content: center;" accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                     <div class="row">
                        <div class="col-md-12 text-center">
                           <div class="row">
                              <div class="col-md-12 col-lg-12" *ngIf="!file">
                                 <img src="../../assets/icons/upload-file.svg" class="tr-drop-zone-upload-file" alt="upload-logo"><br>
                                 <span class="tr-drop-zone-info-label">Drag file</span><br>
                                 <ngx-dropzone-label class="tr-drop-zone-file-types" trans="(jpg, png max. size 2MB)">(jpg, png max. size 2MB)</ngx-dropzone-label>
                                 <div class="tr-drop-zone-browse-shape top10"><span>Browse</span></div>
                              </div>
                              <div class="col-md-12 col-lg-12">
                                 <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of file" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                 <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                 </ngx-dropzone-image-preview>
                              </div>
                           </div>
                        </div>

                     </div>
                  </ngx-dropzone>
                  <p *ngIf="!file && fileUploadTouched"><span class="error-message" trans="National Id image is required">National Id image is required</span></p>
               </div>
               <div class="col-md-11" *ngIf="nationalId">
                   <label class="tr-tutor-reg-label mb-1">National ID</label>
                  <div class="d-flex align-items-center justify-content-center justify-content-md-start tr-bg-highlight tr-national-id-sec">
                    <img [src]="nationalId" alt="nationalid" class="img img-fluid tr-national-id-img">
                  </div>
                  <div>
                     <button class="tr-btn tr-btn-outline-primary mt-2" (click)="changeNationalId()"> Change photo</button>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="row">
         <div class="col-md-12 col-lg-12" formArrayName="spokenLanguages">
            <div class="row d-flex justify-content-around" *ngFor="let itemRow of subjArr.controls; let i=index;" [formGroupName]="i" >
            <div class="col-md-5 col-lg-5 col-sm-5 col-10">
               <div class="row  top20">
                  <div class="col-md-12 col-lg-12 col-12">
                     <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Speak language</label>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12 col-lg-12 col-12">
                     <select class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input tr-tutor-reg-default-option" type="text" formControlName="langId">
                        <option value="" hidden disabled selected>Select language</option>
                        <option *ngFor="let language of languages| async" [value]="language.id">
                        {{language.name}}
                        </option>
                     </select>
                  </div>
               </div>
               <span class="error-message top5" *ngIf="itemRow.get('langId')?.touched && itemRow.get('langId')?.errors && itemRow.get('langId')?.hasError('required')">
                          {{errorMessages.langId[0].message}}               
               </span>
            </div>
            <div class="col-md-5 col-lg-5 col-sm-5 col-10">
               <div class="row  top20">
                  <div class="col-md-12 col-lg-12 col-12">
                     <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label">Proficiency</label>
                  </div>
               </div>
               <div class="row position-relative">
                  <div class="col-12" [ngClass]="i != 0?'col-sm-10':'col-md-12'">
                     <select class="col-md-12 col-lg-12 col-12 top5 tr-tutor-reg-input tr-tutor-reg-default-option" type="text" formControlName="profId">
                        <option value="" hidden disabled selected>Select level</option>
                        <option *ngFor="let prof of this.proficiencyLevels | async" [value]="prof.id">
                        {{prof.name}}
                        </option>
                     </select>
                  </div>
                  <span class="error-message tr-error-message" *ngIf="itemRow.get('profId')?.touched && itemRow.get('profId')?.errors && itemRow.get('profId')?.hasError('required')">
                          {{errorMessages.profId[0].message}}               
                  </span>
                  <div class="col-sm-1 col-12 d-flex align-items-center justify-content-center mt-4 mt-sm-0" *ngIf="subjArr.controls.length > 1 && i != 0" (click)="deleteSubject(i)">
                     <a class="tr-delete-subj">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" />
                        </svg>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="row top20 ">
      <div class="col-md-12 col-lg-12 d-flex justify-content-center">
         <div class="col-md-11 col-lg-11 tr-tutor-reg-add-another-language" (click)="addNewSubject()">
            + Add another language
         </div>
      </div>
   </section>
   <section class="row top20 bottom20">
      <div class="col-md-12 col-lg-12 d-flex justify-content-center">
         <div class="col-md-11  col-10 d-flex flex-column flex-sm-row justify-content-end">
            <button class="col-md-2 col-lg-2 col-sm-6 col-12 tr-tutor-reg-save-btn tr-btn" 
            (click)="savePhaseTwo()">
            <span *ngIf="!isLoading">Save for later</span>
            <tr-spinner *ngIf="isLoading"></tr-spinner>
           </button>
            <button class="col-md-1 col-lg-1 col-sm-6 col-12 tr-tutor-reg-next-btn left10 tr-btn" [disabled]="phaseTwoForm.invalid" (click)="nextPhase()">
               <span *ngIf="!isLoading">Next</span>
               <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
         </div>
      </div>
   </section>
</form>
</div>
</main>