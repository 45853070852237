import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod, TRObject } from '@shared/models/shared';
import 'moment-timezone';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StudentAccountSettingsService extends TRObject{
  countriesMap: any = new Map();
  countries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private trHttp: TrHttpService) {
    super()
  }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET) {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getCountries() {
    this.fetchCore('countries/list').subscribe(
      (response: any) => {
        this.countries$.next(response.data);
        response.data.map((country: any) => {
          this.countriesMap.set(country.Id, country);
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  checkUserName(userName: any) {
     const formData = new FormData();
     formData.append('userName', userName)
     return this.fetchCore('user-name-validation', formData, HttpMethod.POST)
  }

  updateInfo(form:any){
     return this.fetchCore('student-info', form, HttpMethod.POST)
  }  

  updatePassword(form:any){
     return this.fetchCore('user-password', form, HttpMethod.POST)
  }

  getUserProfile(){
    return this.fetchCore('student-profile')
  }  

  sendVerificationCode(form:any , isMobile:boolean = false){
    const endPoint: string = isMobile ? 'user-send-mobile-confirmation-code' : 'user-send-email-confirmation-code';
    return this.fetchCore(endPoint, form, HttpMethod.POST)
  }  

  updateEmail(email:any, code:any){
    const formData = new FormData();
    formData.append('email', email)
    formData.append('confirmation_code', code)
    return this.fetchCore('user-email', formData, HttpMethod.POST)
  }

  updateMobile(phone:any, code:any){
    const formData = new FormData();
    formData.append('mobileNumber', phone)
    formData.append('confirmation_code', code)
    return this.fetchCore('update-user-mobile', formData, HttpMethod.POST)
  }

}
