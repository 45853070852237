import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tr-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.css']
})
export class SvgComponent implements OnInit {
  @Input()
  path:string = '';

  @Input()
  width:any = 24;  

  @Input()
  height:any = 24;  

  @Input()
  viewBox:any ='0 0 24 24';


  constructor() { }

  ngOnInit(): void {
  }

}
