import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormDataService } from '@core/form-data.service';
import { BehaviorSubject } from 'rxjs';
import { BookLessonService } from '../book-lesson.service';
import { TRObject } from '@shared/models/shared';
@Component({
  selector: 'tr-book-lesson-type-details',
  templateUrl: './book-lesson-type-details.component.html',
  styleUrls: ['./book-lesson-type-details.component.css']
})
export class BookLessonTypeDetailsComponent extends TRObject implements OnInit {
  lessonAgeGroup: any = '';
  lessonTypeDetails$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  subjects$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  specialties$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  ageGroups$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectedSubject: any = 'Select your Subject';
  selectedSpecialty: any = 'Select your Specialty';
  selectedGroup: any;
  constructor(@SkipSelf() public formDataService: FormDataService, @SkipSelf() public bookLessonService: BookLessonService) {
    super();

    this.bookLessonService.getTutorLessonTypeInfo().subscribe(
      (res: any) => {
        this.lessonTypeDetails$.next(res.data)
        this.subjects$.next(res.data.subjects);
      }
    )
  }

  ngOnInit(): void {
  }

  set selectedAgeGroup(id: any) {
    this.bookLessonService.bookLessonTypeDetails$.value.lessonTypeAgeGroupId = id;
  }

  onSubjectSelect(selectedSubject: any) {
    this.bookLessonService.bookLessonTypeDetails$.value.lessonSubject.id = selectedSubject.id
    this.bookLessonService.bookLessonTypeDetails$.value.lessonSubject.name = selectedSubject.name
    this.specialties$.next(this.selectedSubject.specialties);
    this.selectedSpecialty = 'Select your Specialty';
    this.selectedAgeGroup = null;
  }

  onSpecialtySelect(selectedSpecialty: any) {
    this.bookLessonService.bookLessonTypeDetails$.value.lessonSpecialityId = selectedSpecialty.id
    this.ageGroups$.next(this.selectedSpecialty.ageGroups);
    this.selectedAgeGroup = null;
  }

  checkInput(groupId: any) {
    this.selectedAgeGroup = groupId;
    this.selectedGroup = groupId;
  }

}