<div class="tr-tutor-register-container container-fluid d-flex justify-content-center">
   <div class="tr-tutor-reg-wrapper col-md-11 col-lg-8 top30 bottom50">
      <tr-tutor-registration-form-header [tabClasses]="['tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-active-tab', 'tr-reg-comp-tab']" title="5. Tutor's resume"></tr-tutor-registration-form-header>
      <div class="row d-flex justify-content-center top30">
         <div class="col-md-11 col-lg-11 col-11 py-4 tr-px-2 tr-tutor-reg-policy-wrapper">
            <label class="tr-tutor-reg-policy-title mb-2">Enhance your profile credibility and get more students</label>
            <p class="tr-tutor-reg-education mb-2">
               <tr-svg [path]="phaseFoureducationIcon"></tr-svg>
               <span class="ms-3">Add education and teaching experience related to your teaching languages and get a <b> “Professional tutor” </b> title </span>
            </p>
            <p class="tr-tutor-reg-verified mb-0">
               <tr-svg [path]="verifiedBadgeIcon"></tr-svg>
               <span class="ms-3">Add certificate and get a <b>“Certificate verified”</b> badge </span>
            </p>
         </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
         <div class="col-11 px-0 tr-py-2">
            <tr-form-notification #notificationAlertChild></tr-form-notification>
         </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
         <div class="col-11 px-0 tr-py-2 tr-bdb">
            <div class="tr-education-row d-flex align-items-center justify-content-between">
               <label for="education">Education <span>(optional)</span>
               </label>
               <a data-bs-toggle="modal" data-bs-target="#addEducationModal" class="cursor-pointer text-decoration-none"> + add</a>
            </div>
            <div class="mt-4 pb-3 d-flex align-items-center justify-content-between" *ngFor= "let education of educations">
                <div class="tr-uploaded-data d-flex align-items-start">
                    <span class="tr-uploaded-date me-4">{{ education.yearsOfStudyFrom | date: 'M/y' }} - {{ education.yearsOfStudyTo | date: 'M/y'}}</span>
                    <div class="tr-uploaded-data">
                       <p class="mb-0">{{ education.degreeName}} - {{ education.major }}</p>
                       <span> {{ education.universityName}} </span>
                       <p class="tr-uploaded-proof d-flex align-items-center mb-0"><img src="assets/icons/checked-icon.svg" alt="verified-icon" class="me-2" *ngIf="education.attachment"> Attachment uploaded</p>
                    </div>
                </div>
               <tr-svg [path]="deleteIconPath" (click)="deleteRecordById(education.id, 'education')"></tr-svg>
            </div>            
         </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
         <div class="col-11 px-0 tr-py-2 tr-bdb">
            <div class="tr-education-row d-flex align-items-center justify-content-between">
               <label for="education">Teaching experience <span>(optional)</span>
               </label>
               <a data-bs-toggle="modal" data-bs-target="#addExperienceModal" class="cursor-pointer text-decoration-none"> + add</a>
            </div>
            <div class="mt-4 d-flex align-items-center justify-content-between" *ngFor= "let experience of experiences">
                <div class="tr-uploaded-data d-flex align-items-start">
                    <span class="tr-uploaded-date me-4">{{ experience.yearsOfExperienceFrom | date: 'M/y' }} - {{ experience.yearsOfExperienceTo | date: 'M/y' }}</span>
                    <div class="tr-uploaded-data">
                       <p class="mb-0">{{experience.position}} - {{experience.company}}</p>
                       <span> {{experience.description}}</span>
                    </div>
                </div>
               <tr-svg [path]="deleteIconPath" (click)="deleteRecordById(experience.id, 'experience')"></tr-svg>
            </div>
         </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
         <div class="col-11 px-0 tr-py-2 tr-bdb">
            <div class="tr-education-row d-flex align-items-center justify-content-between">
               <label for="education">Certificates <span>(optional)</span>
               </label>
               <a data-bs-toggle="modal" data-bs-target="#addCertificateModal" class="cursor-pointer text-decoration-none"> + add</a>
            </div>
            <div class="mt-4 d-flex align-items-center justify-content-between" *ngFor= "let certificate of certificates">
                <div class="tr-uploaded-data d-flex align-items-start">
                    <span class="tr-uploaded-date me-4">{{certificate.yearsOfStudyFrom | date: 'M/y' }} - {{certificate.yearsOfStudyTo | date: 'M/y'}}</span>
                    <div class="tr-uploaded-data">
                       <p class="mb-0">{{certificate.certificateName}} - {{certificate.subjectName}}</p>
                       <span> {{ certificate.issuedBy}} </span>
                       <p class="tr-uploaded-proof d-flex align-items-center mb-0"><img src="assets/icons/checked-icon.svg" alt="verified-icon" class="me-2" *ngIf="certificate.attachment"> Attachment uploaded</p>
                    </div>
                </div>
                <tr-svg [path]="deleteIconPath" (click)="deleteRecordById(certificate.id, 'certificate')"></tr-svg>
            </div>
         </div>
      </div>
      <div class="row  d-flex justify-content-center top20 bottom30">
         <div class="col-md-11 col-11 d-sm-flex justify-content-center ps-0">
            <button class="col-md-1 col-sm-2 col-lg-1 col-12 tr-tutor-reg-back-btn tr-btn" (click)="backToPrevPhase()">
               <span *ngIf="!isLoading">Back</span>
               <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
            <div class="col-md-11 col-lg-11 col-sm-10 col-12 d-sm-flex justify-content-end">
               <button class="col-md-2 col-lg-2 col-sm-8 col-12 tr-tutor-reg-save-btn tr-btn" (click)="nextPhase(false)">
                 <span *ngIf="!isLoading">Save for later</span>
                  <tr-spinner *ngIf="isLoading"></tr-spinner>
               </button>
               <button (click)="nextPhase(true)" class="col-md-1 col-lg-1 col-sm-4 col-12 tr-tutor-reg-submit-btn left5 tr-btn">
                    <span *ngIf="!isLoading">Next</span>
                   <tr-spinner *ngIf="isLoading"></tr-spinner>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<tr-add-certificate-popup (onChangeStatus)="getPhaseFiveData()"></tr-add-certificate-popup>
<tr-add-education-popup (onChangeStatus)="getPhaseFiveData()"></tr-add-education-popup>
<tr-add-experience-popup (onChangeStatus)="getPhaseFiveData()"></tr-add-experience-popup>