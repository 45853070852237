<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#croppedImageModal" id="openModal" style="visibility: hidden;">
</button>
<!-- Modal -->
<div class="modal fade" id="croppedImageModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" >
      <div class="modal-body tr-padding-2">
        <div class="d-flex row p-0 m-0 flex-column-reverse flex-sm-row">
          <div class="col-md-8">
             <h3 class="p-0 mb-2 tr-crop-title">Edit thumbnail</h3>
            <image-cropper
                       [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="1"
                       [hideResizeSquares]="true"
                       [cropperMaxWidth]=800
                       [cropperMaxHeight]=800
                       [resizeToWidth]="300"
                       [resizeToHeight]="300"
                       format="png"
                       (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded($event)"
                       (cropperReady)="cropperReady()"
                       (loadImageFailed)="loadImageFailed()">
             
           </image-cropper>
          </div>
          <div class="col-md-4 col-6" *ngIf="imageChangedEvent">
             <h3 class="p-0 mb-2 tr-crop-title">Preview</h3>
            <img class="img img-fluid tr-crop-preview" [src]="croppedImage" />
          </div>
         </div>
      </div>
      <div class="modal-footer tr-crop-modal-footer p-0 m-0 d-flex align-items-center flex-column  justify-content-center">
         <div class="tr-crop">
            <p class="d-flex flex-column text-center">
               <span>Drag the shape to adjust preview image.</span>
               <span>This version of image will appear on the dashboard.</span>
               
            </p>
         </div>
         <div class="px-0 m-0 tr-pb-2 d-flex align-items-center tr-flex-gap-1">
           <button type="button" class="btn tr-discard-btn" data-bs-dismiss="modal" (click)="closeCropperDialog()">Discard</button>
           <button type="button" class="btn btn-primary tr-crop-btn" data-bs-dismiss="modal" (click)="saveImage()">crop & save image</button>
         </div>
      </div>
    </div>
  </div>
</div>
