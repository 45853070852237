import { Component, OnInit } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';


@Component({
  selector: 'tr-tutor-wallet',
  templateUrl: './tutor-wallet.component.html',
  styleUrls: ['./tutor-wallet.component.css']
})
export class TutorWalletComponent implements OnInit {
  sideNavItemNames = sideNavItemNames;
  constructor() { }

  ngOnInit(): void {
  }

}
