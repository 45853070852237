<div class="tr-class-room-main-container">
    <div class="container-fluid">
        <div class="tr-class-room-wrapper">
            <div class="row trMarginAuto">
                <!-- left section -->
                <div class="col-md-8">
                    <div class="row tr-class-room-header d-flex justify-content-between borderBlue">
                        <div class="col-md-3">
                            <div class="row justify-content-around">
                                <div class="col-md-2 tr-classroom-back-arrow top10">
                                    <img src="./assets/icons/classroom/backarrow.svg">
                                </div>
                                <div class="col-md-10">
                                    <span class="tr-lesson-subject">Arabic lesson</span><br>
                                    <span class="tr-lesson-subject-time">Thu 06 Mar 09:00 am, 30 min</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row justify-content-around">
                                <div class="col-md-3 tr-classroom-back-arrow">
                                    <img src="./assets/icons/classroom/invited-people.svg">
                                </div>
                                <div class="col-md-9">
                                    <span class="tr-lesson-subject">Invited to this lesson: 2</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="row justify-content-end">
                                <div class="col-md-3 tr-classroom-back-arrow">
                                    <img src="./assets/icons/classroom/absent-people.svg">
                                </div>
                                <div class="col-md-9">
                                    <span class="tr-lesson-subject">Absent: 1</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="row justify-content-end">
                                <div class="col-md-12 text-end">
                                    <span class="tr-lesson-time-remaining-label">Time remaining</span><br>
                                    <img src="./assets/icons/classroom/recording.svg"><span class="tr-lesson-time-remaining left5">03 : 00 : 01</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row tr-class-room-video-call-wrapper borderBlue">
                        <div class="col-md-12 tr-class-room-video-call-display">
                            <!-- live video goes here -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="row justify-content-around trMarginAuto">
                        <div class="col-md-10 tr-class-room-right-section borderBlue">
                            <div class="row trMarginAuto ">
                                <div class="col-md-6 text-center">Room Chat</div>
                                <div class="col-md-6 text-center">Participant</div>
                            </div>
                        </div>
                    </div>
                    <div class="row tr-right-section-body justify-content-around trMarginAuto">
                        <div class="col-md-10 borderBlue">
                            <!-- chat & participants goes here -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>