<main>
   <section>
      <div *ngFor="let log of lessonLogs; let isFirst = first; let index = index;">
         <div *ngIf="isFirst || isNewDate(log.date, lessonLogs[index - 1]?.date)">
            <h3 class="tr-date pb-4 mb-0" *ngIf="isToday(log.date)">Today</h3>
            <h3 class="tr-date py-4 mb-0" *ngIf="!isToday(log.date)">{{ log.date | date:'mediumDate' }}</h3>
         </div>
         <div class="tr-log d-flex align-items-center justify-content-between tr-bd">
            <div class="tr-log-user d-flex align-items-center gap-2">
               <img [src]="log.image" alt="user-img" *ngIf="log.image">
               <img src="assets/images/profile-placeholder.png" alt="user-img" *ngIf="!log.image">
               <p class="tr-user-name mb-0">{{ log.userName }}</p>
               <span class="tr-log-status-tag px-2 py-1 {{ log.type }}"> 
               <img [src]="LOG_TYPE_IMAGES[log.type]" alt="">
               {{ LOG_HISTORY_TYPE[log.type] }}</span>
               <span class="tr-desc">{{ log.message }}</span>
            </div>
            <span class="tr-log-date"> {{ formatTime(log.date) }}</span>
         </div>
      </div>
   </section>
</main>