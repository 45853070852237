import { Component, ElementRef, OnInit, QueryList, SkipSelf, ViewChild, ViewChildren } from '@angular/core';
import { FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod, TRObject, formatTimestamp } from '@shared/models/shared';
import { BookLessonService } from '../book-lesson.service';
import { extractLessonDateTime } from '@shared/models/scheduler';
import { TrPaymentsService } from '@core/tr-payments.service';
import { GATEWAYS } from '@shared/models/payment';
import { ToastService } from '@components/shared-components/toast.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'tr-book-lesson-checkout',
  templateUrl: './book-lesson-checkout.component.html',
  styleUrls: ['./book-lesson-checkout.component.css']
})
export class BookLessonCheckoutComponent extends TRObject implements OnInit {
  @ViewChild(FormNotificationComponent) formNotificationChild!: FormNotificationComponent;
  @ViewChildren('paymentOptionsSection') paymentOptionsSection!: QueryList<ElementRef>;

  infoMessage: string = "It's safe to pay on Tutor Reach. All transactions are protected by SSL encryption.";
  selectedPaymentOption: any;
  selectedCard$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  GATEWAYS: any = GATEWAYS;
  extractLessonDateTime: Function = extractLessonDateTime;
  totalPrice: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  totalPriceWithDiscount: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  walletBalance$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  finalPrice: any;
  discount$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  isSaveCard: boolean = true;
  processingFees$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  lessonsTimeSummary$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  savedPaymentMethods$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  paymentGateways$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  checkoutClientKey$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  checkoutPaymentIntentId$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  savedCardClientKey$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  checkoutPay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPaymentSucceeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  succeededPaymentDetails$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  gatewayId: any;
  payFromWallet: boolean = false;
  selectedTutor:any;
  isLoading:any;

  constructor(private router: Router, 
    private trHttp: TrHttpService, 
    @SkipSelf() public bookLessonService: BookLessonService, 
    public paymentService: TrPaymentsService, 
    private toastService: ToastService) {
    super();
    this.getPaymentGateways();
    this.getSavedPaymentMethods();

    this.bookLessonService.lessonSummaryPrice$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (lessonSummaryPrice: any) => {
        this.totalPrice.next(lessonSummaryPrice?.prices?.totalPrice);
        this.totalPriceWithDiscount.next(lessonSummaryPrice?.prices?.totalPriceWithDiscount);
        this.walletBalance$.next(lessonSummaryPrice?.prices?.walletBalance);
        this.finalPrice = lessonSummaryPrice?.prices?.finalPrice;
        this.discount$.next(lessonSummaryPrice?.prices?.discount);
        this.lessonsTimeSummary$.next(lessonSummaryPrice?.prices?.data);
        this.selectedTutor = this.bookLessonService.selectedTutor$.value;
        console.log(this.selectedTutor);

        if (lessonSummaryPrice.length == 0) {
          this.router.navigate(['student', 'tutors', 'find-tutor']);
        }
      }
    )
  }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.POST): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => { this.setNotificationAlert() });
    this.checkWalletPayment();
  }

  checkWalletPayment() {

    this.walletBalance$.pipe(takeUntil(this.ngUnsubscribe)).
      subscribe(
        (data: any) => {
          if (data > this.totalPrice.getValue()) {
            this.payFromWallet = true;
            // LOCK OTHER PAYMENTS
            this.lockPaymentOptions();

          }
        }
      )
  }

  lockPaymentOptions() {
    this.paymentOptionsSection.forEach((paymentOption: ElementRef) => {
      paymentOption.nativeElement.style.display = 'none';
      paymentOption.nativeElement.style.pointerEvents = 'none';
      paymentOption.nativeElement.style.cursor = 'not-allowed';
    });
  }
  isPaymentSuccessPopupClosed(isClosed: boolean) {
    let resetPaymentStatus:boolean = !isClosed; // when success payment popup closed, user can do another payment
    this.isPaymentSucceeded$.next(resetPaymentStatus);
  }
  pay() {
    this.isLoading = true;
    let totalPrice: number = this.processingFees$.getValue() + this.totalPrice.getValue();
    if ((this.walletBalance$.getValue() >= totalPrice) && (this.finalPrice == 0)) {
      this.payWithWalletBalance();
      return;
    }

    if (this.selectedCard$.getValue() != null) {
      this.payWithSavedCard();
      return;
    }

    if (this.selectedPaymentOption.id) {
      this.payWithNewCard();
    }
  }

  setNotificationAlert() {
    this.formNotificationChild?.showNotificationAlert(this.infoMessage, 'Success_Light');
  }

  get paymentMethod(): any {
    return this.selectedPaymentOption;
  }

  set paymentMethod(value: any) {
    this.selectedPaymentOption = value;
    this.gatewayId = this.selectedPaymentOption.id;
    this.getPaymentFees();
  }

  getPaymentGateways() {
    this.paymentService.getPaymentGateways().subscribe(
      (res: any) => {
        this.paymentGateways$.next(res.gateways)
      }
    )
  }

  getPaymentFees() {
    let formData: FormData = this.bookLessonService.calculateLessonPriceFormData$.getValue();
    // delete prev value before appending new value to the same key
    formData.delete('gatewayId');

    formData.append('gatewayId', this.gatewayId)
    this.paymentService.getPaymentFees(formData).subscribe(
      (res: any) => {
        this.processingFees$.next(res.data.prices.fees)
      }
    )
  }

  getCardSaveOption(isSaveCard: boolean) {
    this.isSaveCard = isSaveCard;
  }
  isPaymentProcessed(paymentResult: any) {
    let result: any = paymentResult?.paymentIntent;
    if (result?.status == "succeeded" || paymentResult == true) {
      this.checkoutPay$.next(false);

      let totalPrice: number = this.processingFees$.getValue() + this.totalPrice.getValue();
      const formattedDateTime = moment().format('YYYY-MM-DD HH:mm:ss');;

      // Create the paymentDetails object
      const paymentDetails = {
        dateTime: formattedDateTime,
        via: "stripe",
        amount: totalPrice
      }; //result.created
      this.succeededPaymentDetails$.next(paymentDetails)
      this.toastService.showSuccessToast('Payment succeeded', 'Your payment has been successfully processed');
      this.isPaymentSucceeded$.next(true);
      this.isLoading = false;
      } else {
      this.isLoading = false;
      this.isPaymentSucceeded$.next(false);
      this.toastService.showErrorToast('Payment Failed', ' Please verify details and try again');
    }

  }

  createPaymentIntent() {
    // reset selection
    this.selectedCard$.next(null);

    let formData: FormData = this.bookLessonService.calculateLessonPriceFormData$.getValue();

    formData.delete('gatewayId');
    formData.delete('savePaymentMethod');

    formData.append('gatewayId', (this.selectedPaymentOption.id).toString());
    formData.append('savePaymentMethod', (this.isSaveCard).toString());

    this.paymentService.createStripePaymentIntent(formData).subscribe(
      (res: any) => {
        this.checkoutClientKey$.next(res.data.clientSecret);
        this.checkoutPaymentIntentId$.next(res.data.paymentIntentId);
      }
    )
  }

  getSavedPaymentMethods() {
    this.paymentService.getSavedPaymentMethods().subscribe(
      (res: any) => {
        this.savedPaymentMethods$.next(res.data)
      }
    )
  }

  CheckoutPay() {
    // trigger the payment method in the tr-stripe-payment component
    this.checkoutPay$.next(true);
  }

  payWithSavedCard() {
    let formData: FormData = this.bookLessonService.calculateLessonPriceFormData$.getValue();

    formData.delete('gatewayId');
    formData.delete('paymentMethodId');
    formData.delete('paymentId');

    formData.append('gatewayId', (this.selectedCard$.getValue().gateway_id).toString())
    formData.append('paymentMethodId', (this.selectedCard$.getValue().payment_method_id).toString())
    this.paymentService.payFromSavedCard(formData).subscribe(
      (res: any) => {
        this.isPaymentProcessed(res.status)
      }
    )
  }

  payWithWalletBalance() {
    let formData: FormData = this.bookLessonService.calculateLessonPriceFormData$.getValue();

    formData.delete('gatewayId');
    formData.delete('paymentMethodId');
    formData.delete('paymentId');

    this.paymentService.payFromWallet(formData).subscribe(
      (res: any) => {
        this.isPaymentProcessed(res.status);
      }
    )
  }

  payWithNewCard() {
    let formData: FormData = this.bookLessonService.calculateLessonPriceFormData$.getValue();

    formData.delete('gatewayId');
    formData.delete('paymentMethodId');

    formData.append('gatewayId', (this.selectedPaymentOption.id).toString());
    formData.append('paymentId', (this.checkoutPaymentIntentId$.getValue()).toString());
    formData.append('savePaymentMethod', (this.isSaveCard).toString());

    this.paymentService.payFromNewCard(formData).subscribe(
      (res: any) => {
        if (res.status) {
          this.CheckoutPay();
        } else {
          this.isPaymentProcessed(false);
        }
      }
    );
  }

  getSelectedCard(selectedCard: any) {
    this.selectedCard$.next(selectedCard);
    this.gatewayId = selectedCard.gateway_id;
    this.getPaymentFees()
    // reset selection
    this.selectedPaymentOption = null;
  }

  validatePayButton(): boolean {
    if (this.payFromWallet) {
      return this.processingFees$.getValue() === 0;
    } else {
      return this.processingFees$.getValue() > 0 && this.totalPrice.getValue() > 0;
    }
  }

}