<section *ngIf="userConverstaion">
  <div class="tr-chat-header d-flex align-items-center p-4">
    <div  (click)="this.hideMenu = !this.hideMenu" class="d-lg-flex gap-2 d-none cursor-pointer" *ngIf="(isSideMenuLoading | async ) == false && selectedUser">
      <img [src]="selectedUser.avatar" alt="" *ngIf="selectedUser.avatar" class="tr-header-img" />
      <span class="tr-no-img d-flex align-items-center justify-content-center" *ngIf="!selectedUser.avatar">{{ selectedUser.fname.charAt(0)  }}</span>
      <div class="tr-chat-header-details " *ngIf="selectedUser">
        <p class="mb-0">{{ selectedUser.fname  }} {{ selectedUser.lname  }}</p>
       <!--  <span class="" >Click to see more details about the contact</span> -->
      </div>
    </div>
    <div class="row p-0 m-0 w-100" *ngIf="(isSideMenuLoading | async ) == true">
      <div class="col-md-6 p-0 position-relative">
        <div class="d-flex gap-2">
          <tr-skeleton-image></tr-skeleton-image>
          <tr-double-line></tr-double-line>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center d-lg-none w-100 justify-content-between">
      <div class="d-flex align-items-center gap-3">
        <div class="mb-btn d-flex align-items-center justify-content-center" (click)="hideConversation()">
          <img src="assets/icons/arrow-back-black.svg" alt="back-img" />
        </div>
        <h2 class="tr-mb-title mb-0">Messages</h2>
      </div>
      <div class="mb-btn d-flex align-items-center justify-content-center">
        <img src="assets/icons/search.svg" alt="search-img" />
      </div>
    </div>
  </div>
  <section [ngClass]="{ 'd-lg-flex': hideMenu == true }">
    <section class="position-relative flex-grow-1">
      <div class="tr-chat-middle-sec" #chatScrollContainer>
        <div class="position-relative px-4 py-2 px-lg-0 py-lg-0 d-flex d-lg-none align-items-center tr-search-svg">
          <input type="search" class="form-control tr-chat-search" name="search" placeholder="Search" />
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path [attr.d]="searchDropdownIcon" fill="#888888" />
          </svg>
        </div>
       <div class="d-flex justify-content-center align-items-center mt-5" *ngIf="isLoading" >
          <div class="tr-spinner-sec d-flex align-items-center justify-content-center">
            <tr-spinner></tr-spinner>
          </div>
        </div>
        <section class="p-4 pb-5" *ngIf="!isLoading">
          <button *ngIf="lastPage > currentPage" class="tr-load-btn" (click)="loadMoreMessages(this.currentPage + 1)"> Load previous messages</button>
          <ul class="tr-conversations-list p-0 m-0 d-flex flex-column gap-4" *ngIf="userConverstaion">
            <li class="chats" *ngFor="let message of userConverstaion.slice().reverse()" [ngClass]="{'right': message.user_id != userId}" (click)="scrollToBottom()">
              <div class="tr-conversation-list d-flex gap-2">
                <!-- <img [src]="message['user']['avatar']" alt="" class="tr-user-img" /> -->
                <img [src]="message['user']['avatar']" alt="" class="tr-user-img" *ngIf="message['user']['avatar']">
                <span class="tr-dummy-image d-flex align-items-center justify-content-center" *ngIf="!message['user']['avatar']">
                  {{message['user']['fname'].charAt(0)}}
                </span>
                <div class="d-flex flex-column gap-2">
                  <div class="tr-conversation-msg p-3">
                    <p class="mb-0">{{ message['user']['fname'] }}.</p>
                    <span>{{ message.body }}</span>
                    <p class="tr-lite mb-0 mt-2">{{ message.created_at | chatMessageTime}}</p>
                  </div>
                  <div class="tr-conversation-msg tr-border p-3 tr-mx-content d-flex gap-3 gap-lg-4 flex-wrap" *ngIf="message.attachments && message.attachments.length > 0">
                    <ng-container *ngFor="let attachment of message.attachments">
                      <span>
                        <img src="assets/images/landing-page-images/landing-page-sec1-right.png" alt="" />
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <section class="tr-bottom-sec" [formGroup]="chatForm" *ngIf="!isLoading">
        <div class="position-relative p-4 d-flex align-items-end">
          <div class="d-flex w-100 position-relative me-lg-3">
            <textarea formControlName="body" name="body"  (input)="adjustTextareaHeight()"
             [style.height.px]="textareaHeight" [(ngModel)]="message" class="form-control tr-chat-textarea"></textarea>
            <button class="tr-chat-attach" data-bs-toggle="modal" data-bs-target="#fileUploadModal">
              <img src="assets/icons/attach.svg" alt="" />
            </button>
          </div>
          <button class="border-0 bg-transparent p-0 m-0 tr-send-chat-btn" (click)="sendMessage()" [disabled]="chatForm.invalid">
            <span class="d-flex align-items-center justify-content-center">
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" [attr.d]="chatSendIcon" fill="white"/>
                </svg>
            </span>
          </button>
        </div>
      </section>
    </section>
    <section *ngIf="hideMenu">
      <div class="right-col-tutor-details p-4 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <img src="assets/icons/close-circle.svg" alt="close-icon" (click)="this.hideMenu = !this.hideMenu" class="cursor-pointer mb-4 mb-xxl-5" />
          <div class="d-flex flex-column gap-3 align-items-center justify-content-center">
            <img [src]="selectedUser.avatar" alt="" *ngIf="selectedUser.avatar" class="tr-tutor-img" />
            <span class="tr-no-img d-flex align-items-center justify-content-center" *ngIf="!selectedUser.avatar">{{ selectedUser.fname.charAt(0)  }}</span>
            <div class="d-flex flex-column align-items-center justify-content-center gap-2">
              <p class="mb-0 tr-text-dark">{{ selectedUser.fname  }} {{ selectedUser.lname  }}.</p>
              <span class="tr-text-lite">Last seen : 20 Mar 2023</span>
            </div>
          </div>
          <div class="d-flex flex-column gap-3 mt-4 mt-xxl-5" *ngIf="!hideTutorProfileMenu">
            <div class="tr-right-col-list">
              <div class="d-flex align-items-center justify-content-between">
                <label>Search in Conversation</label>
                <img src="assets/icons/search.svg" alt="search-icon" />
              </div>
            </div>
            <div class="tr-right-col-list">
              <div class="d-flex align-items-center justify-content-between">
                <label>Documents</label>
                <img src="assets/icons/links-line.svg" alt="search-icon" />
              </div>
            </div>
            <div class="tr-right-col-list">
              <div class="d-flex flex-column gap-2 justify-content-between">
                <label>Shared Photo</label>
                <div class="tr-user-all-images">
                  <div class="d-flex flex-wrap justify-content-between gap-2">
                    <img src="assets/images/article-img.svg" alt="images" />
                    <img src="assets/images/article-img.svg" alt="images" />
                    <img src="assets/images/article-img.svg" alt="images" />
                    <img src="assets/images/article-img.svg" alt="images" />
                    <img src="assets/images/article-img.svg" alt="images" />
                    <img src="assets/images/article-img.svg" alt="images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" *ngIf="hideTutorProfileMenu">
            <input type="search" class="form-control" />
            <div class="search-data p-4 mt-2 d-flex gap-2 flex-column">
              <div class="tr-search-results cursor-pointer" (click)="goToSelectedChat('li-tag-1')">
                <p class="mb-0">Hello</p>
                <span>Thu 06 Mar, 09:00 am</span>
              </div>
              <div class="tr-search-results cursor-pointer">
                <p class="mb-0">Hello</p>
                <span>Thu 06 Mar, 09:00 am</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  
</section>
<!-- Modal -->
<div class="modal fade" id="fileUploadModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header b-none">
        <h5 class="modal-title" id="staticBackdropLabel">File Upload</h5>
        <button type="button" class="tr-btn-close p-0 b-none" data-bs-dismiss="modal" aria-label="Close" (click)="removeAllFiles()">
          <img src="assets/icons/close-circle.svg" alt="close-icon" />
        </button>
      </div>
      <div class="modal-body">
        <ngx-dropzone (change)="addDocument($event)" style="
            flex-direction: column-reverse;
            height: auto !important;
            min-height: 180px;
            width: 100%;
            justify-content: center;
            border: 1px dashed #bebebe;
          " accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" [maxFileSize]="20000000" [multiple]="true">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="row">
                <div class="col-md-12 col-lg-12" *ngIf="files.length <= 0">
                  <div class="d-flex align-items-center flex-column mb-3">
                    <img src="../../assets/icons/upload-file.svg" class="tr-drop-zone-upload-file mb-3" alt="upload-logo" />
                    <span class="tr-drop-zone-info-label mb-1">Drag attachment here</span>
                    <ngx-dropzone-label class="tr-drop-zone-file-types m-0">(PNG, JPG, PDF max. size 20MB) </ngx-dropzone-label>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12">
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-image-preview>
                </div>
              </div>
            </div>
          </div>
        </ngx-dropzone>
        <div class="col-12 mt-3">
          <button class="tr-save-btn px-3 py-2" (click)="sendMessage()">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>