import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tr-double-line',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './double-line.component.html',
  styleUrls: ['./double-line.component.css']
})
export class DoubleLineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
