import { Component, OnInit, SkipSelf, Input } from '@angular/core';
import { AddTimePipe } from '@core/pipes/add-time.pipe';
import { optionsIcon, eventRepeatIcon, cancelPath } from '@shared/models/svgIcons';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { finalize } from "rxjs/operators";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { TruncatePipe } from '@core/pipes/truncate.pipe';
import { SvgModule } from '@modules/svg.module';
import { ConfirmDeletePopupService } from '@components/shared-components/confirm-delete-popup/confirm-delete-popup.service';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';


@Component({
  selector: 'tr-student-next-lessons',
  standalone: true,
  imports: [CommonModule, AddTimePipe, SvgModule, TruncatePipe, RouterModule],
  templateUrl: './student-next-lessons.component.html',
  styleUrls: ['./student-next-lessons.component.css']
})
export class StudentNextLessonsComponent implements OnInit {
  @Input() userType: string = 'student';
  totalLessons!: number;
  lessons: any;
  optionsIcon: string = optionsIcon;
  cancelPath: string = cancelPath;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  eventRepeatIcon: string = eventRepeatIcon;
  todayWithTime: any;
  constructor(private userLessonsService: UserLessonsService, 
    private router: Router,
    private messageService:SendMessagePopupService,
    private deleteService: ConfirmDeletePopupService,
    private schedulerNextService: SchedulerNextService,
    @SkipSelf() public reschedulerService: ReschedulerService,
    private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.todayWithTime = moment().format('YYYY-MM-DD HH:mm');;
    this.getNextLessons();
  }

  getNextLessons() {
    this.userLessonsService
      .filterLessons(this.getParams())
      .pipe(finalize(() => ''))
      .subscribe(
        (res: any) => {
          this.lessons = res['data'];
          console.log( this.lessons );
          this.totalLessons = this.lessons.length;
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }
  navigateLessonBoard(id:any){
    this.router.navigate([this.userType + '/lessons/lesson-details/' + id]);
  }

  getLessonAction(actionName: any, lesson: any) {
    switch (actionName) {
      case LESSON_PERMISSIONS.CANCEL_REQ:
        this.cancelRequest(lesson.id, lesson.lessonRequestId);
        break;
      case LESSON_PERMISSIONS.CANCEL:
        this.directCancelLesson(lesson.id);
        break;
      case LESSON_PERMISSIONS.RESCHEDULE:
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        this.rescheduleLesson(actionName, lesson);
        break;
    }
  }

  rescheduleLesson(actionName: any, lessonData: any) {
    this.userLessonsService.lessonAction$.next(actionName);
    this.reschedulerService.lessonAction$.next(actionName);
    this.reschedulerService.lessonTimeAndPeriod$.next(lessonData);
    this.schedulerNextService.lessonTimeAndPeriod$.next(lessonData);
  }

  directCancelLesson(lessonId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    this.deleteService.setData('cancel', formData);
  }

  cancelRequest(lessonId: any, lessonRequestId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    formData.append('lessonRequestId', lessonRequestId);
    this.deleteService.setData('request_cancellation', formData);
  }

  getDaysUntilLesson(lessonTime: string): number {
    const lessonDate = new Date(lessonTime);
    const today = new Date();

    // Set both dates to midnight to compare only the dates
    lessonDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const timeDiff = lessonDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }

  getParams(params?: any): any {
    return params = {
      lessonStatus: params?.lessonStatus || 1,
      sortBy: 'date', 
      sortByType: 'ASC',
      startDateTime: this.todayWithTime,
      endDateTime: '',
      userType: this.userType,
      perPage: 1,
    }
  }

  sendMessage(id:any , name:any, logo:any){
    let tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    this.messageService.sendUserDetails(tutorData);
  }

  
  mapPermission(permission: string): string {

    switch (permission) {
      case LESSON_PERMISSIONS.CANCEL:
        return 'Cancel';
      case LESSON_PERMISSIONS.CANCEL_REQ:
        return 'Cancel request';
      case LESSON_PERMISSIONS.RESCHEDULE:
        return 'Reschedule';
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        return 'Request reschedule';
      case LESSON_PERMISSIONS.APPROVE:
        return 'Approve';
      case LESSON_PERMISSIONS.REJECT:
        return 'Reject';
      case LESSON_PERMISSIONS.ACCEPT:
        return 'Accept';      
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        return 'Give new time';
      default:
        return permission;
    }
  }

}
