<main>
    <div class="row  tr-paginator justify-content-center">
        <div class="col-md-12 top20">
            <div class="row">
                <div class="tr-paginator-page-btns-wrapper col-md-6 justify-content-start">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-start align-items-center">
                            <input (click)="previousPage()" class="tr-paginator-prev-btn" type="button" [disabled]="currentPage <= 1"/>
                            <ng-container *ngFor="let page of totalPages">
                                <input (click)="selectPage(page)" class="tr-paginator-page-number left5" type="button" [value]="page" [ngClass]="{ 'tr-paginator-active-page': currentPage === page }">
                              </ng-container>
                            <input (click)="nextPage()" class="tr-paginator-next-btn left5" type="button" [disabled]="currentPage >= totalPages.length"/>
                        </div>
                    </div>
                </div>
        
                <div class="tr-paginator-page-indicators col-md-6 d-flex justify-content-end">
                    <span class="tr-paginator-page-indicators-label">Rows per page &nbsp; </span>
                    <select class="form-select tr-select-item-perpage" (change)="getSelectedItemsPerPage($event)">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                    </select>
                    <div class="tr-paginator-page-indicators-pages-label">
                        1 - 3
                        <span>of</span> {{ _totalRecords }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>