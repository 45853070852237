<main>
  <div class="tr-lesson-resch-right-panel-container row p-4 m-0 justify-content-start w-100">
    <div class="tr-lesson-resch-selected-lesson-counter-title col-md-12 w-100 text-center">
      Total selected lesson ({{ (rescheduleLesson$ | async).length }})
    </div>
    <div class="tr-lesson-resch-lesson col-md-12 mt-12 d-flex align-items-center justify-content-between" *ngIf="(rescheduleLesson$ | async).length > 0">
      <ng-container *ngFor="let lesson of rescheduleLesson$ | async">
        {{ extractLessonDateTime(lesson.dateTime) }}, {{ lesson.duration }} mins
        lesson
        <button class="border-0 p-0 m-0 tr-slot-close-icon d-flex align-items-center justify-content-center" (click)="removeLesson(lesson.dateTime)">
              <img src="assets/icons/slot-close.svg" alt="slot-close-svg">
        </button>
      </ng-container>
    </div>
    <ng-container *ngIf="(reschedulerService.rescheduleType$|async) === LESSON_PERMISSIONS.REQ_RESCHEDULE || (reschedulerService.rescheduleType$|async) === LESSON_PERMISSIONS.GIVE_NEW_TIME" [formGroup]="requestRescheduleForm">
    <div class="tr-lesson-resch-splitter col-md-12 my-4"></div>
      <div class="tr-lesson-resch-req-title px-0 mb-2">Request Rescheduling</div>
      <div class="tr-lesson-resch-req-info px-0">
        Send a request to reschedule your lesson
      </div>
  
      <div class="tr-lesson-resch-req-dropdown col-md-12 px-0 my-18">
        <select formControlName="reasonId" (change)="checkFormStatus()" [(ngModel)]="selectedReason">
          <option hidden selected *ngIf="selectedReason == null">Select reason of rescheduling</option>
          <option *ngFor="let reason of reschedulerService.reasons$ |async" [value]="reason?.id">  {{reason?.name}}
          </option>
        </select>
        <span *ngFor="let error of errorMessages.reasonId">
          <ng-container *ngIf="requestRescheduleForm.controls['reasonId'].hasError(error.type) && (requestRescheduleForm.controls['reasonId'].touched)">
              <small class="error-message">{{error.message}}</small>
            </ng-container>
        </span>
      </div>
  
      <div class="tr-lesson-resch-req-text col-md-12 px-0">
        <textarea
          cols="30"
          formControlName="note"
          rows="4"
          (input)="checkFormStatus()"
          placeholder="Your message here ..."
        ></textarea>
        <span *ngFor="let error of errorMessages.note">
          <ng-container *ngIf="requestRescheduleForm.controls['note'].hasError(error.type) && (requestRescheduleForm.controls['note'].touched)">
              <small class="error-message">{{error.message}}</small>
            </ng-container>
        </span>
      </div>
    </ng-container>
  </div>
</main>