//Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormNotificationModule } from '@modules/form-notification.module';
import { FindTutorModule } from '@modules/find-tutor.module';
import { CurrencyDirective } from '@core/currency.directive';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SvgModule } from './svg.module';
import { PaymentModule } from './payment/payment.module';
import { SendConfirmationCodePopupComponent } from '@components/shared-components/send-confirmation-code-popup/send-confirmation-code-popup.component';
import { StudentCompleteInformationPopupComponent } from '@components/shared-components/student-complete-information-popup/student-complete-information-popup.component';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';
//Components
import { VideoPlayerComponent } from '@components/shared-components/video-player/video-player.component';
import { FindTutorComponent } from '@studentComponents/student-tutors/find-tutor/find-tutor.component';
import { FindTutorSkeletonLoaderComponent } from '@studentComponents/student-tutors/find-tutor-skeleton-loader/find-tutor-skeleton-loader.component';
import { StudentTutorsComponent } from '@studentComponents/student-tutors/student-tutors.component';
import { LiveTutorsComponent } from '@studentComponents/student-tutors/live-tutor/live-tutor.component';
import { MyTutorsComponent } from '@studentComponents/student-tutors/my-tutors/my-tutors.component';
import { SavedTutorsComponent } from '@studentComponents/student-tutors/saved-tutors/saved-tutors.component';
import { MyCurrentTutorsComponent } from '@studentComponents/student-tutors/my-current-tutors/my-current-tutors.component';
import { BookLessonComponent } from '@studentComponents/student-tutors/book-lesson/book-lesson.component';
import { BookLessonHeaderComponent } from '@studentComponents/student-tutors/book-lesson/book-lesson-header/book-lesson-header.component';
import { BookLessonFooterComponent } from '@studentComponents/student-tutors/book-lesson/book-lesson-footer/book-lesson-footer.component';
import { LessonTypeComponent } from '@studentComponents/student-tutors/book-lesson/lesson-type/lesson-type.component';
import { LessonCommunicationToolComponent } from '@studentComponents/student-tutors/book-lesson/lesson-communication-tool/lesson-communication-tool.component';
import { LessonSummaryComponent } from '@studentComponents/student-tutors/book-lesson/lesson-summary/lesson-summary.component';
import { BookLessonTypeDetailsComponent } from '@components/student-components/student-tutors/book-lesson/book-lesson-type-details/book-lesson-type-details.component';
import { SkeletonImageComponent } from '@components/shared-components/skeleton/skeleton-image/skeleton-image.component';
import { SingleLineComponent } from '@components/shared-components/skeleton/single-line/single-line.component';
import { DoubleLineComponent } from '@components/shared-components/skeleton/double-line/double-line.component';
import { SkeletonButtonComponent } from '@components/shared-components/skeleton/skeleton-button/skeleton-button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BookLessonCheckoutComponent } from '@components/student-components/student-tutors/book-lesson/book-lesson-checkout/book-lesson-checkout.component';
import { StudentBookingPaymentSuccessComponent } from '@components/student-components/student-tutors/student-booking-payment-success/student-booking-payment-success.component';
import { SendMessagePopupComponent } from '@components/shared-components/send-message-popup/send-message-popup.component';

//Services
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { FormDataResolver } from '../core/form-data-service.resolver';
//pipes
import { SafePipe } from '../shared/pipes/safe-url.pipe';

const routes: Routes = [
  {
    path: 'find-tutor', component: FindTutorComponent,
    data: { "tutorRoute": true },
    resolve: {
      formData: FormDataResolver,
    },
  },
  {
    path: 'live-tutors', component: LiveTutorsComponent,
  },
  {
    path: 'my-tutors', component: MyTutorsComponent,
    children: [
      { path: '', redirectTo: 'saved-tutors', pathMatch: 'full' },
      { path: 'saved-tutors', component: SavedTutorsComponent },
      { path: 'current-tutors', component: MyCurrentTutorsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class TrTutorsRoutingModule { }

@NgModule({
  declarations: [
    StudentTutorsComponent,
    SavedTutorsComponent,
    MyCurrentTutorsComponent,
    MyTutorsComponent,
    BookLessonComponent,
    BookLessonHeaderComponent,
    LessonTypeComponent,
    BookLessonTypeDetailsComponent,
    BookLessonFooterComponent,
    LessonCommunicationToolComponent,
    LessonSummaryComponent,
    BookLessonCheckoutComponent,
    StudentBookingPaymentSuccessComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SendMessagePopupComponent,
    TrTutorsRoutingModule,
    RouterModule,
    VideoPlayerComponent,
    StudentCompleteInformationPopupComponent,
    SvgModule,
    SendConfirmationCodePopupComponent,
    FindTutorModule,
    SafePipe,
    SpinnerComponent,
    FindTutorSkeletonLoaderComponent,
    FormNotificationModule,
    ReactiveFormsModule,
    SkeletonImageComponent,
    SingleLineComponent,
    CurrencyDirective,
    DoubleLineComponent,
    NgxIntlTelInputModule,
    SkeletonButtonComponent,
    PaymentModule
  ],
  exports: [
    StudentTutorsComponent,
    SavedTutorsComponent,
    MyCurrentTutorsComponent,
    MyTutorsComponent,
    SvgModule,
    BookLessonComponent,
    BookLessonHeaderComponent,
    BookLessonFooterComponent,
    LessonTypeComponent,
    BookLessonTypeDetailsComponent,
    LessonCommunicationToolComponent,
  ],
  providers: [
    TutorRegPhasesService,
    DatePipe
  ]
})
export class StudentTutorsModule { }