<div class="container-fluid mx-0 p-0 d-flex">
    <div class="tr-side-panel-col" id="tr-sidepanel-col">
         <tr-side-panel sidePanelTitle="Settings" [componentName]="sideNavItemNames.TUTORINGSETTINGS"></tr-side-panel>
    </div>
    <div class="w-100 mx-lg-100">
        <div class="row p-0 m-0 d-flex tr-px-lg-4">
            <div class="col-md-12 p-0">
                <router-outlet></router-outlet> 
            </div>
         </div>
    </div>
</div>