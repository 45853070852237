<div class="row top30">
    <div class="tr-tutor-reg-main-title col-md-12 col-lg-12 text-center">Tutor's application</div>
</div>
<div class="row d-flex justify-content-center top20">
    <div class="col-md-6 col-lg-6 col-10"><hr class="tr-reg-header-underline"></div>
</div>

<div class="row d-flex justify-content-center">
    <div class="tr-tutor-reg-label col-md-6 col-lg-6 col-10 text-center bottom15">
        you can save for later till you prepare all required fields then come back again to complete the application
    </div>
</div>

<div class="row top30">
    <div class="col-md-12 col-lg-12 text-center tr-tutor-reg-header-label">{{title}}</div>
    <div class="col-md-12 col-lg-12 d-flex justify-content-center top15">
        <ng-container *ngFor="let class of tabClasses">
            <span class="{{class}} left10"></span>
        </ng-container>
    </div>
</div>