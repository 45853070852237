<section class="tr-main-div position-relative position-md-absolute">
  <div>
    <div class="tr-pb-2 tr-bdb">
      <div class="px-3 px-xl-4 pt-4 mx-100" style="overflow: hidden;">
        <section class="tr-calender-day d-flex align-items-center gap-3 mb-4">
          <span class="tr-calendar">
            <img src="assets/images/calendar.svg" alt="calendar-svg">
          </span>
          <span
            >
            {{ getFormattedDates() }}
          </span>
        </section>
        <section class="d-flex align-items-center gap-3">
          <button
            class="tr-calendar-arrow d-flex align-items-center justify-content-center"
            (click)="getPrev7Days()"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path [attr.d]="arrowLeftPath" />
            </svg>
          </button>
          <div
            class="tr-calendar-row"
          >
            <div
              class="tr-calender-date d-flex flex-column align-items-center cursor-pointer"
              *ngFor="let day of current7days; let i = index"
              [ngClass]="{ active: day.completeDate === activeDay }"
              (click)="getLessonsBydate(day.completeDate)"
            >
              {{ day.date }} <span> {{ day.name }}</span>
            </div>
          </div>
          <button
            class="tr-calendar-arrow d-flex align-items-center justify-content-center"
            (click)="getNext7Days()"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path [attr.d]="arrowRightPath" />
            </svg>
          </button>
        </section>
      </div>
    </div>
    <section>
      <h3 class="tr-title mx-4 mt-3 mb-2">Lessons of day <ng-container *ngIf="lessonsCount">({{ lessonsCount }})</ng-container> <ng-container *ngIf="!lessonsCount">(0)</ng-container></h3>
      <section class="d-flex align-items-center justify-content-center w-100 tr-mh-100" *ngIf="isLoading">
          <div class="tr-spinner-sec d-flex align-items-center justify-content-center">
            <tr-spinner></tr-spinner>
          </div>
      </section>
      <section class="d-flex flex-column pb-0 tr-lessons-sec" *ngIf="lessonsCount > 0 && !isLoading">
        <div
          class="tr-lessons-row px-4 d-flex justify-content-between py-3"
          *ngFor="let lesson of lessons"
        >
          <div class="d-flex align-items-start gap-3 pe-4 py-1">
            <img src="assets/icons/schedule.svg" alt="schedule-icon" />
            <div class="tr-booking-time d-flex gap-2 flex-column">
              <ng-container [ngSwitch]="lesson.lessonPeriod">
                <ng-container *ngSwitchCase="'30'">
                  <span
                    >{{ lesson.lessontime | date : "hh:mm a" | lowercase }} -
                    {{
                      lesson.lessontime
                        | addTime : 30
                        | date : "hh:mm a"
                        | lowercase
                    }}</span
                  >
                </ng-container>
                <ng-container *ngSwitchCase="'60'">
                  <span
                    >{{ lesson.lessontime | date : "hh:mm a" | lowercase }} -
                    {{
                      lesson.lessontime
                        | addTime : 60
                        | date : "hh:mm a"
                        | lowercase
                    }}</span
                  >
                </ng-container>
                <ng-container *ngSwitchCase="'90'">
                  <span
                    >{{ lesson.lessontime | date : "hh:mm a" | lowercase }} -
                    {{
                      lesson.lessontime
                        | addTime : 90
                        | date : "hh:mm a"
                        | lowercase
                    }}</span
                  >
                </ng-container>
                <ng-container *ngSwitchDefault>Default case</ng-container>
              </ng-container>
              <p class="mb-0" *ngIf="userType == 'student'">
                {{ lesson.subjectName }} <span>with</span>
                {{ lesson.tutorName }}.
                <img
                  src="assets/icons/mode_comment.svg"
                  alt="comment-icon"
                  class="ms-2 cursor-pointer"
                  (click)="sendMessage(lesson.studentId ? lesson.studentId : lesson.tutorId, lesson.tutorName ? lesson.tutorName : lesson.studentName, lesson.studentImage ? lesson.studentImage : lesson.tutorImage)"
                />
              </p>
              <p class="mb-0" *ngIf="userType == 'tutor'">
                {{ lesson.subjectName }} <span>with</span>
                {{ lesson.studentName }}.
                <img
                  src="assets/icons/mode_comment.svg"
                  alt="comment-icon"
                  class="ms-2 cursor-pointer"
                  (click)="sendMessage(lesson.studentId ? lesson.studentId : lesson.tutorId, lesson.tutorName ? lesson.tutorName : lesson.studentName, lesson.studentImage ? lesson.studentImage : lesson.tutorImage)"
                />
              </p>
            </div>
          </div>
<!--           <div> 
            <img src="assets/images/re-reschedule.svg" class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reschedule a lesson" alt="reschedule" />
          </div> -->
        </div>
      </section>
      <ng-container *ngIf="!isLoading">
      <section *ngIf="lessonsCount <= 0 || !lessonsCount">
        <div class="d-flex align-items-center justify-content-center tr-mh-100">
          <span class="tr-text-lite"> No lessons found </span>
        </div>
      </section>
    </ng-container>
    </section>
  </div>
</section>