import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { reschedulePath, closePath } from 'src/app/shared/models/svgIcons';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { NotificationsAlertsService } from './notifications-alerts.service';
import { finalize } from 'rxjs/operators';
import { getFormattedTimeAgo } from '@shared/models/messages';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { ConfirmDeletePopupService } from '@components/shared-components/confirm-delete-popup/confirm-delete-popup.service';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
import * as bootstrap from 'bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'tr-notifications-alerts',
  templateUrl: './notifications-alerts.component.html',
  styleUrls: ['./notifications-alerts.component.css']
})
export class NotificationsAlertsComponent implements OnInit {
  @ViewChild('openReschedulerModal') openReschedulerModal?: ElementRef;
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  reschedulePath:string = reschedulePath;
  cancelPath:string = closePath;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  userNotifications:any;
  isLoading:boolean = false;
  perPage:any = 20;
  constructor(public userLessonsService: UserLessonsService, 
    public reschedulerService: ReschedulerService,
    private router: Router,
    private schedulerNextService: SchedulerNextService,
    private deleteService: ConfirmDeletePopupService,
    private responseHandlerService: ResponseHandlerService,
    public notificationsAlertsService: NotificationsAlertsService) { }

  ngOnInit(): void {
    this.getNotifications();
    this.userLessonsService.lessonAction$.subscribe(action => {
      if (action === LESSON_PERMISSIONS.RESCHEDULE || action === LESSON_PERMISSIONS.REQ_RESCHEDULE) {
        setTimeout(() => {this.openReschedulerModal?.nativeElement.click()},100);
      }
    });
  }

  getNotifications(){
    this.isLoading = true;
    this.notificationsAlertsService
      .getNotifications(this.perPage)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.userNotifications = res['data'];
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

  formatTime(timestamp:any) {
    return getFormattedTimeAgo(timestamp);
  }

  rescheduleLesson(actionName: any, lessonData: any) {
    this.userLessonsService.lessonAction$.next(actionName);
    this.reschedulerService.lessonAction$.next(actionName);
    this.reschedulerService.lessonTimeAndPeriod$.next(lessonData);
    this.schedulerNextService.lessonTimeAndPeriod$.next(lessonData);
  }

  getLessonAction(actionName: any, lesson: any) {  
    switch (actionName) {
      case LESSON_PERMISSIONS.CANCEL_REQ:
        this.cancelRequest(lesson.id, lesson.lessonRequestId);
        break;
      case LESSON_PERMISSIONS.CANCEL:
        this.directCancelLesson(lesson.id);
        break;      
      case LESSON_PERMISSIONS.ACCEPT:
        this.acceptRequest(lesson);
        break;      
      case LESSON_PERMISSIONS.REJECT:
        this.rejectRequest(lesson);
        break;      
      case LESSON_PERMISSIONS.COMPLETE:
        this.completeLesson(lesson.id);
        break;       
      case LESSON_PERMISSIONS.RAISE_COMPLAINT:
        this.raiseComplaint(lesson.id);
        break;      
       case LESSON_PERMISSIONS.VIEW_BOOKING:
        this.viewLesson(lesson.bookingId);
        break;      
      case LESSON_PERMISSIONS.RESCHEDULE:
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        this.rescheduleLesson(actionName, lesson);
        break;
    }
  }


  viewLesson(bookingId:any){
    this.router.navigate(['/tutor/lessons/my-lessons/'+ bookingId]);
  }

  completeLesson(lessonId:any){
    this.reschedulerService.completeLesson(lessonId).subscribe(
      (data: any) => {
        this.responseHandlerService.handleSuccessRessponse(data['message']);
        this.success.emit(true);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }  

  raiseComplaint(lessonId:any){
    this.userLessonsService.complaintLessonId$.next(lessonId);
    const modalElement = document.getElementById('raiseComplaintModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }  

  acceptRequest(lesson:any){
    this.reschedulerService.acceptRequest(lesson).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.responseHandlerService.handleSuccessRessponse(data['message']);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }  

  rejectRequest(lesson:any){
    this.reschedulerService.rejectRequest(lesson).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.responseHandlerService.handleSuccessRessponse(data['message']);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

  directCancelLesson(lessonId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    this.deleteService.setData('cancel', formData);
  }

  cancelRequest(lessonId: any, lessonRequestId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    formData.append('lessonRequestId', lessonRequestId);
    this.deleteService.setData('request_cancellation', formData);
  }

    mapPermission(permission: string): string {

    switch (permission) {
      case LESSON_PERMISSIONS.CANCEL:
        return 'Cancel';
      case LESSON_PERMISSIONS.CANCEL_REQ:
        return 'Cancel request';
      case LESSON_PERMISSIONS.RESCHEDULE:
        return 'Reschedule';
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        return 'Request reschedule';
      case LESSON_PERMISSIONS.APPROVE:
        return 'Approve';      
      case LESSON_PERMISSIONS.COMPLETE:
        return 'Complete';      
      case LESSON_PERMISSIONS.RAISE_COMPLAINT:
        return 'Raise complaint';
      case LESSON_PERMISSIONS.REJECT:
        return 'Reject';
      case LESSON_PERMISSIONS.ACCEPT:
        return 'Accept';      
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        return 'Give new time';      
      case LESSON_PERMISSIONS.VIEW_BOOKING:
        return 'View booking';
      default:
        return permission;
    }
  }

}
