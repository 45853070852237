import { Component, OnInit, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tr-skeleton-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-button.component.html',
  styleUrls: ['./skeleton-button.component.css']
})
export class SkeletonButtonComponent implements OnInit {
  @Input() top:any = 50;
  @Input() height:any = 2;
  constructor() { }

  ngOnInit(): void {
  }

}
