<div class="tr-lesson-rescheduler-wrapper">
  <header class="tr-lesson-rescheduler-header d-flex align-items-center justify-content-between">
    <div class="tr-lesson-rescheduler-header-title"> Reschedule a lesson </div>
    <div class="tr-lesson-rescheduler-header-close-btn" type="button" data-bs-dismiss="modal" aria-label="Close">
      <img src="assets/icons/close-icon.svg" alt="" class="src" (click)="closeLessonRescheduler()">
    </div>
  </header>
  <div class="tr-lesson-rescheduler-phase-one row p-0 mx-0 tr-mt-scheduler" *ngIf="(reschedulerService.phase$ |async) == 'resch_phase' ">
    <div class="tr-lesson-rescheduler-grid-wrapper col-md-8 col-xl-9 tr-ps-2 pe-0" #GridWrapper *ngIf="tutorData && tutorData.tutorId">
       <tr-scheduler-next viewType="reschedule" [lessonId]="tutorData.id ? tutorData.id : tutorData.lessonId" tutorId="{{ tutorData.tutorId }}"></tr-scheduler-next>
    </div>
    <div class="tr-lesson-rescheduler-right-panel col-md-4 col-xl-3 tr-px-2" *ngIf="( schedulerNextService.selectedSlots$ | async).length > 0">
      <tr-lesson-rescheduler-right-panel></tr-lesson-rescheduler-right-panel>
    </div>
  </div>
  <div class="tr-lesson-rescheduler-phase-two tr-mt-scheduler" *ngIf="(reschedulerService.phase$ |async) == 'confirm_resch_phase'">
    <tr-lesson-rescheduler-confirmation></tr-lesson-rescheduler-confirmation>
  </div>
  <div class="tr-lesson-rescheduler-footer d-flex align-items-center justify-content-between">
    <tr-lesson-rescheduler-footer class="w-100"></tr-lesson-rescheduler-footer>
  </div>
</div>