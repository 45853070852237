import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tr-single-line',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-line.component.html',
  styleUrls: ['./single-line.component.css']
})
export class SingleLineComponent implements OnInit {
  @Input() width:any = 30;
  constructor() { }

  ngOnInit(): void {
  }

}
