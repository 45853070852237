import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClearTokenGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): boolean {
    // Call the method in your AuthService to clear the token
    this.authService.clearData();
    this.router.navigate(['/home']);
    return true;
   // After clearing the token, navigate to the logout component
    // Prevent the default navigation by returning false
  }
  
}
