import { Component, OnInit } from '@angular/core';
import { StudentDashboardService } from '@components/student-components/student-dashboard/student-dashboard.service';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";


@Component({
  selector: 'tr-student-dashboard-my-lessons',
  templateUrl: './student-dashboard-my-lessons.component.html',
  styleUrls: ['./student-dashboard-my-lessons.component.css']
})
export class StudentDashboardMyLessonsComponent implements OnInit {

  userData!:any;
  isLoading:boolean = true;

  constructor(private service:StudentDashboardService , private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.getUserStats();
  }

  getUserStats() {
    this.isLoading = true;
    this.service
      .getUserStats()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          if(res){
            this.userData = res;
            console.log(this.userData);
          } 
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
    }
}
