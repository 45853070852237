import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonImageComponent } from '@components/shared-components/skeleton/skeleton-image/skeleton-image.component';
import { DoubleLineComponent } from '@components/shared-components/skeleton/double-line/double-line.component';
import { SingleLineComponent } from '@components/shared-components/skeleton/single-line/single-line.component';

@Component({
  selector: 'tr-chat-sidemenu-skeleton-loader',
  standalone: true,
  imports: [CommonModule, SkeletonImageComponent, DoubleLineComponent, SingleLineComponent],
  templateUrl: './chat-sidemenu-skeleton-loader.component.html',
  styleUrls: ['./chat-sidemenu-skeleton-loader.component.css']
})
export class ChatSidemenuSkeletonLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
