<div class="container-fluid p-0 m-0">
  <div class="row tr-filter-bg p-0 m-0 position-relative">
    <div class="col-12 m-0 p-0 d-flex flex-column flex-xxl-row tr-mb-bg-highlight">
      <div class="d-flex align-items-center justify-content-between px-sm-4 pe-xxl-0 py-xxl-4 py-3">
        <div class="me-3 d-flex align-items-center" id="tr-filter-select">
          <span class="tr-filter-text pe-3">I want to learn</span>
          <tr-custom-dropdown [id]="'dropdown-1'" [dropDownMenuData]="subjects" [targetList]="'subjectId'" [title]="'All Subjects'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
        </div>
        <span (click)="this.ifBottomFilter = !this.ifBottomFilter" class="align-items-center justify-content-center tr-find-tutor-filter-toggle d-none d-sm-flex cursor-pointer">
          <img src="assets/icons/arrow_forward_ios.svg" alt="arrow_up-svg" class="tr-filter-right-arrow-img" *ngIf="this.ifBottomFilter">
          <img src="assets/icons/arrow-down.svg" alt="arrow_up-svg" class="tr-filter-arrow-bottom-svg" *ngIf="!this.ifBottomFilter">
        </span>
        <div class="d-flex d-sm-none me-3 tr-bg-grey" type="button"  data-bs-toggle="modal" data-bs-target="#mobileFilterModal">
          <img src="assets/images/tune.svg" alt="tune-img">
        </div>
      </div>
      <div class="px-4 px-xxl-0 py-3 d-flex align-items-center d-sm-flex d-none justify-content-between w-xxl-75 flex-wrap gap-3">
        <div class="d-flex align-items-center">
          <div class="form-check form-switch tr-filter-switch  d-flex align-items-center p-0">
            <input class="form-check-input ms-0" type="checkbox" id="online_tutor" name="darkmode" value="yes">
            <label class="form-check-label ms-2" for="mySwitch">Online tutors</label>
          </div>
          <div class="form-check form-switch tr-filter-switch  d-flex align-items-center">
            <input class="form-check-input ms-2 cursor-pointer" type="checkbox" id="top_tutor" name="darkmode" value="yes" (change)="setSelectedData('topTutors',$event)">
            <label class="form-check-label ms-2" for="mySwitch">Only top tutors</label>
          </div>
          <div class="form-check form-switch tr-filter-switch  d-flex align-items-center">
            <input class="form-check-input ms-2" type="checkbox" id="disability" name="darkmode" value="yes" checked>
            <label class="form-check-label ms-2" for="mySwitch">Disability learners</label>
          </div>
        </div>
        <div>
          <span class="position-relative d-flex tr-me-2">
            <input class="form-control me-xxl-2 tr-find-tutor-filter-search" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="searchQuery" (ngModelChange)="onSearchInputChange($event)">
            <img src="assets/icons/search.svg" alt="search-svg" class="tr-filter-search" *ngIf="!searchQuery">
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row tr-filter-bg m-0 p-0 tr-filter-bd-top d-none d-sm-flex" *ngIf="ifBottomFilter">
    <div class="col-md-12 gap-3 px-lg-4 d-flex align-items-center flex-wrap py-3 py-md-4">
      <tr-custom-dropdown [clearCheckbox]="clearCheckbox$ | async" [id]="'dropdown-2'" (clear)="clearAll($event)" [dropDownMenuData]="proficiencyLevels" [targetList]="'proficiency'" [title]="'Native speaker'" [menuTitle]="'All languages'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
      <tr-custom-dropdown [clearCheckbox]="clearCheckbox$ | async" [id]="'dropdown-3'" (clear)="clearAll($event)" [dropDownMenuData]="countries" [targetList]="'countries'" [title]="'Tutor from'" [menuTitle]="'All countries'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
      <tr-custom-dropdown [clearCheckbox]="clearCheckbox$ | async" [id]="'dropdown-5'" (clear)="clearAll($event)" [dropDownMenuData]="ageGroups" [targetList]="'ageGroups'" [title]="'For age'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
      <tr-custom-dropdown [clearCheckbox]="clearCheckbox$ | async" [id]="'dropdown-4'" (clear)="clearAll($event)" [dropDownMenuData]="languages" [targetList]="'languages'" [title]="'Also speaks'" [menuTitle]="'All languages'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
      <tr-custom-dropdown [clearCheckbox]="clearCheckbox$ | async" [id]="'dropdown-6'" (clear)="clearAll($event)" [type]="'slider'" [title]="'Price per hour'" (selectedCheckData)="setPriceRange($event)"></tr-custom-dropdown>
      <tr-custom-dropdown (clear)="clearAll($event)" [id]="'dropdown-7'" [dropDownMenuData]="specialities" [targetList]="'specialities'" [title]="'Tutor type'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
      <!-- <tr-lesson-type-dropdown [title]="'Lesson time'"></tr-lesson-type-dropdown> -->
      <!-- <tr-custom-dropdown  [id]="'dropdown-8'" [dropDownMenuData]="currencies" [menuTitle]="'All currencies'" [targetList]="'currencies'"  [title]="'Change currency'" [isCurrency]="true"></tr-custom-dropdown> -->
    </div>
  </div>
</div>
<!-- <div class="row m-0 p-0 d-none">
  <div class="col-12 px-0 ">
    <a class="btn btn-outline-primary top20 w-100 tr-find-tutor-filter-mb-btn">Filters</a>
  </div>
</div> -->

<!-- Modal -->
<div class="modal fade" id="mobileFilterModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mobileFilterModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="tr-reg-popup-title p-0 m-0" id="mobileFilterModalLabel">Filters</h5>
        <button type="button" class="border-0 p-0 m-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close">
          <img src="assets/icons/close-circle.svg" alt="close-icon">
        </button>
      </div>
      <div class="modal-body">
       <div class="row p-0 m-0">
         <div class="col-12 p-0 m-0 d-flex flex-column gap-3">
            <tr-custom-dropdown [id]="'dropdown-2'" (clear)="clearAll($event)" [dropDownMenuData]="proficiencyLevels" [targetList]="'proficiency'" [title]="'Native Speaker'" [menuTitle]="'All languages'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="'dropdown-3'" (clear)="clearAll($event)" [dropDownMenuData]="countries" [targetList]="'countries'" [title]="'Tutor from'" [menuTitle]="'All countries'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="'dropdown-5'" (clear)="clearAll($event)" [dropDownMenuData]="ageGroups" [targetList]="'ageGroups'" [title]="'For age'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="'dropdown-4'" (clear)="clearAll($event)" [dropDownMenuData]="languages" [targetList]="'languages'" [title]="'Also speaks'" [menuTitle]="'All languages'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
            <tr-custom-dropdown [id]="'dropdown-6'" (clear)="clearAll($event)" [type]="'slider'" [title]="'Price per hour'" (selectedCheckData)="setPriceRange($event)"></tr-custom-dropdown>
            <tr-custom-dropdown (clear)="clearAll($event)" [id]="'dropdown-7'" [dropDownMenuData]="specialities" [targetList]="'specialities'" [title]="'Tutor type'" (selectedCheckData)="getSelectedData($event)" (selectedUnCheckData)="getUnSelectedData($event)"></tr-custom-dropdown>
         </div>
       </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-4 d-flex align-items-center gap-3 justify-content-between" *ngIf="targetedFilters && targetedFilters.length > 0">
  <div class="d-flex align-items-center gap-3">
    <p *ngFor="let filter of targetedFilters" class="mb-0 tr-font-12  tr-selected-filter-tag">
     {{ filter.name }}
    </p>
  </div>
  <div>
    <button class="tr-btn-danger p-2 py-1" (click)="clearAllFilters()"> Clear all </button>
  </div>
</div>