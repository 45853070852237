import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDay',
})
export class ShortDayPipe implements PipeTransform {
  transform(value: Date): string {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return dayNames[value.getDay()];
  }
}
