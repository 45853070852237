<ng-container *ngIf="!(this.bookLessonService.openBookingWindow$ | async)">
<section class="tr-pb-2">
	<img src="assets/images/patterns.svg" alt="patterns-img" class="tr-bg-img">
	<div class="row p-0 m-0 position-relative tr-z-2" *ngIf="tutor">
		<div class="col-md-8 px-4 m-0 d-flex flex-column gap-3">
			<div class="mt-4">
				<tr-tutor-intro [tutor]="tutor"></tr-tutor-intro>
			</div>
			<div>
				<tr-tutor-profile-discounts [discount]="tutor?.tutorDicount" [tutor]="tutor">
				</tr-tutor-profile-discounts>
			</div>
			<div>
				<tr-tutor-profile-header [tutor]="tutor"></tr-tutor-profile-header>
			</div>			
		</div>		
		<div class="col-md-4 mt-4 ps-md-2 px-4 tr-pe-2">
			<tr-tutor-profile-video [tutor]="tutor" [videoLinkUrl]="tutor?.videoLink" (playVideoStatus)="playVideo()"></tr-tutor-profile-video>
		</div>
		<div class="col-12 p-0 ps-4 m-0 mt-3">
			<tr-suggested-tutors></tr-suggested-tutors>
		</div>
	</div>
</section>
<ng-container *ngIf="tutor && tutor.videoLink">
	<tr-video-player [videoLink]="tutor.videoLink | safe" [showPlayer]="showPlayer" (onClosePlayer)="this.showPlayer = false"></tr-video-player>
</ng-container>
</ng-container>

<tr-raise-complaint-popup ></tr-raise-complaint-popup>
<tr-send-message-popup></tr-send-message-popup>
<section class="row p-0 m-0">
  <div class="tr-book-lesson-window col-md-12 col-lg-12 px-0 " *ngIf="this.bookLessonService.openBookingWindow$ | async">
   
   <ng-container *ngIf="(this.bookLessonService.bookingPhase$ | async) != 'incompleteProfile'">
     <tr-book-lesson></tr-book-lesson>
   </ng-container>    
   <ng-container *ngIf="(this.bookLessonService.bookingPhase$ | async) === 'incompleteProfile'">
      <tr-student-complete-information-popup></tr-student-complete-information-popup>
   </ng-container> 
   
  </div>
</section>

