import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'addTime',
  standalone: true
})
export class AddTimePipe implements PipeTransform {

  transform(value: string, minutesToAdd: number): string {
     if (value && minutesToAdd) {
      const date = new Date(value);
      date.setMinutes(date.getMinutes() + minutesToAdd);

      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return value;
  }

}
