<div class="row d-flex justify-content-center left5 tr-scroll-display">
  <div class="col-md-11 col-lg-12 top20">
<!--     <div class="row d-flex justify-content-center">
      <div class="col-md-10 col-lg-10 mb-4">
        <div class="row pb-2 justify-content-center">
          <div class="col-md-5 col-lg-5 text-center tr-scroll-display-tabs p-1">
            <span class="active">Schedule</span>
          </div> -->
<!--           <div class="col-md-5 col-lg-5 text-center tr-scroll-display-tabs p-1">
            <span>Reviews</span>
          </div> -->
<!--         </div>
      </div>
    </div> -->
    <div class="col-md-12 col-lg-12 mb-4">
      <div class="row p-0 m-0 tr-video-bg position-relative d-flex align-items-center justify-content-center" style="background-image:url('{{tutorVideoThumbnail}}');">
        <svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="playVideo()" class="cursor-pointer">
            <rect width="72" height="40" rx="6" fill="black" fill-opacity="0.5" />
            <path d="M41.2672 20.9757C42.2443 20.4114 42.2443 19.0006 41.2672 18.4363L32.1985 13.1987C31.2214 12.6344 30 13.3398 30 14.4684L30 24.9435C30 26.0722 31.2214 26.7776 32.1985 26.2133L41.2672 20.9757Z" fill="white" />
          </svg>
      </div>
    </div>
    <div class="tr-tutr-availability text-center">
<!--       <p class="tr-availability-date text-center mb-2">{{ startOfWeekDate | date:'MMM d'}} - {{ endOfWeekDate | date:'MMM d' }}</p>
      <div class="d-flex align-items-center justify-content-center mb-3">
        <p class="d-flex align-items-center me-3 mb-0">
          <span class="tr-circle d-block bg-green me-2"></span>
          <span class="tr-availability-status">Available</span>
        </p>
        <p class="d-flex align-items-center me-3 mb-0">
          <span class="tr-circle d-block bg-lite-blue me-2"></span>
          <span class="tr-availability-status">Booked</span>
        </p>
        <p class="d-flex align-items-center mb-0">
          <span class="tr-circle d-block bg-grey me-2"></span>
          <span class="tr-availability-status">Not available</span>
        </p>
      </div> -->
<!--       <div>
        <div class="d-flex align-items-center justify-content-between tr-tutor-date">
          <p></p>
          <div class="d-flex align-items-center justify-content-between tr-w-75">
            <span class="text-center">Mon</span>
            <span class="text-center">Tue</span>
            <span class="text-center">Wed</span>
            <span class="text-center">Thu</span>
            <span class="text-center">Fri</span>
            <span class="text-center">Sat</span>
            <span class="text-center">Sun</span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between tr-tutor-date-time mt-2">
          <p class="d-flex flex-column mb-0"> Morning <span>06:00 - 12:00</span>
          </p>
          <div class="d-flex align-items-center justify-content-between tr-w-75">
            <span *ngFor="let day of weekDays" id="{{day}}">
              
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between tr-tutor-date-time mt-1">
          <p class="d-flex flex-column mb-0"> Afternoon <span>12:00 - 18:00</span>
          </p>
          <div class="d-flex align-items-center justify-content-between tr-w-75">
            <span class="bg-booked"></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg-booked"></span>
            <span></span>
            <span class="bg-available"></span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between tr-tutor-date-time mt-1">
          <p class="d-flex flex-column mb-0"> Evening <span>18:00 - 24:00</span>
          </p>
          <div class="d-flex align-items-center justify-content-between tr-w-75">
            <span></span>
            <span class="bg-available"></span>
            <span class="bg-available"></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg-booked"></span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between tr-tutor-date-time mt-1">
          <p class="d-flex flex-column mb-0"> Night <span>00:00 - 06:00</span>
          </p>
          <div class="d-flex align-items-center justify-content-between tr-w-75">
            <span></span>
            <span class="bg-booked"></span>
            <span class="bg-booked"></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div> -->
 <!--      <p class="tr-tutor-timezone text-center mt-4 mb-4">Based on your timezone ({{timezone?.offset}})</p> -->
      <button class="tr-tutr-schedule-btn text-center py-2 px-3 w-100 mb-4 cursor-pointer tr-btn" [routerLink]="(auth.currentRole$ | async) !== 'guest' ? '/student/tutor/' + tutorData.id : '/tutor/' + tutorData.id"> View full schedule</button>
    </div>
  </div>
</div>
