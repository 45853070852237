export enum LESSON_PERMISSIONS {
    CANCEL = 'cancel',
    CANCEL_REQ = 'cancel-request',
    RESCHEDULE = 'reschedule',
    REQ_RESCHEDULE = 'request_reschedule',
    APPROVE = 'APPROVE',
    REJECT = 'reject',
    ACCEPT = 'accept',
    GIVE_NEW_TIME = 'give_new_time',
    COMPLETE = 'complete',
    RAISE_COMPLAINT = 'raise_complaint',
    VIEW_BOOKING = 'view_booking',
}

export enum LESSON_PAGES {
    MY_LESSONS = 'my-lessons',
    MY_REQ = 'my-requests',
    UP_COMING = 'upcoming-lessons',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    WAITING_MY_ACTION = 'waiting-my-action',
}

export const LOG_HISTORY_TYPE = {
  'AUTO_APPROVE': 'Auto approved',
  'PENDING_APPROVAL': 'Pending approval',
  'TUTOR_APPROVE_LESSON': 'Approved',
  'USER_DIRECT_CANCEL': 'Canceled',
  'USER_APPROVE_RESCHEDULE_LESSON': 'Rescheduled',
  'STUDENT_DIRECT_RESCHEDULE': 'Rescheduled',
  'USER_REQUEST_RESCHEDULE': 'Request reschedule',
  'TUTOR_REJECT_RESCHEDULE': 'Rejected reschedule',
  'USER_CANCEL_HIS_REQUEST': 'Canceled reschedule',
  'AUTO_CANCELED': 'Auto canceled',
  'OVERDUE': 'Overdue',
  'AUTO_COMPLETE': 'Auto completed'
};

export const LOG_TYPE_IMAGES = {
  'AUTO_APPROVE': 'assets/icons/lesson-log/complete.svg',
  'TUTOR_APPROVE_LESSON': 'assets/icons/lesson-log/complete.svg',
  'AUTO_COMPLETE': 'assets/icons/lesson-log/complete.svg',
  'PENDING_APPROVAL': 'assets/icons/lesson-log/pending-approval.svg',
  'STUDENT_DIRECT_RESCHEDULE':'assets/icons/lesson-log/reschedule.svg',
  'USER_REQUEST_RESCHEDULE': 'assets/icons/lesson-log/reschedule.svg',
  'USER_APPROVE_RESCHEDULE_LESSON': 'assets/icons/lesson-log/reschedule.svg',
  'TUTOR_REJECT_RESCHEDULE': 'assets/icons/lesson-log/reschedule.svg',
  'USER_CANCEL_HIS_REQUEST': 'assets/icons/lesson-log/reschedule.svg',
  'AUTO_CANCELED': 'assets/icons/lesson-log/cancel.svg',
  'USER_DIRECT_CANCEL': 'assets/icons/lesson-log/cancel.svg',
};