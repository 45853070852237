//Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../app/modules/shared.module';
import { CustomGridModule } from '@modules/custom-grid.module';
import { FindTutorModule } from '@modules/find-tutor.module';
import { TutorProfileModule } from '@modules/tutor-profile.module';

// Components - The below components are loaded AFTER the login
import { SideNavComponent } from '@components/core-components/side-nav/side-nav.component';
import { AppLayoutComponent } from '@components/core-components/app-layout/app-layout.component';
import { InAppHeaderComponent } from '@components/core-components/in-app-header/in-app-header.component';
import { StudentSettingsComponent } from '@studentComponents/student-settings/student-settings.component';
import { StudentWalletComponent } from '@studentComponents/student-wallet/student-wallet.component';
import { ToastComponent } from '@components/shared-components/toast/toast.component';
import { ToasterComponent } from '@components/shared-components/toaster/toaster.component';
import { AuthGuard } from '@auth/auth.guard';
import { RoleGuard } from '@auth/role.guard';
import { NotLoggedInGuard } from '@auth/not-logged-in.guard';
import { StudentDashboardComponent } from '@studentComponents/student-dashboard/student-dashboard.component';
import { StudentLessonComponent } from '@studentComponents/student-lesson/student-lesson.component';
import { StudentTutorsComponent } from '@studentComponents/student-tutors/student-tutors.component';
import { NotificationsAlertsComponent } from '@components/shared-components/notifications-alerts/notifications-alerts.component';
import { SvgModule } from './svg.module';
import { TutoringSettingsComponent } from '@components/tutor-components/tutoring-settings/tutoring-settings.component';
import { TutorDashboardComponent } from '@components/tutor-components/tutor-dashboard/tutor-dashboard.component';
import { ClassRoomComponent } from '@components/shared-components/class-room/class-room.component';
import { ROLES } from '@shared/models/shared';
import { TutorWalletComponent } from '@components/tutor-components/tutor-wallet/tutor-wallet.component';
import { TutorLessonsComponent } from '@components/tutor-components/tutor-lessons/tutor-lessons.component'; 
import { MyStudentsComponent } from '@components/tutor-components/my-students/my-students.component';
import { ChatComponent } from '@components/shared-components/chat/chat.component';
import { FindTutorGuestComponent } from '@components/shared-components/find-tutor-guest/find-tutor-guest.component';
import { FormDataResolver } from '@core/form-data-service.resolver';
import { TutorProfileComponent } from '@components/student-components/tutor-profile/tutor-profile.component';
import { TutorProfileGuestComponent } from '@components/student-components/tutor-profile/tutor-profile-guest/tutor-profile-guest.component';

const routes: Routes = [
  { 
    path: 'student', component: AppLayoutComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ROLES.STUDENT },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: StudentDashboardComponent, loadChildren: () => import('src/app/modules/student-dashboard.module').then(mod => mod.StudentDashboardModule) },
      { path: 'tutors', component: StudentTutorsComponent, loadChildren: () => import('./student-tutor.module').then(mod => mod.StudentTutorsModule) },
      { path: 'lessons', component: StudentLessonComponent, loadChildren: () => import('src/app/modules/student-lesson.module').then(mod => mod.StudentLessonModule) },
      { path: "settings", component: StudentSettingsComponent, loadChildren: () => import('./student-settings.module').then(mod => mod.StudentSettingsModule) },
      { path: "wallet", component: StudentWalletComponent, loadChildren: () => import('./student-wallet.module').then(mod => mod.StudentWalletModule) },
      { path: "chat", component: ChatComponent, loadChildren: () => import('./chat.module').then(mod => mod.ChatModule) },
      { path: "chat/:id", component: ChatComponent, loadChildren: () => import('./chat.module').then(mod => mod.ChatModule) },
      { path: "tutor/:id", component: TutorProfileComponent, loadChildren: () => import('./tutor-profile.module').then(mod => mod.TutorProfileModule) },
      { path: 'alerts', component: NotificationsAlertsComponent,loadChildren: () => import('./notifications-alerts.module').then(mod => mod.NotificationsAlertsModule) },
      { path: 'classroom', component: ClassRoomComponent, loadChildren: () => import('./class-room.module').then(mod => mod.ClassRoomModule) },
      { path: '**', redirectTo: '/student' }
    ]
  },
  // Allow access to "tutor/:id" for all users, including those who are not logged in
  { path: "find-tutor/tutor/:id", component: TutorProfileGuestComponent, loadChildren: () => import('./tutor-profile.module').then(mod => mod.TutorProfileModule) },
  {
    path: 'admin', component: AppLayoutComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ROLES.ADMIN },
    children: [
      // { path: '**', redirectTo: '/admin', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule) }
    ]
  },
  {
    path: 'find-tutor', component: FindTutorGuestComponent,canActivate: [NotLoggedInGuard],
    data: { "tutorRoute": true },
    resolve: {
      formData: FormDataResolver,
    },
  },
  {
    path: 'tutor', component: AppLayoutComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ROLES.TUTOR },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: "dashboard", component: TutorDashboardComponent, loadChildren: () => import('./tutor-dashboard.module').then(mod => mod.TutorDashboardModule) },
      { path: "settings", component: TutoringSettingsComponent, loadChildren: () => import('./tutor-settings.module').then(mod => mod.TutorSettingsModule) },
      { path: "lessons", component: TutorLessonsComponent, loadChildren: () => import('./tutor-lessons.module').then(mod => mod.TutorLessonsModule) },
      { path: "wallet", component: TutorWalletComponent, loadChildren: () => import('./tutor-wallet.module').then(mod => mod.TutorWalletModule) },
      { path: 'alerts', component: NotificationsAlertsComponent,loadChildren: () => import('./notifications-alerts.module').then(mod => mod.NotificationsAlertsModule) },
      { path: "chat", component: ChatComponent, loadChildren: () => import('./chat.module').then(mod => mod.ChatModule) },
      { path: "chat/:id", component: ChatComponent, loadChildren: () => import('./chat.module').then(mod => mod.ChatModule) },
      { path: "students", component: MyStudentsComponent, loadChildren: () => import('./tutor-students.module').then(mod => mod.TutorStudentsModule) },
      { path: '**', redirectTo: '/tutor' }
    ]
  },  
  {
    path: '**', redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class TrCoreRoutingModule { }

// Core Module imported by App Module only
@NgModule({
  declarations: [
    AppLayoutComponent,
    SideNavComponent,
    InAppHeaderComponent,
    ToastComponent,
    FindTutorGuestComponent,
    TutorProfileGuestComponent,
    ToasterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TrCoreRoutingModule,
    SvgModule,
    FindTutorModule,
    SharedModule,
    TutorProfileModule,
    CustomGridModule
  ],
  exports: [
    AppLayoutComponent,
    SideNavComponent,
    InAppHeaderComponent,
    ToastComponent,
    ToasterComponent
  ]
})

export class TrCoreModule { }