import { Component, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';
import { BookLessonService } from '../book-lesson.service';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';
import { SLOT_DURATIONS, extractLessonDateTime } from '@shared/models/scheduler';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'tr-lesson-summary',
  templateUrl: './lesson-summary.component.html',
  styleUrls: ['./lesson-summary.component.css']
})
export class LessonSummaryComponent extends TRObject implements OnInit {
  @ViewChild(FormNotificationComponent) formNotificationChild!: FormNotificationComponent;
  infoMessage: string = "You can't edit your booking process after clicking on go to payment so check again your order and if no changes needed, click go to payment";
  profilePicture!: string;
  lessonTutor$: any;
  extractLessonDateTime: Function = extractLessonDateTime;
  totalPrice: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  discount$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  totalDiscountPrice$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  lessonsTimeSummary$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(@SkipSelf() public bookLessonService: BookLessonService) {
    super();
    this.lessonTutor$ = this.bookLessonService.selectedTutor$.getValue();
    this.bookLessonService.lessonSummaryPrice$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (lessonSummaryPrice: any) => {
        this.totalPrice.next(lessonSummaryPrice?.prices?.totalPrice);
        this.discount$.next(lessonSummaryPrice?.prices?.discount);
        this.totalDiscountPrice$.next(lessonSummaryPrice?.prices?.totalPriceWithDiscount);
        this.lessonsTimeSummary$.next(lessonSummaryPrice?.prices?.data);
        console.log(this.lessonsTimeSummary$.value);
        
      }
    )
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => { this.setNotificationAlert() }, 0);
  }

  setNotificationAlert() {
    this.formNotificationChild?.showNotificationAlert(this.infoMessage, 'Warning');
  }

}