import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as bootstrap from 'bootstrap';


@Component({
  selector: 'tr-video-player',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
  @Output() onClosePlayer = new EventEmitter();
  @Input() videoLink:any;
  @Input() showPlayer:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
