import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';
import { TRObject } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponseHandlerService } from '@components/shared-components/response-handler.service';
import { finalize } from 'rxjs/operators';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';


@Component({
  selector: 'tr-send-message-popup',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SpinnerComponent],
  templateUrl: './send-message-popup.component.html',
  styleUrls: ['./send-message-popup.component.css'],
})
export class SendMessagePopupComponent extends TRObject implements OnInit {
  @ViewChild('openModalBtn') openModalBtn?: ElementRef;
  @ViewChild('closeModalBtn') closeModalBtn?: ElementRef;
  userDetails: any;
  isLoading: boolean = false;

  public chatForm = this.fb.group({
    user_id: ['', Validators.required],
    body: ['', Validators.required],
    attachment: [''],
  });

  constructor(
    private service: SendMessagePopupService,
    private fb: UntypedFormBuilder,
    private responseHandlerService: ResponseHandlerService) {
    super();
  }

  ngOnInit(): void {
    this.service.userDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((details: any) => {
        if (details) {
          this.chatForm.patchValue({
            user_id: details.id,
          });
          this.userDetails = details;
          this.openModalBtn?.nativeElement.click();
        }
      });
  }

  ngOnDestroy(){
    this.closeModalBtn?.nativeElement.click();
  }

  sendMessage() {
    this.isLoading = true;
    this.service
      .sendMessage(this.chatForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {    
          this.responseHandlerService.handleSuccessRessponse(res['message']);
          this.closeModalBtn?.nativeElement.click();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

}
