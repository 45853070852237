<div class="d-flex flex-wrap gap-3 flex-wrap flex-md-nowrap align-items-center justify-content-between mt-4">
  <section class="d-flex align-items-center gap-2" *ngIf="totalPages > 1">
<!--     <span class="tr-first-btn cursor-pointer d-flex align-items-center px-2 py-1" (click)="setPage(firstPage)" *ngIf="lastPage > 5"> 
      <img src="assets/icons/double-arrow-left.svg" alt="">
      First
    </span> -->
    <button class="tr-prev-btn" (click)="previousPage()" [disabled]="currentPage === 1"></button>
    <span class="tr-paginate-btn text-center cursor-pointer" *ngFor="let page of pages" [ngClass]="{'active': page === currentPage}" (click)="setPage(page)"> {{ page }} </span>
    <button class="tr-next-btn" (click)="nextPage()" [disabled]="currentPage === totalPages"></button>
<!--     <span class="tr-last-btn cursor-pointer d-flex align-items-center px-2 py-1" (click)="setPage(lastPage)" *ngIf="lastPage > 5">
      Last
      <img src="assets/icons/double-arrow-left.svg" alt="">
    </span> -->
  </section>
  <section class="d-flex justify-content-end w-100">
    <div class="tr-custom-page d-flex align-items-center gap-3">
      <span>Rows per page</span>
      <select class="form-select" aria-label="Default select example" (change)="changePerPageSize($event)"  [(ngModel)]="perPage">
        <option value="10">10</option>
        <option value="1">1</option>
        <option value="20">20</option>
      </select>
      <div class="tr-no-text">
        {{ getStartIndex() }} - {{ getEndIndex() }}
        <span>of</span> {{ totalRows }}
      </div>
    </div>
  </section>
</div>