import { Component, OnInit } from '@angular/core';
import { scrollTop, TRObject } from 'src/app/shared/models/shared';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from '@angular/forms';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { FormDataService } from 'src/app/core/form-data.service';
import { formValidationMessages } from 'src/app/shared/models/validation';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastService } from '@components/shared-components/toast.service';
import { Phases } from 'src/app/shared/models/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-tutor-reg-save-phase-two',
  templateUrl: './tutor-reg-save-phase-two.component.html',
  styleUrls: ['./tutor-reg-save-phase-two.component.css']
})
export class TutorRegSavePhaseTwoComponent extends TRObject implements OnInit {
  isLoading:boolean = false;
  nationalId:any;

  public errorMessages = {
    firstName: formValidationMessages['firstName'],
    lastName: formValidationMessages['lastName'],
    mobileNumber: formValidationMessages['mobileNumber'],
    gender: formValidationMessages['gender'],
    pricePerHour: formValidationMessages['pricePerHour'],
    nationalId_img: formValidationMessages['nationalId_img'],
    countryId: formValidationMessages['countryId'],
    langId: formValidationMessages['langId'],
    profId: formValidationMessages['profId'],
  }

  public phaseTwoForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    mobileNumber: ['', Validators.required],
    gender: ['', Validators.required],
    pricePerHour: ['', Validators.required],
    nationalId_img: [''],
    currencyId: 47,
    countryId: ['', Validators.required],
    spokenLanguages: this.fb.array([this.initSubjectRows()])
  });

  constructor(private fb: UntypedFormBuilder, private toastService: ToastService, private tutorRegPhasesService: TutorRegPhasesService, public formDataService: FormDataService) {
    super();
    this.countries = this.formDataService.countries$;
    this.proficiencyLevels = this.formDataService.proficiencyLevels$;
    this.languages = this.formDataService.languages$;
    this.currencies = this.formDataService.currencies$;
  }

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  countries: any;
  proficiencyLevels: any;
  languages: any;
  file: any;
  currencies: any;
  fileUploadTouched: boolean = false;
  selectedCurrency: string = "GBP";
  searchText: string = '';

  ngOnInit(): void {
    this.fillForm()
  }

  ngAfterViewInit() {
    scrollTop();
  }

  changeNationalId(){
    this.nationalId = null;
    this.phaseTwoForm.get('nationalId_img')?.setValidators([Validators.required]);
   }

  fillForm() {
    this.tutorRegPhasesService.phaseTwoFormData$.subscribe(
      (data: any) => {
        let res: any = data.user;
        console.log(res);
        if (res) {
          if(res['nationalId_img']){
            this.nationalId = res['nationalId_img'];
          }
          else{
            this.phaseTwoForm.get('nationalId_img')?.setValidators([Validators.required]);
          }
          let spokenLanguages = JSON.parse(res.spokenLanguages);
          // Add new row fields to the form
          for (let i = 0; i < spokenLanguages.length - 1; i++) {
            this.addNewSubject()
          }

          this.phaseTwoForm.patchValue({
            firstName: res.firstName,
            lastName: res.lastName,
            mobileNumber: res.mobileNumber,
            gender: res.gender,
            pricePerHour: res.pricePerHour,
            nationalId_img: '',
            currencyId: 47, // TODO: make it db driven 
            countryId: res.countryId,
            spokenLanguages: JSON.parse(res.spokenLanguages)
          })
        }

      },
      (error: any) => {
        this.handleRegPhaseTwoFailure(error.message);
      }
    )
  }
  nextPhase() {
    this.isLoading = true;
    this.getMobileNumber();
    console.log(this.phaseTwoForm.value, "this.phaseTwoForm.value")
    this.tutorRegPhasesService.registerPhaseTwo(this.phaseTwoForm.value).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        console.log(data)
        if (data.status) {
          this.handleRegPhaseTwoSuccess(data['message']);
          this.tutorRegPhasesService.setPhase(Phases.PHASE_THREE)
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }
  initSubjectRows() {
    return this.fb.group({
      langId: ['', Validators.required],
      profId: ['', Validators.required],
    });
  }

  //Add subjects row as array
  get subjArr() {
    return this.phaseTwoForm.get('spokenLanguages') as UntypedFormArray;
  }

  get f() {
    return this.phaseTwoForm.controls;
  }

  getMobileNumber() {
    let mobileNumber = this.phaseTwoForm.get('mobileNumber') || null;
    if (mobileNumber?.value !== null) {
      this.phaseTwoForm.get('mobileNumber')?.setValue(mobileNumber?.value['e164Number'])
    }
  }

  selectNationalIdImage(e: any) {
    this.file = e.addedFiles;
    this.phaseTwoForm.get('nationalId_img')?.setValue(e.addedFiles[0])
  }

  onRemove(event: any) {
    this.file.splice(this.file.indexOf(event), 1);
    this.phaseTwoForm.get('nationalId_img')?.setValue('')
  }
  savePhaseTwo() {
    this.isLoading = true;
    this.getMobileNumber();
    this.tutorRegPhasesService.savePhaseTwo(this.phaseTwoForm.value).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        this.handleRegPhaseTwoSuccess(data['message']);
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }
  onSubmitPhaseTwoForm() {
    this.fileUploadTouched = true;
    this.initSubjectRows();
    return this.fb.group({
      langId: ['', Validators.required],
      profId: ['', Validators.required],
    });
  }

  //Add new subjects row
  addNewSubject() {
    this.subjArr.push(this.initSubjectRows());
  }

  //Delete selected subjects row
  deleteSubject(i: number) {
    this.subjArr.removeAt(i);
  }

  // saveCurrencyId() {
  //   let currencyValues = this.currencies.value;
  //   let currency = currencyValues.find((x: any) => {
  //     if (x.code == this.selectedCurrency) { return x; }
  //   });
  //   this.phaseTwoForm.patchValue({ currencyId: currency.id });
  // }

  handleRegPhaseTwoSuccess(res: any) {
    this.toastService.showSuccessToast('Success !', res);
  }

  handleRegPhaseTwoFailure(res: any) {
    this.toastService.showErrorToast('Error!', res);
  }

  public handleResponseException(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422) {
        const validationErrors = error.error['errors'];
        console.log(validationErrors);
        Object.keys(validationErrors).forEach(prop => {
          const formControl = this.phaseTwoForm.get(prop);
          this.handleRegPhaseTwoFailure(validationErrors[prop]);
          if (formControl) {
            formControl.setErrors({
              serverError: validationErrors[prop]
            });
          }
        });
      }
    }
    else {
      this.handleRegPhaseTwoFailure(error.message);
    }
  }

}