import { Component, OnInit, Input } from '@angular/core';
import { TutorProfileService } from './../tutor-profile.service';

@Component({
  selector: 'tr-tutor-profile-discounts',
  templateUrl: './tutor-profile-discounts.component.html',
  styleUrls: ['./tutor-profile-discounts.component.css']
})
export class TutorProfileDiscountsComponent implements OnInit {
  @Input() discount:any;
  @Input() tutor:any;
  constructor( private tutorProfileService:TutorProfileService) { }

  ngOnInit(): void {
  }

  openBookingWindow(){
   this.tutorProfileService.openBookingWindow$.next(this.tutor);
  }

}
