import { Component, OnInit, Input, Inject } from '@angular/core';
import { favSavedTutorIcon, arrowDownPath, lessonBookPath } from 'src/app/shared/models/svgIcons';
import { BookLessonService } from '../book-lesson/book-lesson.service';
import { FindTutorService } from '../find-tutor/find-tutor.service';
import { ToastService } from '@components/shared-components/toast.service';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';
import { AuthService } from '@core/auth/auth.service';
import * as bootstrap from 'bootstrap';
import { BOOKING_PHASES } from '@shared/models/shared';
import { Router } from '@angular/router';
@Component({
  selector: 'tr-tutor-tile',
  templateUrl: './tutor-tile.component.html',
  styleUrls: ['./tutor-tile.component.css']
})
export class TutorTileComponent implements OnInit {
  isListView: any;
  arrowDownPath: string = arrowDownPath;
  lessonBookPath: string = lessonBookPath;
  tutorAboutExpanded: boolean = false;
  @Input() tutor: any;
  spokenLanguages: any[] = [];
  tutorData!: { id: number; name: string; logo: string };
  @Input() role:any;
  user = this.authService.user$.value;

  @Input()
  public set selectedViewType(value: any) {
    if (value) {
      // this.selectedView = value;
    }
  }
  constructor(private bookLessonService: BookLessonService, 
    private findTutorService: FindTutorService, 
    private router: Router,
    private toastService: ToastService,
    public authService:AuthService,
    private messageService:SendMessagePopupService) 
  {

    this.isListView = this.findTutorService.isListView$
  }

  ngOnInit(): void {
    this.spokenLanguages = this.tutor.spokenLanguages;
  }

  getTutorCountry() {

  }

  openBookingWindow(tutor: any) {
    this.user = this.authService.user$.value;
    if(this.user && this.user['id']){
        if (
          this.user.fname && 
          this.user.lname &&
          this.user.isEmailVerified === true &&
          this.user.isPhoneVerified === true ) 
        {
          this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.LESSON_TYPE_DETAILS);
        } 
      else{
          this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.INCOMPLETE);
          const modalElement = document.getElementById('openCompleteInformationModal');
          if(modalElement){
            const modal = new bootstrap.Modal(modalElement);
            modal.show();
          }
      }
      this.bookLessonService.openBookingWindow$.next(true);
      this.bookLessonService.enableDisablePageScroll('hidden');
      this.bookLessonService.selectedTutor$.next(tutor);

    }
    else{
       sessionStorage.setItem('tutorData', JSON.stringify(tutor));
      this.router.navigate(['/login']);
    }
  }

  updateTutorFavFlag(isFav: boolean) {
    this.tutor.isFav = !isFav
  }

  sendMessage(id:any , name:any, logo:any){
    this.tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    this.messageService.sendUserDetails(this.tutorData);
  }

  removePTags(html: string): string {
    // Remove the <p> tags using regular expressions
    return html.replace(/<\/?p>/g, '');
  }

  toggleTutorFavorite(id: any, isFav: any) {
    this.findTutorService.toggleTutorFavorite(id, isFav).subscribe(
      (res: any) => {
        if (res.status) {
          this.toastService.showSuccessToast('Success!', res['message']);
          this.updateTutorFavFlag(isFav)
        }
      },
      (error: any) => {
        this.toastService.showErrorToast('Error!', error['message']);
      }
    )
  }
}
