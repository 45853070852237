import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { finalize } from 'rxjs/operators';
import { formValidationMessages } from 'src/app/shared/models/validation';

@Component({
  selector: 'tr-add-experience-popup',
  templateUrl: './add-experience-popup.component.html',
  styleUrls: ['./add-experience-popup.component.css']
})
export class AddExperiencePopupComponent implements OnInit {
  @ViewChild('closeModal') closeModal?: ElementRef;
  @Output() onChangeStatus = new EventEmitter();
  disable:boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private tutorRegPhasesService: TutorRegPhasesService) { }

  public errorMessages = {
          yearsOfExperienceFrom: formValidationMessages['fromDate'],
          yearsOfExperienceTo: formValidationMessages['toDate'],
          company: formValidationMessages['company'],
          position: formValidationMessages['position'],
          description: formValidationMessages['description'],
  }

  public addExperienceForm = this.fb.group({
          yearsOfExperienceFrom:['', Validators.required],
          yearsOfExperienceTo:['', Validators.required],
          company: ['',Validators.required],
          position: ['',Validators.required],
          description: ['', Validators.required],
  });

  ngOnInit(): void {}

  addExperience(){
    this.disable = true;
    this.tutorRegPhasesService.registerWorkExperience(this.addExperienceForm.value).pipe(finalize(() => this.disable = false)).subscribe(
      (data: any) => {
       if(data.status){
          this.onChangeStatus.emit('success');
          this.closeModal?.nativeElement.click();
          this.toastService.showSuccessToast('Success !', 'Experience added successfully !!');
        }
      },
      (error: any) => {
        this.toastService.showErrorToast('Error!', error.message);
      }
    )
  }

  clearForm(){
    this.addExperienceForm.reset();
  }

}
