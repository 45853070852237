import { Injectable, Inject, } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LessonDetailsService {
  public chatId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public lessonId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  lessonPhase$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  lessonUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  

  constructor(private trHttp: TrHttpService, @Inject(DOCUMENT) private document: Document) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  enableDisablePageScroll(prop: string) {
    if(prop === 'scroll'){
        this.document.body.classList.remove('tr-overflow-hidden');
    }
    else{
        this.document.body.classList.add('tr-overflow-hidden');
    }
  }

  getLessonDetails(id:any){
    const formData = new FormData();
    formData.append('lessonId', id)
    return this.fetchCore('lesson-board-data', formData);
  }
  
  raiseComplaint(formData:any){
    return this.fetchCore("add-complaint", formData, HttpMethod.POST);
  } 

  addLessonDescription(formData:any){
    return this.fetchCore("add-lesson-description", formData, HttpMethod.POST);
  }

  getLessonBoardMessages(chatId:any){
    return this.fetchCore('get-chat-messages/'+ chatId)
  } 

  getLessonLogHistory(id:any, perPage:any){
    const formData = new FormData();
    formData.append('lessonId', id);
    formData.append('perPage', perPage);
    return this.fetchCore('lesson-log-history-data', formData);
  } 

  submitReview(formData:any){
    return this.fetchCore('add-tutor-review', formData, HttpMethod.POST);
  }

  sendMessage(form:any){
    return this.fetchCore('send-chat-message', form, HttpMethod.POST)
  }


}
