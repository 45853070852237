import { Component, ComponentFactoryResolver, ElementRef, Input, OnInit, SkipSelf, ViewChild, ViewContainerRef } from '@angular/core';
import { TRObject } from '@shared/models/shared';
import { take, takeUntil } from 'rxjs/operators';
import { BookLessonService } from './book-lesson.service';
import { FindTutorService } from '../find-tutor/find-tutor.service';
import { SchedulerService } from '@components/scheduler/scheduler.service';
import { BOOKING_PHASES } from '@shared/models/shared';

@Component({
  selector: 'tr-book-lesson',
  templateUrl: './book-lesson.component.html',
  styleUrls: ['./book-lesson.component.css'],
  providers: [SchedulerService] //To preserve the values of the selected slots when the user clicks on the Back button
})

export class BookLessonComponent extends TRObject implements OnInit {
  @ViewChild('tutorSchedulerWrapper', { read: ViewContainerRef, static: true }) public tutorSchedulerWrapper!: ViewContainerRef;
  @ViewChild('bookingFooter', { static: true }) bookingFooter!: ElementRef;
  selectedTutor: any;
  bookingPhase: any;
  isLoaded: boolean = false;
  componentFactory: any;
  componentInstance: any;
  BOOKING_PHASES: any = BOOKING_PHASES;


  constructor(@SkipSelf() public findTutorService: FindTutorService, @SkipSelf() public bookLessonService: BookLessonService, private resolver: ComponentFactoryResolver) {
    super();
    this.bookingPhase = this.bookLessonService.bookingPhase$;
    this.selectedTutor = this.findTutorService.targetedTutorTile$;
    console.log(this.bookingPhase.value, this.selectedTutor.value)
  }

  ngOnInit(): void {
    let footer: HTMLDivElement = this.bookingFooter.nativeElement;

    this.bookLessonService.bookingPhase$.pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(
        (phaseName: any) => {
          if (phaseName == BOOKING_PHASES.TIME_SLOTS) {
            this.lazyLoadSchedulerModule();
          } else {
            this.tutorSchedulerWrapper.remove(); // remove scheduler components from the view
            if (phaseName == BOOKING_PHASES.BOOKING_CHECKOUT) {
              footer.style.display = 'none';
            } else {
              footer.style.display = 'block';
            }
          }
        }
      )
  }

  // load scheduler module
  lazyLoadSchedulerModule() {
    import('../../../../modules/scheduler.module').then(({ SchedulerModule }) => {
      const schedulerComponents = SchedulerModule.getSchedulerComponents();
      this.componentFactory = this.resolver.resolveComponentFactory(schedulerComponents);
      this.componentInstance = this.tutorSchedulerWrapper.createComponent(this.componentFactory);
      this.bookLessonService.isSchedulerModuleLoaded$.next(true);
    });
  }

  ngOnDestroy(): void {
    this.tutorSchedulerWrapper.remove(); // Delete scheduler components when the popup is closed
    this.closeBookingWindow()
  }

  closeBookingWindow() {
    this.bookLessonService.closeBookingWindow()
  }
}