<!-- Modal -->
<div class="modal-header tr-modal-header top-0 border-0 tr-bg-highlight">
  <h3 class="tr-main-header-title w-100 text-center">Complete information </h3>
  <div class="tr-close-window cursor-pointer" type="button" (click)="closeBookingWindow()">
    <img src="assets/icons/close-icon.svg" alt="close-icon">
  </div>
</div>
<div class="modal-body tr-modal-body">
  <section>
    <div class="d-flex align-items-center justify-content-center">
      <div class="tr-custom-padding tr-bg-highlight w-75 d-flex align-items-center justify-content-center">
        <div class="tr-max-600">
          <h3 class="tr-sub-title-dark-text tr-font-16 text-center lh-2">Before you proceed to book a lesson, please take a moment to complete your profile. </h3>
          <section class="row p-0 mx-0 mb-0 justify-content-center tr-gap-2 tr-mt-2" [formGroup]="updateInfoForm">
            <div class="form-group col-sm-11 px-0 px-md-3">
              <label class="tr-dark-text-md tr-font-12 mb-2">First name</label>
              <input type="text" formControlName="firstName" class="form-control" placeholder="Enter first name">
              <ng-container>
                <div *ngFor="let error of errorMessages.firstName">
                  <ng-container *ngIf="updateInfoForm.controls['firstName'].hasError(error.type) && (updateInfoForm.controls['firstName'].touched)">
                    <small class="error-message">{{error.message}}</small>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="form-group col-sm-11 px-0 px-md-3">
              <label class="tr-dark-text-md tr-font-12 mb-2">Last name</label>
              <input type="text" formControlName="lastName" class="form-control" placeholder="Enter last name">
              <ng-container>
                <div *ngFor="let error of errorMessages.lastName">
                  <ng-container *ngIf="updateInfoForm.controls['lastName'].hasError(error.type) && (updateInfoForm.controls['lastName'].touched)">
                    <small class="error-message">{{error.message}}</small>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="form-group col-sm-11 px-0 px-md-3" [formGroup]="emailUpdateInfoForm">
              <label class="tr-dark-text-md tr-font-12 mb-2">Email</label>
              <div class="d-flex flex-column flex-sm-row gap-3" *ngIf="!this.isEmailVerified">
                <input type="email" formControlName="email" [(ngModel)]="email" class="form-control" placeholder="Enter your email">
                <button class="px-3 py-2 text-nowrap tr-verify-btn  border-0" (click)="verify('Email')" type="button" [disabled]="emailUpdateInfoForm.invalid">
                  <span *ngIf="!isLoading">   Verify Email</span>
                  <tr-spinner *ngIf="isLoading"></tr-spinner>
              </button>
              </div>
              <div class="top5" *ngFor="let error of errorMessages.email">
                <ng-container *ngIf="
                          emailUpdateInfoForm.controls['email'].hasError(error.type) &&
                          emailUpdateInfoForm.controls['email'].touched
                        ">
                  <small class="error-message">{{ error.message }}</small>
                </ng-container>
              </div>
              <div class="tr-bg-grey d-flex align-items-center justify-content-between px-3 py-2" *ngIf="this.isEmailVerified">
                <span> {{ email }}</span>
                <div class="tr-verify d-flex justify-content-center gap-1 align-items-center">
                  <img src="assets/icons/checked-white.svg" alt="checked-image" />Verified
                </div>
              </div>
            </div>
            <div class="form-group col-sm-11 px-0 px-md-3" [formGroup]="mobileUpdateForm">
              <label class="tr-dark-text-md tr-font-12 mb-2">Mobile no.</label>
              <div class="d-flex flex-column flex-sm-row gap-3" *ngIf="!this.ismobileVerified">
                <div class="tr-w-100">
                  <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="[
                              CountryISO.UnitedStates,
                              CountryISO.UnitedKingdom
                            ]" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]" [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedKingdom" [maxLength]="15" [separateDialCode]="true" name="phone" formControlName="mobileNumber" [(ngModel)]="mobileNumber"></ngx-intl-tel-input>
                </div>
                <button class="px-3 py-2 text-nowrap tr-verify-btn border-0" (click)="verify('Phone')" type="button" [disabled]="mobileUpdateForm.invalid">
              
                  <span *ngIf="!isLoading">     Verify Mobile </span>
                  <tr-spinner *ngIf="isLoading"></tr-spinner>
              </button>
              </div>
              <div class="tr-bg-grey d-flex align-items-center justify-content-between px-3 py-2" *ngIf="this.ismobileVerified">
                <span> {{ this.user['mobile'] }}</span>
                <div class="tr-verify d-flex justify-content-center gap-1 align-items-center">
                  <img src="assets/icons/checked-white.svg" alt="checked-image" />Verified
                </div>
              </div>
            </div>
            <div class="col-sm-11 px-0 px-md-3">
              <button class="tr-btn-primary w-100 tr-btn d-flex align-items-center justify-content-center" [disabled]="updateInfoForm.invalid || !isEmailVerified || !ismobileVerified" (click)="goToBookingPhase()"> Continue </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</div>