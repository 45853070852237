<div class="row p-0 m-0 p-4 tr-bg-highlight">
      <div class="d-flex align-items-start gap-3 p-0 col-md-9 position-relative tr-blur">
        <span class="d-none d-md-block">
          <tr-skeleton-image [width]="9" [height]="9"></tr-skeleton-image>
        </span>        
        <span class="d-md-none">
          <tr-skeleton-image [width]="4" [height]="4"></tr-skeleton-image>
        </span>
       
        <div class="position-relative w-100 d-flex flex-column align-items-start">
          <div class="position-relative w-100">
            <tr-single-line [width]="70"></tr-single-line>
          </div>         
           <div class="position-relative w-100 top20">
            <tr-single-line [width]="30"></tr-single-line>
          </div>           
          <div class="position-relative w-100 top30">
            <tr-single-line [width]="40"></tr-single-line>
            <div class="position-relative w-100 top20">
              <tr-single-line [width]="60"></tr-single-line>
            </div>
          </div>          
          <div class="position-relative w-100 top30">
            <tr-single-line [width]="80"></tr-single-line>
             <div class="position-relative w-100 top30">
              <tr-single-line [width]="70"></tr-single-line>
            </div>
          </div>
       </div>
      </div>
      <div class="col-md-3 d-flex position-relative p-0 d-none d-md-flex">
        <tr-skeleton-button></tr-skeleton-button>
        <tr-skeleton-button [top]="80"></tr-skeleton-button>
      </div>
</div>