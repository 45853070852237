import { Component, OnInit, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { Phases, ROLES } from '@shared/models/shared';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    @SkipSelf() private authService: AuthService,
    private router: Router,
    private tutorRegPhasesService: TutorRegPhasesService,
    private toastService: ToastService
  ) {}

  loginForm!: UntypedFormGroup;
  processing: Boolean = false;
  error: Boolean = false;
  isLoading: boolean = false;
  showPassword: boolean = false;
  tokenLoader: boolean = false;

  ngOnInit(): void {
    this.authService.clearData();
    this.initForm();

    try {
      const urlData = this.router.parseUrl(this.router.url);

      if (urlData?.queryParams?.token) {
        this.tokenLoader = true;
        this.authService
          .getLoginToken(urlData.queryParams.token)
          .pipe(finalize(() => (this.tokenLoader = false)))
          .subscribe((res: any) => {
            this.handleLoginSuccess(res.data);
            this.toastService.showSuccessToast('Success', res['message']);
          },
          (error: any) => {
            this.handleLoginFailure(error);
          }
        );
      }
    } 
    catch (error) {}
  }

  public login() {
    this.isLoading = true;
    this.authService
      .login(this.loginForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.handleLoginSuccess(res.data);
          this.toastService.showSuccessToast('Success', res['message']);
        },
        (error: any) => {
          this.handleLoginFailure(error);
        }
      );
  }

  private initForm() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  handleLoginSuccess(data: any) {
    let user: any = data.user;
    let role: any = user.active_role;

    switch (role) {
      case ROLES.STUDENT:
        this.handleStudentLogin(user);
        break;
      case ROLES.TUTOR:
        this.handleTutorLogin(user);
        break;
      case ROLES.ADMIN:
        this.handleAdminLogin(user);
    }
  }

  handleTutorLogin(user: any) {
    let registerPhase: string = user.register_phase;
    switch (registerPhase) {
      case Phases.CODE_CONFIRMATION:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.router.navigate(['register-tutor']);
        break;
      case Phases.PHASE_TWO:
        this.handleNavigationPhaseTwo(user);
        break;
      case Phases.PHASE_THREE:
        this.handleNavigationPhaseThree(user);
        break;
      case Phases.PHASE_FOUR:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.handleNavigationPhaseFour(user);
        break;
      case Phases.PHASE_FIVE:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.handleNavigationPhaseFive(user);
        break;     
      case Phases.PHASE_SIX:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.router.navigate(['register-tutor']);
        break;      
      case Phases.PHASE_REVIEW:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.router.navigate(['register-tutor']);
        break;
      case Phases.PHASE_COMPLETED:
        this.tutorRegPhasesService.setPhase(registerPhase);
        this.router.navigate(['tutor']);
        break;
    }
  }

  handleStudentLogin(user: any) {
    const tutor = sessionStorage.getItem('tutorData');
    if (tutor) {
      console.log(tutor);
       this.router.navigate(['student/tutors/find-tutor']);
    }
    else{
      this.router.navigate(['student']);
    }
  }

  handleAdminLogin(user: any) {
    this.router.navigate(['admin']);
  }

  handleNavigationPhaseTwo(user: any) {
    this.tutorRegPhasesService.getPhaseTwoFormData().subscribe((res: any) => {
      this.tutorRegPhasesService.phaseTwoFormData$.next(res.data);
      this.tutorRegPhasesService.setPhase(user.register_phase);
      this.router.navigate(['register-tutor']);
    });
  }

  handleNavigationPhaseThree(user: any) {
    this.tutorRegPhasesService.getPhaseThreeFormData().subscribe((res: any) => {
      this.tutorRegPhasesService.phaseThreeFormData$.next(res.data);
      this.tutorRegPhasesService.setPhase(user.register_phase);
      this.router.navigate(['register-tutor']);
    });
  }

  handleNavigationPhaseFour(user: any) {
    this.tutorRegPhasesService.getPhaseFourFormData().subscribe((res: any) => {
      this.tutorRegPhasesService.phaseFourFormData$.next(res.data);
      this.tutorRegPhasesService.setPhase(user.register_phase);
      this.router.navigate(['register-tutor']);
    });
  }  

  handleNavigationPhaseFive(user: any) {
    this.tutorRegPhasesService.getPhaseFiveFormData().subscribe((res: any) => {
      this.tutorRegPhasesService.phaseFiveFormData$.next(res.data);
      this.tutorRegPhasesService.setPhase(user.register_phase);
      this.router.navigate(['register-tutor']);
    });
  }

  handleLoginFailure(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (
        error.status === 422 ||
        error.status === 404 ||
        error.status === 500
      ) {
        this.toastService.showErrorToast('Error!', error['error'].message);
      }
    } else {
      this.toastService.showErrorToast('Error!', error.message);
    }
  }
}
