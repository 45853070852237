import { Injectable, SkipSelf } from '@angular/core';
import { HttpMethod } from '@shared/models/shared';
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { TrHttpService } from '@core/tr-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { validateObjKey } from '@shared/models/validation';

@Injectable({
  providedIn: 'root'
})
export class TutorDashboardService {

  constructor(private trHttp: TrHttpService) {
  }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

   filterLessons(params: any) {
    return this.fetchCore('user-lessons-requests', params)
  }

  getUserChats(){
    const formData = new FormData();
    formData.append('perPage', '3');
    formData.append('page', '1');
    return this.fetchCore('get-user-chats' , formData);
  } 
  
  getTodayLessons(formData:any){
    return this.fetchCore('user-lessons' , formData);
  }

  getUserStats() {
    return this.fetchCore('get-user-stats')
  }  

  getTutorReviews(formData:any){
    return this.fetchCore('get-tutor-all-reviews', formData);
  }

}
