import { Component, EventEmitter, Input, OnInit, Output, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { BookLessonService } from '../book-lesson/book-lesson.service';
import { delayedExecution } from '@shared/models/shared';
@Component({
  selector: 'tr-student-booking-payment-success',
  templateUrl: './student-booking-payment-success.component.html',
  styleUrls: ['./student-booking-payment-success.component.css']
})
export class StudentBookingPaymentSuccessComponent implements OnInit {
  constructor(private router: Router, @SkipSelf() public bookLessonService: BookLessonService) { }
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter(false);
  _paymentDetails: any;
  @Input() title:any = 'Payment'
  @Input()
  set paymentDetails(val: any) {
    this._paymentDetails = val;
    console.log(this._paymentDetails);
  }

  ngOnInit(): void {
  }

  redirectToLessons() {
    delayedExecution(()=>{
      this.router.navigate(['student', 'lessons', 'upcoming-lessons']);
      this.bookLessonService.closeBookingWindow();
    },200)
  }

  closePopup() {
    this.isClosed.emit(true);

    delayedExecution(()=>{
      this.router.navigate(['student', 'tutors', 'find-tutor']);
      this.bookLessonService.closeBookingWindow();
    }, 100);
  }

}
