import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tr-skeleton-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-image.component.html',
  styleUrls: ['./skeleton-image.component.css']
})
export class SkeletonImageComponent implements OnInit {
  @Input() width:number = 2.5;
  @Input() height:number = 2.5;

  constructor() { }

  ngOnInit(): void {
  }

}
