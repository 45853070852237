import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AddCertificatePopupComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-four/add-certificate-popup/add-certificate-popup.component';
import { AddEducationPopupComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-four/add-education-popup/add-education-popup.component';
import { AddExperiencePopupComponent } from '@components/app-components/login-registration/tutor-reg-save-phase-four/add-experience-popup/add-experience-popup.component';
import { NgxDropzoneModule } from 'ngx-dropzone';



@NgModule({
  declarations: [    
    AddCertificatePopupComponent,
    AddEducationPopupComponent,
    AddExperiencePopupComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule
  ],
  exports:[
    AddCertificatePopupComponent,
    AddEducationPopupComponent,
    AddExperiencePopupComponent
  ]
})
export class AddDetailsPopupModule { }
