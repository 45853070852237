<div class="modal fade modal-lg" id="addExperienceModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addExperienceModal" aria-hidden="true">
   <div class="modal-dialog">
      <div class="modal-content tr-bd-6">
         <div class="modal-body tr-padding-2">
         	<div>
            	<img src="assets/icons/close-icon.svg" alt="close-icon" class="float-end d-md-none" data-bs-dismiss="modal">
            	<h6 class="tr-reg-popup-title mb-4 mb-md-3">Add work experience</h6>
         	</div>
            <p class="tr-reg-popup-desc">If you have training or experience as a teacher, show your student this.</p>
            <form [formGroup]="addExperienceForm">
               <div class="row p-0 m-0 tr-row-gap-2">
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study">Years of study</label>
                        <div class="d-flex mt-2 w-100">
                           <div class="d-flex align-items-center flex-grow-1">
                              <input type="text" id="from" formControlName="yearsOfExperienceFrom" placeholder="from" class="br-none tr-year-input" onfocus="(this.type='date')">
                           </div>
                           <span class="tr-form-divider m-auto"></span>
                           <div class="d-flex align-items-center flex-grow-1">
                              <input type="text" id="to" placeholder="to" formControlName="yearsOfExperienceTo" class="bl-none tr-year-input" onfocus="(this.type='date')">
                           </div>
                        </div>
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.yearsOfExperienceFrom">
                           <ng-container *ngIf="addExperienceForm.controls['yearsOfExperienceFrom'].hasError(error.type) && (addExperienceForm.controls['yearsOfExperienceFrom'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>                        
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.yearsOfExperienceTo">
                           <ng-container *ngIf="addExperienceForm.controls['yearsOfExperienceTo'].hasError(error.type) && (addExperienceForm.controls['yearsOfExperienceTo'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div> 
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study" class="mb-2">Company</label>
                        <input type="text" placeholder="Company" id="company" formControlName="company" class="form-control">
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.company">
                           <ng-container *ngIf="addExperienceForm.controls['company'].hasError(error.type) && (addExperienceForm.controls['company'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div> 
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study" class="mb-2">Position</label>
                        <input type="text" placeholder="Position" id="Position" formControlName="position" class="form-control">
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.position">
                           <ng-container *ngIf="addExperienceForm.controls['position'].hasError(error.type) && (addExperienceForm.controls['position'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="Attachment" class="mb-2">Description</label>
                        <textarea id="w3review" formControlName="description" name="description" rows="4" cols="50" placeholder="type here"></textarea>
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.description">
                           <ng-container *ngIf="addExperienceForm.controls['description'].hasError(error.type) && (addExperienceForm.controls['description'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-12">
                      <div class="d-flex align-items-center justify-content-center gap-3">
                          <button class="btn-discard tr-btn" data-bs-dismiss="modal" #closeModal>Discard</button>
                          <button class="btn-add tr-btn" (click)="addExperience()" [disabled]="addExperienceForm.invalid || disable">Add work experience</button>
                      </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>