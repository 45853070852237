import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { scrollTop } from 'src/app/shared/models/shared';
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from '@angular/forms';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { FormDataService } from 'src/app/core/form-data.service';
import { formValidationMessages } from 'src/app/shared/models/validation';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastService } from '@components/shared-components/toast.service';
import{  FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';
import { CropModalComponent } from '@components/shared-components/crop-modal/crop-modal.component';
import { Phases } from 'src/app/shared/models/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-tutor-reg-save-phase-three',
  templateUrl: './tutor-reg-save-phase-three.component.html',
  styleUrls: ['./tutor-reg-save-phase-three.component.css']
})

export class TutorRegSavePhaseThreeComponent implements OnInit {

  isLoading:boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoaded: boolean = false;
  isCropperDialogClosed: boolean = true;
  profilePicture:any
  profileImage: any = '';
  @ViewChild('cropper') cropper!: ElementRef;
  @ViewChild('profilePictureEl') profilePictureEl!: ElementRef;
  @ViewChild(FormNotificationComponent) formNotificationChild!: FormNotificationComponent;
  @ViewChild( CropModalComponent ) cropModalChild!: CropModalComponent;

  public errorMessages = {
    profilePicture: formValidationMessages['profilePicture'],
    userName: formValidationMessages['userName'],
    about: formValidationMessages['about'],
    videoLink: formValidationMessages['videoLink'],
    subjectId: formValidationMessages['subjectId'],
    specialistId: formValidationMessages['specialistId'],
    ageId: formValidationMessages['ageId'],
  }

  constructor(private renderer: Renderer2, private toastService: ToastService, private fb: UntypedFormBuilder, private tutorRegPhasesService: TutorRegPhasesService, private formDataService: FormDataService) {
  }

  isVideoReqExpanded: boolean = false;
  videoReqPanelIcon: string = 'expand-icon.svg';
  givenCharacters: number = 0;

  public phaseThreeForm = this.fb.group({
    profilePicture: [''],
    userName: ['', Validators.required],
    about: ['', Validators.required],
    videoLink: ['', Validators.required],
  });

  ngOnInit(): void {
    this.fillForm()
  }

  checkUserName(userName: string) {
      this.tutorRegPhasesService.checkUserName(userName).subscribe(
        (data: any) => {
          let checkAlert = this.formNotificationChild.showAlert;
          if(checkAlert){
             this.formNotificationChild.hideAlert();
          }
        },
        (error: any) => {
         if(error instanceof HttpErrorResponse){
          if(error.status === 422){
            const validationError = error.error['message'];
            this.formNotificationChild.showNotificationAlert(validationError, 'Error');
          }
        }
        else{
           this.handleRegPhaseThreeFailure(error.message); 
         }
        }
      )
  }

  changeImage(){
    this.profilePicture = null;
    this.phaseThreeForm.get('profilePicture')?.setValidators([Validators.required]);

  }

  fillForm() {
    this.tutorRegPhasesService.phaseThreeFormData$.subscribe(
      (data: any) => {
        let res: any = data.user;
        console.log(res);
        if(res['profilePicture']){
          this.profilePicture = res['profilePicture'];
        }
        else{
          this.phaseThreeForm.get('profilePicture')?.setValidators([Validators.required]);
        }

        if (res) {
          this.phaseThreeForm.patchValue({
            profilePicture: '',
            userName: res.username,
            about: res.about,
            videoLink: res.videoLink,
          })
        }
      },
      (error: any) => {
        console.error(error)
      }
    )
  }
  ngAfterViewInit() {
    scrollTop()
  }

  nextPhase() {
    this.isLoading = true;
    this.tutorRegPhasesService.registerPhaseThree(this.phaseThreeForm.value).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        if(data.status){
          this.handleRegPhaseThreeSuccess(data['message']);
          this.tutorRegPhasesService.setPhase(Phases.PHASE_FOUR)
        }
      },
      (error: any) => {
       this.handleResponseException(error);
      }
    )
  }

  fileChangeEvent(event: any): void {
    this.cropModalChild.imageChangedEvent = event;
  }

  handleUploadProfilePicture(file: any) {
    this.phaseThreeForm.get('profilePicture')?.setValue(file)
    this.profilePictureEl.nativeElement.setAttribute('style', 'background-repeat: no-repeat; background-size: cover;background-position: center center; ')
  }

  backToPrevPhase() {
    this.isLoading = true;
    this.tutorRegPhasesService.getPhaseTwoFormData().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.status) {
          this.handleRegPhaseThreeSuccess(res['message']);
          this.tutorRegPhasesService.phaseTwoFormData$.next(res.data);
          this.tutorRegPhasesService.setPhase(Phases.PHASE_TWO)
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }
  savePhaseThree() {
    this.isLoading = true;
    this.tutorRegPhasesService.savePhaseThree(this.phaseThreeForm.value).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        this.handleRegPhaseThreeSuccess(data['message']);
        console.log(data, ">> Saved Data");
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }

  expandVideoRequirements() {
    this.isVideoReqExpanded = !this.isVideoReqExpanded;
    this.videoReqPanelIcon = this.isVideoReqExpanded ? "collapse-icon.svg" : 'expand-icon.svg';
  }

  onSubmit() {
    console.log(this.phaseThreeForm.value, "this.phaseThreeForm.value")
  }

  public countCharacters(values: any) {
    let charactersEntered = values.target.value;
    this.givenCharacters = charactersEntered.length;
  }

  handleRegPhaseThreeSuccess(res:any) {
    this.toastService.showSuccessToast('Success !', res);
  }
  handleRegPhaseThreeFailure(res:any) {
    this.toastService.showErrorToast('Error!', res);
  }

  public handleResponseException(error:any){
    if(error instanceof HttpErrorResponse){
      if(error.status === 422){
        const validationErrors = error.error['errors'];
        console.log(validationErrors);
          Object.keys(validationErrors).forEach(prop => {
          const formControl = this.phaseThreeForm.get(prop);
          this.handleRegPhaseThreeFailure(validationErrors[prop]); 
          if (formControl) {
            formControl.setErrors({
              serverError: validationErrors[prop]
            });
          }
        });
      }
    }
    else{
       this.handleRegPhaseThreeFailure(error.message); 
    }
  }
}