<div class="tr-student-register-component container-fluid d-flex justify-content-center">
    <div class="tr-student-reg-wrapper col-md-6 col-lg-6 top20">
        <div class="row top30">
            <div class="tr-student-reg-main-title col-md-12 col-lg-12 text-center">Student registration</div>
        </div>

        <div class="row top20">
            <div class="tr-student-reg-label col-md-12 col-lg-12 text-center bottom15">
                Register as
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 col-lg-12 d-flex justify-content-center">
                <div class="col-md-2 col-lg-2 d-flex justify-content-center">
                    <div class="col-md-4 col-lg-6 d-flex justify-content-end tr-register-student-tab" routerLink="/register-student">Student</div>
                    <div class="col-md-4 col-lg-4 d-flex justify-content-center tr-register-tabs-splitter top15"><span></span></div>
                    <div class="col-md-4 col-lg-4 d-flex justify-content-start tr-register-tutor-tab" routerLink="/register-tutor">Tutor</div>
                </div>
            </div>
        </div>
<!--         <div class="tr-padding-2">
            <tr-register-with></tr-register-with>
         </div>

        <div class="row">
            <div class="tr-student-reg-splitter-wrapper col-md-10 col-lg-10 text-center">
                <span>or</span>
            </div>
        </div> -->
           <!-- Student Register Form - Start -->
        <form [formGroup]="studentRegisterForm">        
        <div class="row tr-student-reg-input d-flex justify-content-center top20">
            <div class="col-md-11 col-lg-11 col-11">
                <label class="col-md-2 col-lg-2 d-flex justify-content-start" >Email</label>
            </div>

            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start top5">
                <input class="col-md-12 col-lg-12 col-12 ps-3" formControlName="email" autofill="off" autocomplete="nope" placeholder="Email" type="email"/>
            </div>
             <div class="col-md-11 top5" *ngFor= "let error of errorMessages.email">
               <ng-container *ngIf="studentRegisterForm.controls['email'].hasError(error.type) && (studentRegisterForm.controls['email'].touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
        </div>  

        <div class="row tr-student-reg-input d-flex justify-content-center top30">
            <div class="col-md-11 col-lg-11 col-11">
                <label class="col-md-2 col-lg-2 d-flex justify-content-start">Password</label>
            </div>

            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start top5">
                <input #password (input)="getPass(password.value)" autofill="off" autocomplete="new-password" class="col-md-12 col-lg-12 col-12 ps-3" placeholder="Password" formControlName="password" type="{{ showPassword ? 'text' : 'password' }}" />
                <img src="assets/images/password_icon.svg" alt="password" class="tr-view-pass-img cursor-pointer" (click)="this.showPassword = !this.showPassword">
            </div>
        </div>

        <div class="row d-flex justify-content-center top20">
            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start">
                <label class="tr-reg-password-criteria col-md-12 col-lg-12">
                    Password must:
                </label>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-md-11 col-lg-11 col-11 top10 tr-reg-password-criteria-items">
                <ng-container *ngFor="let rule of passRules">
                    <label class="col-md-12 col-lg-12"> <img src="../../../assets/icons/{{rule.icon}}" alt="validate-img" class="me-2"> {{rule.text}}</label><br>
                </ng-container>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-md-12 col-lg-12 col-11 tr-reg-privacy-policy d-md-flex justify-content-center text-center top30 bottom15">
                By creating an account, you agree to tutor reach's <span class="left5 tr-reg-privacy-link"> terms of service </span> <span class="left5"> and</span> <span class="left5 tr-reg-privacy-link"> privacy policy</span>
            </div>
        </div>

        <div class="row d-flex justify-content-center top30">
            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start">
                <button class="tr-student-reg-btn-disabled col-md-12 col-lg-12 col-12 bottom30 tr-btn" [ngClass]="this.isPassValid && studentRegisterForm.valid?'tr-student-reg-btn-enabled':'tr-student-reg-btn-disabled'" [disabled]="!this.isPassValid || !studentRegisterForm.valid"  (click)="registerStudent()">
                    <span *ngIf="!isLoading">Create an account</span>
                    <tr-spinner *ngIf="isLoading"></tr-spinner>
                </button>
            </div>
        </div>
    </form>
        
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="col-md-12 col-lg-12 tr-student-reg-footer d-flex align-items-center d-flex justify-content-center">
                    Already have an account? <span class="tr-student-reg-footer-login-switch left5 cursor-pointer" routerLink="/login">Login</span>
                </div>
            </div>
        </div>

    </div>
</div>
