import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { TRObject } from '@shared/models/shared';
@Component({
  selector: 'tr-tutor-lessons',
  templateUrl: './tutor-lessons.component.html',
  styleUrls: ['./tutor-lessons.component.css'],
  providers:[ReschedulerService]
})
export class TutorLessonsComponent extends TRObject implements OnInit {
  sideNavItemNames = sideNavItemNames;
  @ViewChild('openReschedulerModal') openReschedulerModal?: ElementRef;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;

  constructor(public userLessonsService: UserLessonsService) {super()}

  ngOnInit(): void {
    this.userLessonsService.lessonAction$.subscribe(action => {
      if (action === LESSON_PERMISSIONS.RESCHEDULE || action === LESSON_PERMISSIONS.REQ_RESCHEDULE || action === LESSON_PERMISSIONS.GIVE_NEW_TIME) {
        setTimeout(() => {this.openReschedulerModal?.nativeElement.click()},100);
      }
    });
  }
}
