import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'getLessonDate',
  standalone: true
})
export class GetLessonDatePipe implements PipeTransform {

  transform(value: string): string {
    const targetDate = moment(value);
    const currentDate = moment();
    const tomorrowDate = moment().add(1, 'day');

    if (targetDate.isSame(currentDate, 'day')) {
      // Show time if it's today
      return targetDate.format('hh:mm a');
    } else if (targetDate.isSame(tomorrowDate, 'day')) {
      // Show tomorrow's time
      return `Tomorrow ${targetDate.format('hh:mm a')}`;
    } else {
      // Show date and time
      return targetDate.format('MMM DD, YYYY hh:mm a');
    }
  }
}