import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { scrollTop } from 'src/app/shared/models/shared';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { FormDataService } from 'src/app/core/form-data.service';
import { formValidationMessages } from 'src/app/shared/models/validation';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastService } from '@components/shared-components/toast.service';
import { FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';
import { Phases } from 'src/app/shared/models/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-tutor-reg-save-phase-four',
  templateUrl: './tutor-reg-save-phase-four.component.html',
  styleUrls: ['./tutor-reg-save-phase-four.component.css'],
})
export class TutorRegSavePhaseFourComponent implements OnInit {
  isLoading:boolean = false;
  subjects: any;
  specialities: any;
  specialities$: any;
  ageGroups: any;
  disable: boolean = true;
  selectedSubjectId?: number;
  selectedSpecialistId?: number;
  selectedageId?: number;
  finalFormValues: any;
  subjectPrices: any;
  editRecordData: any;
  disabeleBtn: any;
  dataStoreArray: any = [];

  public phaseFourForm = this.fb.group({
    subjectId: ['', Validators.required],
    ageId: ['', Validators.required],
    specialistId: ['', Validators.required],
    period30: ['', Validators.required],
    period60: ['', Validators.required],
    period90: ['', Validators.required],
  });



  public errorMessages = {
         subjectId: formValidationMessages['subjectId'],
         specialistId: formValidationMessages['specialistId'],
         ageId: formValidationMessages['ageId'],
         price: formValidationMessages['price'],
  };

  constructor(
    private renderer: Renderer2,
    private toastService: ToastService,
    private fb: UntypedFormBuilder,
    private tutorRegPhasesService: TutorRegPhasesService,
    private formDataService: FormDataService
  ) {
    this.subjects = this.formDataService.subjectsFindTutor$;
    this.ageGroups = this.formDataService.ageGroups$;
    this.specialities$ = this.formDataService.specialities$;
  }

  ngOnInit(): void {
    this.fillForm()
  }

  onSubjectChange(event:any){
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.tutorRegPhasesService.subjectById(selectedValue).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res['data']) {
          this.specialities = res['data'];
          console.log(this.specialities)
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    );
  }

  backToPrevPhase() {
    this.isLoading = true;
    this.tutorRegPhasesService.getPhaseThreeFormData().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.status) {
          this.handleRegPhaseFourSuccess(res['message']);
          this.tutorRegPhasesService.phaseThreeFormData$.next(res.data);
          this.tutorRegPhasesService.setPhase(Phases.PHASE_THREE);
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    );
  }

  nextPhase() {
    this.isLoading = true;
    this.loopPeriod(this.dataStoreArray);
    this.tutorRegPhasesService
      .registerPhaseFour(this.finalFormValues)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data: any) => {
          if (data.status) {
            this.handleRegPhaseFourSuccess(data['message']);
            this.tutorRegPhasesService.setPhase(Phases.PHASE_FIVE);
          }
        },
        (error: any) => {
          this.handleResponseException(error);
        }
      );
  }

  fillForm() {
    this.tutorRegPhasesService.phaseFourFormData$.subscribe(
      (data: any) => {
        let subjects: any = data.subjects;
        console.log(subjects);
        if (subjects) {
          this.getFormattedData(subjects);
        }
      },
      (error: any) => {
        console.error(error)
      }
    )
  }

  ngAfterViewInit() {
    scrollTop()
  }

  //function to format backend data into required format
getFormattedData(array: any) {
  const formattedData = array.reduce((result: any, obj: any) => {
    const subjectId = obj.subjectId.toString();
    const ageId = obj.ageId.toString();
    const specialistId = obj.specialistId.toString();
    const subjectPrices = obj.subjectPrice;

    if (!result[subjectId]) {
      result[subjectId] = {
        subjectId,
        ageId,
        specialistId,
      };
    }

    subjectPrices.forEach((priceObj: any) => {
      const period = priceObj.period;
      const price = parseInt(priceObj.price);
      result[subjectId]["period" + period] = price;
    });

    return result;
  }, {} as Record<string, any>);

  const finalArray = Object.values(formattedData);
  this.dataStoreArray = finalArray;
  console.log(this.dataStoreArray);
}


  addSubjects() {
    this.dataStoreArray.push(this.phaseFourForm.value);
    this.phaseFourForm.reset();
  }

  handleRegPhaseFourSuccess(res: any) {
    this.toastService.showSuccessToast('Success !', res);
  }

  handleRegPhaseFourFailure(res: any) {
    this.toastService.showErrorToast('Error!', res);
  }

  //function to format form values to send in required format
  loopPeriod(objArray: any) {
    const outputArray: any = [];
    objArray.forEach((obj: any) => {
      for (let key in obj) {
        if (key.startsWith('period')) {
          let period = key.substr(6);
          let price = obj[key].toString().replace(/\s+/g, '');

          outputArray.push({
            subjectId: obj.subjectId,
            ageId: obj.ageId,
            specialistId: obj.specialistId,
            period: period,
            price: price,
          });
        }
      }
    });
    this.finalFormValues = outputArray;
  }

  public handleResponseException(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422) {
        const validationErrors = error.error['errors'];
        console.log(validationErrors);
        Object.keys(validationErrors).forEach((prop) => {
          const formControl = this.phaseFourForm.get(prop);
          this.handleRegPhaseFourFailure(validationErrors[prop]);
          if (formControl) {
            formControl.setErrors({
              serverError: validationErrors[prop],
            });
          }
        });
      }
    } else {
      this.handleRegPhaseFourFailure(error.message);
    }
  }

  deleteRecord(index: any) {
    this.dataStoreArray.splice(index, 1);
  }
}