import { Component, OnInit, Output, EventEmitter, SkipSelf, Input } from '@angular/core';
import { CardViewService } from '@core/card-view.service';
import { FormDataService } from '@core/form-data.service';
import { BehaviorSubject, of } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { gridDisplayPath, listDisplayPath, sortByFilterIcon, FilterBySvg, searchDropdownIcon } from 'src/app/shared/models/svgIcons';
import { lessonStatuses, lessonRequestStatus, lessonRequestTypes, lessonSortBy, nameSortBy } from '@shared/models/shared';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'tr-lessons-action-header',
  templateUrl: './lessons-action-header.component.html',
  styleUrls: ['./lessons-action-header.component.css']
})

export class LessonsActionHeaderComponent implements OnInit {
  gridDisplayPath: string = gridDisplayPath;
  listDisplayPath: string = listDisplayPath;
  sortByFilterIcon: string = sortByFilterIcon;
  FilterBySvg: string = FilterBySvg;
  @Input() hideFilter: boolean = false;
  isListView: boolean = false;
  searchText:any;
  allSubjects$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  @Output() lessonFilters: EventEmitter<any> = new EventEmitter<any>();
  @Input() lessonPage: string = 'default';
  lessonStatuses: any;
  targetLessonList: any;
  selectedSortBy: string = 'Date ASC'; //to store the selected sortByType
  tempTutors: any = of(['tutor 1', 'tutor 2']);
  userRole = this.authService.currentRole$.getValue();
  userData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  type:any;
  private searchSubject = new Subject<string>();
  searchDropdownIcon: string = searchDropdownIcon;
  @Input() set hideGridView(hideGridView: boolean) {
    this.sortByType = hideGridView ? nameSortBy : lessonSortBy;
  }
  sortByType: Array<any> = lessonSortBy;
  targetedFilters:any = [];
  userFilterSelection: any = {
      lessonStatus: [],
      requestStatus: [],
      userId: [],
      subjectId: [],
   };

  constructor(@SkipSelf() public cardViewService: CardViewService, 
    private formData: FormDataService,
    private authService: AuthService,
    private sanitizer: DomSanitizer) {
    this.getSubjectsForActionHeaderFilters();
    this.getLisView('list');
  }

  ngOnInit(): void {
    this.type = this.userRole === 'student' ? 'Tutors' : 'Students';
    this.getUsersData();
    this.getLessonStatuses();
     this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm:any) => {
      this.search(searchTerm);
    });
  }

  getLessonStatuses() {
    switch (this.lessonPage) {
      case 'default':
      case 'upcomingLessons':
      case 'myLessons':
        this.lessonStatuses = of(lessonStatuses);
        this.targetLessonList = 'lessonStatus'
        break;
      case 'myRequestLessons':
      case 'waitingMyAction':
        this.lessonStatuses = of(lessonRequestStatus);
        this.targetLessonList = 'requestStatus'
        break;
    }

  }

  getSanitizedSVG(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(FilterBySvg);
  }

  onSearchKeyUp() {
    this.searchSubject.next(this.searchText);
  }

  search(value:any) {
      this.userFilterSelection['searchBy'] = value;
      this.lessonFilters.emit(this.userFilterSelection);
   }
  
  getSelectedData(item: any, isSortBy: boolean = false) {
    let targetedFilter: any;
    targetedFilter = item;
    let targetList = item.targetList;
    let selectedData = item.data;
    this.userFilterSelection[targetList].push(selectedData);
    this.targetedFilters.push(targetedFilter);
    this.lessonFilters.emit(this.userFilterSelection);
  }

  removeFilterIfExist(newFilter: any) {
    this.targetedFilters = this.targetedFilters.filter((filter:any) => !(filter.targetList === newFilter.targetList && filter.data === newFilter.data));
  }
  
   getUnSelectedData(data: any) {
      this.removeFilterIfExist(data);
      let filteredArray = this.userFilterSelection[data.targetList].filter(
         function (number: any) {
            return number !== data.data;
         }
      );
      this.userFilterSelection[data.targetList] = filteredArray;
      this.lessonFilters.emit(this.userFilterSelection);
   }

  // Function to add or update a filter in 'targetedFilters'
  addOrUpdateFilter(newFilter:any) {
    const index =  this.targetedFilters.findIndex((filter:any) => filter.targetList === newFilter.targetList);
    if (index !== -1) {
      // A filter with the same 'targetList' exists; update it
      this.targetedFilters[index] = newFilter;
    } else {
      // No filter with the same 'targetList' found; add the new filter
      this.targetedFilters.push(newFilter);
    }
  }


  getSubjectsForActionHeaderFilters() {
    this.formData.getSubjects().subscribe(
      (res: any) => {
        let subjects = res.data.map(
          (subject: any) => (
            { ...subject, target: 'subjectId' }
          )
        )

        this.allSubjects$.next(subjects);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  emitFilters(item: any, isSortBy: boolean = false) {
    let targetedFilter: any;
    if (isSortBy) {

      targetedFilter = item;

      targetedFilter.data = item.name; // to match the custom-dropdown data
      console.log(targetedFilter, "..")
    } else {
      targetedFilter = JSON.parse(item.target.value)

      console.log(targetedFilter, "..")

    }
    this.lessonFilters.emit(targetedFilter);
  }

  getLisView(view?: string) {
    let isListView: boolean = view === 'list' ? false : true;
    this.isListView = isListView;
    this.cardViewService.isListView$.next(isListView)
  }

  getUsersData(){
    this.formData.getUsers(this.userRole).pipe()
      .subscribe(
        (res: any) => {
          let data = res['data'];
          if(data['turors']){
             data = res['data']['turors'];
          }
          else if(data['students']){
             data = res['data']['students'];
          }
          this.userData$.next(data);
          console.log(data);
        },
        (error: any) => {
          
        }
    );
  }


}