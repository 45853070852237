<main class="tr-communication-container d-flex align-items-center justify-content-center">
	<section class="row p-0 m-0 tr-communication-row">
		<div class="col-12 d-flex flex-column align-items-center justify-content-center">
			<p class="mb-4">Which communication tool would you like to use for your lesson?</p>
			<div class="tr-communication-select p-4 d-grid gap-3 align-items-center flex-column">
				<div class="tr-select tr-default-communication-tool tr-select-platform d-flex align-items-center justify-content-center gap-3 p-3 flex-column">
					<div class="tr-lesson-ct-header d-flex align-items-center justify-content-center">
						<img src="assets/logos/colored-logo-h.svg" alt="">
						<div class="tr-divider left10"></div>
						<span class="left10">Classroom</span>
					</div>
<!-- 					<div class="tr-add-phone-number">
						<label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label tr-dark-text-md tr-font-14 mb-2">Mobile no.</label>
                        <ngx-intl-tel-input
                        [cssClass]="'tr-tutor-reg-input'"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true"
                        [selectedCountryISO]="CountryISO.UnitedKingdom"
                        [maxLength]="15"
                        [separateDialCode]="true"
                        name="phone"
                        >
                        </ngx-intl-tel-input>
					</div> -->
				</div>			
				<div class="tr-select tr-select-zoom tr-communication-disabled d-flex align-items-center justify-content-center p-3 position-relative">
					<img src="assets/images/zoom.svg" alt="">
					<figcaption>Coming soon</figcaption>
				</div>			
				<div class="tr-select tr-select-meet tr-communication-disabled d-flex align-items-center justify-content-center p-3 position-relative">
					<img src="assets/images/gmeet.svg" alt="">
					<figcaption>Coming soon</figcaption>
				</div>
			</div>
		</div>
	</section>
</main>