import { Component, OnInit, SkipSelf } from '@angular/core';
import { TutorDashboardService } from '@components/tutor-components/tutor-dashboard/tutor-dashboard.service';
import { finalize } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";


@Component({
  selector: 'tr-tutor-dashboard-waiting-my-action',
  templateUrl: './tutor-dashboard-waiting-my-action.component.html',
  styleUrls: ['./tutor-dashboard-waiting-my-action.component.css']
})
export class TutorDashboardWaitingMyActionComponent implements OnInit {
  lessonsCount!:number;
  isLoading: boolean = true;
  lessons:any;

  constructor(@SkipSelf() public tutorDashboardService: TutorDashboardService, private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.filterLessons();
  }

  filterLessons() {
    this.tutorDashboardService
      .filterLessons(this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.lessons = res.data;
          this.lessonsCount = res.data.length;
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  getParams(params?: any): any  {
    return (params = {
      perPage: 3,
      page: 1,
      tab: 'waitingAction',
      sortByType:'ASC',
      userType: "tutor",
    });
  }

}
