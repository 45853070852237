<ng-container *ngFor="let card of _savedCards">
  <section (click)="toggleCardSelection(card)" class="top10">
    <div class="py-3 px-4 tr-bd-highlight d-flex align-items-center cursor-pointer justify-content-between bottom5">
      <div class="tr-paymnt-mthd w-100">
        <div class="d-flex align-items-center justify-content-start">
          <label class="checkbox mt-2">
           <input 
              type="radio" 
              [checked]="selectedCard?.id == card.id" 
              [(ngModel)]="chosenCard" 
              [value]="card"
              (click)="toggleCardSelection(card)"
              name="tr-card" 
              class="tr_checkbox__input" 
            />
            <span class="tr_checkbox__inner"></span>
          </label>
          <div class="tr-radio-label d-flex justify-content-start align-items-center w-100">
            <img *ngIf="parseJson(card.payment_method_details).brand === 'visa'" src="assets/icons/payments/visa-saved-card.svg" alt="" class="ms-2">
            <img *ngIf="parseJson(card.payment_method_details).brand === 'mastercard'" src="assets/icons/payments/mastercard-icon.png" alt="" class="ms-2">
            <div class="left15">
              <p class="mb-0 tr-saved-card-details-label">******** **** {{parseJson(card.payment_method_details).lastFourDigits}}</p>
              <p class="mb-0 tr-saved-card-details-label">{{parseJson(card.payment_method_details).brand}}</p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </section>
</ng-container>