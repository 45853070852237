import * as bootstrap from 'bootstrap';

export const sidenavItems: any = {
  "student": [
    { id: "collapse", title: "", icon: "collapse", class: "d-none d-lg-table", function: () => hideSidePanel() },
    { id: "dashboard", title: "Dashboard", icon: "dashboard", link: "dashboard", function: () => '' },
    { id: "studenttutors", title: "Tutors", icon: "tutors", link: "tutors/find-tutor", function: () => '' },
    { id: "Lessons", title: "Lessons", icon: "lessons", link: "lessons", function: () => '' },
    { id: "mywallet", title: "My wallet", icon: "wallet", link: "wallet", class: "d-none d-lg-table", function: () => '' },
    { id: "chat", title: "Chat", icon: "chat", link: "chat", class: "d-none d-md-table", function: () => '' },
    { id: "blog", title: "Blog", icon: "blog", class: "d-none d-md-table", function: () =>  openBlog() },
    { id: "Settings", title: "Settings", icon: "settings", link: "settings", class: "d-none d-md-table", function: () => '' },
    { id: "help", title: "Help", icon: "support", class: "d-none d-md-table", function: () => openHelp()  },
    { id: "logout", title: "Logout", icon: "logout", link: "/logout", class: "d-none d-md-table tr-logout", function: () => '' },
    { id: "more", title: "More", icon: "more",  class: "d-md-none", function: () => openMobileMenu() },
  ],
  "admin": [
    { id: "collapse", title: "", icon: "collapse", class: "d-none d-lg-table", function: () => hideSidePanel() },
    { id: "dashboard", title: "Dashboard", icon: "dashboard", link: "dashboard", function: () => '' },
    { id: "logout", title: "Logout", icon: "logout", link: "/login", class: "d-none d-md-table", function: () => '' },
  ],  
  "guest": [
    { id: "collapse", title: "", icon: "collapse", class: "d-none d-lg-table", function: () => hideSidePanel() },
    { id: "Home",    title: "Home", icon: "dashboard", link: "/home", function: () => '' },
    { id: "Find tutor",    title: "Find tutor", icon: "tutors", link: "/find-tutor", function: () => '' },
    { id: "blog", title: "Blog", icon: "blog", class: "d-none d-md-table", function: () =>  openBlog() },
    // { id: "Create an account", title: "Register", icon: "logout", link: "/register-student", class: "d-none d-md-table tr-logout", function: () => '' },
    { id: "Login", title: "Login", icon: "logout", link: "/login", class: "d-none d-md-table tr-logout", function: () => '' },
  ],
  "tutor": [
    { id: "collapse", title: "", icon: "collapse", class: "d-none d-lg-table", function: () => hideSidePanel() },
    { id: "tutordashboard", title: "Dashboard", icon: "dashboard", link: "dashboard", function: () => '' },   
    { id: "Lessons", title: "Lessons", icon: "lessons", link: "lessons", function: () => '' },
    { id: "Students", title: "Students", icon: "students", link: "students", function: () => '' },
    { id: "chat", title: "Chat", icon: "chat", link: "chat", class: "d-none d-md-table", function: () => '' },
    { id: "tutoringsettings", title: "Settings", icon: "tutoring-settings", class:"d-none d-md-table", link: "settings", function: () => '' },
    { id: "mywallet", title: "My wallet", icon: "wallet", class:"d-none d-md-table", link: "wallet", function: () => '' },
    { id: "Blog", title: "Blog", icon: "blog",class:"d-none d-md-table", function: () =>  openBlog() },
    { id: "logout", title: "Logout", icon: "logout", link: "/logout", class: "d-none d-md-table tr-logout", function: () => '' },
    { id: "more", title: "More", icon: "more",  class: "d-md-none", function: () => openMobileMenu() },
  ],

  "studentMobile": [
    { id: "Lessons", title: "Lessons", icon: "lessons", link: "lessons", function: () => '' },
    { id: "mywallet", title: "My Wallet", icon: "wallet", link: "wallet", class: "d-none d-lg-table", function: () => '' },
    { id: "chat", title: "Chat", icon: "chat", link: "chat", class: "d-none d-md-table", function: () => '' },
    { id: "blog", title: "Blog", icon: "blog", link: "blog", class: "d-none d-md-table", function: () =>  openBlog() },
    { id: "Settings", title: "Settings", icon: "settings", link: "settings", class: "d-none d-md-table", function: () => '' },
    { id: "help", title: "Help", icon: "support", link: "/help", class: "d-none d-md-table", function: () => '' },
    { id: "logout", title: "Logout", icon: "logout", link: "/logout", class: "d-none d-md-table tr-logout", function: () => '' },
  ], 

   "tutorMobile": [
    { id: "chat", title: "Chat", icon: "chat", link: "chat", class: "d-none d-md-table", function: () => '' },
    { id: "tutoringsettings", title: "Settings", icon: "tutoring-settings", class:"d-none d-md-table", link: "settings", function: () => '' },
    { id: "mywallet", title: "My Wallet", icon: "wallet", class:"d-none d-md-table", link: "wallet", function: () => '' },
    { id: "Blog", title: "Blog", icon: "blog", link: "blog", class:"d-none d-md-table", function: () =>  openBlog() },
    { id: "logout", title: "Logout", icon: "logout", link: "/logout", class: "d-none d-md-table tr-logout", function: () => '' },
  ],   

  "organisation": [
    { id: "collapse", title: "", icon: "collapse", class: "d-none d-lg-table", function: () => hideSidePanel() },
    { id: "dashboard", title: "Dashboard", icon: "dashboard", link: "dashboard", function: () => '' },
    { id: "account", title: "Account", icon: "user-settings-line", link: "account", function: () => '' },
    { id: "teachers", title: "Teachers", icon: "teachers-line", link: "teachers", function: () => '' },
    { id: "students", title: "Students", icon: "students-line", link: "students", function: () => '' },
    { id: "classrooms", title: "Classrooms", icon: "classrooms", link: "classrooms", function: () => '' },
    { id: "revenue", title: "Revenue", icon: "revenue", link: "revenue", function: () => '' },
    { id: "help", title: "Help", icon: "support", class: "d-none d-md-table", function: () => openHelp()  },
    { id: "logout", title: "Logout", icon: "logout", link: "/logout", class: "d-none d-md-table tr-logout", function: () => '' },
    
  ],

}

export const studentMobileMenuItems: any = sidenavItems['studentMobile'];
export const tutorMobileMenuItems: any = sidenavItems['tutorMobile'];

function hideSidePanel() {
  let RouterId = document.getElementById("tr-main-outlet-div");
  let SideNavId = document.getElementById("tr-side-nav");

  if (RouterId && SideNavId) {
    RouterId.classList.toggle("tr-hide-side-panel");
    SideNavId.classList.toggle("show");
  }
}

function openMobileMenu() {
    const modalElement = document.getElementById('mobileMenuModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
}

function openBlog() {
   const externalUrl = 'https://www.tutorreach.com/news';
    window.open(externalUrl, '_blank');
}

function openHelp() {
   const externalUrl = 'https://support.tutorreach.com';
    window.open(externalUrl, '_blank');
}