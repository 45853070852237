import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'tr-scheduler-next-edit-modal',
  templateUrl: './scheduler-next-edit-modal.component.html',
  styleUrls: ['./scheduler-next-edit-modal.component.css'],
})
export class SchedulerNextEditModalComponent implements OnInit {
  @Input() slot: any;
  @Output() toggleRepeated = new EventEmitter<{
    id: string;
    isRepeated: boolean;
  }>();
  @Output() removeSlot = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  title: string = '';
  subTitle: string = '';

  constructor() {}
  ngOnInit(): void {
    this.title = moment(this.slot.start).format('dddd, D MMMM');
    this.subTitle = `${moment(this.slot.start).format('h:mm')} - ${moment(
      this.slot.start
    )
      .add(30, 'minutes')
      .format('h:mm A')}`;
  }

  onToggleRepetition(event: any): void {
    this.toggleRepeated.emit(this.slot);
  }

  onRemoveSlot(): void {
    this.removeSlot.emit(this.slot);
    this.onClose();
  }

  onClose(): void {
    this.close.emit();
  }
}
