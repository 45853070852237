<section class="tr-padding-2 tr-bg-highlight" id="getOffset">
  <div class="row px-0 pt-0 m-0 gap-3 gap-sm-0 d-flex align-items-center pb-4" id="tr-slots-section">
    <div class="d-sm-flex d-none align-items-center gap-3 col-sm-6 p-0 ">
      <button class="d-flex align-items-center gap-2 border-0 bg-transparent p-0" (click)="this.selectedTimeSlot = 'morning'">
        <input type="radio" name="timeslots" value="morning" [(ngModel)]="selectedTimeSlot"  [checked]="selectedTimeSlot === 'morning'">
        <label class="tr-font-12 tr-dark-text-md cursor-pointer"> Morning slots</label>
      </button>
      <button class="d-flex align-items-center gap-2 border-0 bg-transparent p-0" (click)="this.selectedTimeSlot = 'afternoon'">
        <input type="radio" name="timeslots" value="afternoon" [(ngModel)]="selectedTimeSlot"  [checked]="selectedTimeSlot === 'afternoon'">
        <label class="tr-font-12 tr-dark-text-md cursor-pointer"> Afternoon slots</label>
      </button>
      <button class="d-flex align-items-center gap-2 border-0 bg-transparent p-0" (click)="this.selectedTimeSlot = 'evening'">
        <input type="radio" name="timeslots" value="evening" [(ngModel)]="selectedTimeSlot"  [checked]="selectedTimeSlot === 'evening'">
        <label class="tr-font-12 tr-dark-text-md cursor-pointer"> Evening slots</label>
      </button>
      <button class="d-flex align-items-center gap-2 border-0 bg-transparent p-0" (click)="this.selectedTimeSlot = 'all'">
        <input type="radio" name="timeslots" value="all" [(ngModel)]="selectedTimeSlot"  [checked]="selectedTimeSlot === 'all'">
        <label class="tr-font-12 tr-dark-text-md cursor-pointer"> All </label>
      </button>
    </div>
    <div class="col-12 d-sm-none p-o">
      <label for="timeSlot" class="mb-1 tr-font-12 tr-dark-text-md">Select a time slot:</label>
      <select class="form-select form-control tr-font-12 tr-dark-text-md" aria-label="Default select example" [(ngModel)]="selectedTimeSlot">
        <option selected value="all">All</option>
        <option value="morning">Morning</option>
        <option value="afternoon">Afternoon</option>
        <option value="evening">Evening</option>
      </select>
    </div>
    <section class="tr-scheduler-display-filter-legends d-flex align-items-center justify-content-sm-end gap-2 gap-sm-3 flex-wrap col-sm-6 p-0">
      <div class="tr-scheduler-filter-btn align-items-center">
        <span class="tr-sch-available-legend"></span>
        <label>Available</label>
      </div>
      <div class="tr-scheduler-filter-btn align-items-center">
        <span class="tr-sch-booked-legend"></span>
        <label>Booked</label>
      </div>
      <div class="tr-scheduler-filter-btn align-items-center">
        <span class="tr-sch-default-legend"></span>
        <label>Not Available</label>
      </div>
      <div class="tr-scheduler-filter-btn align-items-center">
        <span class="tr-sch-selected-legend tr-selected-legend"></span>
        <label>Selected</label>
      </div>
    </section>
  </div>
  <div class="scheduler-next-wrapper position-relative" [attr.data-view-type]="viewType">
    <div class="scheduler-next position-relative" id="schedulerNext">
      <ng-template #customHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked" let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
        <div class="cal-day-headers border-0 position-relative" role="row" id="tr-fixed-header-row">
          <div class="cal-header d-flex flex-column border-0 position-relative" *ngFor="let day of days; let isFirst = first;  let isLast = last; trackBy: trackByWeekDayHeaderDate" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass" (mwlClick)="
              dayHeaderClicked.emit({ day: day, sourceEvent: $event })
            " tabindex="0" role="columnheader">
            <span class="tr-day">{{ day.date
                | calendarDate : "weekViewColumnHeader" : locale
                | slice : 0 : 3
            }}</span>
            <span class="tr-date" *ngIf="!isSubscription">{{ day.date | calendarDate : "weekViewColumnSubHeader" : locale
              }}
            </span>
            <button (click)="prevWeek()" *ngIf="isFirst" class="border-0 position-absolute start-0 tr-arrow-btn d-flex align-items-center justify-content-center">
              <img src="./assets/icons/arrow-left.svg" alt="" />
            </button>
            <button (click)="nextWeek()" *ngIf="isLast" class="border-0 position-absolute end-0 tr-arrow-btn d-flex align-items-center justify-content-center">
              <img src="/assets/icons/arrow-right.svg" alt="" />
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template #customHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel">
        <div class="cal-hour-segment border-0" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart" [class.slot-loading]="isLoading" [class.cal-after-hour-start]="!segment.isStart" [attr.data-slot-id]="!isTimeLabel && isAvailableSlot(segment).slotId" [ngClass]="{
            'available-slot':
              !isTimeLabel &&
              viewType !== 'editAvailabilty' &&
              isAvailableSlot(segment).available
          }" *ngIf="isMorningAfternoonEveningSlot(segment)">
          <div class="cal-time border-0" *ngIf="isTimeLabel">
            {{ (segment.displayDate.getHours() === 0
                ? "00"
                : segment.displayDate.getHours()) +
                ":" +
                (segment.displayDate.getMinutes() === 0
                  ? "00"
                  : segment.displayDate.getMinutes() === 30
                  ? "30"
                  : segment.displayDate.getMinutes())
            }}
          </div>
        </div>
      </ng-template>
      <ng-template #customEventTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement" let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column" let-daysInWeek="daysInWeek" let-locale="locale">
        <div class="cal-event" [ngStyle]="{
            color: weekEvent.event.color?.secondaryText,
            backgroundColor: weekEvent.event.color?.secondary,
            borderColor: weekEvent.event.color?.primary
          }" [mwlCalendarTooltip]="
            !tooltipDisabled
              ? (weekEvent.event.title
                | calendarEventTitle
                  : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
                  : weekEvent.tempEvent || weekEvent.event)
              : ''
          " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.tempEvent || weekEvent.event" [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" (mwlClick)="eventClicked.emit({ sourceEvent: $event })" (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })" tabindex="0" role="application" [attr.data-slot-type]="weekEvent.event.slotType" [attr.data-slot-classes]="weekEvent.event.cssClass" [attr.data-slot-repeated]="weekEvent.event.isRepeated" [attr.aria-label]="
            { event: weekEvent.tempEvent || weekEvent.event, locale: locale }
              | calendarA11y : 'eventDescription'
          ">
          <mwl-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event"></mwl-calendar-event-actions> &ngsp; <mwl-calendar-event-title [event]="weekEvent.tempEvent || weekEvent.event" [view]="daysInWeek === 1 ? 'day' : 'week'"></mwl-calendar-event-title>
        </div>
      </ng-template>
      <mwl-calendar-week-view [viewDate]="viewDate" [daysInWeek]="daysInWeek" [events]="events" [headerTemplate]="customHeaderTemplate" [hourSegmentTemplate]="customHourSegmentTemplate" [eventTemplate]="customEventTemplate" (eventClicked)="handleEventClick($event)" (hourSegmentClicked)="handleHourSegmentClick($event)"></mwl-calendar-week-view>
      <tr-scheduler-next-edit-modal *ngIf="editModalOpen" [slot]="editModalSlot" (close)="closeEditModal()" (removeSlot)="removeSlot($event)" (toggleRepeated)="toggleRepeated($event)"></tr-scheduler-next-edit-modal>
      <tr-scheduler-next-extend-prompt *ngIf="promtModalOpen" [promptData]="nextFollowingSlotsExtensions$.getValue()" (isExtendCancelled)="keepCurrentSelection($event)" (extendPeriodValue)="extendLessonSlots($event)"></tr-scheduler-next-extend-prompt>
    </div>
  </div>
</section>
<div class="d-flex  mt-4 gap-3" *ngIf="viewType === 'editAvailabilty'">
  <button class="tr-btn-primary tr-btn" (click)="updateAvailability()" [disabled]="isLoading">
    <span *ngIf="!isLoading">Save</span>
    <tr-spinner *ngIf="isLoading"></tr-spinner>
  </button>
  <button class="tr-btn-outline-primary tr-btn" (click)="discardAvailability()" data-bs-toggle="collapse" data-bs-target="#openSchedulerSection" aria-expanded="false" aria-controls="openSchedulerSection"> Discard</button>
</div>