import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ForgotPasswordService } from './forgot-password.service';
import { ToastService } from '@components/shared-components/toast.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'tr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  form!: UntypedFormGroup;
  isLoading:boolean = false;
  email:any;
  isPasswordSent:boolean = false;
  constructor(private service:ForgotPasswordService,private toastService: ToastService) { }

  ngOnInit(): void {
     this.initForm();
  }


  private initForm() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  resetPassword(){
    this.isLoading = true;
    this.service.sendPasswordResetEmail(this.form.value)
    .pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        this.isPasswordSent = true;
        this.toastService.showSuccessToast('Success', res['message'])
      },
      (error: any) => {
        this.handleLoginFailure(error)
      }
    )

  }

  handleLoginFailure(error:any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422 || error.status === 404 || error.status === 500) {
        this.toastService.showErrorToast('Error!', error['error'].message);
      }
    } else {
      this.toastService.showErrorToast('Error!', error.message);
    }
  }





}
