<div class="btn-group">
  <button class="btn btn-secondary dropdown-toggle tr-filter-bottom-select me-3 my-xxl-3 my-2" type="button" id="custom_dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
    {{ title }}
  </button>
  <ul class="dropdown-menu tr-country-menu p-4" aria-labelledby="custom_dropdown">
    <div>
      <div class="mb-4">
        <p class="mb-3 tr-dropdown-title">Choose Day(s)</p>
        <div class="tr-dropdown-countries-list d-flex align-items-center justify-content-start flex-wrap">
          <span class="p-2">Mon</span>
          <span class="p-2">Tue</span>
          <span class="p-2">Wed</span>
          <span class="p-2">Thurs</span>
          <span class="p-2">Fri</span>
          <span class="p-2">Sat</span>
        </div>
      </div>
      <div class="tr-dropdown-countries">
        <p class="mb-3 tr-dropdown-title">Time of the Day</p>
        <div class="tr-countries-drop-list d-flex align-items-center">
          <div class="tr-times me-2">
            <div class="mb-2 d-flex align-items-center justify-content-center">
              <span class="mb-1 me-2">
                <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path [attr.d]="dayTimeIcon"></path>
                </svg>
              </span> Morning
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2">
                <div class="form-group">
                  <input type="checkbox" id="6-9" value="6-9">
                  <label for="6-9" class="p-2">06:00 - 09:00</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="form-group">
                  <input type="checkbox" id="9-12" value="9-12">
                  <label for="9-12" class="p-2">09:00 - 12:00</label>
                </div>
              </div>
            </div>
          </div>          
          <div class="tr-times">
            <div class="mb-2 d-flex align-items-center justify-content-center">
              <span class="me-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path [attr.d]="dayTimeIcon"></path>
                </svg>
              </span> 	
              Afternoon
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2">
                <div class="form-group">
                  <input type="checkbox" id="12-15" value="12-15">
                  <label for="12-15" class="p-2">12:00 -15:00</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="form-group">
                  <input type="checkbox" id="15-18" value="15-18">
                  <label for="15-18" class="p-2">15:00 - 18:00</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div class="tr-dropdown-countries mt-3">
        <div class="tr-countries-drop-list d-flex align-items-center">
          <div class="tr-times me-2">
            <div class="mb-2 d-flex align-items-center justify-content-center">
              <span class="me-2">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path [attr.d]="eveningIcon"></path>
                </svg>
              </span> Evening
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2">
                <div class="form-group">
                  <input type="checkbox" id="18-21" value="18-21">
                  <label for="18-21" class="p-2">18:00 - 21:00</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="form-group">
                  <input type="checkbox" id="21-24" value="21-24">
                  <label for="21-24" class="p-2">21:00 - 24:00</label>
                </div>
              </div>
            </div>
          </div>          
          <div class="tr-times">
            <div class="mb-2 d-flex align-items-center justify-content-center">
              <span class="me-2">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<mask id="mask0_2419_4012" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
				<rect width="16" height="16" fill="#D9D9D9"/>
				</mask>
				<g mask="url(#mask0_2419_4012)">
				<path [attr.d]="midNightIcon" fill="#888888"/>
				<path d="M13 5H10V4.35599L11.7599 1.83363H10.1053V1H12.9388V1.70841L11.2265 4.16637H13V5Z" fill="#888888"/>
				<path d="M15 6H13V5.51699L14.1733 3.62522H13.0702V3H14.9592V3.53131L13.8177 5.37478H15V6Z" fill="#888888"/>
				</g>
				</svg>
              </span> 	
              Mid-night
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2">
                <div class="form-group">
                  <input type="checkbox" id="0-3" value="0-3">
                  <label for="0-3" class="p-2">00:00 - 03:00</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="form-group">
                  <input type="checkbox" id="3-6" value="3-6">
                  <label for="3-6" class="p-2">03:00 - 06:00</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ul>
</div>