<section class="tr-mh-100 d-flex align-items-center justify-content-center">
  <div class="tr-login-component container-fluid d-flex justify-content-center">
    <div class="tr-login-wrapper col-md-8 col-lg-6">
      <div class="row top30 p-0 m-0">
        <section class="px-0 m-0"  [formGroup]="form" *ngIf="!isPasswordSent">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <h4 class="tr-title mb-0">Forgot your password?</h4>
            <p class="tr-sub-title tr-dark-text-md  mt-4 tr-font-16 text-center">Please enter your email address below so we can send you a link to reset your password.</p>
          </div>
          <div class="row tr-forgot-password-input d-flex flex-column gap-2 justify-content-center px-0 pt-0 tr-pb-2 m-0">
            <div class="col-md-11 px-0 col-lg-12 col-11">
                <label class="col-md-2 col-lg-2 px-0 d-flex justify-content-start" >Email</label>
            </div>

            <div class="col-md-11 px-0 col-lg-12 d-flex justify-content-start col-11">
                <input class="col-md-12 col-lg-12 px-0 col-12 ps-3" [(ngModel)]="email" placeholder="Enter your Email" type="email" formControlName="email"/>
            </div>
         </div>  
          <div class=" d-flex">
            <button class="tr-btn-primary tr-btn w-100" [disabled]="form.invalid" (click)="resetPassword()"> 
                <span *ngIf="!isLoading">Submit </span>
                <tr-spinner *ngIf="isLoading"></tr-spinner>
           </button>
          </div>
          <br>
          <p class="mb-2 tr-bold-md">
           To go back to login please <a routerLink="/login" class="tr-bold-md cursor-pointer">click here</a>.
          </p>
        </section>
        <section class="tr-padding-2" *ngIf="isPasswordSent">
          <div class="d-flex flex-column align-items-center justify-content-center">
           <h4 class="tr-title mb-0">Please check your email</h4>
           <p class="tr-sub-title tr-dark-text-md  mt-4 tr-font-16 text-center">We sent an email to {{email}} with reset instructions.</p>
          </div>
          <div class="tr-pt-2"> 
            <div class="d-flex align-items-center flex-column justify-content-center">  
              <img src="assets/images/mail-check.svg" alt="">
              <div class="d-flex flex-column align-items-center justify-content-center mt-4">
                <p class="mb-2 tr-bold-md">Didn't receive the email?</p>
                <span class="text-center tr-bold-sm w-75">Please check your email's spam or junk folder
                 If this does not work, try re-sending your request. <a  (click)="resetPassword()" class="tr-bold-md cursor-pointer">Re-send Request</a>.
                 <br>
                 To login please <a routerLink="/login" class="tr-bold-md cursor-pointer">click here</a>.
               </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>