import { Component, OnInit } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';

@Component({
  selector: 'tr-tutoring-settings',
  templateUrl: './tutoring-settings.component.html',
  styleUrls: ['./tutoring-settings.component.css']
})
export class TutoringSettingsComponent implements OnInit {
  sideNavItemNames = sideNavItemNames;

  constructor() { }

  ngOnInit(): void {
  }

}
