import { Component, OnInit } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';

@Component({
  selector: 'tr-student-wallet',
  templateUrl: './student-wallet.component.html',
  styleUrls: ['./student-wallet.component.css']
})
export class StudentWalletComponent implements OnInit {
  sideNavItemNames = sideNavItemNames;

  constructor() { }

  ngOnInit(): void {
  }

}
