import { Injectable } from '@angular/core';
import { TrHttpService } from './tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TrPaymentsService {

  constructor(private trHttp: TrHttpService) { }
  fetch(endPoint: any, params: any = {}, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getPaymentGateways() {
    return this.fetch('payment/gateways')
  }

  getSavedPaymentMethods() {
    return this.fetch('payment/methods')
  }  

  deleteCard(id:any) {
    return this.fetch('payment/methods/'+id+'/delete','', HttpMethod.POST);
  }  

  setDefaultCard(id:any) {
    return this.fetch('payment/methods/'+id+'/default','', HttpMethod.POST);
  }

  getPaymentFees(formData: any) {
    return this.fetch('payment/booking/calculate', formData, HttpMethod.POST);
  }

  createStripePaymentIntent(formData: any): any {
    return this.fetch('payment/booking/create', formData, HttpMethod.POST);
  }

  payFromSavedCard(formData: any) {
    return this.fetch('payment/booking/init', formData, HttpMethod.POST);
  }

  payFromWallet(formData: any) {
    return this.fetch('payment/booking/init', formData, HttpMethod.POST);
  }

  payFromNewCard(formData: any) {
    return this.fetch('payment/booking/init', formData, HttpMethod.POST);
  }

  addPaymentMethod(formData: any) {
    return this.fetch('payment/setup', formData, HttpMethod.POST);
  }

  topUpWalletCheckOut(formData: any) {
    // - amount
    // - gatewayId
    return this.fetch('payment/deposit/create', formData, HttpMethod.POST);
  }

  topUpWalletFromSavedCard(formData: any) {
    // - amount
    // - gatewayId
    // - paymentMethodId
    return this.fetch('payment/deposit/charge', formData, HttpMethod.POST);
  }
  getBuyingCreditPaymentFee(formData: any) {
    return this.fetch('payment/fees', formData, HttpMethod.GET);

  }
}

// POST api/payment/setup
// - gatewayId
// it will create a setupIntent and return client_secret 
// https://stripe.com/docs/js/setup_intents/confirm_setup


// api/payment/booking/pay
// - tutorId
// - subjectId
// - specialistId
// - ageId
// - type
// - lessons
// -----
// this is to be used if the payment if done fully by wallet balance 
// only use this one if the finalPrice is 0

// Get all subscriptions:
// {apiBaseUrl}/payment/subscriptions

// Get a specific subscription by ID:
// {apiBaseUrl}/payment/subscriptions/{id}

// Cancel a subscription by ID:
// {apiBaseUrl}/payment/subscriptions/{id}/cancel



// Get enabled payment gateways:
// GET {apiBaseUrl}/payment/gateways

// Calculate the price for a booking:
// POST {apiBaseUrl}/payment/booking/calculate
// - tutorId
// - subjectId
// - specialistId
// - ageId
// - type
// - lessons
// - gatewayId: optional if you want the fees to be included

// Create a booking payment if there are no saved payment methods:
// POST {apiBaseUrl}/payment/booking/create
// - tutorId
// - subjectId
// - specialistId
// - ageId
// - type
// - lessons
// - gatewayId
// - savePaymentMethod: optional

// Charge a booking payment if there are saved payment methods and you can provide paymentMethodId:
// POST {apiBaseUrl}/payment/booking/charge
// - tutorId
// - subjectId
// - specialistId
// - ageId
// - type
// - lessons
// - gatewayId
// - paymentMethodId

// Calculate payment fees for buying a credit:
// POST {apiBaseUrl}/payment/fees
// - amount
// - gatewayId
// - currency: always send GBP

// Create a deposit payment if there are no saved payment methods:
// POST {apiBaseUrl}/payment/deposit/create
// - amount
// - gatewayId

// Charge a deposit payment if there are saved payment methods and you can provide paymentMethodId:
// POST {apiBaseUrl}/payment/deposit/charge
// - amount
// - gatewayId
// - paymentMethodId