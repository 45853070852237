<section class="tr-bg-highlight tr-nav-div mb-3 tr-pt-10" [ngClass]="{'sticky': isSticky}">
	<ul class="d-flex py-0 pe-0 m-0">
		<li  [class.active]="activeSectionId === 'tr-about-sec'" (click)="goToSection('#tr-about-sec')">About</li>
		<li  [class.active]="activeSectionId === 'tr-availability-sec'" (click)="goToSection('#tr-availability-sec')">Availability</li>
		<li  [class.active]="activeSectionId === 'tr-review-sec'" (click)="goToSection('#tr-review-sec')">Reviews</li>
		<li  [class.active]="activeSectionId === 'tr-resume-sec'" (click)="goToSection('#tr-resume-sec')">Resume</li>
	</ul>
</section>

<section class="d-flex flex-column gap-3">
	<div class="tr-target" id="tr-about-sec">
		<div class="tr-padding-2 tr-bg-highlight">
			<h3 class="tr-sub-title-dark-text tr-font-18 mb-3 p-0"> About </h3>
			<span class="tr-tutor-desc" [innerHTML]="removePTags(tutor?.about)"> </span>
		</div>
	</div>	

	<div class="tr-target" id="tr-availability-sec" *ngIf="tutor">
		  <tr-scheduler-next viewType="viewOnly"  tutorId="{{ tutor.id }}" ></tr-scheduler-next>
	</div>

	<div class="tr-target" id="tr-review-sec">
		<tr-tutor-profile-reviews tutorId="{{ tutor.id }}"></tr-tutor-profile-reviews>
	</div>

	<div  class="tr-target" id="tr-resume-sec">
		<tr-tutor-profile-resume [educations]="tutor?.educations" [experiences]="tutor?.workExperiences" [certificates]="tutor?.certificates"></tr-tutor-profile-resume>
	</div>
</section>
