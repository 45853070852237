  <div class="d-flex align-items-center mb-4">
    <a class="tr-back d-flex align-items-center gap-2 cursor-pointer" (click)="goBackToPreviousRoute()">
      <img src="assets/icons/back.svg" alt="back-icon"> Back </a>
    <p class="mb-0 tr-title">Lesson Details </p>
  </div>
  <div class="tr-bg-highlight" *ngIf="lessonData">
    <section class="tr-px-2 tr-pb-2 tr-bdr-0">
      <section class="pt-4">
        <div class="mb-3">
          <h3 class="tr-bold-title m-0">{{lessonData.subjectName}}</h3>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-start gap-4">
            <p class="mb-0 tr-sub-title d-flex align-items-center gap-2">
              <img src="assets/icons/cam-video.svg" alt="cam-video-img"> Tutorreach classroom
            </p>
            <p class="mb-0 d-flex align-items-center gap-2 tr-count">
              <img src="assets/icons/people.svg" alt="people"> 2
            </p>
          </div>
          <div class="tr-lesson-time d-flex align-items-center gap-4" >
            <p class="mb-0" *ngIf="timeRemaining != 'started'">
             <ng-container *ngIf="(timeRemaining !== 'finished') && (timeRemaining !== 'completed')"> after</ng-container>
              <span class="text-green">
               {{ this.timeRemaining}}
            </span>
            </p>
            <button class="tr-attend-btn py-2 px-3" [disabled]="buttonDisabled" (click)="attend()">Attend </button>
              <section class="d-flex align-items-center justify-content-between" *ngIf="lessonData?.premissions.length > 0">
              <div class="tr-svg-svg" type="button" dropdown-toggle data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path [attr.d]="optionsIcon" />
                </svg>
              <ul class="dropdown-menu tr-dropdown-menu p-3" aria-labelledby="custom_dropdown">
                <div class="d-flex flex-column">
                  <ng-container *ngFor="let permission of lessonData?.premissions ">
                    <div class="tr-dropdown-options" (click)="getLessonAction(permission,lessonData)">
                      <li class="d-flex align-items-center p-2 gap-2" class="{{permission}}">
                              <ng-container *ngIf="(permission == LESSON_PERMISSIONS.CANCEL || permission == LESSON_PERMISSIONS.CANCEL_REQ)">
                              <tr-svg [path]="cancelPath" [width]="10" [height]="10" [viewBox]="'0 0 10 10'"></tr-svg>
                              </ng-container>                      
                              <ng-container *ngIf="permission == LESSON_PERMISSIONS.RESCHEDULE || permission == LESSON_PERMISSIONS.REQ_RESCHEDULE">
                               <tr-svg [path]="eventRepeatIcon" [width]="15" [height]="15" [viewBox]="'0 0 14 15'"></tr-svg>
                              </ng-container>
                            <span class="tr-capitalize-first-letter">
                             {{ permission.replace('_', ' ')}}
                            </span>
                        </li>
                    </div>
                  </ng-container>
                </div>
             </ul>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section class="tr-grey-bg mt-3 position-relative">
        <span (click)="this.showInput = !this.showInput" *ngIf="currentRole == 'tutor'">
          <img src="assets/icons/edit-underline.svg" alt="" class="position-absolute tr-end"></span>
        <div class="tr-introduction-sec">
            <h4 class="mb-2">Introduction and lesson overview</h4>
            <p class="mb-0 w-90" *ngIf="lessonData.description && !showInput">{{ lessonData.description }}</p>         
            <p class="mb-0 w-90" *ngIf="!lessonData.description && !showInput">No description Found.</p></div>
        <div *ngIf="showInput" class="bg-white">
          <textarea class="form-control tr-input" [(ngModel)]="lessonDescription" placeholder="Write lesson description here ...."></textarea>
          <button class="tr-save-btn tr-btn px-4 py-2 mt-2" (click)="addDescription()" [disabled]="!lessonDescription">Save</button>
        </div>
      </section>
      <section class="mt-4">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-start  gap-5 flex-grow-1">
            <div class="tr-tutor-details d-flex align-items-center gap-2">
              <img [src]="lessonData.userLogo" alt="" *ngIf="lessonData.userLogo">
              <img src="assets/images/profile-placeholder.png" alt="" *ngIf="!lessonData.userLogo">
              <div>
                <h2 class="tr-tutr-name mb-0">{{ lessonData.userName}}</h2>
               <!--  <span>Tutor’s title</span> -->
              </div>
            </div>
            <div class="tr-lesson-options d-flex flex-column">
              <span>Lesson time</span>
              <p class="mb-0">{{ lessonData.lessontime | getLessonDate }}, {{ lessonData.lessonPeriod }} min</p>
            </div>
<!--             <div class="tr-lesson-options d-flex flex-column">
              <span>Payment method</span>
              <p class="mb-0">PayPal</p>
            </div> -->
<!--             <div class="tr-lesson-options d-flex flex-column">
              <span>Passcode</span>
              <p class="mb-0">3456</p>
            </div> -->
          </div>
          <div class="d-flex justify-content-end flex-grow-1 gap-2" *ngIf="currentRole == 'student'">
            <span class="tr-lesson-flag p-2"  type="button" data-bs-toggle="modal" data-bs-target="#addReviewModal"> Add Review
              <img src="assets/icons/rate.svg" alt="complaint-flag" class="ms-2">
            </span>            
            <span class="tr-lesson-flag p-2 cursor-pointer" (click)="raiseComplaint()">Raise complaint <img src="assets/icons/complaint-flag.svg" alt="complaint-flag" class="ms-2">
            </span>
          </div>
        </div>
      </section>
    </section>
    <div>
      <section class="tr-padding-2 tr-bd-t" *ngIf="lessonData.attachments && lessonData.attachments.length > 0">
        <h3 class="tr-bold-title m-0 mb-4 d-flex align-items-center gap-2">Attachment <span class="tr-num">(3)</span>
        </h3>
        <section class="d-flex align-items-center gap-3 flex-wrap">
          <div class="d-flex align-items-center justify-content-between p-2 m-0 tr-bd gap-6 flex-grow-1">
            <div class="tr-lesson-recording d-flex align-items-center gap-2">
              <img src="assets/images/lesson-details.svg" alt="">
              <div class="tr-attachment-details d-flex flex-column gap-2">
                <span>Arabic recording.mp4</span>
                <p class="mb-0">Thu 06 Mar</p>
              </div>
            </div>
            <div class="d-flex gap-2 pe-4">
              <span class="tr-attch-size">16 Mb</span>
              <img src="assets/icons/download-icon.svg" alt="download-icon">
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between p-2 m-0 tr-bd gap-6 flex-grow-1">
            <div class="tr-lesson-recording d-flex align-items-center gap-2">
              <img src="assets/images/pdf-attachment.svg" alt="">
              <div class="tr-attachment-details d-flex flex-column gap-2">
                <span>Arabic_lesson.pptx</span>
                <p class="mb-0">Thu 06 Mar</p>
              </div>
            </div>
            <div class="d-flex gap-2 pe-4">
              <span class="tr-attch-size">45 Mb</span>
              <img src="assets/icons/download-icon.svg" alt="download-icon">
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between p-2 m-0 tr-bd gap-6 flex-grow-1">
            <div class="tr-lesson-recording d-flex align-items-center gap-2">
              <img src="assets/images/pdf-attachment.svg" alt="">
              <div class="tr-attachment-details d-flex flex-column gap-2">
                <span>Arabic_lesson.pptx</span>
                <p class="mb-0">Thu 06 Mar</p>
              </div>
            </div>
            <div class="d-flex gap-2 pe-4">
              <span class="tr-attch-size">34 KB</span>
              <img src="assets/icons/download-icon.svg" alt="download-icon">
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
<tr-raise-complaint-popup ></tr-raise-complaint-popup>
<tr-submit-review-popup (reviewData)="submitReview($event)" [modalStatus]="closeReviewModal"></tr-submit-review-popup>
