import { Injectable } from '@angular/core';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod, TRObject } from '../../shared/models/shared';
import { map, skip, takeUntil } from 'rxjs/operators';
import { RRule } from 'rrule';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { CalendarEvent } from 'angular-calendar';
import { viewType } from './scheduler-next.component';
import { uniqBy } from 'lodash';
import { getDateAndTime, getDayName, reshapeSlot, Slot, getRecurringScheduleSlots, SLOT_STATE, isWithinFirstWeek, getSchedulerDays } from '@shared/models/scheduler';
// export interface CalendarEvent<MetaType = any> {
//   id?: string | number;
//   start: Date;
//   end?: Date;
//   slotType: string;
//   color?: EventColor;
//   actions?: EventAction[];
//   allDay?: boolean;
//   cssClass?: string;
//   resizable?: {
//       beforeStart?: boolean;
//       afterEnd?: boolean;
//   };
//   draggable?: boolean;
//   meta?: MetaType;
// }

@Injectable({
  providedIn: 'root',
})


export class SchedulerNextService extends TRObject {
  selectedSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  removeSlots$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  deletedSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lessonTimeAndPeriod$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  resetSelectedSlots$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private trHttp: TrHttpService) {
    super();
  }

  getAvailability(
    tutorId: string,
    type: viewType = 'viewOnly',
    isSubscription: boolean = false,
    timezoneId?:any,
  ) {
    const requestUri =
      type === 'editAvailabilty'
        ? 'user-availability'
        : 'user-availability-student';
    const formData = new FormData();
    if(timezoneId){
      formData.append('timezoneId', timezoneId);
    }
    formData.append('tutorId', tutorId);

    return this.fetch(requestUri, formData, HttpMethod.GET).pipe(
      map((res) => this.processAvailability(res, type, isSubscription))
    );
  }

  getLessonData(lessonId: string) {
    return this.fetch(`get-lesson-slots?lessonId=${lessonId}`).pipe(
      map((res) => {
        if (!res.data?.getLessonSlots) {
          return null;
        }

        const lesson = res.data.getLessonSlots;

        return {
          id: lesson.lessonId,
          period: parseInt(lesson.period),
          dateTime: lesson.dateTimeUtc,
          slots: lesson.slots.map((slot: any) => ({
            dateTime: slot.dateTimeUtc,
            recordId: slot.tutorAvailabilityId,
          })),
        };
      })
    );
  }

  getBookingData(bookingId: string) {
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          id: '1',
          lessons: [
            {
              id: '1',
              period: 60,
              dateTime: '2023-09-01 06:30',
              slots: [
                {
                  dateTime: '2023-08-25 06:30',
                  isRepeated: true,
                  recordId: 187,
                  state: 'available',
                },
                {
                  dateTime: '2023-08-25 07:00',
                  isRepeated: true,
                  recordId: 188,
                  state: 'available',
                },
              ],
            },
          ],
        },
      });
    });
  }

  processAvailability = (res: any, type: viewType, isSubscription: boolean) => {
    if (!res.status || !res.data.slots) {
      return [];
    }

    let availableSlots: any[] = [];
    let bookedSlots: any[] = [];
    let timeOffSlots: any[] = [];
    let events: any[] = [];

    const { availability, timeOff } = res.data.slots;

    availability.forEach((slot: any) => {
      if (slot.isRepeated) {
        const futureRecurrences = this.getFutureRecurrences(
          slot.dateTime,
          52
        ).map((date) => {
          return this.mapSlotData(slot, date);
        });

        if (slot.state === 'booked') {
          bookedSlots = bookedSlots.concat(futureRecurrences);
        } else {
          availableSlots = availableSlots.concat(futureRecurrences);
        }
      } else {
        if (slot.state === 'booked') {
          bookedSlots.push(this.mapSlotData(slot));
        } else if (!isSubscription) {
          availableSlots.push(this.mapSlotData(slot));
        }
      }
    });

    if (timeOff && timeOff.length) {
      timeOff.forEach((off: any) => {
        timeOffSlots.push(this.mapTimeOffData(off));
      });
    }

    bookedSlots = uniqBy(bookedSlots, 'id');
    if (type === 'editAvailabilty') {
      events = [...bookedSlots];
    } else {
      events = [...bookedSlots, ...timeOffSlots];
    }

    // This value will be set by admin in the future
    const onlySlotsAfterDate = moment().add(4, 'hours');

    availableSlots = availableSlots.filter(
      (slot) =>
        (type === 'editAvailabilty' ||
        moment(slot.start).isAfter(onlySlotsAfterDate)) &&
        !events.find(
          (event) =>
            event.start.getTime() === slot.start.getTime() ||
            (event.end &&
              slot.start.getTime() > event.start.getTime() &&
              slot.start.getTime() < event.end.getTime())
        )
    );

    if (isSubscription) {
      availableSlots = availableSlots.filter(
        (slot) =>
          !bookedSlots.find(
            (event) =>
              !event.isRepeated &&
              event.meta.slots[0].recordId === slot.meta.slots[0].recordId
          )
      );
    }

    return { bookedSlots, timeOffSlots, availableSlots };
  };


  updateTutorAvailability(updatedSlots: any) {
    let slots: any = [];
    slots = JSON.stringify(updatedSlots);
    let formData = new FormData();
    formData.append('userAvailability', slots);
    return this.fetch('user-availability', formData, HttpMethod.POST);
  }

  getActionName(slot: any) {
    console.log(slot)
    debugger
    let actionType: string = '';

    if (slot.state == SLOT_STATE.SELECTED && slot.recordId == null) {
      actionType = 'add';
    }

    if (slot.state == SLOT_STATE.DEFAULT && slot.recordId != null) {
      actionType = 'remove';
    }

    if ((slot.state == SLOT_STATE.AVAILABLE && slot.recordId != null) || (slot.state == SLOT_STATE.SELECTED && slot.recordId != null)) {
      actionType = 'update';
    }

    return actionType;
  }

  mapSlotData(slot: any, dateTime?: string) {
    return {
      id: `${dateTime || slot.dateTime}`,
      start: new Date(dateTime || slot.dateTime),
      cssClass: `slot-${slot.state}`,
      isRepeated: slot.isRepeated,
      title:
        slot.state === 'booked'
          ? ''
          : moment(dateTime || slot.dateTime).format('ddd Do MMM h:mma'),
      slotType: slot.state === 'booked' ? 'booked' : 'available',
      meta: { slots: [{ ...slot, dateTime: dateTime }] },
    };
  }

  mapLessonSlots(lesson: any, state: string = 'available') {
    return [
      {
        id: lesson.dateTime,
        start: new Date(lesson.dateTime),
        end:
          lesson.period > 30
            ? moment(lesson.dateTime).add(lesson.period, 'minutes').toDate()
            : undefined,
        cssClass: `slot-${state}`,
        title: moment(lesson.dateTime).format('ddd Do MMM h:mma'),
        slotType: state,
        meta: { slots: lesson.slots },
      },
    ];
  }

  mapTimeOffData(off: any) {
    return {
      start: new Date(off.startDate),
      end: new Date(off.endDate),
      cssClass: 'time-off',
      slotType: 'time-off',
      meta: { off },
    };
  }

  getFutureRecurrences(startDateString: string, count: number) {
    const startDate = moment(startDateString).toDate();
    const today = moment().startOf('day').toDate();

    const rule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: startDate,
    });

    let nextDate = rule.after(today);
    const futureDates = [];

    while (futureDates.length < count && nextDate) {
      futureDates.push(moment(nextDate).format('YYYY-MM-DD HH:mm'));
      nextDate = rule.after(nextDate);
    }

    return futureDates;
  }

  fetch(
    endPoint: any,
    params?: any,
    method: HttpMethod = HttpMethod.GET
  ): Observable<any> {
    return this.trHttp.fetch(endPoint, params, method);
  }


}
