<div class="tr-padding-2 tr-bg-highlight">
	<h3 class="tr-sub-title-dark-text tr-font-18 mb-3 p-0"> Resume</h3>
	 <ul class="px-0 m-0 d-flex align-items-start">
	 	<li class="tr-bdr pe-4 cursor-pointer" (click)="this.selectedTab = 'experience'">
	 	   <span [class.active]="selectedTab === 'experience'">Experience</span>
	    </li>
	 	<li class="tr-bdr px-4 cursor-pointer" (click)="this.selectedTab = 'certificates'">
	 	   <span [class.active]="selectedTab === 'certificates'">Certificates</span>
	 	</li>
	 	<li class="ps-4 cursor-pointer" (click)="this.selectedTab = 'education'">
	 	    <span [class.active]="selectedTab === 'education'">Education</span>
	    </li>
	 </ul>
	 <section class="mt-3">
	 	<div *ngIf="selectedTab === 'experience'">
	 		<section class="tr-bg-grey">
	 			<div class="tr-row d-flex gap-4" *ngFor="let experience of experiences">
	 				<span class="tr-date"> {{ experience.yearsOfExperienceFrom | date:'MMM dd, yyyy' }} - {{ experience.yearsOfExperienceTo | date:'MMM dd, yyyy'}}</span>
	 				<div class="lh-0">
	 					<span class="tr-sub-title-dark-text tr-font-12">{{ experience.company}}</span>
	 					<p class="mb-0 tr-sub-title-lite-text tr-font-12"> {{ experience.description}}</p>
	 				</div>
	 			</div>
	 			<div *ngIf="experiences && experiences.length == 0">
	 				<p class="tr-sub-title-lite-text tr-font-14 text-left"> No data found.. </p>
	 			</div>
	 		</section>
	 	</div>		 	
	 	<div *ngIf="selectedTab === 'education'">
	 		<section class="tr-bg-grey">
	 			<div class="tr-row d-flex gap-4" *ngFor="let education of educations">
	 				<span class="tr-date"> {{ education.yearsOfStudyFrom | date:'MMM dd, yyyy' }} - {{ education.yearsOfStudyTo | date:'MMM dd, yyyy'}}</span>
	 				<div class="lh-0">
	 					<span class="tr-sub-title-dark-text tr-font-12">{{ education.degreeName}} - {{ education.major}}</span>
	 					<p class="mb-0 tr-sub-title-lite-text tr-font-12"> {{ education.universityName}}</p>
	 				</div>
	 			</div>
	 		    <div *ngIf="educations && educations.length == 0">
	 				<p class="tr-sub-title-lite-text tr-font-14 text-left"> No data found.. </p>
	 			</div>
	 		</section>
	 	</div>	 	
	 	<div *ngIf="selectedTab === 'certificates'">
	 		<section class="tr-bg-grey">
	 			<div class="tr-row d-flex gap-4" *ngFor="let certificate of certificates">
	 				<span class="tr-date"> {{ certificate.yearsOfStudyFrom | date:'MMM dd, yyyy' }} - {{ certificate.yearsOfStudyTo | date:'MMM dd, yyyy'}}</span>
	 				<div class="lh-0">
	 					<span class="tr-sub-title-dark-text tr-font-12">{{ certificate.subjectName}}</span>
	 					<p class="mb-0 tr-sub-title-lite-text tr-font-12"> {{ certificate.issuedBy}}</p>
	 				</div>
	 			</div>
	 			<div *ngIf="certificates && certificates.length == 0">
	 				<p class="tr-sub-title-lite-text tr-font-14 text-left"> No data found.. </p>
	 			</div>
	 		</section>
	 	</div>
	 </section>
</div>