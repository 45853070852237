import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
@Component({
  selector: 'tr-student-lesson',
  templateUrl: './student-lesson.component.html',
  styleUrls: ['./student-lesson.component.css'],
  providers:[ReschedulerService]
})
export class StudentLessonComponent implements OnInit {
  @ViewChild('openReschedulerModal') openReschedulerModal?: ElementRef;
  sideNavItemNames = sideNavItemNames;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  constructor(public userLessonsService: UserLessonsService) { }
  ngOnInit(): void {
    this.userLessonsService.lessonAction$.subscribe(action => {
      if (action === LESSON_PERMISSIONS.RESCHEDULE || action === LESSON_PERMISSIONS.REQ_RESCHEDULE || action === LESSON_PERMISSIONS.GIVE_NEW_TIME) {
        setTimeout(() => {this.openReschedulerModal?.nativeElement.click()},100);
      }
    });
  }

}
