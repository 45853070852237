import { Injectable } from '@angular/core';
import { TrHttpService } from '../tr-http.service';
import { HttpMethod, TRObject } from '../../shared/models/shared';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormDataService } from '@core/form-data.service';
import { ToastService } from '@components/shared-components/toast.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService extends TRObject {
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isClassRoomActivated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  theme:any;
  fontSize:any;
  currentRole$: BehaviorSubject<string> = new BehaviorSubject<string>('guest');
  userTheme$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userEmail$: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  user$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  userId$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private token: any = null;

  constructor(private trHttp: TrHttpService, private router: Router, private formDataService: FormDataService, private toastService: ToastService) {
    super();
    console.log(this.currentRole$.value)

  }

  fetchAuth(endPoint: any, params: any, method: HttpMethod = HttpMethod.POST) {
    return this.trHttp.fetch(endPoint, params, method).pipe(
      tap(
        (data: any) => { },
        (error: any) => {
          // this.router.navigate(['/login']);
        }
      )
    )
  }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getTokenResponse(token:any){
    const formData = new FormData();
    formData.append("remember_token", token)
    return this.fetchCore('login-post-by-token', formData, HttpMethod.GET);
  }

  switchRole(role: string) {
    return this.fetchAuth('auth-switch/' + role, {}, HttpMethod.GET);
  }

  validateToken() {
    return this.fetchAuth('auth-checker', {}, HttpMethod.GET).pipe(
      tap(
        (res: any) => {
          this.user$.next(res.data.user);
          this.userEmail$.next(res.data.user.email);
          let theme = res.data.user['theme_appearance'];
          if(theme){
            this.setTheme(theme);
          }
          let font = res.data.user['theme_font_size'];
          if(font){
            this.setFont(font);
          }
          this.userId$.next(res.data.user.id);
          return res.status;
        },
        (error: any) => {
          this.router.navigate(['/login']);
          this.toastService.showErrorToast('Error', error.message);
        }
      )
    )
  }  

  getThemeFont() {
    return this.fetchAuth('user-general-settings', {}, HttpMethod.GET).pipe(
      tap(
        (res: any) => {
          console.log(res);
        },
        (error: any) => {
          this.toastService.showErrorToast('Auth checker error', error['message']);
        }
      )
    )
  }

  getUserRole() {
    return this.fetchAuth('auth-role', {}, HttpMethod.GET).pipe(
      tap(
        (data: any) => {
          return data.status
        },
        (error: any) => {
          this.router.navigate(['/login']);
        }
      )
    )
  }

  getToken() {
    this.token = localStorage.getItem('TrTokenKey')
    return this.token;
  }

  setUserRole(role: string) {
    this.currentRole$.next(role);
  }

  login(cred: any): Observable<any> {
    const formData = new FormData();
    formData.append("email", cred.email)
    formData.append("password", cred.password)

    return this.fetchAuth('login', formData).pipe(
      tap(
        (response: any) => {
          this.user$.next(response.data.user);
          this.userEmail$.next(response.data.user.email);
          this.isLoggedIn.next(true);
          this.userId$.next(response.data.user.id);
          this.setToken(response.data);
          this.setUserRole(response?.data?.user?.active_role);
        }
      )
    )
  }

  setLoginResponse(response:any){
    this.setUserRole(response?.data?.user?.active_role);
    this.setToken(response.data);
    this.isLoggedIn.next(true);
    this.user$.next(response.data.user);
    this.userEmail$.next(response.data.user.email);
    this.userId$.next(response.data.user.id);
    this.toastService.showSuccessToast('Success', response['message']);

  }  

  getLoginToken(token: any): Observable<any> {
    const formData = new FormData();
    formData.append("remember_token", token)

    return this.fetchAuth('login-post-by-token', formData, HttpMethod.GET).pipe(
      tap(
        (res: any) => {
          this.user$.next(res.data.user);
          this.setToken(res.data);
          this.userEmail$.next(res.data.user.email);
          let theme = res.data.user['theme_appearance'];
          if(theme){
            this.setTheme(theme);
          }
          let font = res.data.user['theme_font_size'];
          if(font){
            this.setFont(font);
          }
          this.userId$.next(res.data.user.id);
          return res.status;
        },
        (error: any) => {
          this.router.navigate(['/login']);
          this.toastService.showErrorToast('Error', error.message);
        }
      )
    )
  }

  tutorRegisterPhaseOne(phaseOneData: any, timezoneID:any) {
    const formData = new FormData();
    formData.append('email', phaseOneData.email);
    formData.append('password', phaseOneData.password);
    formData.append('timezoneId', timezoneID);
    if(phaseOneData.referral_code){
      formData.append('referral_code', phaseOneData.referral_code);
    }

    return this.fetchAuth('tutor-register', formData).pipe(
      tap(
        (response: any) => {
          this.userEmail$.next(response.data.user.email);
          this.setToken(response.data)
        },
        (error: any) => {
          this.clearData()
          // this.router.navigate
        }
      )
    )
  }

  clearToken(){
     this.token = null;
  }

  clearData() {
    this.token = null;
    localStorage.clear();

    setTimeout(() => {
      this.currentRole$.next('guest');
      this.isLoggedIn.next(false);
      this.user$.next([]);
      this.isLoggedIn.complete();
    });
  }

  private setToken(data: any) {
    this.token = data['access_token'];
    localStorage.setItem('TrTokenKey', this.token);
  }

  setTheme(theme:any){
    if(theme){
      this.theme = theme;
      this.userTheme$.next(theme);
      let body = document.getElementsByTagName("body")[0];
        if (theme == "dark") {
          body.classList.add(theme);
        } else {
          body.classList.remove("dark");
      }
   }
  }

  setFont(font:any){
    this.fontSize = font;
    let pixelValue = font === 'small' ? '14px' : font === 'medium' ? '16px' : '18px';
    let root = document.querySelector<HTMLElement>(":root");
    if (root) {
      let gcs = getComputedStyle(root);
      let font = gcs.getPropertyValue("--font-size");
      root.style.setProperty("--font-size", pixelValue);
    }
  }

  registerStudent(cred: any): Observable<any> {
    const formData = new FormData();
    formData.append("email", cred.email);
    formData.append("password", cred.password);
    formData.append("timezoneId", cred.timezone_id);
    if(cred.referral_code){
       formData.append("referral_code", cred.referral_code);
    }

    return this.fetchAuth('student-register', formData).pipe(
      tap(
        (response: any) => {
          this.setUserRole('student');
          this.isLoggedIn.next(true);
          this.setToken(response.data)
        }
      )
    )
  }
}