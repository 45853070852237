import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data.expectedRole;

    if (this.authService.isLoggedIn.getValue()) {
      return this.checkRoleForLoggedInUser(expectedRole)
    } else {
      return this.validateRoleOnBackend(expectedRole)
    }
  }

  checkRoleForLoggedInUser(expectedRole: any) {
    return this.authService.currentRole$.pipe(
      map(
        (currentRole: any) => {
          if (expectedRole == currentRole) {
            return true;
          }
          alert("your account has " + currentRole + " role. But trying to access " + expectedRole + " view")
          return false;
        },
        (error: any) => { }
      )
    )
  }

  validateRoleOnBackend(expectedRole: any) {
    return this.authService.getUserRole().pipe(
      map(
        (res: any) => {
          if (expectedRole == res.data.role) {
            this.authService.currentRole$.next(expectedRole)
            return true;
          }
          alert("your account has " + res.data.role + " role. But trying to access " + expectedRole + " view")
          return false;
        }
      )
    )
  }
}