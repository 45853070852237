import { Component, OnInit } from '@angular/core';
import { LessonDetailsService } from './../lesson-details-overview/lesson-details.service';
import { finalize } from 'rxjs/operators';
import { TRObject } from 'src/app/shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from "@angular/forms";
import { getFormattedTimeAgo } from '@shared/models/messages';
import { AuthService } from '@core/auth/auth.service'

@Component({
  selector: 'tr-lesson-board',
  templateUrl: './lesson-board.component.html',
  styleUrls: ['./lesson-board.component.css']
})
export class LessonBoardComponent extends TRObject implements OnInit {
  isListView:boolean = false;
  files: File[] = [];
  totalComments:number = 0;
  lessonComments:any;
  chatId:any;
  uploadImageStatus: any;
  isLoading:boolean = false
  message:any;
  userLogo:any;


  constructor(
    public lessonDetailsService: LessonDetailsService,
    private fb: UntypedFormBuilder, 
    public authService: AuthService, 
    private responseHandlerService: ResponseHandlerService) {
    super();
  }

  ngOnInit(): void {
    this.userLogo = this.authService.user$.value.avatar;
    this.lessonDetailsService.chatId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if(data) {
           this.chatId = data;
           this.getLessonBoardMessages(this.chatId);
             this.chatForm.patchValue({
               chat_id: this.chatId
            });
         }
    });
  }

  public chatForm = this.fb.group({
         chat_id: ["", Validators.required],
         body: ["", Validators.required],
         attachment : [""]
  });

  getLessonBoardMessages(chatId:any){
    this.lessonDetailsService.getLessonBoardMessages(chatId)
      .pipe(finalize(() => ('')))
      .subscribe(
        (res: any) => {
          this.lessonComments = res['data'];
          this.totalComments =  this.lessonComments.length;
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  addDocument(event: any) {
    event.rejectedFiles.forEach((file: any) => {
    if (file.reason === 'type') {
        this.uploadImageStatus =
       'File has wrong format ("image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application")';
    } else if (file.reason === 'size') {
        this.uploadImageStatus = 'File too big (max file size is 2mb)';
    } else {
        this.uploadImageStatus = 'File not added';
    }
        this.responseHandlerService.showErrorMessage(this.uploadImageStatus);
    });
    this.files.push(...event.addedFiles);
    this.chatForm.patchValue({
       attachment : this.files
    });
    this.uploadImageStatus = '';
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public removeAllFiles() {
    this.files = [];
    this.chatForm.patchValue({
       attachment : ''
    });
  }

  formatTime(timestamp:any) {
    return getFormattedTimeAgo(timestamp);
  }

  isNewDate(currentDateStr: string, previousDateStr: string): boolean {
    const currentDate = new Date(currentDateStr);
    const previousDate = previousDateStr ? new Date(previousDateStr) : null;
    return !previousDate || currentDate.toDateString() !== previousDate.toDateString();
  }

  isToday(dateStr: string): boolean {
    const date = new Date(dateStr);
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }


  sendMessage(){
    this.lessonDetailsService
      .sendMessage(this.chatForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
         this.message = '';
         if(this.lessonComments){
            this.lessonComments.unshift(res);
         }else{
           this.getLessonBoardMessages(this.chatId);
         }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

}
