<section class="tr-px-2 py-3 tr-bg-highlight d-flex flex-wrap gap-3 align-items-sm-center justify-content-between" *ngIf="discount && discount.length > 0">
	<section class="d-flex flex-column flex-sm-row align-items-sm-center gap-4">
		<section class="d-flex flex-column flex-sm-row  align-items-sm-center gap-4">
		<div class="tr-bg-grey p-2 d-flex"> 
			<span class="d-flex align-items-center justify-content-center">{{ discount?.[0].percentage}}</span>
			<mark>&#37;</mark>
		</div>
		<div>
			<h2>Book {{ discount?.[0].numberOfLesson}} Lessons</h2>
			<span>When you book {{ discount?.[0].numberOfLesson}} lessons with the current teacher, you will get a {{ discount?.[0].percentage}}% discount.</span>
		</div>
	   </section>
		<span class="tr-book-btn" (click)="openBookingWindow()">
			<button class="px-3 py-2 border-0 d-flex align-items-center gap-2">
				Book now 
			<img src="assets/images/arrow-right-full.svg" alt="arrow-right"></button>
	    </span>
	</section>
</section>