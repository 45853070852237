import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
@Component({
  selector: "tr-custom-pagination",
  templateUrl: "./custom-pagination.component.html",
  styleUrls: ["./custom-pagination.component.css"],
})
export class CustomPaginationComponent implements OnInit {
  @Input() currentPage!: number;
  @Input() perPage: number = 10;
  @Input() totalRows!: number;
  @Input() firstPage: number = 1;
  @Input() lastPage!: number;
  @Output() onChangeEmit: EventEmitter<{ target: any; value: any }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}


  changePerPageSize(event: any) {
    this.perPage = event.target.value;
    const objectToEmit = { target: "perPage", value: this.perPage };
    this.onChangeEmit.emit(objectToEmit);
  }

  get pages(): (number | "...")[] {
    let pages: (number | "...")[] = [];

    if (this.totalPages <= 7) {
      pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      const firstPage = 1;
      const lastPage = this.totalPages;

      const currentPage = this.currentPage;
      const totalPages = this.totalPages;

      if (currentPage <= 4) {
        pages = [1, 2, 3, 4, 5, "...", totalPages];
      } else if (currentPage >= totalPages - 3) {
        pages = [
          firstPage,
          "...",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        pages = [
          firstPage,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages,
        ];
      }
    }

    return pages;
  }

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
        const objectToEmit = { target: "page", value: this.currentPage + 1 };
        this.onChangeEmit.emit(objectToEmit);
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
        const objectToEmit = { target: "page", value: this.currentPage - 1 };
        this.onChangeEmit.emit(objectToEmit);
    }
  }

  setPage(page: number | "..."): void {
    console.log(page);
    if (page === "...") {
      return; // Do nothing for ellipsis
    }

    const pageNumber = page as number;
    if (this.currentPage !== pageNumber) {
      const objectToEmit = { target: "page", value: pageNumber };
      this.onChangeEmit.emit(objectToEmit);
    }
  }

  getStartIndex(): number {
    if (this.totalRows === 0) {
      return 0;
    }
    return (this.currentPage - 1) * this.perPage + 1;
  }

  getEndIndex(): number {
    if (this.currentPage === this.totalPages) {
      return this.totalRows;
    }
    return this.currentPage * this.perPage;
  }
  
}
