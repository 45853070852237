<div
  class="edit-slot-modal modal fade show d-block"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <span>{{ title }}</span>
          <span>{{ subTitle }}</span>
        </div>
        <span
          data-bs-dismiss="modal"
          aria-label="Close"
          class="cursor-pointer tr-close"
          (click)="onClose()"
        >
          <img src="assets/icons/close-circle.svg" alt="close-icon" />
        </span>
      </div>
      <div class="modal-body">
        <div class="form-check form-switch">
          <label class="form-check-label" for="repeatedToggle">Repeat Weekly</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="repeatedToggle"
            [checked]="slot.isRepeated"
            (change)="onToggleRepetition($event)"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button class="tr-btn-danger" (click)="onRemoveSlot()">Delete</button>
      </div>
    </div>
  </div>
</div>
