import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { HttpMethod, TRObject } from '../shared/models/shared';
import { TrHttpService } from './tr-http.service';
import * as moment from 'moment';
import 'moment-timezone';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FormDataService extends TRObject {
  constructor(private trHttp: TrHttpService, private route: ActivatedRoute) {
    super()
    this.userTimezone = moment.tz.guess() || 'Europe/London';
  }

  timezoneMap: any = new Map();
  currenciesMap: any = new Map();
  countriesMap: any = new Map();
  countriesMapFindTutor: any = new Map();
  timezoneDataSubject = new BehaviorSubject<boolean>(false);
  countries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  proficiencyLevels$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  currencies$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  languages$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  subjects$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  ageGroups$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  specialities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  certificates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  universities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  degrees$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  userTimezone: any;
  countriesFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  languagesFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  proficiencyLevelsFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  subjectsFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  ageGroupsFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  specialitiesFindTutor$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  usersData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);


  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET) {
    return this.trHttp.fetch(endPoint, params, method);
  }

  // #Start get data for filter tutors

  getCountriesFindTutor() {
    this.fetchCore('countries-list-find-tutor').subscribe(
      (response: any) => {
        this.countriesFindTutor$.next(response.data);
        response.data.map((country: any) => {
          this.countriesMapFindTutor.set(country.Id, country);
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getLanguagesFindTutor() {
    this.fetchCore('languages-list-find-tutor').subscribe(
      (response: any) => {
        this.languagesFindTutor$.next(response.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getProficiencyLevelsFindTutor() {
    this.fetchCore('languages-native-list-find-tutor').subscribe(
      (response: any) => {
        this.proficiencyLevelsFindTutor$.next(response.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getSubjectsFindTutor() {
    this.fetchCore('subjects-list-find-tutor').subscribe(
      (response: any) => {
        this.subjectsFindTutor$.next(response.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getAgeGroupsFindTutor() {
    this.fetchCore('ages-list-find-tutor').subscribe((res: any) => {
      this.ageGroupsFindTutor$.next(res.data);
    });
  }

  getSpecialitiesFindTutor() {
    this.fetchCore('specialities-list-find-tutor').subscribe((res: any) => {
      this.specialitiesFindTutor$.next(res.data);
    });
  }

  // #End get data for filter tutors

  getCountries() {
    this.fetchCore('countries/list').subscribe(
      (response: any) => {
        this.countries$.next(response.data);
        response.data.map((country: any) => {
          this.countriesMap.set(country.Id, country);
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getCountryById(id: any) {
    return this.countriesMap.get(id) || null;
  }

  getLanguages() {
    this.fetchCore('languages/list').subscribe(
      (response: any) => {
        this.languages$.next(response.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getProficiencyLevels() {
    this.fetchCore('levels/list').subscribe(
      (response: any) => {
        this.proficiencyLevels$.next(response.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getCurrencies() {
    this.fetchCore('currencies/list').subscribe(
      (response: any) => {
        this.currencies$.next(response.data);
        response.data.map((currency: any) => {
          this.currenciesMap.set(currency.id, currency);
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getSubjects() {
    return this.fetchCore('subjects/list').pipe(
      tap(
        (res: any) => {
          this.subjects$.next(res.data);
          return of(res.data)
        }
      )
    )
  }

  getTimezones(): Observable<void> {
    return this.fetchCore('timezones/list').pipe(
      map((res: any) => {
        res.data.map((el: any) => {
          this.timezoneMap.set(el.name, el);
        });
      }),
      tap(() => {
        this.timezoneDataSubject.next(true); // Mark timezone data as available
      })
    );
  }

  getUserTimezoneId(): any { 
    return this.timezoneMap.get(this.userTimezone);
  }

  // wait until response of timezones
  async fetchDataAndUseTimezone(): Promise<any> {
    try {
      await this.getTimezones().toPromise();
      const userTimezoneData = this.getUserTimezoneId();
      console.log(userTimezoneData, this.userTimezone);
      return userTimezoneData;
    } catch (error) {
      throw error;
    }
  }


  getAgeGroups() {
    this.fetchCore('ages/list').subscribe((res: any) => {
      this.ageGroups$.next(res.data);
      res.data;
    });
  }

  getSpecialities() {
    this.fetchCore('specialities/list').subscribe((res: any) => {
      this.specialities$.next(res.data);
    });
  }

  getCertificates(id: any) {
    this.fetchCore('subject-certificates-list?subjectId=' + id).subscribe(
      (response: any) => {
        this.certificates$.next(response.data);
      },
      (error: any) => {
        console.error(error)
      }
    )
  }

  getUniversities() {
    this.fetchCore('universities/list').subscribe(
      (res: any) => {
        this.universities$.next(res.data)
      }
    )
  }

  getDegrees() {
    this.fetchCore('degrees/list').subscribe(
      (res: any) => {
        this.degrees$.next(res.data)
      }
    )
  }

  getFindTutorFilters(route: any) {
    this.getTimezones();

    if (route['tutorRoute']) {
      this.getCountriesFindTutor();
      this.getLanguagesFindTutor();
      this.getProficiencyLevelsFindTutor();
      this.getSubjectsFindTutor();
      this.getAgeGroupsFindTutor();
      this.getSpecialitiesFindTutor();
      this.getCurrencies();
      // For lesson Booking Details
      this.getBookLessonTypeDetailsForm()
    }
  }

  getUserAccountSettingsData() {
    this.getLanguages();
    this.getProficiencyLevels();
    this.getSubjects();
    this.getAgeGroups();
    this.getSpecialities();
    this.getDegrees();
    this.getUniversities();
  }

  getFormData(route: any) {
    this.getTimezones();

    if (route['tutorRoute']) {
      this.getSubjects();
      this.getCountries();
      this.getProficiencyLevels();
      this.getAgeGroups();
      this.getSpecialities();
      this.getLanguages();
      this.getCurrencies();
      this.getDegrees();
      this.getUniversities();
      this.getSubjectsFindTutor()

    }

    if (route['studentRoute']) {
      //get student form data
    }
  }

  getBookLessonTypeDetailsForm() {
    this.getSubjects();
    this.getAgeGroups();
    this.getSpecialities();
  }

  getUsers(role:any){
    let endpoint = '';
    if(role === 'student'){
     endpoint = 'get-my-tutors-for-lessons-filters'
    }    
    else if(role === 'tutor'){
      endpoint = 'get-my-students-for-lessons-filters' 
    }
    const formData = new FormData();
    formData.append('perPage', '20');
    return this.fetchCore(endpoint, formData)
  }
}
