import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
@Component({
  selector: 'tr-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
 showAlert:boolean = true;
 @ViewChild('openReschedulerModal') openReschedulerModal?: ElementRef;
 LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
 constructor(public userLessonsService: UserLessonsService) { }

  ngOnInit(): void {
    this.userLessonsService.lessonAction$.subscribe(action => {
      if (action === LESSON_PERMISSIONS.RESCHEDULE || action === LESSON_PERMISSIONS.REQ_RESCHEDULE || action === LESSON_PERMISSIONS.GIVE_NEW_TIME) {
        setTimeout(() => {this.openReschedulerModal?.nativeElement.click()},100);
      }
    });
  }

 ngOnDestroy(){
   this.userLessonsService.lessonAction$.next('close');
 }

}
