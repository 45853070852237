import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetpasswordGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check if the "code" query parameter is present in the URL
    const codeParam = route.queryParamMap.get('code');

    if (codeParam) {
      return true; // Allow navigation to the component
    } else {
      // Redirect to another route or show an error page if needed
      return false; // Prevent navigation to the component
    }
  }
}