<div class="p-4">
	<section class="d-flex flex-column gap-4">
	 <div class="d-flex align-items-start justify-content-between">
		<div class="d-flex flex-column gap-1">
			<span class="tr-text-lite">My lessons</span>
			<span class="tr-text-dark">{{ userData?.totalLessons }}</span>
		 </div>
		<div>
			<img src="assets/images/dashboard/dashboard-lessons.svg" alt="">
		</div>
	 </div>	 
	 <div class="d-flex align-items-start justify-content-between">
		<div class="d-flex flex-column gap-1">
			<span class="tr-text-lite">Total hours</span>
			<span class="tr-text-dark">{{ userData?.totalHours }}<span>hr</span></span>
		 </div>
		<div>
			<img src="assets/images/dashboard/dashboard-clock.svg" alt="">
		</div>
	 </div>	 
	 <div class="d-flex align-items-start justify-content-between">
		<div class="d-flex flex-column gap-1">
			<span class="tr-text-lite">My balance</span>
			<div>
				<span class="tr-text-dark" trCurrency></span>
				<span class="tr-text-dark">{{ userData?.balance }}</span>
			</div>
		 </div>
		<div>
			<img src="assets/images/dashboard/dashboard-balance.svg" alt="">
		</div>
	 </div>
	</section>
</div>