import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastEventTypes, ToastEvent, ToastEventIcons } from 'src/app/shared/models/notifications';
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastEvents!: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents;
  }

  // showToast(title: string, message: string, type: ToastEventTypes, icon: ToastEventIcons) {
  //   this._toastEvents.next({
  //     message,
  //     title,
  //     type,
  //     icon
  //   })
  // }

  showSuccessToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: ToastEventTypes.Success,
      icon: ToastEventIcons.Success,
    })
  }

  showInfoToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: ToastEventTypes.Info,
      icon: ToastEventIcons.Info,
    });
  }

  showWarningToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: ToastEventTypes.Warning,
      icon: ToastEventIcons.Warning,
    });
  }

  showErrorToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: ToastEventTypes.Error,
      icon: ToastEventIcons.Error,
    });
  }
}
