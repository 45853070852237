<div
  class="tr-tutor-register-code-conf-container container-fluid align-items-center d-flex justify-content-center"
>
  <div class="tr-tutor-reg-code-conf-wrapper col-md-6 col-lg-6 top30 bottom50">
    <div class="row top30">
      <div
        class="tr-tutor-reg-code-conf-main-title col-md-12 col-lg-12 col-12 text-center"
      >
        Thank you for joining Tutor reach
      </div>
    </div>

    <div class="row d-flex justify-content-center top20">
      <div
        class="tr-tutor-reg-code-conf-label col-md-5 col-lg-5 col-10 text-center bottom15"
      >
        Check your mail to continue. Enter code that sent on mail
      </div>
    </div>
    <form [formGroup]="codeConfirmationForm">
      <div class="row">
        <div class="col-md-12 col-lg-12 d-flex justify-content-center">
          <div class="tr-tutor-register-code-conf-form">
            <input
              type="number"
              autofocus
              maxlength="1"
              (input)="keytab($event)"
              class="tr-tutor-register-code-conf-input"
              name="code"
              autocomplete="off"
              formControlName="confirmCodeOne"
              required
            />
            <input
              type="number"
              maxlength="1"
              (input)="keytab($event)"
              class="tr-tutor-register-code-conf-input"
              name="code"
              autocomplete="off"
              formControlName="confirmCodeTwo"
              required
            />
            <input
              type="number"
              maxlength="1"
              (input)="keytab($event)"
              class="tr-tutor-register-code-conf-input"
              name="code"
              autocomplete="off"
              formControlName="confirmCodeThree"
              required
            />
            <input
              type="number"
              maxlength="1"
              (input)="keytab($event)"
              class="tr-tutor-register-code-conf-input"
              name="code"
              autocomplete="off"
              formControlName="confirmCodeFour"
              onKeyPress="if(this.value.length==1) return false;"
              required
            />
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12 col-lg-12 text-center tr-tutor-reg-code-timer">
        Remaining
        <span class="time-counter">{{ counter | formatTime }} sec.</span>
      </div>
    </div>

    <div class="row d-flex justify-content-center top20">
      <div class="col-md-12 col-lg-12 col-10 text-center">
        <span class="tr-code-expired"
          >Didn't receive mail or expired code? </span
        ><span class="tr-resend-code" (click)="resendCode()"> Send again</span>
      </div>
    </div>

    <div class="row d-flex justify-content-center top100">
      <div class="col-md-11 col-lg-11 col-10 d-flex justify-content-start">
        <button
          class="tr-tutor-reg-verify-code-btn col-md-12 col-lg-12 col-12 bottom30 tr-btn"
          (click)="nextPhase()"
          [disabled]="codeConfirmationForm.invalid"
          >
        <span *ngIf="!isLoading">Go to Tutor application</span>
        <tr-spinner *ngIf="isLoading"></tr-spinner>
      </button>
      </div>
    </div>
  </div>
</div>
