import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private currencySymbolSubject = new BehaviorSubject<string>('£'); // Initial value can be an empty string or your default currency symbol
  currencySymbol$ = this.currencySymbolSubject.asObservable();

 
  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
     return this.trHttp.fetch(endPoint, params, method);
  }

  getNotifications(){
     const formData = new FormData();
     formData.append('perPage', '10');
     return this.fetchCore('list-user-notification', formData);
  }

  getUserChats(){
   return this.fetchCore('get-user-chats');
  }


}
