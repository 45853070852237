<div class="tr-my-tutor-component container-fluid">
    <div class="row top30 px-4">
        <div class="col-md-12 col-lg-12">
            <div class="row">
                <div class="col-md-4 col-lg-4 d-flex text-start">
                    <div class="col-md-12 col-lg-12 tr-my-tutor-tabs d-flex align-items-center" >
                        <p class="mb-0 tr-saved-tutor-tab   align-items-center pb-2">
                            <a class="align-items-center" routerLink="current-tutors" routerLinkActive="active">My current tutors</a>
                        </p>
                        <p class="mb-0 tr-my-tutor-tab align-items-center pb-2">
                          <a class="align-items-center" routerLink="saved-tutors" routerLinkActive="active">Favourite tutors</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      <router-outlet></router-outlet>
    </div>
</div>