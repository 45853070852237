import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TutorDashboardService } from '../tutor-dashboard.service';
import { finalize } from "rxjs/operators";
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";


@Component({
  selector: 'tr-dashboard-today-lessons',
  templateUrl: './dashboard-today-lessons.component.html',
  styleUrls: ['./dashboard-today-lessons.component.css']
})
export class DashboardTodayLessonsComponent implements OnInit {

  isLoading:boolean = false;
  today:any;
  todayEndDate:any;
  todayLessons:any;

  constructor(private tutorDashboardService:TutorDashboardService, 
    private responseHandlerService: ResponseHandlerService) {
    this.today = moment().format('Y-MM-DD 00:00');
    this.todayEndDate = moment().format('Y-MM-DD 23:59');
   }

  ngOnInit(): void {
   this.getTodayLessons();
  }

  getTodayLessons(){
    this.isLoading = true;
    const formData = new FormData();
    formData.append('perPage', '3');
    formData.append('lessonStatus', '1');
    formData.append('userType', 'tutor');
    formData.append('startDateTime', this.today);
    formData.append('endDateTime', this.todayEndDate);
    this.tutorDashboardService
      .getTodayLessons(formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          let data = res['data'];
           if(data){
             this.todayLessons = data;
             console.log(data)
           }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
   );
  }

  // formatTime(timestamp:any) {
  //   const lessonDateTime = moment(timestamp);
  //   const now = moment();
    
  //   const minutesDifference = lessonDateTime.diff(now, 'minutes');
  //   const hoursDifference = lessonDateTime.diff(now, 'hours');

  //   if (minutesDifference < 60) {
  //     return `${minutesDifference} minutes`;
  //   } else {
  //     return  `${hoursDifference} hours`;
  //   }
  // }

  formatTime(timestamp: any, period: any) {
    const lessonDateTime = moment(timestamp);
    const now = moment();

    const minutesDifference = lessonDateTime.diff(now, 'minutes');

    if (minutesDifference <= 0 && minutesDifference >= -period) {
      return 'Started';
    } else if (minutesDifference < 0) {
      return 'Completed';
    } else {
      const hoursDifference = lessonDateTime.diff(now, 'hours');
      const remainingMinutes = minutesDifference % 60;

      if (minutesDifference < 60) {
        return `${minutesDifference} minutes`;
      } else {
        if (period) {
          return `${hoursDifference} hours and ${remainingMinutes} minutes`;
        } else {
          return `${hoursDifference} hours`;
        }
      }
    }
  }



}
