import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonImageComponent } from '@components/shared-components/skeleton/skeleton-image/skeleton-image.component';
import { SingleLineComponent } from '@components/shared-components/skeleton/single-line/single-line.component';
import { DoubleLineComponent } from '@components/shared-components/skeleton/double-line/double-line.component';
import { SkeletonButtonComponent } from '@components/shared-components/skeleton/skeleton-button/skeleton-button.component';
@Component({
  selector: 'tr-find-tutor-skeleton-loader',
  standalone: true,
  imports: [CommonModule,
  SkeletonImageComponent,
  SingleLineComponent,
  DoubleLineComponent,
  SkeletonButtonComponent],
  templateUrl: './find-tutor-skeleton-loader.component.html',
  styleUrls: ['./find-tutor-skeleton-loader.component.css']
})
export class FindTutorSkeletonLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
