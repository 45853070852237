<button
  data-bs-toggle="modal"
  data-bs-target="#sendMessagePopup"
  class="d-none border-0"
  #openModalBtn
></button>
<!-- send messsage modal -->
<div
  class="modal fade"
  id="sendMessagePopup"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content tr-padding-2">
      <div class="modal-header border-0">
        <h5
          class="modal-title w-100 d-flex align-items-center justify-content-center"
          id="staticBackdropLabel"
        >
          Message tutor
        </h5>
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeModalBtn
          (click)="this.chatForm.reset()"
          class="tr-close-btn"
        >
          <img src="assets/icons/close-circle.svg" alt="close-icon" />
        </button>
      </div>

      <div class="modal-body" *ngIf="userDetails">
        <div [formGroup]="chatForm">
          <div
            class="tr-send-msg d-flex align-items-center gap-3 tr-bdr-b pb-3"
          >
            <span>To:</span>
            <div class="d-flex align-items-center gap-3">
              <img
                [src]="userDetails.logo"
                alt=""
                class="tr-img-sm"
                *ngIf="userDetails.logo"
              />
              <p class="mb-0">{{ userDetails.name }}</p>
            </div>
          </div>
          <div class="mt-4">
            <textarea
              class="form-control"
              cols="30"
              rows="7"
              formControlName="body"
              placeholder="type your text"
            ></textarea>
          </div>
          <div class="mt-4">
            <button class="tr-btn-primary tr-btn" [disabled]="chatForm.invalid" (click)="sendMessage()">
              <span *ngIf="!isLoading">send now</span>
              <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
