<div class="container-fluid p-0 m-0">
	<div class="row tr-main-container-fluid-right p-0">
		<div class="col-md-12 p-0" *ngIf="!isLoading">
			<tr-in-app-header></tr-in-app-header>
			<tr-side-nav *ngIf="!(auth.isClassRoomActivated$ |async)" ></tr-side-nav>
			<div id="tr-main-outlet-div">
				<router-outlet></router-outlet> 
			</div>
		</div>
	</div>
</div>
<div class="tr-spinner-loader d-flex align-items-center justify-content-center" *ngIf="isLoading">
    <span class="tr-bg-highlight tr-spinner-inner-sec d-flex align-items-center justify-content-center">
      <div class="spinner-border spinner-border-sm" role="status">
       <span class="visually-hidden">Loading...</span>
	  </div>
    </span>
</div>