<section class="container-fluid mx-0 p-0 d-flex">
  <div class="tr-side-panel-col" id="tr-sidepanel-col">
    <tr-side-panel sidePanelTitle="Tutors" [componentName]="sideNavItemNames.TUTORS"></tr-side-panel>
  </div>
  <div class="w-100 mx-lg-100">
    <router-outlet></router-outlet>
  </div>
</section>
<section class="row p-0 m-0">
  <div class="tr-book-lesson-window col-md-12 col-lg-12 px-0 " *ngIf="this.bookLessonService.openBookingWindow$ | async" (scroll)="handleScroll($event)">
   
   <ng-container *ngIf="(this.bookLessonService.bookingPhase$ | async) != 'incompleteProfile'">
     <tr-book-lesson></tr-book-lesson>
   </ng-container>    
   <ng-container *ngIf="(this.bookLessonService.bookingPhase$ | async) === 'incompleteProfile'">
      <tr-student-complete-information-popup [type]="'booking'"></tr-student-complete-information-popup>
   </ng-container> 
   
  </div>
</section>
<tr-send-message-popup></tr-send-message-popup>
<tr-send-confirmation-code-popup></tr-send-confirmation-code-popup>

