import { Component, OnInit } from '@angular/core';
import { sideNavItemNames } from 'src/app/shared/models/shared';
@Component({
  selector: 'tr-student-settings',
  templateUrl: './student-settings.component.html',
  styleUrls: ['./student-settings.component.css']
})
export class StudentSettingsComponent implements OnInit {
  sideNavItemNames = sideNavItemNames;

  constructor() {
  }

  ngOnInit(): void {
  }

}
