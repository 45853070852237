import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormDataService } from '@core/form-data.service';
import { searchDropdownIcon } from '@shared/models/svgIcons';
import { FindTutorService } from '../find-tutor.service';
import { debounce } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
   selector: 'tr-find-tutor-filter-panel',
   templateUrl: './find-tutor-filter-panel.component.html',
   styleUrls: ['./find-tutor-filter-panel.component.css'],
})
export class FindTutorFilterPanelComponent implements OnInit {
   @Output() filters = new EventEmitter<any>();
   clearCheckbox$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   searchBy: any;
   ifBottomFilter: boolean = true;
   countries: any;
   languages: any;
   currencies: any;
   proficiencyLevels: any;
   ageGroups: any;
   specialities: any;
   subjects: any;
   searchDropdownIcon: string = searchDropdownIcon;
   selectedLanguages: any = [];
   selectedAges: any = [];
   selectedProficiencies: any = [];
   selectedCountries: any = [];
   selectedSubjectId: any;
   userFilterSelection: any = {
      proficiency: [],
      countries: [],
      languages: [],
      ageGroups: [],
      specialities: [],
      subjectId: [],
      topTutors:[],
      hourlyRateFrom:undefined,
      hourlyRateTo:undefined,
   };
  targetedFilters:any = [];
   constructor(public formDataService: FormDataService, private findTutorService: FindTutorService) {
      this.countries = this.formDataService.countriesFindTutor$;
      this.languages = this.formDataService.languagesFindTutor$;
      this.proficiencyLevels = this.formDataService.proficiencyLevelsFindTutor$;
      this.ageGroups = this.formDataService.ageGroupsFindTutor$;
      this.specialities = this.formDataService.specialitiesFindTutor$;
      this.subjects = this.formDataService.subjectsFindTutor$;
      this.currencies = this.formDataService.currencies$;
   }

   ngOnInit(): void { }

   searchQuery: string = '';

   // Create a debounced version of the search function
   debouncedSearch = debounce(this.search, 500);

   onSearchInputChange(query: string) {
      this.searchQuery = query;
      this.debouncedSearch();
   }

   search() {
      this.userFilterSelection['searchBy'] = this.searchQuery;
      this.filters.emit(this.userFilterSelection);
   }

   setSelectedData(name:any, event: any){
      if (event) {
        let isCheck = event.target['checked'];

        if (name == 'topTutors') {
          const index = this.userFilterSelection[name].indexOf(1);
          if (isCheck) {
            // If the checkbox is checked and there's no 1 in the array, push 1
            if (index === -1) {
              this.userFilterSelection[name].push(1);
            }
          } else {
            this.userFilterSelection[name] = [];
          }
          this.filters.emit(this.userFilterSelection);
        }
      }
   }

   getSelectedData(data: any) {
      let targetList = data.targetList;
      let selectedData = data.data;
      this.userFilterSelection[targetList].push(selectedData);
      this.targetedFilters.push(data);
      this.filters.emit(this.userFilterSelection);
      this.clearCheckbox$.next(false);
   }

   removeFilterIfExist(newFilter: any) {
    this.targetedFilters = this.targetedFilters.filter((filter:any) => !(filter.targetList === newFilter.targetList && filter.data === newFilter.data));
  }

  removeFilterByTargetList(key:any){
    this.targetedFilters = this.targetedFilters.filter((filter:any) => filter.targetList !== key);
  }
  
   getUnSelectedData(data: any) {
      this.removeFilterIfExist(data);
      let filteredArray = this.userFilterSelection[data.targetList].filter(
         function (number: any) {
            return number !== data.data;
         }
      );
      this.userFilterSelection[data.targetList] = filteredArray;
      this.filters.emit(this.userFilterSelection);
      if (this.clearCheckbox$.value) {
         this.clearCheckbox$.next(false);
      } 
   }

   clearAll(key:any){
       if (this.userFilterSelection.hasOwnProperty(key) && Array.isArray(this.userFilterSelection[key])) {
         this.userFilterSelection[key] = [];
       }
      console.log(this.targetedFilters);
      this.filters.emit(this.userFilterSelection);
      this.removeFilterByTargetList(key);
   }

   setPriceRange(data: any) {
      let min = data[0];
      let max = data[1];
      this.userFilterSelection['hourlyRateFrom'] = min;
      this.userFilterSelection['hourlyRateTo'] = max;
      this.filters.emit(this.userFilterSelection);
   }

   clearAllFilters(){
      this.userFilterSelection = {
         proficiency: [],
         countries: [],
         languages: [],
         ageGroups: [],
         specialities: [],
         subjectId: [],
         hourlyRateFrom:undefined,
         hourlyRateTo:undefined,
      };
      this.targetedFilters = [];
      this.clearCheckbox$.next(true);
      this.filters.emit(this.userFilterSelection);

   }
}
