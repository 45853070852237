import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { getRecurringScheduleSlots } from '@shared/models/scheduler';

import { HttpMethod, TRObject, generateUniqueKeyFromDateTime } from '@shared/models/shared';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()

export class SchedulerService extends TRObject {
  filterTimesBy$: BehaviorSubject<any> = new BehaviorSubject<any>('all');
  selectedSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  nextFollowingSlotsExtensions$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  gridWrapperRef$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  gridData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lessons$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  recurringDaysAndTimes$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  recurringSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lessonKeys: Array<any> = [];

  constructor(private trHttp: TrHttpService) {
    super();
  }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getTutorAvailability(tutorId: any) {
    const formData = new FormData();
    formData.append('tutorId', tutorId);
    return this.fetch('user-availability-student', formData, HttpMethod.GET).pipe(
      map(
        (res: any) => {
          this.recurringDaysAndTimes$.next(getRecurringScheduleSlots(res.data.slots.availability));
          return res.data.slots.availability;
        }
      )
    );
  }

  removeSlotByDateTime(removeFrom$: BehaviorSubject<any>, slotToRemove: any | any[]) {
    let slots = removeFrom$.getValue();
    slots = slots.filter((slot: any) => (slot?.dateTime !== slotToRemove?.dateTime));
    removeFrom$.next(slots);
  }

  removeLessonSlots(removeFrom$: BehaviorSubject<any>, slotToRemove: any | any[]) {
    let slots = removeFrom$.getValue();
    slots = slots.filter((slot: any) => (slot?.lessonKey != slotToRemove?.lessonKey));
    removeFrom$.next(slots);
  }

  updateSelectedSlotsLessonKey(lessonKey: string, array$: BehaviorSubject<any> = this.selectedSlots$) {
    let selectedSlots = array$.getValue();

    for (let i = 0; i < selectedSlots.length; i++) {
      let selectedSlotLessonKey: any = generateUniqueKeyFromDateTime(selectedSlots[i].dateTime);
      if (selectedSlotLessonKey == lessonKey) {
        selectedSlots[i].lessonKey = lessonKey;
        break;
      }
    }
    array$.next(selectedSlots);
  }

  updateSlotStateById(id: number, state: string) {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.id === id);
      if (slotIndex !== -1) {
        slots[slotIndex].state = state;
        break;
      }
    }
  }

  updateSlotStateByLessonKey(lessonKey: string, state: string) {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.lessonKey === lessonKey);
      if (slotIndex !== -1) {
        slots[slotIndex].state = state;
        // break;
      }
    }
    setTimeout(() => {
      this.gridData$.next(slotsData)
    });
  }

  removeLessonKeyGridSlots(lessonKey: string) {
    const slotsData: Array<any> = this.gridData$.getValue();
    slotsData.forEach(obj => {
      obj.slots.forEach((slot: any) => {
        if (slot.lessonKey === lessonKey) {
          delete slot.lessonKey;
        }
      });
    });
    return slotsData;
  }

  updateGridWithTheLessonKey(id: any, lessonKey: string): void {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.id === id);
      if (slotIndex !== -1) {
        slots[slotIndex].lessonKey = lessonKey;
        break;
      }
    }
    this.gridData$.next(slotsData)
  }
}