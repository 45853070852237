<section
  class="cursor-pointer tr-card-loading-state-{{ isFetching }} border-{{lesson?.status}} tr-card-content-wrapper bg-white d-flex align-items-start align-items-lg-center justify-content-between"
  (click)="navigateTo(lesson.id, lesson.status)"
  [ngClass]="isListView ? 'tr-grid-view' : 'tr-list-view gap-3'"
>
  <div class="d-flex align-items-start tr-left-sec flex-wrap w-100">
    <div
      class="tr-lesson-card-profile d-flex align-items-start flex-wrap gap-2"
    >
      <img
        [src]="lesson?.tutorImage"
        alt="profile-img"
        class="tr-tutor-profile-img"
        *ngIf="lesson?.tutorImage"
      />
      <img
        [src]="lesson.studentImage"
        alt="profile-img"
        class="tr-tutor-profile-img"
        *ngIf="lesson?.studentImage"
      />
      <img
        src="assets/images/profile-placeholder.png"
        alt="profile-img"
        class="tr-tutor-profile-img"
        *ngIf="!lesson?.studentImage && !lesson?.tutorImage"
      />
      <div class="mb-0 tr-tutor-name d-flex">
        <div class="d-flex flex-column gap-2 min-120">
          <span *ngIf="lesson?.tutorName">{{
            lesson?.tutorName | truncate : 15
          }}</span>
          <span *ngIf="lesson.studentName">{{
            lesson?.studentName | truncate : 15
          }}</span>
          <span class="tr-tutor-title" *ngIf="userRole == 'student'">Certified tutor</span>
        </div>
        <div class="d-flex ms-3 me-md-4">
          <span class="tr-comment-img cursor-pointer" 
           (click)="preventDefaultClick($event, lesson)">
            <img src="assets/icons/mode_comment.svg" />
          </span>
          <!-- <span class="tr-call-img ps-3"><img src="assets/icons/call.svg" alt="call-icon"></span> -->
        </div>
      </div>
    </div>

    <div
      class="tr-lesson-details d-flex align-items-start"
      [ngClass]="isListView ? 'flex-column' : 'flex-wrap'"
    >
      <div
        class="mb-0 d-flex flex-column"
        [ngClass]="isListView ? 'tr-grid-view-subj' : 'tr-list-view-subj'"
        *ngIf="lesson.subjectName"
      >
        <span> Subject </span>
        <p class="mb-0">{{ lesson?.subjectName }}</p>
      </div>
      <div
        class="mb-0 d-flex tr-date-sec-1"
        [ngClass]="isListView ? 'flex-row' : 'flex-column gap-1'"
        *ngIf="lesson.lessontime"
      >
        <span>
          <li>Lesson time</li>
        </span>
        <p class="mb-0">
          {{ lesson?.lessontime | date : "EEE dd MMM" }} ,
          {{ lesson?.lessontime | date : "hh:mm a" | lowercase }},
          {{ lesson?.lessonPeriod }}mins
        </p>
      </div>
      <div
        class="mb-0 d-flex tr-date-sec-2"
        [ngClass]="isListView ? 'flex-row' : 'flex-column gap-1'"
        *ngIf="lesson.newlessontime && lesson.type != 'TUTOR_APPROVAL'"
      >
        <span>
          <li>New date</li>
        </span>
        <p class="mb-0 tr-bg-green">
          {{ lesson?.newlessontime | date : "EEE dd MMM" }} ,
          {{ lesson?.newlessontime | date : "hh:mm a" | lowercase }}
        </p>
      </div>  
      <div
        class="mb-0 d-flex tr-date-sec-2"
        [ngClass]="isListView ? 'flex-row' : 'flex-column gap-1 d-none'"
        *ngIf="lesson.requestTime"
      >
        <span>
          <li>Request date</li>
        </span>
        <p class="mb-0 tr-bg-grey">
          {{ lesson?.requestTime | date : "EEE dd MMM" }} ,
          {{ lesson?.requestTime | date : "hh:mm a" | lowercase }}
        </p>
      </div>    
      <div
        class="mb-0 d-flex tr-date-sec-2"
        [ngClass]="isListView ? 'flex-row' : 'flex-column gap-1'"
        *ngIf="lesson.communicationTool"
      >
        <span>
          <li>Communication tool</li>
        </span>
        <!--  <p class="mb-0">{{ lesson.communicationTool }}</p> -->
      </div>
    </div>
  </div>

  <div
    class="d-flex align-items-center justify-content-center gap-4"
     [ngClass]="isListView ? '' : 'position-relative'"
    class="{{ lesson?.status }}"
  >
    <p
      class="px-2 py-1 mb-0 tr-fixed-tag d-none"
      *ngIf="lesson?.type == 'TUTOR_APPROVAL' || lesson.type === 'RESCHEDULE'"
      [ngClass]="isListView ? 'd-none' : 'd-md-block tr-lesson-status-tag'"
    >
      <span  *ngIf="lesson.type === 'RESCHEDULE'"> {{ lesson.type }} </span> 
      <span *ngIf="lesson.type === 'TUTOR_APPROVAL'"> NEW LESSON </span>
    </p>    

    <p
      class="tr-lesson-status px-2 py-1 mb-0 d-none d-md-block"
      *ngIf="lesson?.status"
      [ngClass]="isListView ? 'grid-lesson-status' : 'list-lesson-status'"
    >
      <span> {{ lesson?.status }} </span>
    </p>

<!--     <p class="mb-0 position-relatve">
      <img src="assets/images/calendar-lesson.svg" alt="">
      <span>
            {{ lesson.requestTime | date : "EEE dd MMM" }} ,
          {{ lesson.requestTime | date : "hh:mm a" | lowercase }}
      </span>
    </p> -->

    <div *ngIf="lesson.requestTime" [ngClass]="isListView ? 'd-none' : 'tr-tooltip'">
        <img src="assets/images/calendar-lesson.svg" alt="">
    <span class="tr-tooltiptext">
          <span>
            Request date : 
          </span>
           {{ lesson?.requestTime | date : "EEE dd MMM, YYYY" }}
    </span>
  </div>
    <span
      [ngClass]="isListView ? 'tr-grid-view-svg' : 'tr-list-view-svg'"
      type="button"
      dropdown-toggle
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      (click)="preventDefault($event)"
      *ngIf="lesson.premissions && lesson.premissions.length > 0"
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path [attr.d]="optionsIcon" />
      </svg>
      <ul
        class="dropdown-menu tr-dropdown-menu p-3"
        aria-labelledby="custom_dropdown"
      >
        <div class="d-flex flex-column">
          <ng-container *ngFor="let permission of lesson?.premissions">
            <div
              class="tr-dropdown-options"
              (click)="getLessonAction(permission, lesson)"
            >
              <li
                class="d-flex align-items-center p-2 gap-2"
                class="{{ permission }}"
              >
                <ng-container
                  *ngIf="
                    permission == LESSON_PERMISSIONS.CANCEL ||
                    permission == LESSON_PERMISSIONS.REJECT ||
                    permission == LESSON_PERMISSIONS.CANCEL_REQ
                  "
                >
                  <tr-svg
                    [path]="cancelPath"
                    [width]="10"
                    [height]="10"
                    [viewBox]="'0 0 10 10'"
                  ></tr-svg>
                </ng-container>              
                <ng-container
                  *ngIf="
                    permission == LESSON_PERMISSIONS.ACCEPT ||
                    permission == LESSON_PERMISSIONS.COMPLETE 
                  "
                >
                  <tr-svg
                    [path]="approveIcon"
                    [width]="10"
                    [height]="10"
                    [viewBox]="'0 0 10 10'"
                  ></tr-svg>
                </ng-container>                
                <ng-container
                  *ngIf="
                    permission == LESSON_PERMISSIONS.RAISE_COMPLAINT
                  "
                >
                  <tr-svg
                    [path]="complaintIcon"
                    [width]="14"
                    [height]="14"
                    [viewBox]="'0 0 14 14'"
                  ></tr-svg>
                </ng-container>
                <ng-container
                  *ngIf="
                    permission == LESSON_PERMISSIONS.RESCHEDULE ||
                    permission == LESSON_PERMISSIONS.GIVE_NEW_TIME ||
                    permission == LESSON_PERMISSIONS.REQ_RESCHEDULE
                  "
                >
                  <tr-svg
                    [path]="eventRepeatIcon"
                    [width]="15"
                    [height]="15"
                    [viewBox]="'0 0 14 15'"
                  ></tr-svg>
                </ng-container>
                <span >
                  {{ mapPermission(permission) }}
                </span>
              </li>
            </div>
          </ng-container>
        </div>
      </ul>
    </span>
  </div>
</section>
