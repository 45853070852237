import { Component, OnInit } from '@angular/core';
import { TutorDashboardService } from '@components/tutor-components/tutor-dashboard/tutor-dashboard.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { finalize } from 'rxjs/operators';
import { getFormattedTimeAgo } from '@shared/models/messages';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tr-tutor-dashboard-latest-messages',
  templateUrl: './tutor-dashboard-latest-messages.component.html',
  styleUrls: ['./tutor-dashboard-latest-messages.component.css']
})
export class TutorDashboardLatestMessagesComponent implements OnInit {

  isLoading:boolean = false;
  latestMessages:any;
  constructor(
  private service:TutorDashboardService,
  private router: Router,
  private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.getMessages();
  }

  getMessages() {
    this.service.getUserChats()
    .pipe(finalize(() => (this.isLoading = false)))
    .subscribe(
      (res: any) => {
        let messages = res.slice(0, 3);
        this.latestMessages = messages;
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    );
  }

  formatTime(timestamp:any){
    return getFormattedTimeAgo(timestamp);
  }

  goToChat(id:any){
    this.router.navigate(['/tutor/chat/', id]);
  }

}
