<section>
  <div class="tr-sticky-div">
    <section class="pt-lg-4">
        <div class="tr-back-sec d-lg-flex d-none align-items-center gap-4">
          <div class="d-flex align-items-center gap-2 px-4">
            <h2 class="tr-title mb-0">Messages</h2>
            <span class="tr-msgs-count" *ngIf="users">({{ users.length }})</span>
          </div>
        </div>
        <div class="d-flex align-items-center d-lg-none w-100 justify-content-between tr-mb-header px-4 py-3">
          <div class="d-flex align-items-center gap-3">
            <h2 class="tr-mb-title mb-0">Messages</h2>
          </div>
          <div class="mb-btn d-flex align-items-center justify-content-center">
            <img src="assets/icons/search.svg" alt="search-img" (click)="this.showSearchBar = !this.showSearchBar">
          </div>
        </div>
        <div class="position-relative mt-3 mt-lg-4 px-4 px-lg-0 d-flex align-items-center tr-search-svg" [ngClass]="{'d-none': showSearchBar == false}">
          <input type="search" class="form-control tr-chat-search" name="search" placeholder="Search" [(ngModel)]="searchText">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!searchText || searchText == ''">
            <path [attr.d]="searchDropdownIcon" fill="#888888" />
          </svg>
      </div>
   </section>
   <section class="d-flex flex-column tr-search px-4 px-lg-0">
      <div class="mt-3 px-4">
        <div class="tr-tabs p-0 m-0 d-flex align-items-start">
          <div class="tr-bdr pe-4 cursor-pointer" (click)="getUsersWithChat()">
            <div class="pb-2 active tr-tab" [ngClass]="{'active': type === 'read'}">All</div>
          </div>
          <div class="ps-4 cursor-pointer" (click)="getUsersWithChat(true)">
            <div class="pb-2 tr-tab" [ngClass]="{'active': type === 'unread'}">Unread</div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
  <section class="px-lg-0 mt-4 mt-lg-0" *ngIf="isLoading">
    <div class="px-4">
      <tr-chat-sidemenu-skeleton-loader></tr-chat-sidemenu-skeleton-loader>
    </div>    
    <div class="mt-4 px-4">
      <tr-chat-sidemenu-skeleton-loader></tr-chat-sidemenu-skeleton-loader>
    </div>
  </section>
  <section class="tr-mb-mt-0 tr-hide d-flex flex-column tr-users-sec p-0 p-lg-0 pb-lg-0" *ngIf="!isLoading">
      <ng-container *ngIf="users && users.length == 0">
        <p class="py-2 px-4 tr-no-chat text-start">
         <img src="assets/images/no-chats-found.png" alt="no-chats" class="me-2"> 
         No chats found 
        </p>
      </ng-container>
      <ng-container *ngFor="let user of users">
        <div class="cursor-pointer py-3 tr-hover px-4" (click)="setChatId(user.id , user['participants'][0]['user'])"
        [ngClass]="{'tr-active-chat': selectedChatId == user.id}">
          <div class="tr-user-row d-flex align-items-start justify-content-between gap-3" (click)="showMenu()" *ngIf="searchText === '' || user.participants[0]['user']['fname'].toLowerCase().includes(searchText | lowercase)">
            <div class="tr-chat-details d-flex align-items-start gap-3 tr-70">
              <img [src]="user.participants[0]['user']['avatar']" alt="user-img" *ngIf="user.participants[0]['user']['avatar']">
              <span class="tr-dummy-image d-flex align-items-center justify-content-center" *ngIf="!user.participants[0]['user']['avatar']">
                {{user.participants[0]['user']['fname'].charAt(0)}}
              </span>
              <div class="tr-chat d-flex flex-column gap-1 tr-hide">
                <p class="mb-0">{{user.participants[0]['user']['fname']}}</p>
                <span *ngIf="user.latest_message">{{ user['latest_message']['body'] }}</span>
                <span *ngIf="user.messages">{{ user['messages'][0]['body'] }}</span>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center gap-1 justify-content-center">
              <span class="tr-time" *ngIf="user.latest_message">{{ formatTime(user['latest_message']['created_at']) }}</span>
              <span class="tr-time" *ngIf="user.messages">{{ formatTime(user['messages'][0]['created_at']) }}</span>
              <span class="tr-count" *ngIf="user.unread_count > 0">{{ user.unread_count }}</span>
            </div>
          </div>
        </div>
    </ng-container>
  </section>
</section>