// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GetLessonDatePipe } from '@shared/pipes/get-lesson-date.pipe';
// Components
import { SidePanelComponent } from '@components/shared-components/side-panel/side-panel.component';
import { SvgModule } from './svg.module';
import { CustomDropdownComponent } from '@components/shared-components/custom-dropdown/custom-dropdown.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LessonTypeDropdownComponent } from '@components/shared-components/lesson-type-dropdown/lesson-type-dropdown.component';
import { LessonDetailsOverviewComponent } from '@components/shared-components/lesson-details-overview/lesson-details-overview.component';
import { LessonLogHistoryComponent } from '@components/shared-components/lesson-log-history/lesson-log-history.component';
import { LessonBoardComponent } from '@components/shared-components/lesson-board/lesson-board.component';
import { LessonsActionHeaderComponent } from '@components/shared-components/lessons-action-header/lessons-action-header.component';
import { CustomPaginationComponent } from '@components/shared-components/custom-pagination/custom-pagination.component';
import { LessonCardLoaderComponent } from '@components/shared-components/lesson-card-loader/lesson-card-loader.component';
import { SkeletonImageComponent } from '@components/shared-components/skeleton/skeleton-image/skeleton-image.component';
import { DoubleLineComponent } from '@components/shared-components/skeleton/double-line/double-line.component';
import { SmallButtonComponent } from '@components/shared-components/skeleton/small-button/small-button.component';
import { SingleLineComponent } from '@components/shared-components/skeleton/single-line/single-line.component';
import { GridPaginatorComponent } from '@components/shared-components/grid-paginator/grid-paginator.component';
import { RaiseComplaintPopupComponent } from '@components/shared-components/raise-complaint-popup/raise-complaint-popup.component';
import { SubmitReviewPopupComponent } from '@components/shared-components/submit-review-popup/submit-review-popup.component';

@NgModule({
  declarations: [
    SidePanelComponent,
    CustomDropdownComponent,
    LessonTypeDropdownComponent,
    LessonDetailsOverviewComponent,
    LessonLogHistoryComponent,
    LessonBoardComponent,
    LessonsActionHeaderComponent,
    CustomPaginationComponent,
    GridPaginatorComponent,
    LessonCardLoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SvgModule,
    RaiseComplaintPopupComponent,
    SubmitReviewPopupComponent,
    NgxDropzoneModule,
    NgxSliderModule,
    GetLessonDatePipe,
    FormsModule,
    SkeletonImageComponent,
    DoubleLineComponent,
    SmallButtonComponent,
    SingleLineComponent,
    ReactiveFormsModule
  ],
  exports: [
    SidePanelComponent,
    SvgModule,
    CustomDropdownComponent,
    LessonTypeDropdownComponent,
    LessonLogHistoryComponent,
    LessonDetailsOverviewComponent,
    LessonBoardComponent,
    FormsModule,
    LessonCardLoaderComponent,
    LessonsActionHeaderComponent,
    CustomPaginationComponent,
    GridPaginatorComponent,
  ],
  providers: [
  ]
})

export class SharedModule { }