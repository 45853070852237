<ng-container *ngIf="lessons && lessons.length > 0">
	<section class="d-flex align-items-center justify-content-between tr-pb-2">
		   <h4 class="tr-main-header-title mb-0">Waiting my action</h4>
		   <div class="d-flex align-items-center gap-3 cursor-pointer tr-navigate-link">
		      <span class="tr-view-more tr-sub-title-dark-text tr-font-12" routerLink="/tutor/lessons/waiting-my-action"> view more</span>
		      <img src="assets/icons/arrow-right-black.svg" alt="back-btn-img">
		   </div>
	</section>
	<section class="d-flex flex-column gap-4" *ngIf="!isLoading">
		   <ng-container *ngFor="let lesson of lessons">
		      <tr-user-lesson-card [lesson]="lesson"></tr-user-lesson-card>
		   </ng-container>
	</section>
</ng-container>
