<section class="row p-0 m-0 tr-book-lesson-footer-wrapper">
    <div class="tr-footer-booking-section col-md-12 col-lg-12 d-flex justify-content-between align-items-center bottom-0 bg-white tr-px-2">
        <div class="d-flex justify-content-start">
            <div class="tr-book-lesson-img d-flex align-items-center justify-content-center flex-gap-12">
               <img src={{selectedTutor.getValue().profilePicture}} alt="booking-tutor-image" *ngIf="selectedTutor.getValue().profilePicture">         
               <img src="assets/images/profile-placeholder.png" alt="booking-tutor-image" *ngIf="!selectedTutor.getValue().profilePicture">
               <div>
                  <p class="mb-0 tr-booking-tutor-name">{{selectedTutor.getValue().firstName}}</p>
                  <p class="mb-0 tr-booking-tutor-spec">Certified Tutor</p>
               </div>
            </div>
         </div>
         <div class="tr-action-bar d-flex align-items-center">
            <button *ngIf="(this.bookingPhase | async) != BOOKING_PHASES.LESSON_TYPE_DETAILS"  class="back-btn tr-btn px-3 py-2 me-2" (click)="previousPhase()">
               Back
            </button>
            <button class="tr-btn next-btn px-3 py-2" [disabled]="!(validateNextButton() | async)" [ngClass]="{'disabled-button': !(validateNextButton() | async)}" (click)="nextBookingPhase()" >
               Next
            </button>
         </div>
    </div>
 </section>