<!-- footer-section-starts -->
<div class="container-fluid p-0 m-0 bgWhite">
<div class="container tr-landg-pge-footer-main-container">
   <div class="row pb-42">
      <div class="tr-landg-pge-footer-main col-md-9 col-sm-9 d-sm-flex">
         <div class="tr-landg-pge-footer-main-logo">
            <img alt="footer-logo" src="assets/logos/colored-logo-v.svg" class="footer-image" />
         </div>
         <div class="tr-landg-pge-footer-main-content">
            <div class="tr-landg-pge-footer-main-content-text font-16">Tutor Reach is an online educational platform that pairs students with private tutors remotely via online chat. It features a ranking algorithm that uses machine learning for classification and recommendation of tutors.</div>
            <div class="tr-landg-pge-footer-main-social top16">
               <a href="https://www.facebook.com/TutorReach/" target="_blank">
               <img alt="facebook-image-footer" src="assets/icons/facebook.svg" class="footer-image1" />
               </a>
               <a href="https://twitter.com/TutorReach/" target="_blank">
               <img alt="twitter-image-footer" src="assets/icons/twitter.svg" class="footer-image3" target="_blank" />
               </a>
               <a href="https://www.instagram.com/tutor_reach/?hl=en" target="_blank">
               <img alt="linkedin-image-footer" src="assets/icons/linkedin.svg" class="footer-image2" />
               </a>
            </div>
         </div>
      </div>
      <div class="col-md-3 col-sm-3 tr-landg-pge-footer-right-col mt-32">
         <p class="footer-text1" trans="Learn a new language"> Learn a new language </p>
         <a routerLink="/student/find-tutor" class="btn btn-primary tr-landg-pge-footer-right-col-btn" trans="Start Learning">Start Learning</a>
      </div>
   </div>
   <div class="row">
      <div class="col-md-3 col-sm-3 col-6 mb-32">
         <h5 class="tr-landg-pge-footer-link-heading" trans="Company">Company</h5>
         <div class="tr-landg-pge-footer-link-sec1 d-flex flex-column">
            <a
               rel=""
               class="footer-link"
               trans="About Tutor reach"
               routerLink="/student/more/about-us"
               >
            About Tutor reach
            </a>
            <a
               rel=""
               class="footer-link11"
               trans="Find A Tutor"
               routerLink="/student/find-tutor"
               >
            Find A Tutor
            </a>
            <a
               rel=""
               class="footer-link13"
               trans="Become a tutor"
               routerLink="/register"
               >
            Become a tutor
            </a>
            <a
               rel=""
               class="footer-link01"
               trans="Prices & offers"
               routerLink="/student/more/offers"
               >
            Prices & offers
            </a>
            <!-- <a
               rel=""
               class="footer-link02"
               trans="Help / FAQ"
               routerLink="/student/more/faq"
               >
               Help / FAQ
               </a> -->
            <a
               rel=""
               class="footer-link03"
               trans="Cookies policy"
               routerLink="/student/more/Cookies-Policy"
               >
            Cookies policy
            </a>
            <a
               rel=""
               class="footer-link04"
               trans="Refund policy"
               routerLink="/student/more/Refund-Policy"
               >
            Refund policy
            </a>
            <a
               rel=""
               class="footer-link05"
               trans="Privacy policy"
               routerLink="/student/more/Privacy-Policy"
               >
            Privacy policy
            </a>
         </div>
      </div>
      <div class="col-md-3 col-sm-3 col-6 mb-32">
         <h5 class="tr-landg-pge-footer-link-heading" trans="Languages">Languages</h5>
         <div class="tr-landg-pge-footer-link-sec1 d-flex flex-column">
            <a > English</a>
            <a > Arabic</a>
         </div>
      </div>
      <div class="col-md-3 col-sm-3 col-12 mb-32">
         <h5 class="tr-landg-pge-footer-link-heading" trans="More from Tutor Reach">More from Tutor Reach</h5>
         <div class="tr-landg-pge-footer-link-sec1 d-flex flex-column">
            <a
               rel=""
               class="footer-link10"
               trans="Blog / News"
               routerLink="/blog"
               >
            Blog / News
            </a>
            <a
               rel=""
               class="footer-link12"
               trans="Knowledge Base"
               target="_blank"
               href="https://support.tutorreach.com/"
               >
            Knowledge Base
            </a>
            <a
               rel=""
               class="footer-link12"
               trans="Students Support"
               target="_blank"
               href="https://support.tutorreach.com/hc/en-gb/categories/7009194702493-Students"
               >
            Students Support
            </a>
            <a
               rel=""
               class="footer-link12"
               trans="Tutors Support"
               target="_blank"
               href="https://support.tutorreach.com/hc/en-gb/categories/7009156816029-Tutors"
               >
            Tutors Support
            </a>
            <a
               rel=""
               class="footer-link12"
               trans="Submit A Support Ticket"
               target="_blank"
               href="https://support.tutorreach.com/hc/en-gb/requests/new"
               >
            Submit A Support Ticket
            </a>
            <a
               rel=""
               class="footer-link06"
               trans="Contact us"
               routerLink="/student/more/support"
               >
            Contact us
            </a>
         </div>
      </div>
      <div class="col-md-3 col-sm-3 col-12 mb-32">
         <h5 class="tr-landg-pge-footer-link-heading" trans="Early Bird Offer">Early Bird Offer</h5>
      </div>
   </div>
</div>
</div>
<!-- footer-section-ends -->
<!-- copyright section starts -->
<div class="container-fluid tr-landg-pge-copyright-fluid">
  <div class="d-sm-flex justify-content-between container">
   <div class="text-center">
      <svg viewBox="0 0 1024 1024" class="copyrights-icon">
         <path
            d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"
            ></path>
      </svg>
      <span class="tr-landg-pge-copyright-text">Copyrights 2022 All rights reserved.</span>
   </div>
   <div class="text-center">
      <span class="tr-landg-pge-copyright-text1">TUTOR REACH LTD - Company number 12990913</span>
   </div>
  </div> 
</div>
<!-- copyright section ends -->