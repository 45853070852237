import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { StudentAccountSettingsService } from '@components/student-components/student-settings/student-account-settings.service';
import { ConfirmationCodePopupComponent } from '@components/shared-components/confirmation-code-popup/confirmation-code-popup.component';
import { formValidationMessages } from 'src/app/shared/models/validation';
import { TRObject } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationServiceService } from '@components/shared-components/send-confirmation-code-popup/confirmation.service.service';
import { BookLessonService } from '@components/student-components/student-tutors/book-lesson/book-lesson.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { BOOKING_PHASES } from '@shared/models/shared';
import { AuthService } from '@core/auth/auth.service';
import { LessonDetailsService } from '@components/shared-components//lesson-details-overview/lesson-details.service';
import { finalize } from 'rxjs/operators';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';

@Component({
  selector: 'tr-student-complete-information-popup',
  standalone: true,
  imports: [CommonModule, NgxIntlTelInputModule, FormsModule, SpinnerComponent, ReactiveFormsModule, ConfirmationCodePopupComponent],
  templateUrl: './student-complete-information-popup.component.html',
  styleUrls: ['./student-complete-information-popup.component.css']
})
export class StudentCompleteInformationPopupComponent extends TRObject implements OnInit {
  @Input() type:any;
  @Input() url:any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  toggleModal: string = '';
  verifyType: any;
  verifyValue: any;
  email:any;
  isEmailVerified:boolean = false;
  ismobileVerified:boolean = false;
  mobileNumber:any
  user:any;
  isLoading:boolean = false;
  constructor(private fb: UntypedFormBuilder, 
    private bookLessonService: BookLessonService,
    private auth: AuthService,
    private lessonService: LessonDetailsService,
    private codeService: ConfirmationServiceService,
    private responseHandlerService: ResponseHandlerService,
    private service: StudentAccountSettingsService,) { super(); this.detectVerifiedChanges() }

  ngOnInit(): void {
    this.getProfile();
    this.detectSendAgainChanges();
  }


  getProfile(isUpdate?:any){
    this.service.getUserProfile().pipe()
      .subscribe(
        (res: any) => {
         if (res) {
          let user = res['data']['user'][0];
          this.user = user;
            this.isEmailVerified = user['isEmailVerified'];
            this.ismobileVerified = user['isPhoneVerified'];
            if(isUpdate){
              this.auth.user$.next(user);
            }
            this.updateInfoForm.patchValue({
              firstName: user.fname || '',
              lastName: user.lname || '',
            });
            if(user['email']){
              this.email = user['email'];
            }          
            if(user['mobile']){
              this.mobileNumber = user['mobile'];
              this.mobileUpdateForm.patchValue({
                mobileNumber: this.mobileNumber 
              });
            }
          }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  public updateInfoForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
  });

  public emailUpdateInfoForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });  

  public mobileUpdateForm = this.fb.group({
    mobileNumber: ['', [Validators.required]],
  });

  public errorMessages = {
    email: formValidationMessages['email'],
    firstName: formValidationMessages['firstName'],
    lastName: formValidationMessages['lastName'],
    countryId: formValidationMessages['countryId'],
  };

  verify(type:any){
    this.codeService.type$.next(type);
    let value;
    if(type == 'Email'){
      value  = this.email
    }
    else{
      value = this.mobileNumber['e164Number'];
      this.mobileNumber = value;
    }
    this.sendCode(type, value);
    this.codeService.value$.next(value);
  }

  closeBookingWindow() {
    if(this.type != 'attend'){
      this.bookLessonService.closeBookingWindow();
    }
    else{
      this.lessonService.lessonPhase$.next('');
      this.lessonService.enableDisablePageScroll('scroll');
    }
  }

  sendCode(type:any, value:any){
    this.isLoading = true;
    const formData = new FormData();
    let mobile = false;
    if(type == 'Email'){
      formData.append('email', this.email)
    }else{
      mobile = true;
      formData.append('mobileNumber', this.mobileNumber) 
    }
    this.service.sendVerificationCode(formData,mobile).pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
           this.codeService.toggleModal$.next('open');
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  detectVerifiedChanges() {
    this.codeService.isVerified$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((verified: any) => {
        if (verified) {
          this.getProfile(true);
        }
    });
  }  

  detectSendAgainChanges() {
    this.codeService.sendAgain$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((type: any) => {
        if (type) {
          console.log(type);
          this.verify(type);
        }
    });
  }

  goToBookingPhase(){
    if (this.updateInfoForm.valid) {
      if(this.type != 'attend'){
          this.service.updateInfo(this.updateInfoForm.value).subscribe(
            (res: any) => {
              if (res['status']) {
                this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.LESSON_TYPE_DETAILS);
              }
            },
            (error: any) => {
              this.responseHandlerService.handleFailureResponse(error);
            }
          );
      }
      else{
        this.lessonService.lessonPhase$.next('');
        this.lessonService.enableDisablePageScroll('scroll');
        let url = this.lessonService.lessonUrl$.value;
         window.open(url, "_blank");

      }
    }
  }
 

}
