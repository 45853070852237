import { Component, Input, OnInit } from '@angular/core';
import { sidePanelItems, sidePanel, subMenuItems } from 'src/app/shared/models/sidePanel';
@Component({
  selector: 'tr-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css']
})
export class SidePanelComponent implements OnInit {
  @Input() sidePanelTitle!: string;
  @Input() componentName!: string;

  sidePanelItems!: sidePanelItems[];
  public toggle_icon: string = "arrow-down";
  public isMenuOpen: boolean = true;
  constructor() { }

  ngOnInit(): void {
    this.sidePanelItems = sidePanelItems[this.componentName];
  }
  
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (!this.isMenuOpen) {
      this.toggle_icon = "arrow-down";
    }
    else {
      this.toggle_icon = "arrow_forward_ios";
    }
  }
}
