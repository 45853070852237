import { Component, OnInit, Input, Output, EventEmitter,  ElementRef, ViewChild } from '@angular/core';
import { searchDropdownIcon, activeCheckIcon, arrowDownPath } from '@shared/models/svgIcons';
import { Options } from '@angular-slider/ngx-slider';
@Component({
  selector: 'tr-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css']
})
export class CustomDropdownComponent implements OnInit {
  @ViewChild('checkboxContainer') checkboxContainer?: ElementRef;
  @Input() id: string | undefined;
  searchText: any = '';
  @Output() selectedCheckData = new EventEmitter<any>();
  @Output() selectedUnCheckData = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();
  @Input() menuTitle?: string;
  @Input() title?: string;
  @Input() targetList?: string;
  @Input() isCheckbox?: boolean = true;
  dropDownMenuValue: any;
  dropDownMenuValueLength: number = 0;
  currencyCode?: string;
  searchDropdownIcon: string = searchDropdownIcon;
  arrowDownPath: string = arrowDownPath;
  activeCheckIcon: string = activeCheckIcon;
  preferredMenu: any = false;
  @Input() type?: string;
  @Input() isCurrency: boolean = false;

  @Input()
  public set dropDownMenuData(value: any) {
    if (value) {
      this.dropDownMenuValue = value;
    }
  }   

  @Input()
  public set clearCheckbox(value: any) {
    if (value) {
      this.clearKey(this.targetList);
    }
  } 


  minValue: number = 0;
  maxValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '£' + value;
    }
  };


  constructor() { }

  ngOnInit(): void {
  }

  onDropdownButtonClick(event: Event): void {
    // Prevent the event from propagating further
    event.stopPropagation();

    // Your dropdown button click logic here
  }

  setSelectedData(data: any, name:any, event: any) {
    let selectedData = { "data": data, "name": name, "targetList": this.targetList, "id": this.id }
    if (event) {
      let isCheck = event.target['checked'];
      if (isCheck) {
        this.selectedCheckData.emit(selectedData);
      }
      else {
        this.selectedUnCheckData.emit(selectedData);
      }
    }
    else {
      this.currencyCode = data;
      this.selectedCheckData.emit(selectedData);
    }
  }

  clearAll(key:any){
    if(this.checkboxContainer){
      const checkboxes = this.checkboxContainer.nativeElement.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox: HTMLInputElement) => {
        checkbox.checked = false;
      });
    }
    this.clear.emit(key);
  }  

  clearKey(key:any){
    if(this.checkboxContainer){
      const checkboxes = this.checkboxContainer.nativeElement.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox: HTMLInputElement) => {
        checkbox.checked = false;
      });
    }
  }

  valueChange(min: any, max: any) {
    let data: Array<number> = [];
    data.push(min, max);
    this.selectedCheckData.emit(data);
  }

}