import { Component, OnInit } from '@angular/core';
import { TutorProfileService } from '../tutor-profile.service';
import { TRObject } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { favSavedTutorIcon, arrowDownPath, lessonBookPath } from 'src/app/shared/models/svgIcons';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'tr-suggested-tutors',
  templateUrl: './suggested-tutors.component.html',
  styleUrls: ['./suggested-tutors.component.css']
})
export class SuggestedTutorsComponent extends TRObject implements OnInit {
  tutors:any;
  arrowDownPath: string = arrowDownPath;
  lessonBookPath: string = lessonBookPath;
  
  constructor(public tutorProfileService:TutorProfileService, public auth: AuthService) { 
   super();
  }

  ngOnInit(): void {
    this.tutorProfileService.suggestedTutors$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data) {
            this.tutors = data;
        }
    });
  }

  openBookingWindow(tutor: any) {
    this.tutorProfileService.openBookingWindow$.next(tutor);
  }

}
