import { Component, OnInit } from '@angular/core';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
@Component({
  selector: 'tr-tutor-register',
  templateUrl: './tutor-register.component.html',
  styleUrls: ['./tutor-register.component.css']
})

export class TutorRegisterComponent implements OnInit {
  currentPhase$: any;
  constructor(private tutorRegPhasesService: TutorRegPhasesService) {
    this.currentPhase$ = this.tutorRegPhasesService.phases$;
  }

  ngOnInit(): void {
  }

}
