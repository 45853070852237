import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tr-landing-page-section',
  templateUrl: './landing-page-section.component.html',
  styleUrls: ['./landing-page-section.component.css']
})
export class LandingPageSectionComponent implements OnInit {
  @Input() sectionName: string = ""
  constructor() { }

  ngOnInit(): void {
  }

}
