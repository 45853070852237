import { Component } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import { Observable, Subject, throwError, of, BehaviorSubject, Subscription } from 'rxjs';
import { TRObject } from '../shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent extends TRObject {

  constructor(public authService: AuthService, private router: Router,) {
    super();
  }

  ngOnInit() {

  }

}
