import { Component, ElementRef, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { CurrentUserService } from '@auth/current-user.service';
import { TRObject } from '@shared/models/shared';
import { headerIcons } from 'src/app/shared/models/svgIcons';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from './header.service';
import { finalize } from 'rxjs/operators';
import { getFormattedTimeAgo } from '@shared/models/messages';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { PusherService } from 'src/app/shared/services/pusher.service';

@Component({
  selector: 'tr-in-app-header',
  templateUrl: './in-app-header.component.html',
  styleUrls: ['./in-app-header.component.css']
})

export class InAppHeaderComponent extends TRObject implements OnInit {
  @ViewChild('headerWrapper') headerWrapper!: ElementRef;
  favPath: string = '';
  keyPath: string = '';
  notificationPath: string = '';
  messagePath: string = '';
  headerIcons: Array<any> = headerIcons;
  user:any;
  userRole:any;
  userNotifications:any;
  userMessages:any;
  userTheme:any = 'light';

  constructor(private router: Router, private translateService: TranslateService, 
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private headerService: HeaderService,
    private pusherService: PusherService,
    @SkipSelf() private authService: AuthService) {
    super();
    this.detectThemeChanges();
    this.userChanges();
    this.notifyNewNotification();
    this.notifyNewMessage();
  }

  ngOnInit(): void {
    document.getElementById("headerWrapper")?.style.setProperty('--in-app-header-l', '--in-app-header-d');
    this.loadSvgs();
  }

  loadSvgs() {
    for (let elem of headerIcons) {
      if (elem.name == 'favoriteSvg') {
        this.favPath = elem.path;
      }
      if (elem.name == 'keySvg') {
        this.keyPath = elem.path;
      }
      else if (elem.name == 'notificationSvg') {
        this.notificationPath = elem.path;
      }
      else if (elem.name == 'messageSvg') {
        this.messagePath = elem.path;
      }
    }
  }

  NavigateToChat() {
    // Call the navigate method to navigate to the chat route
    this.router.navigate(['chat'], { relativeTo: this.route });
  }

  getNotifications(){
    this.headerService
      .getNotifications()
      .pipe(finalize(() => ('')))
      .subscribe(
        (res: any) => {
          this.userNotifications = res['data'];
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

  getMessages(){
    this.headerService
      .getUserChats()
      .pipe(finalize(() => ('')))
      .subscribe(
        (res: any) => {
          this.userMessages = res;
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

  formatTime(timestamp:any) {
    return getFormattedTimeAgo(timestamp);
  }


  detectThemeChanges(){
    this.authService.userTheme$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((theme: any) => {
        if (theme) {
          this.userTheme = theme;
        }
    });
  }

  userChanges(){
    this.authService.user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: any) => {
        if (user) {
            this.user = user;
            this.userRole = this.user['active_role'];
            this.getNotifications();
            this.getMessages();
        }
    });
  }

  notifyNewNotification(){
    const channelName = `notifications-user-${this.authService.userId$.value}`;
    this.pusherService.subscribeToChannel(channelName, 'new-notification', (notification:any) => {
      if(notification){
        this.userNotifications.push(notification);
      }
    });
  }  

  notifyNewMessage(){
    const channelName = `message-user-${this.authService.userId$.value}`;
    this.pusherService.subscribeToChannel(channelName, 'new-message', (message:any) => {
      if(message){
        const convertedMessage = this.convertToMessagesFormat(message); 
        const chatIndex = this.userMessages.findIndex((chat:any) => chat.latest_message.chat_id === convertedMessage.latest_message.chat_id);
        if (chatIndex !== -1) {
          this.userMessages[chatIndex].latest_message = convertedMessage.latest_message;
          this.userMessages.length = this.userMessages.length + 1;
        }  
        else{
          this.userMessages.unshift(convertedMessage); 
        }
      }
    });
  }

  convertToMessagesFormat(message: any) {
    return {
      id: message.chat_id,
      type: "direct",
      reference_id: null,
      created_at: message.created_at,
      updated_at: message.updated_at,
      participants_count: 2,
      unread_count: 0,
      participants: [
        {
          user_id: message.user.id,
          chat_id: message.chat_id,
          access_type: "guest",
          user: {
            id: message.user.id,
            avatar: message.user.avatar,
            fname: message.user.fname,
            lname: message.user.lname,
            username: message.user.username
          }
        }
      ],
      latest_message: {
        id: message.id,
        chat_id: message.chat_id,
        user_id: message.user_id,
        body: message.body,
        warning: 0,
        created_at: message.created_at,
        updated_at: message.updated_at
      }
    };
  }

}
