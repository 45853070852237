<main>
  <section class="d-flex">
    <div class="tr-chat-left-sec position-relative">
       <tr-chat-users-sidemenu (toggleMenu)="toggleConversation($event)" (usersLength)="getUsersLength($event)" [ngClass]="'tr-left-sec-chat'"></tr-chat-users-sidemenu>
    </div>
    <div class="tr-right-sec position-relative" [ngClass]="{'show-chat': showConversation == true}">
      <section *ngIf="usersLength <= 0" class="d-flex align-items-center justify-content-center tr-no-chat-sec flex-column gap-2">
          <div class="d-flex align-items-center justify-content-center no-user">
              <img src="assets/images/no-chat.svg" alt="no-chat">
          </div>
          <div class="rounded-pill">
            <span>There is no messages yet</span>
          </div>
        </section>
      <ng-container *ngIf="usersLength > 0">
          <tr-chat-conversation (toggleMenu)="toggleConversation($event)"></tr-chat-conversation>
      </ng-container>
    </div>
  </section>
</main>