import { Component, OnInit } from '@angular/core';
import { ToastEventIcons, ToastEventTypes } from 'src/app/shared/models/notifications';
import { ToastService } from '@components/shared-components/toast.service';
@Component({
  selector: 'tr-my-tutors',
  templateUrl: './my-tutors.component.html',
  styleUrls: ['./my-tutors.component.css']
})
export class MyTutorsComponent implements OnInit {

  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
  }
}
