import { Component, OnInit, Input } from '@angular/core';
import { TutorProfileService } from '../tutor-profile.service';
import { finalize } from "rxjs/operators";
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";


@Component({
  selector: 'tr-tutor-intro',
  templateUrl: './tutor-intro.component.html',
  styleUrls: ['./tutor-intro.component.css']
})
export class TutorIntroComponent implements OnInit {
  @Input() tutor:any;
  constructor(    
    private tutorProfileService:TutorProfileService,
    private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
  }

  updateTutorFavFlag(isFav: boolean) {
    this.tutor.isFav = !isFav
  }

  toggleTutorFavorite(id: any, isFav: any) {
    this.tutorProfileService.toggleTutorFavorite(id, isFav).subscribe(
      (res: any) => {
        if (res.status) {
          this.responseHandlerService.handleSuccessRessponse(res['message']);
          this.updateTutorFavFlag(isFav)
        }
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

}
