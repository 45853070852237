<main class="tr-tutor-register-container container-fluid d-flex justify-content-center">
   <div class="tr-tutor-reg-wrapper col-md-8 col-lg-8 top30 bottom50 d-flex align-items-center justify-content-center">
    <div class="container mt-5 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                <div>
                <img src="assets/icons/application-review.svg" alt="review" class="tr-review-img">
                </div>
                <h1 class="mt-4 tr-tutor-reg-agree-statment">Your Application is under review</h1>
                <p class="lead tr-tutor-reg-policy-title">We are processing your application. Please be patient while we review your submission.</p>
                <button class="mt-2 tr-btn tr-btn-outline-primary" routerLink="/login"> Go back to home page</button>
            </div>
        </div>
    </div>
 </div>
</main>
