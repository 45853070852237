import { Component, OnInit, Input } from '@angular/core';
import { ToastEventIcons, ToastEventTypes } from 'src/app/shared/models/notifications';

@Component({
  selector: 'tr-form-notification',
  templateUrl: './form-notification.component.html',
  styleUrls: ['./form-notification.component.css']
})
export class FormNotificationComponent implements OnInit {

  @Input()
  type!: ToastEventTypes;

  @Input()
  icon!: ToastEventIcons;
  
  @Input()
  message!: string; 

  @Input()
  showAlert:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showNotificationAlert(message:string, type:string){
    this.showAlert = true;    
    this.message = message;
    switch (type) {
      case "Error":
            this.type = ToastEventTypes.Error;
            this.icon = ToastEventIcons.Error;
         break;
      case "InfoLight":
            this.type = ToastEventTypes.Info_Light;
            this.icon = ToastEventIcons.Info_Light; 
         break;
      case "Info":
            this.type = ToastEventTypes.Info;
            this.icon = ToastEventIcons.Info; 
         break;       
      case "Warning":
            this.type = ToastEventTypes.Warning;
            this.icon = ToastEventIcons.Warning;
         break;       
      case "Success":
            this.type = ToastEventTypes.Success;
            this.icon = ToastEventIcons.Success;
         break;       
      case "Success_Light":
            this.type = ToastEventTypes.Success_Light;
            this.icon = ToastEventIcons.Success_Light;
         break;     
      default:
         this.showAlert = false;
         break;
       }
  }
  

  hideAlert(){
    this.showAlert = false;
  }

}
