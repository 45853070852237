import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropModalComponent } from '@components/shared-components/crop-modal/crop-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';



@NgModule({
  declarations: [CropModalComponent],
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  exports:[
    CropModalComponent
  ]
})
export class CropModule { }
