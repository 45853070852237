import { Component, OnInit } from '@angular/core';
// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'tr-lesson-communication-tool',
  templateUrl: './lesson-communication-tool.component.html',
  styleUrls: ['./lesson-communication-tool.component.css']
})
export class LessonCommunicationToolComponent implements OnInit {
  // CountryISO: any = CountryISO;
  // PhoneNumberFormat: any = PhoneNumberFormat;
  // SearchCountryField: any = SearchCountryField;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor() { }

  ngOnInit(): void {
  }

}
