<div class="container-fluid">

    <div class="tr-landg-pge-header row px-2 py-3 d-flex align-items-center justify-content-center">
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 mb-0">
            <img src="../../../../assets/logos/logo-with-name-vertical.svg" alt="">
        </div>
        <div class="tr-landg-pge-header-menu-sectioncol-md-9 col-lg-9">
            <ul class="tr-landg-pge-header-menu-ul mb-0">
                <li>Home</li>
                <li>Find a tutor</li>
                <li>Blog</li>
                <li routerLink="/register-tutor" class="cursor-pointer">Become a tutor</li>
                <li class="cursor-pointer" routerLink="/login">Login / <span routerLink="/register-student" class="cursor-pointer">Register</span> </li>
                <li>EN </li>
            </ul>
            <div class="tr-landg-pge-header-menu-mobile col-xs-8 col-sm-8">
                <div class="tr-burger-menu"> <img src="../../../../assets/icons/burger-menu.svg" alt=""> </div>
                <div class="tr-login-register-mobile"> <span></span> <img src="../../../../assets/icons/login-icon.svg" alt=""> Login / Register </div>
            </div>
    </div>
</div>