export const environment = Object.freeze({
  production: false,
  envName: 'development',
  baseURL: 'https://v2tr-sandbox.tutorreach.com/api/',
  configPath: '',
  pusher: {
    appKey: '998a70d160802bfa9d6f',
    cluster: 'eu',
  },
  googleClientId:
    '922300755796-0t16pcisft5o7n5c0i0ekaf0fctgk3nl.apps.googleusercontent.com',
  stripePublishableKey:
    'pk_test_51IEGKsK3rPHVSfHLLSRnAd9CGdjqH0F1FIzZ3RKN0gaVLPO9wkcP606Q5aHOApnyuhApGp80P3gqOHkrbkJmYgY400IPWwXlp2',
});
