import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SendMessagePopupService {

  private userDetailsSubject = new Subject<any>();
  userDetails$ = this.userDetailsSubject.asObservable();


  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  sendMessage(form:any){
    return this.fetchCore('send-chat-message-from-popup', form, HttpMethod.POST)
  }

   sendUserDetails(userDetails: any) {
    this.userDetailsSubject.next(userDetails);
  }

}
