import { Component, OnInit } from '@angular/core';
import { TutorDashboardService } from '../tutor-dashboard.service';
import { finalize } from "rxjs/operators";
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'tr-tutor-dashboard-recent-review',
  templateUrl: './tutor-dashboard-recent-review.component.html',
  styleUrls: ['./tutor-dashboard-recent-review.component.css']
})
export class TutorDashboardRecentReviewComponent implements OnInit {
  isLoading:boolean = false;
  reviews:any;
  perPage:any = 3

  constructor(private service:TutorDashboardService, 
    private authService: AuthService,
    private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.getUserReviews();
  }

  getUserReviews() {
    let user = this.authService.user$.value;
    let userId = user['id'];
    this.isLoading = true;
    const formData = new FormData();
    formData.append('tutorId', userId);
    formData.append('perPage', this.perPage);
    this.service.getTutorReviews(formData).pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          if(res){
            this.reviews = res['data'];
          } 
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
    }

}
