import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment-timezone';
import { SchedulerNextComponent } from './scheduler-next.component';
import { SchedulerNextExtendPromptComponent } from './scheduler-next-extend-prompt/scheduler-next-extend-prompt.component';
import { ShortDayPipe } from '../../core/pipes/short-day.pipe';
import { SchedulerNextEditModalComponent } from './scheduler-next-edit-modal/scheduler-next-edit-modal.component';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    SchedulerNextComponent,
    SchedulerNextExtendPromptComponent,
    SchedulerNextEditModalComponent,
    ShortDayPipe,
  ],
  imports: [
    CommonModule,
    SpinnerComponent,
    FormsModule,
    // BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
  ],
  exports: [
    SchedulerNextComponent,
    SchedulerNextExtendPromptComponent,
    SchedulerNextEditModalComponent,
  ],
})
export class SchedulerNextModule {
  getSchedulerNextComponents() {
    return SchedulerNextComponent;
  }
}
