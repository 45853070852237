import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderService } from '@components/core-components/in-app-header/header.service';

@Directive({
  selector: '[trCurrency]',
  standalone: true
})

export class CurrencyDirective {
  currencySymbol:any = 'E';
  private subscription!: Subscription;
  constructor(private el: ElementRef,
  private headerService: HeaderService) { }
    ngOnInit() {
      this.subscription = this.headerService.currencySymbol$.subscribe((currencySymbol) => {
        this.el.nativeElement.textContent = currencySymbol || '£'; // Display Euro if symbol is empty
      });
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
