<main>
  <div class="tr-student-payment-success-wrapper">
    <div
      class="tr-student-payment-success-header d-flex justify-content-between p-4"
    >
      <span class="tr-student-payment-success-header-title w-100"
        >{{ title}} Success</span
      >
<!--       <img (click)="closePopup()" src="assets/icons/close-icon.svg" alt="close-icon" class="cursor-pointer"/> -->
    </div>

    <div class="tr-student-booking-payment-success-details-wrapper top30">
      <div class="row trMarginAuto justify-content-center">
        <div class="tr-payment-details-container col-md-4 text-center">
          <div class="tr-payment-success-details-header col-md-12 top20">
            <img
              class="tr-payment-success-details-icon"
              src="assets/icons/payments/payment-success.svg"
              alt=""
            />
            <p class="tr-payment-success-details-main-title mb-1 top20">
              {{ title }} Success !
            </p>
            <p class="tr-payment-success-details-info">
              Your {{ title }} has been successfully done
            </p>
          </div>
          <hr class="tr-dashed top35"/>

          <div class="tr-payment-success-body col-md-12">
            <div class="row trMarginAuto justify-content-center">
              <div class="col-md-7">
                <p class="tr-payment-success-body-title text-start">Payment Details</p>

                <div class="tr-payment-success-body">
                  <div class="tr-payment-success-detail d-flex justify-content-center">
                    <div class="row trMarginAuto justify-content-center">

                        <p class="col-md-12 d-flex justify-content-between text-start mb-1">
                          <span class="col-md-5 tr-payment-success-detail-labels">Date</span>
                          <span class="col-md-5 tr-payment-success-detail-values">{{ _paymentDetails.dateTime | date : "EEE dd MMM" }}</span>
                        </p>

                        <p class="col-md-12 d-flex justify-content-between text-start mb-1">
                          <span class="col-md-5 tr-payment-success-detail-labels">Time</span>
                          <span class="col-md-5 tr-payment-success-detail-values">
                              {{ _paymentDetails.dateTime | date : "hh:mm a" | lowercase }}
                          </span>
        
                        </p>

<!--                         <p class="col-md-12 d-flex justify-content-between text-start mb-1">
                          <span class="col-md-5 tr-payment-success-detail-labels">Via</span>
                          <span class="col-md-5 tr-payment-success-detail-values">{{_paymentDetails.via}}</span>
                        </p> -->

                        <hr class="mb-2"/>

                        <p class="col-md-12 d-flex justify-content-between text-start">
                          <span class="col-md-5 tr-payment-success-detail-amount-label">Amount</span>
                          <span class="col-md-5 tr-payment-success-detail-amount-value">{{_paymentDetails.amount}} GBP</span>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row  justify-content-center bottom50 top20">
                <div class="col-md-9 tr-payment-success-view-lessons cursor-pointer" (click)="redirectToLessons()">
                    View my lessons
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</main>
