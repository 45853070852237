import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastEventTypes, ToastEvent } from 'src/app/shared/models/notifications';
import { ToastService } from '../toast.service';
@Component({
  selector: 'tr-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToasterComponent implements OnInit {
  currentToast: ToastEvent | null = null;

  constructor(private toastService: ToastService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscribeToToasts();
  }
  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toast) => {
      this.currentToast = toast;
      this.cdr.detectChanges();
    });
  }

  dispose() {
    this.currentToast = null;
    this.cdr.detectChanges();
  }
}
