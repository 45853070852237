export enum API_RESPONSE {
    SUCCESS = 200,
    FORBIDDEN = 403,
    NOT_FOUND = 401,
    INTERNAL_ERROR = 500,
}

export const passRules: any = [
    { "text": "Be a minimum of 6 characters", "icon": "not-checked.svg" },
    { "text": "Include one numeric digit (0-9)", "icon": "not-checked.svg" },
    { "text": "Include one uppercase letter (A-Z)", "icon": "not-checked.svg" },
    { "text": "Include one special character (@-!)", "icon": "not-checked.svg" }
]

export function checkValidationStatuses(isDisabledCreateAcc: any, validationStatuses: any) {
    isDisabledCreateAcc = (validationStatuses.minLength && validationStatuses.upperCase && validationStatuses.number && validationStatuses.specialCharacter) ? false : true;
    return validationStatuses.minLength && validationStatuses.upperCase && validationStatuses.number && validationStatuses.specialCharacter;
}

export function validatePassword(password: string = '') {
    let validationStatuses: any = {
        minLength: false,
        upperCase: false,
        number: false,
        // lowerCase: false,
        specialCharacter: false
    };

    //validate min length
    if (password.length >= 6) {
        validationStatuses.minLength = true;
    }
    passRules[0].icon = validationStatuses.minLength ? "checked-icon.svg" : "not-checked.svg"


    //validate number
    if (password.match(/(?=.*\d)/)) {
        validationStatuses.number = true;
    }
    passRules[1].icon = validationStatuses.number ? "checked-icon.svg" : "not-checked.svg"


    //validate capital letter
    if (password.match(/[A-Z]/)) {
        validationStatuses.upperCase = true;
    }
    passRules[2].icon = validationStatuses.upperCase ? "checked-icon.svg" : "not-checked.svg"


    //validate special character
    if (password.match(/(?=(.*\W)).*$/)) {
        validationStatuses.specialCharacter = true;
    }
    passRules[3].icon = validationStatuses.specialCharacter ? "checked-icon.svg" : "not-checked.svg"


    //validate letter
    // if (password.match(/[a-z]/)) {
    // validationStatuses.lowerCase = true;
    // }

    validationStatuses = validationStatuses;
    return { "isValid": validationStatuses.minLength && validationStatuses.upperCase && validationStatuses.number && validationStatuses.specialCharacter, "validationStatuses": validationStatuses };
}

export const formValidationMessages = {
    email: [
        { type: 'required', message: 'Email is required' },
        { type: 'email', message: 'Enter valid Mail' }
    ],
    firstName: [
        { type: 'required', message: 'First name is required' },
    ],
    lastName: [
        { type: 'required', message: 'Last name is required' },
    ],
    mobileNumber: [
        { type: 'required', message: 'Mobile number is required' },
    ],
    gender: [
        { type: 'required', message: 'Gender is required' },
    ],
    pricePerHour: [
        { type: 'required', message: 'Price per hour is required' },
    ],
    nationalId_img: [
        { type: 'required', message: 'National Id image is required' },
    ],
    countryId: [
        { type: 'required', message: 'Country is required' },
    ],
    langId: [
        { type: 'required', message: 'Language is required' },
    ],
    profId: [
        { type: 'required', message: 'Proficiency is required' },
    ],
    userName: [
        { type: 'required', message: 'Username is required' },
    ],
    profilePicture: [
        { type: 'required', message: 'Profile picture is required' },
    ],
    videoLink: [
        { type: 'required', message: 'Video link is required' },
    ],
    about: [
        { type: 'required', message: 'About is required' },
    ],
    subjectId: [
        { type: 'required', message: 'Subject is required' },
    ],
    specialistId: [
        { type: 'required', message: 'Specialities is required' },
    ],
    ageId: [
        { type: 'required', message: 'Age is required' },
    ],
    fromDate: [
        { type: 'required', message: 'From date is required' },
    ],
    toDate: [
        { type: 'required', message: 'To date is required' },
    ],
    university: [
        { type: 'required', message: 'University is required' },
    ],
    degree: [
        { type: 'required', message: 'Degree is required' },
    ],
    major: [
        { type: 'required', message: 'Major/Topic is required' },
    ],
    certificate: [
        { type: 'required', message: 'Certificate is required' },
    ],
    issuedBy: [
        { type: 'required', message: 'Issuedby is required' },
    ],
    company: [
        { type: 'required', message: 'Company is required' },
    ],
    position: [
        { type: 'required', message: 'Position is required' },
    ],
    attachment: [
        { type: 'required', message: 'Attachment is required' },
    ],
    description: [
        { type: 'required', message: 'Description is required' },
    ],
    price: [
        { type: 'required', message: 'Price is required' },
    ],
    totalLessons: [
        { type: 'required', message: 'Number of lessons is required' },
        { type: 'min', message: 'Minimum number of lessons is 1' }
    ],    
    expirationFrom: [
        { type: 'required', message: 'Expired date from is required' },
    ],      
    expirationTo: [
        { type: 'required', message: 'Expired date to is required' },
    ],      
    reasonId: [
        { type: 'required', message: 'Reason is required' },
    ],     
    note: [
        { type: 'required', message: 'Reason description is required' },
    ],    
    discount: [
        { type: 'required', message: 'Discount percentage is required' },
        { type: 'max', message: 'Max discount percentage is 100%' }
    ],
}

export function validateObjKey(key: string, obj: any) {
    if (key in obj) {
        const value = obj[key];
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return value !== null && value !== undefined && value !== '';
    }
    return false;
}

