import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef  } from '@angular/core';
import { FormDataService } from 'src/app/core/form-data.service';
import { UntypedFormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@components/shared-components/toast.service';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { finalize } from 'rxjs/operators';
import { formValidationMessages } from 'src/app/shared/models/validation';

@Component({
  selector: 'tr-add-education-popup',
  templateUrl: './add-education-popup.component.html',
  styleUrls: ['./add-education-popup.component.css']
})
export class AddEducationPopupComponent implements OnInit {
  @ViewChild('closeModal') closeModal?: ElementRef;
  @Output() onChangeStatus = new EventEmitter();
  file: any;
  universities:any;
  degrees:any;
  disable:boolean = false;

  constructor(
    private formDataService: FormDataService, 
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private tutorRegPhasesService: TutorRegPhasesService ) 
  { 
    this.universities = this.formDataService.universities$; 
    this.degrees = this.formDataService.degrees$;
    console.log(this.degrees);
  }

  public errorMessages = {
    yearsOfStudyFrom: formValidationMessages['fromDate'],
    yearsOfStudyTo: formValidationMessages['toDate'],
    universityId: formValidationMessages['university'],
    attachment: formValidationMessages['attachment'],
    degreeId: formValidationMessages['degree'],
    major: formValidationMessages['major'],
  }

  public addEducationForm = this.fb.group({
    yearsOfStudyFrom: ['', Validators.required],
    yearsOfStudyTo: ['', Validators.required],
    universityId: ['',Validators.required],
    attachment: [''],
    degreeId: ['', Validators.required],
    major: ['', Validators.required],
  });

  ngOnInit(): void {
  }

  educationImage(e: any) {
    this.file = e.addedFiles;
  }

  onRemove(event: any) {
    this.file.splice(this.file.indexOf(event), 1);
  }

  clearForm(){
    this.addEducationForm.reset();
    this.file = [];
  }


  addEducation(){
    this.disable = true;
    this.addEducationForm.get('attachment')?.setValue(this.file[0]);
    this.tutorRegPhasesService.registerEducation(this.addEducationForm.value).pipe(finalize(() => this.disable = false)).subscribe(
      (data: any) => {
        if(data.status){
          this.onChangeStatus.emit('success');
          this.closeModal?.nativeElement.click();
          this.toastService.showSuccessToast('Success !', data.message);
        }
      },
      (error: any) => {
       this.toastService.showErrorToast('Error!', error.message);
      }
    )
  }

}
