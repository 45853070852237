import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})

export class CardViewService {
  isListView$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  constructor(private formData: FormDataService) {
  }
}
