<div class="tr-in-app-header container-fluid d-flex align-items-center p-0 m-0 d-none d-xl-flex" id="headerWrapper">
  <div class="tr-in-app-content-wrapper d-flex align-items-center justify-content-between">
    <div class="tr-in-app-left-content tr-left-content-img">
      <img [src]="userTheme !== 'dark' ? 'assets/logos/colored-logo-h.svg' : 'assets/logos/logo-white.svg'" class="trMarginAuto tr-header-logo" alt="colored-logo" />
    </div>
    <div class="tr-in-app-right-content d-flex align-items-center" *ngIf="userRole && userRole != 'guest'">
      <!--       <select class="form-select form-select-lg mb-0" aria-label=".form-select-lg example" style="font-size: 12px;max-width: 110px;" (change)="onLangSubject($event)"><option value="en">english</option><option value="ar">arabic</option><option value="fr">french</option></select> -->
      <div class="tr-in-app-fav d-flex me-4">
        <tr-svg [path]="keyPath"></tr-svg>
      </div>
      <div class="tr-in-app-fav d-flex me-4 cursor-pointer" *ngIf="userRole === 'student'" routerLink="/student/tutors/my-tutors/saved-tutors">
        <tr-svg [path]="favPath"></tr-svg>
      </div>
      <div class="tr-in-app-notifications d-flex me-4 cursor-pointer" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
        <tr-svg [path]="notificationPath"></tr-svg>
        <p class="mb-0 tr-notification-count d-flex align-items-center justify-content-center">
          <span>{{ userNotifications?.length }}</span>
        </p>
        <div class="dropdown-menu dropdown-menu-lg-end tr-custom-menu pb-0" aria-labelledby="defaultDropdown">
          <div>
            <div class="px-3 tr-top-sec d-flex flex-column justify-content-center">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="tr-sub-title mb-0">Notifications</h3>
                <img src="assets/images/settings.svg" alt="settings-img" class="cursor-pointer" routerLink="settings/notifications" />
              </div>
              <span class="tr-text-lite">You have {{ userNotifications?.length }} new notifications</span>
            </div>
            <section class="tr-mt-custom">
              <div *ngFor="let notification of userNotifications; let i = index" [ngClass]="{'tr-bg-approved': i % 2 == 0}">
                <div class="tr-notification px-3 py-4 d-flex align-items-center justify-content-between gap-4" routerLink="alerts">
                  <div class="d-flex gap-2">
                    <img [src]="notification.userImage" alt="img" class="tr-noti-img" *ngIf="notification.userImage" />
                    <img src="assets/images/profile-placeholder.png" alt="img" class="tr-noti-img" *ngIf="!notification.userImage" />
                    <div class="d-flex flex-column gap-2">
                      <span class="tr-status-tag tr-{{notification.action}}"> {{ notification.action }}</span>
                      <div class="tr-text-lite">
                        {{ notification.content}}
                      </div>
                    </div>
                  </div>
                  <span class="tr-text-lite tr-space"> {{ formatTime(notification.createdAt) }}</span>
                </div>
              </div>
            </section>
            <section class="tr-dropdown-bottom-sec cursor-pointer" routerLink="alerts">
              <div class="py-3 d-flex align-items-center justify-content-center tr-bdt">
                <span class="tr-text-dark text-center"> View all notifications</span>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="tr-in-app-messages-notifications d-flex me-4">
        <tr-svg [path]="messagePath" type="button" data-bs-toggle="dropdown" aria-expanded="false"></tr-svg>
        <p class="mb-0 tr-notification-count d-flex align-items-center justify-content-center">
          <span> {{ userMessages?.length }}</span>
        </p>
        <ng-container>
          <div class="dropdown-menu dropdown-menu-lg-end tr-custom-menu pb-0" aria-labelledby="defaultDropdown">
            <div>
              <div class="px-3 tr-top-sec d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center justify-content-between">
                  <h3 class="tr-sub-title mb-0">Messages</h3>
                </div>
                <span class="tr-text-lite">You have {{ userMessages?.length }} new message</span>
              </div>
              <section class="tr-mt-custom">
                <div>
                  <div class="tr-notification p-3 d-flex align-items-center justify-content-between gap-4" routerLink="chat/{{message.id}}" *ngFor="let message of userMessages; let i = index">
                    <div class="d-flex gap-2">
                      <img *ngIf="message['participants'][0]['user']['avatar']" [src]="message['participants'][0]['user']['avatar']" alt="img" class="tr-noti-img" />
                      <img *ngIf="!message['participants'][0]['user']['avatar']" src="assets/images/profile-placeholder.png" alt="img" class="tr-noti-img" />
                      <div class="d-flex flex-column gap-2">
                        <div class="tr-text-lite">
                          <mark class="tr-text-dark">
                            {{ message['participants'][0]['user']['fname'] }}</mark>
                        </div>
                        <span class="tr-text-lite">{{ message['latest_message'].body }} </span>
                      </div>
                    </div>
                    <span class="tr-text-lite tr-space"> {{ formatTime(message['latest_message']['created_at']) }} </span>
                  </div>
                </div>
              </section>
              <section class="tr-dropdown-bottom-sec" routerLink="chat">
                <div class="py-3 d-flex align-items-center justify-content-center tr-bdt">
                  <span class="tr-text-dark text-center"> View all messages</span>
                </div>
              </section>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="tr-in-app-messages-profile me-4" *ngIf="user">
        <p class="tr-header-user-icon mb-0 d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
          <span class="tr-header-user-font" *ngIf="user['name']">{{ user['name'].charAt(0) }}</span>
        </p>
        <ul class="dropdown-menu tr-custom-menu-sm pt-0" aria-labelledby="defaultDropdown">
          <div>
            <div class="d-flex align-items-center justify-content-center flex-column p-2 px-4 tr-bg-grey">
              <img src="{{ user?.avatar }}" alt="user-img" class="tr-user-img" *ngIf="user.avatar">
              <img src="assets/images/profile-placeholder.png" alt="placeholder-image" class="tr-user-img" *ngIf="!user.avatar">
              <p class="mb-0 tr-sub-title-lite-text tr-font-12 mt-2">Hello <span class="tr-sub-title-dark-text tr-font-12" *ngIf="user.name"> {{ user?.name }}</span>
              </p>
            </div>
            <a class="tr-hover d-flex align-items-center p-3" *ngIf="userRole != 'student' " routerLink="/tutor/settings/tutor-profile">
              <img src="assets/icons/in-app-header/account_circle.svg" alt="account_circle"> View profile </a>
            <a class="tr-hover d-flex align-items-center p-3" routerLink="settings">
              <img src="assets/images/settings.svg" alt="settings"> Settings </a>
            <!--                 <a class="tr-hover d-flex align-items-center p-3" *ngIf="userRole === 'student' "><img src="assets/icons/in-app-header/become-tutor.svg" alt="become-tutor">
                  Become a tutor
                </a>  -->
            <a class="tr-hover d-flex align-items-center p-3" routerLink="settings/referrals">
              <img src="assets/icons/in-app-header/redeem.svg" alt="redeem"> Refer a friend </a>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>