import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';
import { BookLessonService } from '@components/student-components/student-tutors/book-lesson/book-lesson.service';
import { TutorProfileService } from './../tutor-profile.service';
import { AuthService } from '@core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tr-tutor-profile-video',
  templateUrl: './tutor-profile-video.component.html',
  styleUrls: ['./tutor-profile-video.component.css']
})
export class TutorProfileVideoComponent implements OnInit {
  @Output() playVideoStatus = new EventEmitter();
  videoLink:any;
  videoId:any;
  tutorVideoThumbnail:any;
  user = this.authService.user$.value;
  @Input() tutor:any;
  @Input()
  public set videoLinkUrl(value:any) {
    this.videoLink = value;
    if (this.videoLink) {
        this.setVideo();
    }
  }
  constructor(private messageService:SendMessagePopupService,
    private tutorProfileService:TutorProfileService,
    public authService:AuthService,
     private router: Router
   ) { }

  ngOnInit(): void {
    console.log(this.tutor);
  }

  setVideo(){
     var link = this.videoLink;
     var removeEmbed = link.replace('embed/','');
     var videoid = removeEmbed.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        this.videoId = videoid[1];
        var url = "https://www.youtube.com/embed/" + videoid[1];
        this.tutorVideoThumbnail = "https://img.youtube.com/vi/" + videoid[1] + "/0.jpg";
      }
   
  }

  playVideo(){
    this.playVideoStatus.emit(true);
  }

  sendMessage(id:any , name:any, logo:any){
    let tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    console.log(tutorData);
    this.messageService.sendUserDetails(tutorData);
  }

  openBookingWindow(tutor: any) {
    let user = this.authService.user$.value;
    if(user && user['id']){
       this.tutorProfileService.openBookingWindow$.next(tutor);
    }else{
      sessionStorage.setItem('tutorData', JSON.stringify(tutor));
      this.router.navigate(['/login']);
    }
  } 

}
