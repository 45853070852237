import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { searchDropdownIcon } from "@shared/models/svgIcons";
import { ChatService } from '@components/shared-components/chat/chat.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
import { getFormattedTimeAgo } from '@shared/models/messages';
import { AuthService } from '@auth/auth.service';
import { PusherService } from 'src/app/shared/services/pusher.service';

@Component({
  selector: 'tr-chat-users-sidemenu',
  templateUrl: './chat-users-sidemenu.component.html',
  styleUrls: ['./chat-users-sidemenu.component.css']
})

export class ChatUsersSidemenuComponent implements OnInit {
  @Output() toggleMenu = new EventEmitter<any>();
  @Output() usersLength = new EventEmitter<any>();
  searchDropdownIcon: string = searchDropdownIcon;
  isLoading:boolean = true;
  users:any;
  showSearchBar:boolean = false;
  type:string = 'read';
  searchText:string = '';
  selectedUser:any;
  selectedChatId:any;
  constructor(
  private chatService:ChatService, 
  private activatedRoute: ActivatedRoute,
  private router: Router,
  private pusherService: PusherService,
  private authService: AuthService,
  private responseHandlerService: ResponseHandlerService) 
  {  this.notifyNewMessage() }

  ngOnInit(): void {
   this.activatedRoute.params.subscribe((params) => {
    const id = params['id'];
      if (id) {
        this.getUsersWithChat(false , id);
      }

     else{
       this.getUsersWithChat();
    }
    });
  }

  getUsersWithChat(isUnread?:boolean, id?:any){
    this.isLoading = true;
    this.chatService.sideMenuLoading$.next(true);
    this.type = isUnread ? 'unread' : 'read';
    this.chatService.getUserChats(isUnread)
      .pipe(finalize(() => (this.makeLoadingFalse())))
      .subscribe(
        (res: any) => {
          this.users = res;
          this.usersLength.emit(this.users.length);
          if(this.users && this.users.length > 0){
            if(id){
              this.navigateChatById(id);
            }
            else{
              this.setChatId(this.users[0]['id'], this.users[0]['participants'][0]['user']);
            }
          }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  navigateChatById(id:any){
   const matchingChat = this.users.find((chat:any) => chat.id == id);
    if(matchingChat){
      this.setChatId(matchingChat.id, matchingChat['participants'][0]['user']);
    }
    else{
     const currentUrl = this.router.routerState.snapshot.url;
       if (currentUrl.startsWith('/student/chat')) {
          this.router.navigate(['/student/chat']);
        } else if (currentUrl.startsWith('/tutor/chat')) {
          this.router.navigate(['/tutor/chat']);
        }
     }
  }

  makeLoadingFalse(){
    this.isLoading = false;
    this.chatService.sideMenuLoading$.next(false);
  }

  formatTime(timestamp:any) {
    return getFormattedTimeAgo(timestamp);
  }

   showMenu(){
    this.toggleMenu.emit(true);
  }

  setChatId(id:any , user?:any){
    this.selectedChatId = id;
    if(user){
       this.selectedUser = user;
    }
    this.chatService.selectedChatUser$.next(this.selectedUser);
    this.chatService.chatId$.next(id);
  }

  notifyNewMessage(){
    const channelName = `message-user-${this.authService.userId$.value}`;
    this.pusherService.subscribeToChannel(channelName, 'new-message', (message:any) => {
      if(message){
        const convertedMessage = this.convertToMessagesFormat(message); 
        const chatIndex = this.users.findIndex((chat:any) => chat.latest_message.chat_id === convertedMessage.latest_message.chat_id);
        if (chatIndex !== -1) {
          // Replace the latest_message of the matching chat with the new message
          this.users[chatIndex].latest_message = convertedMessage.latest_message;
        }  
      }
    });
  }

  convertToMessagesFormat(message: any) {
    return {
      id: message.chat_id,
      type: "direct",
      reference_id: null,
      created_at: message.created_at,
      updated_at: message.updated_at,
      participants_count: 2,
      unread_count: 0,
      participants: [
        {
          user_id: message.user.id,
          chat_id: message.chat_id,
          access_type: "guest",
          user: {
            id: message.user.id,
            avatar: message.user.avatar,
            fname: message.user.fname,
            lname: message.user.lname,
            username: message.user.username
          }
        }
      ],
      latest_message: {
        id: message.id,
        chat_id: message.chat_id,
        user_id: message.user_id,
        body: message.body,
        warning: 0,
        created_at: message.created_at,
        updated_at: message.updated_at
      }
    };
  }

}