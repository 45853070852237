import { Component, OnInit, SkipSelf } from '@angular/core';
import { ROLES } from '@shared/models/shared';
import { ReschedulerService } from './rescheduler.service';
import { BehaviorSubject } from 'rxjs';
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';

@Component({
  selector: 'tr-lesson-rescheduler',
  templateUrl: './lesson-rescheduler.component.html',
  styleUrls: ['./lesson-rescheduler.component.css']
})
export class LessonReschedulerComponent implements OnInit {
  tutorData:any;
  constructor(@SkipSelf() public userLessonsService: UserLessonsService, 
    public schedulerNextService: SchedulerNextService, 
    public reschedulerService: ReschedulerService) {
    
  }

  ngOnInit(): void {
    this.tutorData = this.reschedulerService.lessonTimeAndPeriod$.getValue();
    this.reschedulerService.rescheduleType$.next(this.userLessonsService.lessonAction$.getValue());
  }

  closeLessonRescheduler() {
    this.userLessonsService.lessonAction$.next('close');
    this.reschedulerService.resetRescheduler();
    this.schedulerNextService.selectedSlots$.next([]);
  }

}
