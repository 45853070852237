<div class="toaster position-fixed end-0 px-3 py-2 m-16">
      <div *ngIf="currentToast">
        <tr-toast
          [type]="currentToast.type"
          [icon]="currentToast.icon"
          [title]="currentToast.title"
          [message]="currentToast.message"
          (disposeEvent)="dispose()"
        ></tr-toast>
      </div>
</div>
  