import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-lesson-card-loader',
  templateUrl: './lesson-card-loader.component.html',
  styleUrls: ['./lesson-card-loader.component.css']
})
export class LessonCardLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
