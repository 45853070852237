import { Component, OnInit, ViewChild } from '@angular/core';
import { sidenavItems } from 'src/app/shared/models/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { sidePanelItems, sidePanel, subMenuItems, sidePanelItemsOnHover } from 'src/app/shared/models/sidePanel';
import { SidePanelComponent } from '@components/shared-components/side-panel/side-panel.component';
import { sideNavItemNames } from '@shared/models/shared';
import { studentMobileMenuItems, tutorMobileMenuItems } from '@shared/models/sidenav';

@Component({
  selector: 'tr-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})

export class SideNavComponent implements OnInit {
  @ViewChild(SidePanelComponent) sidePanelChild!: SidePanelComponent;
  sidePanelItems!: sidePanelItems[];
  mobileMenuItems:any;
  sidePanelItemsLength: number = 0;
  selectedId: any = '';
  selectedSubMenu: boolean = false;
  items = sidenavItems[this.authService.currentRole$.getValue()];
  
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    let userRole = this.authService.currentRole$.getValue();
    if(userRole == 'student'){
      this.mobileMenuItems = studentMobileMenuItems;
    }
    else if(userRole == 'tutor'){
      this.mobileMenuItems = tutorMobileMenuItems
    }
  }

  openSubmenu(id: any) {
    this.selectedId = id;
    this.sidePanelItems = sidePanelItemsOnHover[this.selectedId];
    if (this.sidePanelItems) {
      this.sidePanelItemsLength = this.sidePanelItems.length;
    }
    else {
      this.sidePanelItemsLength = 0;
    }
    this.sidePanelChild.sidePanelTitle = this.selectedId;
    this.sidePanelChild.sidePanelItems = this.sidePanelItems;
  }

}