import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { rateIcon } from '@shared/models/svgIcons';

@Component({
  selector: 'tr-submit-review-popup',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './submit-review-popup.component.html',
  styleUrls: ['./submit-review-popup.component.css']
})
export class SubmitReviewPopupComponent implements OnInit {

  description:any;
  userRating = 0;
  @ViewChild('closeModal') closeModal?: ElementRef;
  @Output() reviewData = new EventEmitter<{ description: any, userRating: number }>();
  @Input()
  set modalStatus(val: any) {
    this.closeModal?.nativeElement.click();
  }
  rateIcon:string = rateIcon;
  constructor() { }

  ngOnInit(): void {}

  submitReview(){
    if(this.description && this.userRating > 0 ){
       const data = { description: this.description, userRating: this.userRating };
       this.reviewData.emit(data);
    }
  }

  resetData(){
    this.description = null;
    this.userRating = 0;
  }

}
