<main class="row trMarginAuto">
   <div class="col-lg-12">
      <div class="row tr-main-row d-flex justify-content-lg-between">
         <div class="col-12 p-0 mx-0 tr-tutor-tile-bg p-3  mb-4 position-relative" [ngClass]="{'tr-tutor-tile-grid': !(isListView |async)}">
            <div class="row p-0 m-0">
               <div class="col-md-9" [ngClass]="{'col-md-12': !(isListView |async)}">
                  <div class="tr-tutor-img-div bottom20 me-3 float-start cursor-pointer" [routerLink]="(authService.currentRole$ | async) !== 'guest' ? '/student/tutor/' + tutor.id : '/find-tutor/tutor/' + tutor.id">
                     <span class="tr-tutor-online" *ngIf="tutor.online != 0"></span>
                     <img src="{{tutor.profilePicture}}" alt="" class=" tr-tutr-img" [ngClass]="{'tr-tutr-grid-img': !(isListView |async)}" *ngIf="tutor.profilePicture">
                     <img src="assets/images/profile-placeholder.png" alt="" class=" tr-tutr-img" [ngClass]="{'tr-tutr-grid-img': !(isListView |async)}" *ngIf="!tutor.profilePicture">
                     <div class="d-flex justify-content-sm-center top16">
                        <span class="tr-top-tutor d-flex align-items-start align-items-sm-center justify-content-sm-center" *ngIf="tutor?.topTutor"> Top Tutor</span>
                     </div>
                  </div>
                  <div class="tr-tutor-main p-1">
                     <div class="d-flex tr-mx-50">
                        <h6 class="mb-0 me-3 tr-tutor-title cursor-pointer" [ngClass]="{'tr-tutr-grid-name': !(isListView |async)}" [routerLink]="(authService.currentRole$ | async) !== 'guest' ? '/student/tutor/' + tutor.id : '/find-tutor/tutor/' + tutor.id">{{tutor.firstName}} {{tutor.lastName}}
                        </h6>
                        <span class="fi" [ngClass]="'fi-' + tutor.flag"></span>
                     </div>
                     <div class="top10 d-flex">
                        <span class="tr-certified me-4">Certified tutor</span>
                        <div class="d-flex align-items-center"> 
                           <img src="assets/icons/rate.svg" alt="rate-star" class="tr-tutor-star me-1"> 
                           <span class="tr-rate"> {{tutor.averageRate}} </span>
                        </div>
                     </div>
                     <div class="top10">
                        <div class="d-flex align-items-center">
                           <span class="me-0 tr-tutor-book-svg d-flex align-items-center">
                              <tr-svg [path]="lessonBookPath"></tr-svg>
                           </span>
                           <div class="d-flex align-items-center flex-wrap">
                              <span class="me-1 tr-tutor-subject" *ngFor="let subject of tutor.subjects">
                              {{subject.subjectName}}
                              </span>
                           </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                           <span class="tr-tutor-speaks me-1">Speaks: 
                           </span>
                           <div class="d-flex align-items-center flex-wrap h-25">
                              <p class="mb-0 tr-lang-spec me-2" *ngFor="let language of spokenLanguages | slice:0:2 "> 
                                 {{ language?.languageName }}
                                 <span class="tr-lang-level ms-1">( {{ language.levelName }} )</span>
                              </p>
                              <span class="tr-tutor-subj-count d-flex align-items-center justify-content-center" *ngIf="tutor.spokenLanguages && tutor.spokenLanguages.length - 2 > 0">
                              +{{tutor.spokenLanguages.length - 2}}
                              </span>
                           </div>
                        </div>
                        <div class="top10 d-flex flex-column flex-wrap" [ngClass]="{'w-100': !(isListView | async)}">
                           <p class="tr-des mb-0 w-100" [ngClass]="{'tr-hide-text': !tutorAboutExpanded}" [innerHTML]="removePTags(tutor?.about)">
                            
                           </p>
                           <span class="tr-read" [innerText]="tutorAboutExpanded?'Read Less':'Read more'" (click)="tutorAboutExpanded = !tutorAboutExpanded">
                           </span>
                         <!--   <p class="tr-des-lessons top10 mb-0">Tutor has completed <span class="tr-des-read">2,747</span> lessons.</p> -->
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-3 col-12 d-flex flex-column justify-content-end p-0 " [ngClass]="{'col-md-12': !(isListView |async)}">
                  
                  <img [src]="'assets/icons/heart' + (tutor?.isFav ?? '-fav' ? '-fav' : '-not-fav') + '.svg'" alt="heart-image" class="tr-heart" (click)="toggleTutorFavorite(tutor.id,tutor?.isFav)">
                  <div class="d-flex justify-content-center flex-column p-2" [ngClass]="{'tr-top16': !(isListView |async)}">
                     <p class="mb-0 tr-price text-start text-md-start d-flex flex-column gap-2">
                        <span class="tr-price-main"> <span trCurrency></span> {{tutor.pricePerHour }} </span>
                        <span class="tr-highlight-sm">Lessons Start From</span>
                     </p>
                      <a class="btn btn-primary tr-btn top16 d-flex align-items-center justify-content-center" (click)="openBookingWindow(tutor)">
                          <ng-container *ngIf="this.user?.isTrialAvailable && !tutor?.hadLesson; else elseBlock">
                            Book trial lesson
                          </ng-container>
                          <ng-template #elseBlock>
                            Book a lesson
                          </ng-template>
                        </a>
                       <div class="row p-0 m-0 mt-3">
                        <div class="col-12 p-0 m-0 ">
                           <div class="row p-0 m-0  d-flex justify-content-between">
                              <div class="col-12 p-0 m-0" *ngIf="role === 'student'">
                                 <a class="w-40 btn btn-outline-primary tr-btn tr-tutor-msg-btn d-flex align-items-center justify-content-center" (click)="sendMessage(tutor.id, tutor.firstName, tutor.profilePicture)"> Message</a>
                              </div>
<!--                               <div class="col-5 p-0 m-0">
                                 <a class="w-40 btn btn-outline-primary tr-tutor-call-btn d-flex align-items-center justify-content-center">
                                    Call 
                                    <span class="tr-call-arrow d-flex align-items-center">
                                       <tr-svg [path]="arrowDownPath"></tr-svg>
                                    </span>
                                 </a>
                              </div> -->
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</main>
