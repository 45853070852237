<div class="tr-tutor-register-container container-fluid d-flex justify-content-center">
    <div class="tr-tutor-reg-wrapper col-md-6 col-lg-6 top20 bottom50">
        <div class="row top30">
            <div class="tr-tutor-reg-main-title col-md-12 col-lg-12 text-center">Tutor registration</div>
        </div>

        <div class="row top20">
            <div class="tr-tutor-reg-label col-md-12 col-lg-12 text-center bottom15">
                Register as
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 col-lg-12 d-flex justify-content-center">
                <div class="col-md-2 col-lg-2 d-flex justify-content-center">
                    <div class="col-md-4 col-lg-4 d-flex justify-content-end tr-register-student-tab cursor-pointer" routerLink="/register-student">Student</div>
                    <div class="col-md-4 col-lg-4 d-flex justify-content-center tr-register-tabs-splitter top15"><span></span></div>
                    <div class="col-md-4 col-lg-5 d-flex justify-content-center tr-register-tutor-tab">Tutor</div>
                </div>
            </div>
        </div>

<!--        <div class="tr-padding-2">
            <tr-register-with></tr-register-with>
        </div>

        <div class="row">
            <div class="tr-tutor-reg-splitter-wrapper col-md-10 col-lg-10 text-center">
                <span>or</span>
            </div>
        </div> -->
        
        <form [formGroup]="phaseOneForm">
        <div class="row tr-tutor-reg-input d-flex justify-content-center top20">
            <div class="col-md-11 col-lg-11 col-11">
                <label class="col-md-2 col-lg-2 d-flex justify-content-start">Email</label>
            </div>

            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start top5">
                <input class="col-md-12 col-lg-12 col-12 ps-3" placeholder="Email" type="text" formControlName="email"/>
            </div>
            <div class="col-md-11 top5" *ngFor= "let error of errorMessages.email">
               <ng-container *ngIf="phaseOneForm.controls['email'].hasError(error.type) && (phaseOneForm.controls['email'].touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>

            <div class="col-md-11 top5" *ngIf="phaseOneForm.get('email')?.errors?.serverError">
                <span class="error-message"> {{ phaseOneForm.get('email')?.errors?.serverError }} </span>
            </div>
        </div>  

        <div class="row tr-tutor-reg-input d-flex justify-content-center top30">
            <div class="col-md-11 col-lg-11 col-11">
                <label class="col-md-2 col-lg-2 d-flex justify-content-start">Password</label>
            </div>

            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start top5">
                <input #password (input)="getPass(password.value)" class="col-md-12 col-lg-12 col-12 ps-3" placeholder="Password" type="{{ showPassword ? 'text' : 'password' }}"  autocomplete="new-password" formControlName="password"/>
                 <img src="assets/images/password_icon.svg" alt="password" class="tr-view-pass-img cursor-pointer" (click)="this.showPassword = !this.showPassword">
            </div>

            <div class="col-md-11 top5" *ngIf="phaseOneForm.get('password')?.errors?.serverError">
                <span class="error-message"> {{ phaseOneForm.get('password')?.errors?.serverError }} </span>
            </div>
        </div>

        <div class="row d-flex justify-content-center top20">
            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start">
                <label class="tr-reg-password-criteria col-md-12 col-lg-12">
                    Password must:
                </label>
            </div>
        </div>
            <div class="row d-flex justify-content-center">
                <div class="col-md-11 col-lg-11 col-11 top10 tr-reg-password-criteria-items">
                    <ng-container *ngFor="let rule of passRules">
                        <label class="col-md-12 col-lg-12"> <img src="../../../assets/icons/{{rule.icon}}" alt="validate-img" class="me-2">{{rule.text}}</label><br>
                    </ng-container>
                </div>
            </div>
    
            <div class="row d-flex justify-content-center">
                <div class="col-md-12 col-lg-12 col-11 text-center tr-reg-privacy-policy d-md-flex justify-content-center top30 bottom15">
                    By creating an account, you agree to tutor reach's <span class="left5 tr-reg-privacy-link"> terms of service </span> <span class="left5"> and</span> <span class="left5 tr-reg-privacy-link"> privacy policy</span>
                </div>
            </div>
    
            <div class="row d-flex justify-content-center top30">
                <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start">
                    <button class="tr-student-reg-btn col-md-12 col-lg-12 col-12 bottom30 tr-btn"  [disabled]="(!this.isPassValid || !phaseOneForm.valid)" (click)="registerPhaseOne()">
                      <span *ngIf="!isLoading">Create an account</span>
                      <tr-spinner *ngIf="isLoading"></tr-spinner>
                   </button>
                </div>
            </div>
        </form>
        
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="col-md-12 col-lg-12 tr-tutor-reg-footer d-flex align-items-center d-flex justify-content-center">
                    Already have an account? <span class="tr-tutor-reg-footer-login-switch left5 cursor-pointer" routerLink="/login">Login</span>
                </div>
            </div>
        </div>

    </div>
</div>