import { Component, OnInit, ViewChild } from '@angular/core';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { scrollTop } from '@shared/models/shared';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastService } from '@components/shared-components/toast.service';
import { Phases } from '@shared//models/shared';
import { phaseFoureducationIcon, verifiedBadgeIcon} from '@shared/models/svgIcons';
import { FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';
import { deleteIconPath } from '@shared/models/svgIcons';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-tutor-reg-save-phase-five',
  templateUrl: './tutor-reg-save-phase-five.component.html',
  styleUrls: ['./tutor-reg-save-phase-five.component.css']
})
export class TutorRegSavePhaseFiveComponent implements OnInit {

  @ViewChild('notificationAlertChild') notificationAlertChild?: FormNotificationComponent;
  phaseFoureducationIcon:string = phaseFoureducationIcon;
  verifiedBadgeIcon:string = verifiedBadgeIcon;
  infoMessage:string = 'You can always add or change the following later in your profile account settings';
  deleteIconPath:string = deleteIconPath;
  certificates:any;
  educations:any;
  experiences:any;
  isLoading:boolean = false;
  constructor(private tutorRegPhasesService: TutorRegPhasesService, private toastService: ToastService, private fb: UntypedFormBuilder, private router: Router) { }


  ngOnInit(): void {
    this.fillPhaseFiveData();
  }

  ngAfterViewInit() {
    scrollTop();
    setTimeout(() => {this.setNotificationAlert()},0);
  }

  setNotificationAlert(){
    this.notificationAlertChild?.showNotificationAlert(this.infoMessage, 'InfoLight');

  }

  fillPhaseFiveData(){
    this.tutorRegPhasesService.phaseFiveFormData$.subscribe(
      (res) => {
        if (res) {
          let userData = res['user'];
          if(userData){
            this.addPhaseFiveData(userData);
          }
        }
      },
      (error: any) => {
         this.handleResponseException(error);
      }
    )
  }

  getPhaseFiveData(){
    this.tutorRegPhasesService.getPhaseFiveFormData().subscribe(
      (res) => {
        if (res.status) {
          let userData = res.data['user'];
          if(userData){
            this.addPhaseFiveData(userData);
          }
        }
      },
      (error: any) => {
         this.handleResponseException(error);
      }
    )
  }
  
  addPhaseFiveData(data:any){
    this.educations = JSON.parse(data['educations']);
    this.certificates = JSON.parse(data['certificates']);
    this.experiences =  JSON.parse(data['workExperiences']);

  }

  backToPrevPhase() {
    this.isLoading = true;
    this.tutorRegPhasesService.getPhaseFourFormData().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.status) {
          this.handleRegPhaseFourSuccess(res['message']);
          this.tutorRegPhasesService.phaseFourFormData$.next(res.data)
          this.tutorRegPhasesService.setPhase(Phases.PHASE_FOUR)
        }
      },
      (error: any) => {
         this.handleResponseException(error);
      }
    )
  }

  nextPhase(save:boolean) {
    this.isLoading = true;
    this.tutorRegPhasesService.registerPhaseFive().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        if (data.status) {
            this.handleRegPhaseFourSuccess(data['message']);
          if(save){
            this.tutorRegPhasesService.setPhase(Phases.PHASE_SIX)
          }
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }


  handleRegPhaseFourSuccess(res:any) {
    this.toastService.showSuccessToast('Success !', res);
  }

  handleRegPhaseFourFailure(res:any) {
    this.toastService.showErrorToast('Error!', res);
  }

  handleResponse(res:any){
    this.handleRegPhaseFourSuccess(res['message']);
    this.getPhaseFiveData();
  }

  public handleResponseException(error:any){
   if(error instanceof HttpErrorResponse){
      if(error.status === 422){
        const validationErrors = error.error['errors'];
          Object.keys(validationErrors).forEach(prop => {
          this.handleRegPhaseFourFailure(validationErrors[prop]); 
        });
      }
    }
    else{
      this.handleRegPhaseFourFailure(error.message); 
    }
  }

  deleteRecordById(id:number, type:string){
     this.tutorRegPhasesService.deleteRecordById(id,type)?.subscribe(
          (res) => {
            this.handleResponse(res);
          },
          (error: any) => {
             this.handleResponseException(error);
          }
        )
    }

}
