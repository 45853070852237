import { Component, OnInit, SkipSelf, Input } from '@angular/core';
import { arrowLeftPath, arrowRightPath } from '@shared/models/svgIcons';
import * as moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { SLOT_STATE, getSchedulerDays, toggleSlotBackgroundOnHover, extractDateTime, removeDataLessonAttributes, getDayName, getDateAndTime, isWithinFirstWeek } from '@shared/models/scheduler';
import { ToastService } from "@components/shared-components/toast.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { CommonModule } from '@angular/common';
import { TimeFormatPipe } from '@core/pipes/time-format.pipe';
import { AddTimePipe } from '@core/pipes/add-time.pipe';
import { SpinnerComponent } from '@components/shared-components/spinner/spinner.component';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';



@Component({
  selector: 'tr-lessons-of-the-day',
  standalone: true,
  imports: [CommonModule, TimeFormatPipe, AddTimePipe, SpinnerComponent],
  templateUrl: './lessons-of-the-day.component.html',
  styleUrls: ['./lessons-of-the-day.component.css']
})
export class LessonsOfTheDayComponent implements OnInit {
  @Input() userType: string = 'student';
  arrowLeftPath: string = arrowLeftPath;
  arrowRightPath: string = arrowRightPath;
  startOff$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  current7days: any = [];
  activeDay: any
  lessonsCount!: number;
  isLoading:boolean = false;
  lessons: any;
  constructor(private userLessonsService: UserLessonsService, 
    private messageService:SendMessagePopupService,
    private toastService: ToastService) {
    this.startOff$.next(moment().format('YYYY-MM-DD'));
    this.current7days = getSchedulerDays(this.startOff$.getValue());
  }

  ngOnInit(): void {
    this.activeDay = this.current7days[0]['completeDate'];
    this.getAllLessons();
  }

  getNext7Days() {
    let currentWeekLastDayIndex = this.current7days.length - 1;
    let currentWeekLastDay = moment(this.current7days[currentWeekLastDayIndex].completeDate).add(1, 'days');
    this.current7days = getSchedulerDays(currentWeekLastDay);
    this.startOff$.next(currentWeekLastDay);
  }

  getPrev7Days() {
    let currentWeekLastDay = moment(this.current7days[0].completeDate).subtract(7, 'days');
    this.current7days = getSchedulerDays(currentWeekLastDay);
    this.startOff$.next(currentWeekLastDay);

  }

  getUpdatedDate(date: string): string {
    const updatedDate = date.slice(0, 10) + ' 23:59';
    return updatedDate;
  }

  getAllLessons() {
    this.isLoading = true;
    this.userLessonsService
      .filterLessons(this.getParams())
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (res: any) => {
          this.lessons = res['data'];
          this.lessonsCount = this.lessons.length;
        },
        (error: any) => {
          this.handleFailureResponse(error);
        }
      );
  }

  getFormattedDates(): string {
    const firstDate = new Date(this.current7days[0].completeDate);
    const lastDate = new Date(this.current7days[this.current7days.length - 1].completeDate);

    if (firstDate.getMonth() === lastDate.getMonth()) {
      // If the months are the same, show only one date
      return firstDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    } else {
      // If the months are different, show both dates
      return (
        firstDate.toLocaleString('default', { month: 'short' }) +
        ' - ' +
        lastDate.toLocaleString('default', { month: 'short', year: 'numeric' })
      );
    }
  }


  getLessonsBydate(date: any) {
    this.activeDay = date;
    this.getAllLessons();
  }


  handleSuccessRessponse(res: any) {
    this.toastService.showSuccessToast("Success !", res);
  }

  handleFailureResponse(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422) {
        this.toastService.showErrorToast("Error!", error["error"].message);
      }
    } else {
      this.toastService.showErrorToast("Error!", error.message);
    }
  }

  sendMessage(id:any , name:any, logo:any){
    let tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    this.messageService.sendUserDetails(tutorData);
  }

  getParams(params?: any): any {
    return params = {
      lessonStatus: params?.lessonStatus || 1,
      // subjectId: 2,//params?.subjectId,
      sortBy: 'date', //'date',//params?.sortBy,
      sortByType: 'ASC',
      startDateTime: this.activeDay,//params?.startDateTime,
      endDateTime: this.getUpdatedDate(this.activeDay),//params?.endDateTime,
      userType: this.userType,
      perPage: 10,
      // requestStatus: '2',
      // searchBy: params?.searchBy
    }
  }

}

