import { Component, OnInit } from '@angular/core';
import { StudentDashboardService } from '@components/student-components/student-dashboard/student-dashboard.service';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";


@Component({
  selector: 'tr-student-dashboard-waiting-my-action',
  templateUrl: './student-dashboard-waiting-my-action.component.html',
  styleUrls: ['./student-dashboard-waiting-my-action.component.css']
})
export class StudentDashboardWaitingMyActionComponent implements OnInit {
  lessons!:any;
  isLoading:boolean = true;

  constructor(private service:StudentDashboardService , private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {

    this.getAllPendingRequests();
  }

  getAllPendingRequests() {
    this.isLoading = true;
    this.service
      .filterLessons(this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.lessons = res["data"];
          console.log(this.lessons);
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
    }

  getParams(params?: any): any  {
    return (params = {
      perPage: 3,
      page: 1,
      lessonStatus: 1,
      tab: 'waitingAction',
      sortByType:'ASC',
      userType: "student",
    });
  }


}
