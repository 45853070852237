<section class="tr-padding-2 tr-bg-highlight">
  <div class="row p-0 m-0">
    <div class="col-sm-8 px-0">
      <section class="d-flex gap-3 flex-wrap">
        <div>
          <img src="assets/images/profile-placeholder.png" alt="placeholder-image" class="tr-tutor-img" *ngIf="!tutor.profilePicture">
          <img [src]="tutor?.profilePicture" alt="" class="tr-tutor-img" *ngIf="tutor.profilePicture">
        </div>
        <div class="tr-tutor-main d-flex flex-column gap-1">
          <div class="d-flex">
            <h6 class="mb-0 me-3 tr-tutor-title">{{ tutor?.firstName }} {{ tutor?.lastName }}</h6>
            <span class="fi" [ngClass]="'fi-' + tutor?.flag"></span>
          </div>
          <span class="tr-sub-title">Professional tutor</span>
          <div class="d-flex align-items-center gap-2 lh-0">
            <img src="assets/images/book-open-2.svg" alt="book-open-2">
            <div>
               <span class="tr-bold-text" *ngFor="let subject of tutor?.subjects; let last = last">
              {{ subject.subjectName }}
              <ng-container *ngIf="!last">,</ng-container>
            </span>
            </div>
          </div>
          <div class="d-flex align-items-center gap-2 lh-0">
            <span class="tr-sub-title">Speaks:</span>
            <div class="d-flex gap-2">
              <span class="tr-bold-text" *ngFor="let language of tutor?.spokenLanguages; let last = last">{{ language?.languageName }} <span class="tr-lang-tag">({{ language?.levelName }})</span>
              </span>
            </div>
          </div>
          <!-- <p class="mb-0 tr-des-lessons">Tutor has completed <span>2,474</span> lessons</p> -->
        </div>
      </section>
    </div>
    <div class="col-sm-4 px-0 mt-3 mt-sm-0 d-flex justify-content-sm-end">
    	<div>
<!-- 	    	<span class="tr-bg-grey p-2" type="button" data-bs-toggle="modal" data-bs-target="#raiseComplaintModal"> Raise complaint <img  src="assets/icons/complaint-flag.svg" alt="complaint-flag" class="ms-2"> </span> -->
	    	<span class="tr-bg-grey p-2 cursor-pointer">
	    		<img [src]="'assets/icons/heart' + (tutor?.isFav ?? '-fav' ? '-fav' : '-not-fav') + '.svg'" alt="heart-image" class="tr-heart" (click)="toggleTutorFavorite(tutor.id,tutor?.isFav)">
	    	</span>
    	</div>
    </div>
  </div>
</section>