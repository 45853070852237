<section class="row p-0 m-0">
  <div class="col-md-12 px-0 d-flex align-items-centertr-tutor-reg-header-label tr-py-2">
    <div class="p-0 text-center tr-title w-100">
     {{ getTitleForBookingPhase(bookingPhase | async) }}
    </div>
    <div class="tr-close-window cursor-pointer" (click)="closeBookingWindow()">
      <img src="assets/icons/close-icon.svg" alt="close-icon">
    </div>
  </div>
  <div class="col-md-12 px-0 col-lg-12 d-flex justify-content-center position-relative">
    <div class="col-10 col-sm-6 col-xl-4">
      <div class="progress-container">
        <div class="progress tr-{{ bookingPhase | async }}" id="progress"></div>
        <div class="circle" [ngClass]="{'active': (bookingPhase | async) != 'bookLessonTypeDetails'}">
          <img src="assets/icons/done.svg" alt="done-icon">
        </div>
        <div class="circle" [ngClass]="{ 'active': (bookingPhase | async) !== 'bookLessonTypeDetails' && (bookingPhase | async) !== 'lessonType' }">
          <img src="assets/icons/done.svg" alt="done-icon">
        </div>
        <div class="circle" [ngClass]="{'active': (bookingPhase | async) === 'bookingSummary' || (bookingPhase | async) === 'bookingCheckout'}">
          <img src="assets/icons/done.svg" alt="done-icon">
        </div>
        <div class="circle" [ngClass]="{'active': (bookingPhase | async) === 'bookingCheckout'}">
          <img src="assets/icons/done.svg" alt="done-icon">
        </div>
      </div>
    </div>
  </div>
</section>