import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TRObject } from 'src/app/shared/models/shared';
import { ConfirmDeletePopupService } from './confirm-delete-popup.service';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";

@Component({
  selector: 'tr-confirm-delete-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirm-delete-popup.component.html',
  styleUrls: ['./confirm-delete-popup.component.css']
})

export class ConfirmDeletePopupComponent extends TRObject implements OnInit {
  isLoading: boolean = false;
  @ViewChild('openCodeConfirmationModal') openCodeConfirmationModal?: ElementRef;
  @ViewChild('closeCodeConfirmationModal') closeCodeConfirmationModal?: ElementRef;

  constructor(public service:ConfirmDeletePopupService, private responseHandlerService: ResponseHandlerService) { super() }

  ngOnInit(): void {
    this.service.isOpen$.subscribe((isOpen) => {
      if(isOpen){
        this.openCodeConfirmationModal?.nativeElement.click();
      }
    });
  }

  confirmDelete(){
    this.service.confirmDelete().pipe(finalize(() => (this.isLoading = false))).subscribe(
        (res: any) => {
          this.closeCodeConfirmationModal?.nativeElement.click();
          this.responseHandlerService.handleSuccessRessponse(res['message']);
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
    }



  }

