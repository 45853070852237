import { Component, OnInit } from '@angular/core';
import { TutorRegPhasesService } from '@studentComponents/student-tutors/tutor-reg-phases.service';
import { scrollTop } from 'src/app/shared/models/shared';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastService } from '@components/shared-components/toast.service';
import { Phases } from 'src/app/shared/models/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-tutor-reg-save-phase-six',
  templateUrl: './tutor-reg-save-phase-six.component.html',
  styleUrls: ['./tutor-reg-save-phase-six.component.css']
})
export class TutorRegSavePhaseSixComponent implements OnInit {

  constructor(private tutorRegPhasesService: TutorRegPhasesService, private toastService: ToastService, private fb: UntypedFormBuilder, private router: Router) { }
  isLoading:boolean = false;
  public phaseFiveForm = this.fb.group({
    agree: [false, Validators.requiredTrue],
  });

  ngOnInit(): void {
  }

  toggleAgree() {
    const agreeControl = this.phaseFiveForm.get('agree');
    
    if (agreeControl) {
      agreeControl.setValue(!agreeControl.value);
    }
  }

  ngAfterViewInit() {
    scrollTop()
  }

  backToPrevPhase() {
    this.isLoading = true;
    this.tutorRegPhasesService.getPhaseFiveFormData().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.status) {
          this.handleRegPhaseFourSuccess(res['message']);
          this.tutorRegPhasesService.phaseFiveFormData$.next(res.data)
          this.tutorRegPhasesService.setPhase(Phases.PHASE_FIVE)
        }
      },
      (error: any) => {
         this.handleResponseException(error);
      }
    )
  }

  submitApplication() {
    this.isLoading = true;
    this.tutorRegPhasesService.registerPhaseSix(this.phaseFiveForm.value).pipe(finalize(() => (this.isLoading = false))).subscribe(
      (data: any) => {
        if (data.status) {
          this.handleRegPhaseFourSuccess(data['message']);
          this.router.navigate(['/tutor'])
        }
      },
      (error: any) => {
        this.handleResponseException(error);
      }
    )
  }

  handleRegPhaseFourSuccess(res:any) {
    this.toastService.showSuccessToast('Success !', res);
  }

  handleRegPhaseFourFailure(res:any) {
    this.toastService.showErrorToast('Error!', res);
  }

  public handleResponseException(error:any){
   if(error instanceof HttpErrorResponse){
      if(error.status === 422){
        const validationErrors = error.error['errors'];
          Object.keys(validationErrors).forEach(prop => {
          const formControl = this.phaseFiveForm.get(prop);
          this.handleRegPhaseFourFailure(validationErrors[prop]); 
          if (formControl) {
            formControl.setErrors({
              serverError: validationErrors[prop]
            });
          }
        });
      }
      else if(error.status === 404 || error.status === 500) {
        this.handleRegPhaseFourFailure(error['error'].message);
       }
    }
    else{
      this.handleRegPhaseFourFailure(error.message); 
    }
  }

}