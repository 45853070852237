<div class="py-4">
	<section class="d-flex align-items-start justify-content-between gap-3 px-4 pb-2">
		<h2 class="mb-0 tr-title">My teachers</h2>
		<button class="px-3 py-1 tr-view-btn text-center tr-btn" routerLink="/student/tutors/my-tutors/current-tutors"> view all</button>
	</section>
	<section>
	   <div class="tr-hover tr-tutors-list d-flex align-items-start justify-content-between gap-3 p-4 cursor-pointer" *ngFor=" let tutor of tutors; let last = last"
     [ngClass]="{'tr-bdr': !last}" [routerLink]="'/student/tutor/' + tutor.id">
			<div class="d-flex align-items-start gap-2">
				<img [src]="tutor.profilePicture ? tutor.profilePicture : 'assets/images/profile-placeholder.png'" alt="tutor-img" class="tr-tutor-img">
                <div class="tr-tutor-details">
					<p class="mb-0">{{ tutor.firstName }}.</p>
					<span class="tr-text-lite">Certified tutor</span>
				</div>
			</div>
			<div class="d-flex flex-column">
				<span class="tr-text-lite">Subject</span>
				<span class="tr-text-dark">{{ tutor['spokenLanguages'][0]['languageName']}}</span>
			</div>
		</div>	 
	</section>
	<div *ngIf="tutors && tutors.length == 0" class="mt-4 d-flex flex-column gap-2 align-items-center justify-content-center">
		<img src="assets/images/no-tutors-search.svg" alt="">
		<span class="tr-sub-title-lite-text tr-font-12"> No teachers found.</span>
	</div>  		
</div>