import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { SLOT_DURATIONS, SLOT_STATE, convertDatetimeFormat, getDateAndTime, getDayName, getRecurringScheduleSlots, getSchedulerDays, isWithinFirstWeek, reshapeSlot } from '@shared/models/scheduler';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod, ROLES, TRObject, generateUniqueKeyFromDateTime } from '@shared/models/shared';
import { AuthService } from '@auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class ReschedulerService extends TRObject {
  lessonAction$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  lessonTimeAndPeriod$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lessonSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  filterTimesBy$: BehaviorSubject<any> = new BehaviorSubject<any>('all');
  startingSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  recurringDaysAndTimes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  gridData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  timeOffDateRange$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  recurringSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectedSlots$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  tutorData$: BehaviorSubject<any> = new BehaviorSubject<any>({ tutorName: '', tutorImage: '' });
  phase$: BehaviorSubject<string> = new BehaviorSubject<string>('resch_phase');// confirm_resch_phase
  reasons$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  rescheduleType$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  reschedulerFormStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  requestRescheduleData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  
  constructor(private trHttp: TrHttpService, private auth: AuthService) {
    super();


    this.getRescheduleReasons().subscribe(
      (res: any) => {
        this.reasons$.next(res.data.reasons);
      }
    )

    this.lessonTimeAndPeriod$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (lessonData: any) => {
        let userName = lessonData.tutorName ? lessonData.tutorName : lessonData.studentName;
        let userLogo = lessonData.tutorImage ? lessonData.tutorImage : lessonData.studentImage;
        if(!userName && !userLogo){
          userName = lessonData.userName;
          userLogo = lessonData.userLogo;
        }
        console.log(lessonData);
        this.tutorData$.next({ tutorId: lessonData.tutorId, tutorName: userName, tutorImage: userLogo, lessonPeriod: lessonData.lessonPeriod, lessontime: lessonData.lessontime, subjectName: lessonData.subjectName });
        this.lessonSlots$.next(this.generateSlotsFromExistingLesson(lessonData));
      }
    )
  }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): Observable<any> {
    return this.trHttp.fetch(endPoint, params, method);
  }

  resetRescheduler() {
    this.selectedSlots$.next([]);
    this.lessonSlots$.next([]);
    this.startingSlots$.next([]);
    this.selectedSlots$.next([]);
    this.recurringSlots$.next([]);
    this.timeOffDateRange$.next([]);
    this.recurringDaysAndTimes$.next([]);
    this.lessonTimeAndPeriod$.next([]);
    this.tutorData$.next({ tutorName: '', tutorImage: '' });
    this.phase$.next('resch_phase');
  }

  generateSlotsFromExistingLesson(param: any) {
    const firstSlotDateTime = moment(param.lessontime).format('YYYY-MM-DD HH:mm')
    const lessonKey = generateUniqueKeyFromDateTime(firstSlotDateTime)
    const startDateTime = moment(param.lessontime, 'YYYY-MM-DD HH:mm');
    const lessonPeriod = parseInt(param.lessonPeriod);
    const slots = [];
    for (let i = 0; i < lessonPeriod; i += SLOT_DURATIONS.THIRTY_MINS) {
      const slotTime = startDateTime.clone().add(i, 'minutes');
      const slot = {
        dateTime: slotTime.format('YYYY-MM-DD HH:mm'),
        lessonKey: lessonKey,
        state: SLOT_STATE.BOOKED
      };
      slots.push(slot);
    }
    console.log('slots' , slots)
    return slots;
  }

  nextPhase() {
    this.phase$.next('confirm_resch_phase')
  }

  getTutorAvailability(tutorId: any) {
    const formData = new FormData();
    formData.append('tutorId', tutorId);
    return this.fetch('user-availability-student', formData, HttpMethod.GET).pipe(
      map(
        (res: any) => {
          let availability: any = res.data.slots.availability;
          this.recurringDaysAndTimes$.next(getRecurringScheduleSlots(availability));
          return availability;
        }
      )
    );
  }

  removeSlotByDateTime(removeFrom$: BehaviorSubject<any>, slotToRemove: any | any[]) {
    let slots = removeFrom$.getValue();
    slots = slots.filter((slot: any) => (slot?.dateTime !== slotToRemove?.dateTime));
    removeFrom$.next(slots);
  }

  removeLessonSlots(removeFrom$: BehaviorSubject<any>, slotToRemove: any | any[]) {
    let slots = removeFrom$.getValue();
    slots = slots.filter((slot: any) => (slot?.lessonKey != slotToRemove?.lessonKey));
    removeFrom$.next(slots);
  }

  updateSelectedSlotsLessonKey(lessonKey: string, slotsArray$: BehaviorSubject<any> = this.selectedSlots$) {
    let selectedSlots = slotsArray$.getValue();

    for (let i = 0; i < selectedSlots.length; i++) {
      let selectedSlotLessonKey: any = generateUniqueKeyFromDateTime(selectedSlots[i].dateTime);
      if (selectedSlotLessonKey == lessonKey) {
        selectedSlots[i].lessonKey = lessonKey;
        break;
      }
    }
    slotsArray$.next(selectedSlots);
  }

  updateSlotStateById(id: number, state: string) {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.id === id);
      if (slotIndex !== -1) {
        slots[slotIndex].state = state;
        break;
      }
    }
    setTimeout(() => {
      this.gridData$.next(slotsData)
    });
  }

  updateSlotStateByLessonKey(lessonKey: string, state: string) {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.lessonKey === lessonKey);
      if (slotIndex !== -1) {
        slots[slotIndex].state = state;
        // break;
      }
    }
    setTimeout(() => {
      this.gridData$.next(slotsData)
    });
  }

  removeLessonKeyGridSlots(lessonKey: string) {
    const slotsData: Array<any> = this.gridData$.getValue();
    slotsData.forEach(obj => {
      obj.slots.forEach((slot: any) => {
        if (slot.lessonKey === lessonKey) {
          delete slot.lessonKey;
        }
      });
    });
    return slotsData;
  }

  updateGridWithTheLessonKey(id: any, lessonKey: string): void {
    const slotsData: Array<any> = this.gridData$.getValue();

    for (let i = 0; i < slotsData.length; i++) {
      const slots = slotsData[i].slots;
      const slotIndex = slots.findIndex((slot: any) => slot.id === id);
      if (slotIndex !== -1) {
        slots[slotIndex].lessonKey = lessonKey;
        break;
      }
    }
    this.gridData$.next(slotsData)
  }

  rescheduleLesson(params: any) {
    return this.fetch('student-direct-reschedule', params, HttpMethod.POST);
  }  

  requestRescheduleLesson(params: any) {
    return this.fetch('user-request-reschedule', params, HttpMethod.POST);
  }   

  giveNewTime(params: any) {
    return this.fetch('user-give-new-time', params, HttpMethod.POST);
  }  

  handleRequest(lesson:any, endPoint:any) {
    let formData = new FormData();
    formData.append('lessonId', lesson.lessonId);
    formData.append('lessonRequestId', lesson.lessonRequestId);
    
    return this.fetch(endPoint, formData, HttpMethod.POST);
  }  


  completeLesson(lessonId:any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    return this.fetch('mark-lesson-as-done', formData, HttpMethod.POST);
  }

  acceptRequest(lesson:any) {
    let endPoint = '';
    if(lesson.type === 'TUTOR_APPROVAL'){
        endPoint = 'tutor-accept-pending-approval';
    } else if(lesson.type === 'RESCHEDULE'){
        endPoint = 'user-approve-reschedule';
    }
    return this.handleRequest(lesson, endPoint);
  }

  rejectRequest(lesson:any) {
    let endPoint = '';
    if(lesson.type === 'TUTOR_APPROVAL'){
        endPoint = 'tutor-reject-pending-approval';
    } else if(lesson.type === 'RESCHEDULE'){
        endPoint = 'tutor-reject-reschedule';
    }
    return this.handleRequest(lesson, endPoint);
  }

  getRescheduleReasons() {
    return this.fetch('reasons-list')
  }

}