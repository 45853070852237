<div class="col-12 mt-4">
  <div class="row d-flex mb-4">
    <div class="p-0 m-0 d-flex me-3 justify-content-between">
      <p class="p-0 m-0 tr-tutor-tile-count-des d-flex flex-column flex-sm-row align-items-sm-center">
        {{ title }}
        <span class="d-flex align-items-center" *ngIf="tutorsCount">
          <span class="mx-3 d-none d-sm-flex"> | </span> {{ tutorsCount }} tutors </span>
      </p>
      <div class="p-0 m-0 d-flex align-items-center align-items-center d-none d-md-flex">
        <div class="p-0 m-0 tr-grid-div d-flex align-items-center">
          <span class="me-3 tr-grid">Display</span>
          <div class="me-3 mt-2" (click)="toggleView()">
            <span>
              <tr-svg [path]="gridDisplayPath" [ngClass]="{'active': !isListView }"></tr-svg>
            </span>
          </div>
          <div class="me-3 mt-2" (click)="toggleView()">
            <span>
              <tr-svg [path]="listDisplayPath" [ngClass]="{'active': isListView }"></tr-svg>
            </span>
          </div>
          <div class="mt-2">
            <span type="button" id="custom_dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              <tr-svg [path]="sortByFilterIcon"></tr-svg>
            </span>
            <ul class="dropdown-menu tr-country-menu p-4" aria-labelledby="custom_dropdown">
              <div class="position-relative tr-filter-drop-search-div">
                <div class="d-flex align-items-center" *ngFor="let value of findTutorByFilter">
                  <div class="form-group">
                    <input type="checkbox" id="{{value.name}}" [value]="value.id">
                    <label for="{{value.name}}"> {{ value.name }}</label>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>