import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { validateObjKey } from '@shared/models/validation';

@Injectable({
  providedIn: 'root'
})
export class TutorMyStudentsService {


  constructor(private trHttp: TrHttpService) { }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getStudentsList(params: any){
    return this.fetch('tutor-my-students', this.getFormData(params))
  }

  getFormData(params: any) {
    let formData = new FormData();
    for (let key in params) {
      if (validateObjKey(key, params)) {
        formData.append(key, params[key]);
      }
    }
    return formData;
  }

  getSubjects() {
    return this.fetch('subjects', HttpMethod.GET);
  }


}
