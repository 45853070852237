<div class="tr-tutor-register-container container-fluid d-flex justify-content-center">
  <div class="tr-tutor-reg-wrapper col-md-11 col-lg-8 top30 bottom50">
    <tr-tutor-registration-form-header [tabClasses]="['tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-active-tab', 'tr-reg-remain-tab', 'tr-reg-remain-tab']" title="4. Pricing"></tr-tutor-registration-form-header>
    <form class="pt-5">
      <section class="row px-0 m-0 py-4 justify-content-center" [formGroup]="phaseFourForm">
        <div class="col-11">
          <div class="row p-0 m-0">
            <div class="tr-subj-option d-flex flex-column gap-4 col-6 ps-0 mb-4">
              <span>You will teach</span>
              <div>
                <select class="form-select p-2" aria-label="Default select example" formControlName="subjectId" [(ngModel)]="selectedSubjectId" (change)="onSubjectChange($event)">
                  <option [value]="undefined" *ngIf="!selectedSubjectId" hidden>Select subject</option>
                  <option *ngFor="let subject of subjects | async" [value]="subject.id">
                    {{subject.name}}
                  </option>
                </select>
                <div class="top5" *ngFor="let error of errorMessages.subjectId">
                  <ng-container *ngIf="phaseFourForm.controls['subjectId'].hasError(error.type) && (phaseFourForm.controls['subjectId'].touched)">
                    <small class="error-message">{{error.message}}</small>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tr-subj-option d-flex flex-column gap-4 col-6 pe-0 mb-4">
              <span>Specialities</span>
              <div>
                <select class="form-select p-2" aria-label="Default select example" formControlName="specialistId" [(ngModel)]="selectedSpecialistId">
                  <option [value]="undefined" *ngIf="!selectedSpecialistId" hidden>Select speciality</option>
                  <option [value]="speciality.id" *ngFor="let speciality of specialities">{{speciality.name}}
                  </option>
                </select>
                <div class="top5" *ngFor="let error of errorMessages.specialistId">
                  <ng-container *ngIf="phaseFourForm.controls['specialistId'].hasError(error.type) && (phaseFourForm.controls['specialistId'].touched)">
                    <small class="error-message">{{error.message}}</small>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tr-subj-option d-flex flex-column gap-4 col-6 ps-0">
              <span>Age group</span>
              <div>
                <select class="form-select p-2" aria-label="Default select example" formControlName="ageId" [disabled]="disable" [(ngModel)]="selectedageId">
                  <option [value]="undefined" *ngIf="!selectedageId" hidden>Select age group</option>
                  <option [value]="ageGroup.id" *ngFor="let ageGroup of ageGroups | async">{{ageGroup.name}}
                  </option>
                </select>
                <div class="top5" *ngFor="let error of errorMessages.ageId">
                  <ng-container *ngIf="phaseFourForm.controls['ageId'].hasError(error.type) && (phaseFourForm.controls['ageId'].touched)">
                    <small class="error-message">{{error.message}}</small>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tr-subj-option d-flex flex-column gap-1 col-6 pe-0">
              <span>Period & Price</span>
              <div class="row p-0 m-0">
                <div class="col-4  d-flex justify-content-between ps-0">
                  <div class="form-group d-flex flex-column position-relative w-100">
                    <label for="" class="tr-dark-label">30 min</label>
                    <input type="number" name="period30" formControlName="period30">
                    <div class="top5 tr-position-absolute" *ngFor="let error of errorMessages.price">
                      <ng-container *ngIf="phaseFourForm.controls['period30'].hasError(error.type) && (phaseFourForm.controls['period30'].touched)">
                        <small class="error-message">{{error.message}}</small>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-4  d-flex justify-content-between">
                  <div class="form-group d-flex flex-column position-relative w-100">
                    <label for="" class="tr-dark-label">60 min</label>
                    <input type="number" name="period60" formControlName="period60">
                    <div class="top5 tr-position-absolute" *ngFor="let error of errorMessages.price">
                      <ng-container *ngIf="phaseFourForm.controls['period60'].hasError(error.type) && (phaseFourForm.controls['period60'].touched)">
                        <small class="error-message">{{error.message}}</small>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-4 ">
                  <div class="form-group d-flex flex-column position-relative w-100">
                    <label for="" class="tr-dark-label">90 min</label>
                    <input type="number" name="period90" formControlName="period90">
                    <div class="top5 tr-position-absolute" *ngFor="let error of errorMessages.price">
                      <ng-container *ngIf="phaseFourForm.controls['period90'].hasError(error.type) && (phaseFourForm.controls['period90'].touched)">
                        <small class="error-message">{{error.message}}</small>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-3 pb-1 col-12 px-0">
              <button class="tr-add-subj-btn" type="submit" [disabled]="phaseFourForm.invalid || disabeleBtn === 'add'" (click)="addSubjects()"> + Add subject </button>
            </div>
          </div>
        </div>
      </section>
      <div class="row px-0 pt-0 m-0 tr-pb-2 justify-content-center">
        <div class="col-11">
          <section class="pb-4 d-flex flex-column gap-4">
            <div class="m-0 tr-bdr tr-p-4 d-flex align-items-center justify-content-between" *ngFor="let record of dataStoreArray; let i = index">
              <div class="d-flex align-items-center justify-content-between flex-wrap gap-4 w-100">
                <div class="tr-table-column d-flex flex-column gap-2">
                  <span class="tr-lite-text">Subject</span>
                  <span class="tr-dark-text">
                    <ng-container *ngFor="let subject of subjects | async">
                      <ng-container *ngIf="subject.id == record.subjectId">
                        {{ subject.name }}
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
                <div class="tr-table-column d-flex flex-column gap-2">
                  <span class="tr-lite-text">Specialities</span>
                  <span class="tr-dark-text">
                    <ng-container *ngFor="let speciality of specialities$ | async">
                      <ng-container *ngIf="speciality.id == record.specialistId">
                        {{ speciality.name }}
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
                <div class="tr-table-column d-flex flex-column gap-2">
                  <span class="tr-lite-text">Age group</span>
                  <span class="tr-dark-text">
                     <ng-container *ngFor="let ageGroup of ageGroups | async">
                      <ng-container *ngIf="ageGroup.id == record.ageId">
                        {{ ageGroup.name }}
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
                <div class="tr-table-column d-flex flex-column gap-2">
                  <span class="tr-lite-text">Period & Price (GBP)</span>
                  <div class="d-flex gap-2">
                    <div class="d-flex flex-column tr-price-tag text-center gap-1">
                      <span>30 min </span> {{ record.period30 }}GBP
                    </div>
                    <div class="d-flex flex-column tr-price-tag text-center gap-1">
                      <span>60 min </span> {{ record.period60 }} GBP
                    </div>
                    <div class="d-flex flex-column tr-price-tag text-center gap-1">
                      <span>90 min </span> {{ record.period90 }}GBP
                    </div>
                  </div>
                </div>
                <div class="cursor-pointer" (click)="deleteRecord(i)">
                  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#C8102E" />
                  </svg>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="row  d-flex justify-content-center top20 bottom30">
        <div class="col-md-11 col-sm-11 col-11 d-sm-flex justify-content-center">
          <button class="col-md-3 col-lg-1 col-sm-2 col-12 tr-tutor-reg-back-btn tr-btn"  (click)="backToPrevPhase()"> 
            <span *ngIf="!isLoading">Back</span>
            <tr-spinner *ngIf="isLoading"></tr-spinner>
          </button>
          <div class="col-md-9 col-lg-11 col-sm-10 col-12 d-sm-flex justify-content-end">
            <button class="col-md-2 col-lg-2 col-sm-6 col-12 tr-tutor-reg-save-btn tr-btn" (click)="nextPhase()">
              <span *ngIf="!isLoading">Save for later</span>
              <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
            <button class="col-md-2 col-lg-1 col-sm-6 col-12 tr-tutor-reg-next-btn left10 tr-btn"  [disabled]="dataStoreArray.length <= 0" (click)="nextPhase()">
              <span *ngIf="!isLoading">Next</span>
              <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>