<div class="tr-tutor-register-container container-fluid d-flex justify-content-center">
  <div class="tr-tutor-reg-wrapper col-md-11 col-lg-8 top30 bottom50">
    <tr-tutor-registration-form-header [tabClasses]="['tr-reg-comp-tab','tr-reg-comp-tab','tr-reg-active-tab','tr-reg-remain-tab','tr-reg-remain-tab','tr-reg-remain-tab']" title="3. Tutoring profile"></tr-tutor-registration-form-header>
    <form [formGroup]="phaseThreeForm" (ngSubmit)="onSubmit()">
      <div class="row d-flex justify-content-center">
        <div class="col-md-11 col-lg-11 col-11">
          <div class="row justify-content-center">
            <!-- [ngStyle]="{background: 'url('+ profileImage + ')'}" -->
            <div class="col-md-5 col-xl-4 col-sm-5 top20 d-fle align-items-center justify-content-center" *ngIf="!profilePicture">
              <div class="displayTable tr-tutor-reg-profile m-auto" #profilePictureEl [style.background-image]="cropChild.saveProfileImage? 'url('+cropChild.saveProfileImage+')': '' ">
                <div class="displayTableCell">
                  <img *ngIf="!cropChild.saveProfileImage" id="tr-tutor-reg-profile-placeholder" src="../../../../assets/icons/camera-icon.svg" alt="">
                </div>
              </div>
              <div class="tr-tutor-reg-browse-img-btn top20 d-flex align-items-center justify-content-center">
                <input type="file" name="photo" (change)="fileChangeEvent($event)" value="Choose a photo" />
              </div>
            </div>
            <div *ngIf="profilePicture" class="col-md-5 col-xl-4 col-sm-5 top20 d-fle align-items-center justify-content-center">
              <img [src]="profilePicture" alt="" class="img img-fluid tr-profile-picture">
              <button class="tr-btn tr-btn-outline-primary mt-3 w-100" (click)="changeImage()"> Change photo</button>
            </div>
            <div class="col-md-7 col-xl-8 col-sm-7 col-12">
              <div class="row top20">
                <div class="col-md-12 col-lg-12">
                  <label class="col-md-12 col-lg-12 bottom5 tr-tutor-reg-img-instruction-title">Tutor's profile should be</label>
                  <div class="col-md-12 col-lg-12">
                    <img src="../../../../assets/icons/not-checked.svg" alt="">
                    <span class="tr-tutor-reg-img-instruction-item left5">Should be a personal photo, showing eyes and face clearly</span>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <img src="../../../../assets/icons/not-checked.svg" alt="">
                    <span class="tr-tutor-reg-img-instruction-item left5">At least 200 x 200 px</span>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <img src="../../../../assets/icons/not-checked.svg" alt="">
                    <span class="tr-tutor-reg-img-instruction-item left5">PNG, JPG or JPEG</span>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <img src="../../../../assets/icons/not-checked.svg" alt="">
                    <span class="tr-tutor-reg-img-instruction-item left5">Maximum size 2MB</span>
                  </div>
                </div>
              </div>
              <div class="row top30">
                <div class="col-md-12 col-lg-12">
                  <label class="tr-tutor-reg-correct-img-ex-title">Examples:</label>
                  <div class="row">
                    <div class="col-md-12 col-lg-12 top10">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-correct-ex1.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-correct-ex2.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-correct-ex3.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-correct-ex4.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/icons/checked-icon.svg" alt="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 top20">
                  <label class="tr-tutor-reg-wrong-img-ex-title">Don't use photos like below:</label>
                  <div class="row">
                    <div class="col-md-12 col-lg-12 top10">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-wrong-ex1.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/images/tutor-wrong-ex2.svg" alt="">
                      <img class="left5 tr-tutor-reg-correct-img-ex" src="../../../../assets/icons/error-icon.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center top10">
        <!--          <div class="col-sm-9 col-10"><div class="tr-tutor-reg-browse-img-btn right20"><input type="file" name="photo" (change)="fileChangeEvent($event)" value="Choose a photo" /></div></div> -->
        <!--          <span class="error-message col-md-9 col-lg-9 col-sm-9 col-10" >
                          {{errorMessages.profilePicture[0].message}}               
         </span> -->
      </div>
      <div class="row d-flex justify-content-center top30">
        <div class="col-11">
          <tr-form-notification></tr-form-notification>
        </div>
      </div>
      <div class="row d-flex justify-content-center top20">
        <div class="col-md-11 col-lg-11 col-11">
          <div class=" tr-tutor-reg-disp-name-field">
            <label class="col-md-12 col-lg-12 tr-tutor-reg-label"> Username </label>
            <input (blur)="checkUserName(username.value)" class="col-md-12 col-lg-12 col-12 tr-tutor-reg-input top5" name="userName" formControlName="userName" type="text" placeholder="Username" #username />
          </div>
        </div>
        <div class="col-md-11 top5" *ngFor="let error of errorMessages.userName">
          <ng-container *ngIf="phaseThreeForm.controls['userName'].hasError(error.type) && (phaseThreeForm.controls['userName'].touched)">
            <small class="error-message">{{error.message}}</small>
          </ng-container>
        </div>
      </div>
      <div class="row d-flex justify-content-center top20">
        <div class="col-md-11 col-lg-11 col-11">
          <div class=" tr-tutor-reg-disp-name-field">
            <label class="col-md-12 col-lg-12 tr-tutor-reg-label"> About (Bio) </label>
            <textarea class="col-md-12 col-lg-12  col-12 tr-tutor-reg-text-area-bio top5" formControlName="about" maxlength="250" (input)="countCharacters($event)"></textarea>
            <div class="d-flex align-items-center justify-content-between">
              <span class="tr-bio-hint-text d-flex align-items-center gap-2">
                <img class="tr-bio-hint-icon" src="../../../../assets/icons/not-checked.svg" alt="" *ngIf="givenCharacters < 100">
                <img class="tr-bio-hint-icon" src="../../../../assets/icons/checked-icon.svg" alt="" *ngIf="givenCharacters > 100"> Min. charachters 100, Max. 250 </span>
              <p class="mb-0 tr-tutor-bio-count">{{givenCharacters}}/250</p>
            </div>
          </div>
        </div>
        <div class="col-md-11 top5" *ngFor="let error of errorMessages.about">
          <ng-container *ngIf="phaseThreeForm.controls['about'].hasError(error.type) && (phaseThreeForm.controls['about'].touched)">
            <small class="error-message">{{error.message}}</small>
          </ng-container>
        </div>
      </div>
      <div class="row d-flex justify-content-center top20">
        <div class="col-md-11 col-lg-11 col-11">
          <div class=" tr-tutor-reg-disp-name-field">
            <label class="col-md-12 col-lg-12 col-12 tr-tutor-reg-label"> Add Link of your video from YOUTUBE or VIMEO </label>
            <input class="col-md-12 col-lg-12 col-12 tr-tutor-reg-input top5" formControlName="videoLink" type="text" placeholder="Pate the URL" />
            <label>
              <img src="../../../../assets/icons/i.svg" alt="">
              <span class="tr-video-URL-hint-label left5">Learn how to upload to YOUTUBE or VIMEO</span>
            </label>
          </div>
        </div>
        <div class="col-md-11 top5" *ngFor="let error of errorMessages.videoLink">
          <ng-container *ngIf="phaseThreeForm.controls['videoLink'].hasError(error.type) && (phaseThreeForm.controls['videoLink'].touched)">
            <small class="error-message">{{error.message}}</small>
          </ng-container>
        </div>
      </div>
      <div class="row d-flex justify-content-center top20">
        <div class="col-md-11 col-lg-11 col-11 displayTable" (click)="expandVideoRequirements()">
          <div class="tr-tutor-reg-video-requirements displayTableCell" [ngClass]="isVideoReqExpanded?'tr-tutor-reg-show-video-req':'' ">
            <div class="row trMarginAuto">
              <div class="col-md-6 col-lg-6 col-10"> Check the video requirements </div>
              <div class="col-md-6 col-lg-6 col-2 text-end trMarginAuto">
                <img src="../../../../assets/icons/{{videoReqPanelIcon}}" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="tr-tutor-reg-video-requirements-expanded" *ngIf="isVideoReqExpanded">
          <div class="col-md-11 col-lg-11">
            <div class="row d-flex justify-content-center">
              <div class="col-md-10 col-lg-10 col-10 ">
                <div class="row top10">
                  <div class="col-md-12 col-lg-12">
                    <div class="row tr-video-expanded-content-background">
                      <div class="col-md-6 col-lg-6 text-center tr-tutor-reg-correct-video"> Your video has to apply the following characteristics <div class="row d-flex justify-content-center top10">
                          <div class="col-md-12 col-lg-12 text-start">
                            <img src="../../../../assets/icons/checked-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">You must appear in the video</span>
                            <br>
                            <img src="../../../../assets/icons/checked-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">Your video should be between 1 to 3 minutes</span>
                            <br>
                            <img src="../../../../assets/icons/checked-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">You must speak all the languages that you want to teach</span>
                            <br>
                            <img src="../../../../assets/icons/checked-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">It has good lighting and clear sound</span>
                            <br>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 text-start tr-tutor-reg-wrong-video"> Do Not <div class="row d-flex justify-content-center top10">
                          <div class="col-md-12 col-lg-12 text-start">
                            <img src="../../../../assets/icons/error-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">Provide personal contact information or advertisments</span>
                            <br>
                            <img src="../../../../assets/icons/error-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">Use copyrighted music or any media for which you do not have the rights</span>
                            <br>
                            <img src="../../../../assets/icons/error-icon.svg" alt="">
                            <span class="tr-tutor-correct-video-item left5">Shoot vertical or square videos. The video aspect ratio should be 16:9 and to be horizontal shoot to achieve the best effect.</span>
                            <br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row  d-flex justify-content-center top20 bottom30">
        <div class="col-md-11 col-sm-11 col-11 d-sm-flex justify-content-center">
          <button class="col-md-3 col-lg-1 col-sm-2 col-12 tr-tutor-reg-back-btn tr-btn"  (click)="backToPrevPhase()">
            <span *ngIf="!isLoading">Back</span>
            <tr-spinner *ngIf="isLoading"></tr-spinner>
          </button>
          <div class="col-md-9 col-lg-11 col-sm-10 col-12 d-sm-flex justify-content-end">
            <button class="col-md-2 col-lg-2 col-sm-6 col-12 tr-tutor-reg-save-btn tr-btn"  (click)="savePhaseThree()">
              <span *ngIf="!isLoading">Save for later</span>
              <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
            <button class="col-md-2 col-lg-1 col-sm-6 col-12 tr-tutor-reg-next-btn left10 tr-btn"  [disabled]="phaseThreeForm.invalid" (click)="nextPhase()">
              <span *ngIf="!isLoading">Next</span>
              <tr-spinner *ngIf="isLoading"></tr-spinner>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<tr-crop-modal #cropChild (savedImage)="handleUploadProfilePicture($event)"></tr-crop-modal>