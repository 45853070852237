import { Component, OnInit } from '@angular/core';
import { validatePassword, passRules, formValidationMessages } from 'src/app/shared/models/validation';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordService } from '../forgot-password/forgot-password.service';
import { ToastService } from '@components/shared-components/toast.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'tr-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  passRules: any = passRules;
  isPassValid: boolean = false;
  passValidationStatuses: any;
  resetPasswordForm!: UntypedFormGroup;
  showPassword:boolean = false;
  showConfirmPassword:boolean = false;
  isLoading:boolean = false;

  constructor(private route: ActivatedRoute, 
    private service:ForgotPasswordService, 
    private toastService: ToastService,
    private router: Router) {

   }

  private initForm() {
    this.resetPasswordForm = new UntypedFormGroup({
      confirmation_code: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      confirmPassword: new UntypedFormControl('', [Validators.required, this.passwordMatchValidator.bind(this)]),
    });
  }

  passwordMatchValidator(control: UntypedFormControl) {
    if (this.resetPasswordForm && this.resetPasswordForm.get('password')) {
      const passwordControl = this.resetPasswordForm.get('password');
      if (control.value !== passwordControl?.value) {
        return { 'passwordMismatch': true };
      }
    }
    return null;
  }


  ngOnInit(): void {
    this.initForm();
    const code = this.route.snapshot.queryParams['code'];
    this.resetPasswordForm.patchValue({ confirmation_code: code });
  }

  getPass(password: string) {
    this.isPassValid = validatePassword(password)['isValid'];
    this.passValidationStatuses =
      validatePassword(password)['validationStatuses'];
  }

  resetPassword(){
    this.isLoading = true;
    this.service.resetPassword(this.resetPasswordForm.value)
    .pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        this.toastService.showSuccessToast('Success', res['message']);
        this.router.navigate(['/login']);
      },
      (error: any) => {
        this.handleLoginFailure(error)
      }
    )

  }

  handleLoginFailure(error:any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 422 || error.status === 404 || error.status === 500) {
        this.toastService.showErrorToast('Error!', error['error'].message);
      }
    } else {
      this.toastService.showErrorToast('Error!', error.message);
    }
  }







}
