import { Component, OnInit, Input, SkipSelf, Output, EventEmitter } from '@angular/core';
import { optionsIcon, eventRepeatIcon, cancelPath, approveIcon, complaintIcon} from '@shared/models/svgIcons';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { takeUntil, tap } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';
import { CardViewService } from '@core/card-view.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { UserLessonsService } from './user-lessons.service';
import { ConfirmDeletePopupService } from '@components/shared-components/confirm-delete-popup/confirm-delete-popup.service';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
import { SendMessagePopupService } from '@components/shared-components/send-message-popup/send-message-popup.service';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'tr-user-lesson-card',
  templateUrl: './user-lesson-card.component.html',
  styleUrls: ['./user-lesson-card.component.css']
})

export class UserLessonCardComponent extends TRObject implements OnInit {

  @Input() lesson: any;
  isListView: boolean = false;
  optionsIcon: string = optionsIcon;
  userRole = this.authService.currentRole$.value;
  cancelPath: string = cancelPath;
  approveIcon: string = approveIcon;
  complaintIcon: string = complaintIcon;
  eventRepeatIcon: string = eventRepeatIcon;
  @Output() cardView: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  public isFetching: boolean = true;
  tutorData!: { id: number; name: string; logo: string };

  constructor(
    private responseHandlerService: ResponseHandlerService,
    private schedulerNextService: SchedulerNextService,
    private router: Router,
    public authService: AuthService,
    private messageService:SendMessagePopupService,
    @SkipSelf() public userLessonService: UserLessonsService, 
    @SkipSelf() public cardViewService: CardViewService, 
    @SkipSelf() public reschedulerService: ReschedulerService,
    private deleteService: ConfirmDeletePopupService) {
    super();
    this.userLessonService.isFetching$.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(
        (data:any)=>{
          this.isFetching=data
        }
      )
    ).subscribe()
  }

  ngOnInit(): void {
    this.getListDisplayView();
  }

  getListDisplayView() {
    this.cardViewService.isListView$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (listView: boolean) => {
        this.isListView = listView;
        this.cardView.emit(this.isListView);
      }
    )
  }

  rescheduleLesson(actionName: any, lessonData: any) {
    this.userLessonService.lessonAction$.next(actionName);
    this.reschedulerService.lessonAction$.next(actionName);
    this.reschedulerService.lessonTimeAndPeriod$.next(lessonData);
    this.schedulerNextService.lessonTimeAndPeriod$.next(lessonData);
  }

  getLessonAction(actionName: any, lesson: any) {  
    switch (actionName) {
      case LESSON_PERMISSIONS.CANCEL_REQ:
        this.cancelRequest(lesson.id, lesson.lessonRequestId);
        break;
      case LESSON_PERMISSIONS.CANCEL:
        this.directCancelLesson(lesson.id);
        break;      
      case LESSON_PERMISSIONS.ACCEPT:
        this.acceptRequest(lesson);
        break;      
      case LESSON_PERMISSIONS.REJECT:
        this.rejectRequest(lesson);
        break;      
      case LESSON_PERMISSIONS.COMPLETE:
        this.completeLesson(lesson.id);
        break;       
      case LESSON_PERMISSIONS.RAISE_COMPLAINT:
        this.raiseComplaint(lesson.id);
        break;      
      case LESSON_PERMISSIONS.RESCHEDULE:
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        this.rescheduleLesson(actionName, lesson);
        break;
    }
  }

  acceptRequest(lesson:any){
    this.reschedulerService.acceptRequest(lesson).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.responseHandlerService.handleSuccessRessponse(data['message']);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }   

  completeLesson(lessonId:any){
    this.reschedulerService.completeLesson(lessonId).subscribe(
      (data: any) => {
        this.responseHandlerService.handleSuccessRessponse(data['message']);
        this.success.emit(true);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }  

  raiseComplaint(lessonId:any){
    this.userLessonService.complaintLessonId$.next(lessonId);
    const modalElement = document.getElementById('raiseComplaintModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }  

  rejectRequest(lesson:any){
    this.reschedulerService.rejectRequest(lesson).subscribe(
      (data: any) => {
        this.reschedulerService.resetRescheduler();
        this.responseHandlerService.handleSuccessRessponse(data['message']);
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

  sendMessage(id:any , name:any, logo:any){
    this.tutorData = {
        id: id,
        name: name,
        logo: logo,
    };
    this.messageService.sendUserDetails(this.tutorData);
  }

  directCancelLesson(lessonId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    this.deleteService.setData('cancel', formData);
  }

  cancelRequest(lessonId: any, lessonRequestId: any) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);
    formData.append('lessonRequestId', lessonRequestId);
    this.deleteService.setData('request_cancellation', formData);
  }

  navigateTo(id:any, status:any){
    let currentRole = this.authService.currentRole$.getValue();
     if(status != 'CANCELED'){
       this.router.navigate(['/'+currentRole+'/lessons/lesson-details/'+id+'/lesson-board']);
     }
  }

  preventDefaultClick(event: Event, lesson:any) {
    event.preventDefault();
    event.stopPropagation();
    this.sendMessage(lesson.studentId ? lesson.studentId : lesson.tutorId, lesson.tutorName ? lesson.tutorName : lesson.studentName, lesson.studentImage ? lesson.studentImage : lesson.tutorImage);
  }  

  preventDefault(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  mapPermission(permission: string): string {

    switch (permission) {
      case LESSON_PERMISSIONS.CANCEL:
        return 'Cancel';
      case LESSON_PERMISSIONS.CANCEL_REQ:
        return 'Cancel request';
      case LESSON_PERMISSIONS.RESCHEDULE:
        return 'Reschedule';
      case LESSON_PERMISSIONS.REQ_RESCHEDULE:
        return 'Request reschedule';
      case LESSON_PERMISSIONS.APPROVE:
        return 'Approve';      
      case LESSON_PERMISSIONS.COMPLETE:
        return 'Complete';      
      case LESSON_PERMISSIONS.RAISE_COMPLAINT:
        return 'Raise complaint';
      case LESSON_PERMISSIONS.REJECT:
        return 'Reject';
      case LESSON_PERMISSIONS.ACCEPT:
        return 'Accept';      
      case LESSON_PERMISSIONS.GIVE_NEW_TIME:
        return 'Give new time';
      default:
        return permission;
    }
  }


}