 <!-- Modal -->
<div class="modal fade" id="addReviewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content p-4">
      <div class="modal-body mt-2 d-flex flex-column justify-content-center px-4">
        <img src="assets/images/review-popup-img.svg" alt="raise-complaint" class="tr-review-icon">
        <div class="d-flex flex-column align-items-center justify-content-center gap-2 mt-3">
          <span class="tr-sub-title-dark-text tr-font-16">Write a Review</span>
          <span class="tr-sub-title-lite-text tr-font-14 text-center">We love to hear from you , How is your experience with this tutor?</span>
        </div>
        <div class="my-4 tr-bg-grey">
        	<div class="py-4 tr-px-2 d-flex align-items-center justify-content-between gap-3">
        		<div class="tr-review-sec d-flex  gap-2">
<!--         			<div>
	        			<svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path [attr.d]="rateIcon" fill="#E5E5E5"/>
						</svg>
        			</div> -->
        			<!-- icon-list.component.html -->
					
					<div *ngFor="let index of [0, 1, 2, 3, 4]; let i = index" [ngClass]="{'active': i < userRating}" (click)="this.userRating = i
					+1" class="cursor-pointer">
					  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					    <path [attr.d]="rateIcon" [ngStyle]="{'fill': (i < userRating) ? '#FFD401' : '#E5E5E5'}"/>
					  </svg>
					</div>

        		</div>
        		<div class="tr-sub-title-dark-text tr-font-18">
        			{{ userRating }}
        		</div>
        	</div>
        </div>
        <div class="d-flex flex-column mt-4">
          <span class="tr-sub-title-dark-text tr-font-16 mb-3">Add Note</span>
          <textarea name="note" [(ngModel)]="description" id="note" cols="8" rows="5" class="form-control tr-font-14" placeholder="Would you like to write anything about tutor"></textarea>
        </div>
        <div class="d-flex align-items-center gap-2 mt-4">
          <button class="tr-btn-primary-outline px-3 py-2 tr-btn" data-bs-dismiss="modal" aria-label="Close" #closeModal (click)="resetData()">Discard</button>
          <button class="tr-btn-primary px-3 py-2 tr-btn" [disabled]="!description || this.userRating == 0" (click)="submitReview()">Submit Review</button>
        </div>
      </div>
    </div>
  </div>
</div>
