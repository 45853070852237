import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tr-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})

export class PaypalComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}