<main class="tr-padding-2" *ngIf="(userLessonsService.lessonAction$ | async) !== LESSON_PERMISSIONS.RESCHEDULE && (userLessonsService.lessonAction$ | async) !== LESSON_PERMISSIONS.REQ_RESCHEDULE && (userLessonsService.lessonAction$ | async) !== LESSON_PERMISSIONS.GIVE_NEW_TIME">
	<h2 class="tr-title mb-lg-4 px-4 px-lg-0 mb-3 tr-mb-header">
	   <span>Overview</span>
   </h2>
<!-- 	<div class="tr-dashboard-notification flex-wrap py-3 px-4 d-flex align-items-center justify-content-between position-relative gap-3 mb-3" *ngIf="showAlert">
		<img src="assets/icons/close-circle.svg" alt="close-icon" class="tr-close cursor-pointer" (click)="this.showAlert = !this.showAlert">
		<section class="d-flex align-items-center flex-wrap">
			<div class="tr-progress d-flex align-items-center gap-3 tr-pe-25 tr-bdr">
				<img src="assets/images/student-dashboard-chat.svg" alt="">
				<div>
					<span>70% complete</span>
					<p class="mb-0">You’re almost done !</p>
				</div>
			</div>
			<div class="tr-ps-25">
				<span class="tr-text-lite">You need to verify your phone number for Account Security</span>
			</div>
		</section>
		<section class="pe-2">
			<button class="px-3 py-2 tr-verify-btn tr-btn">Verify</button>
		</section>
	</div> -->
	<section class="row p-0 m-0 gap-4 gap-lg-0">
		<div class="col-lg-6 p-0 pe-lg-3">
			<div class="tr-bg-highlight tr-mh-100">
				<tr-student-next-lessons></tr-student-next-lessons>
			</div>
		</div>		
		<div class="col-lg-6 ps-lg-3 pe-0 p-0">
			<div class="tr-bg-highlight position-relative tr-mh-100">
				<tr-lessons-of-the-day></tr-lessons-of-the-day>
			</div>
		</div>		
	</section>	

	<section class="row px-0 m-0 gap-4 gap-lg-0 tr-mt-2">
		<div class="col-lg-4 p-0 pe-lg-3">
			<div class="tr-bg-highlight tr-mh-100">
				<tr-student-dashboard-latest-messages></tr-student-dashboard-latest-messages>
			</div>
		</div>		
		<div class="col-lg-4 p-0 px-lg-3">
		   <div class="tr-bg-highlight position-relative tr-mh-100">
			<tr-student-dashboard-my-students></tr-student-dashboard-my-students>
		   </div>
		</div>
<!-- 		<div class="col-lg-5 p-0 px-lg-3">
			<div class="tr-bg-highlight position-relative tr-mh-100">
				<tr-student-dashboard-progress></tr-student-dashboard-progress>
			</div>
		</div>	 -->	
		<div class="col-lg-4 p-0 ps-lg-3">
		   <div class="tr-bg-highlight position-relative tr-mh-100">
			<tr-student-dashboard-my-lessons></tr-student-dashboard-my-lessons>
		   </div>
		</div>
	</section>

	<section class="row px-0 m-0 tr-mt-2">
		<div class="col-12 p-0 m-0">
			<tr-student-dashboard-waiting-my-action></tr-student-dashboard-waiting-my-action>
		</div>
	</section>
</main>
<button class="border-0 p-0 m-0 d-none" data-bs-toggle="modal" data-bs-target="#lessonReschedulerModal" #openReschedulerModal></button>
<!-- Modal -->
<!-- Modal -->
<ng-container *ngIf="(
    (userLessonsService.lessonAction$ | async) ===  LESSON_PERMISSIONS.RESCHEDULE) 
    || ((userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.REQ_RESCHEDULE)
    || ((userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.GIVE_NEW_TIME)">
<div class="modal fade" id="lessonReschedulerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="reschedulerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen border-0 p-0 m-0">
    <div class="modal-content">
        <div class="tr-lessons-rescheduler-components">
         <tr-lesson-rescheduler></tr-lesson-rescheduler>
       </div>
    </div>
  </div>
</div>
</ng-container>
<tr-confirm-delete-popup></tr-confirm-delete-popup>
<tr-send-message-popup></tr-send-message-popup>