import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatMessageTime'
})
export class ChatMessageTimePipe implements PipeTransform {
  transform(timestamp: string): string {
    const messageTime = new Date(timestamp);
    const currentTime = new Date();

    if (this.isToday(messageTime, currentTime)) {
      return `Today ${this.formatTime(messageTime)}`;
    } else if (this.isYesterday(messageTime, currentTime)) {
      return `Yesterday ${this.formatTime(messageTime)}`;
    } else {
      return this.formatDateTime(messageTime);
    }
  }

  private isToday(date: Date, currentDate: Date): boolean {
    return (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  }

  private isYesterday(date: Date, currentDate: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  private formatTime(date: Date): string {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  private formatDateTime(date: Date): string {
    return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) +
      ' ' + this.formatTime(date);
  }

}


