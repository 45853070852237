import { Component, OnInit } from '@angular/core';
import { LessonDetailsService } from './../lesson-details-overview/lesson-details.service';
import { finalize } from 'rxjs/operators';
import { TRObject } from 'src/app/shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from "@angular/forms";
import { getFormattedTimeAgo } from '@shared/models/messages';
import { LOG_HISTORY_TYPE, LOG_TYPE_IMAGES } from '@shared/models/lesson';

@Component({
  selector: 'tr-lesson-log-history',
  templateUrl: './lesson-log-history.component.html',
  styleUrls: ['./lesson-log-history.component.css']
})
export class LessonLogHistoryComponent extends TRObject implements OnInit {
  lessonId:any;
  perPage:any = 10;
  isLoading:boolean = false;
  lessonLogs:any;
  LOG_HISTORY_TYPE:any = LOG_HISTORY_TYPE;
  LOG_TYPE_IMAGES:any = LOG_TYPE_IMAGES;

  constructor(public lessonDetailsService: LessonDetailsService,
    private fb: UntypedFormBuilder, 
    private responseHandlerService: ResponseHandlerService) { super()

  }

  ngOnInit(): void {
    this.lessonDetailsService.lessonId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if(data) {
           this.lessonId = data;
           this.getLessonDetails();        
        }
    });
  }

  formatTime(timestamp:any) {
    return getFormattedTimeAgo(timestamp);
  }

  getLessonDetails() {
    this.isLoading = true;
    this.lessonDetailsService
      .getLessonLogHistory(this.lessonId, this.perPage)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          let data = res['data']['logHistory'];
          if(data){
            this.lessonLogs = data;
            console.log(data);
          }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  isNewDate(currentDateStr: string, previousDateStr: string): boolean {
    const currentDate = new Date(currentDateStr);
    const previousDate = previousDateStr ? new Date(previousDateStr) : null;
    return !previousDate || currentDate.toDateString() !== previousDate.toDateString();
  }

  isToday(dateStr: string): boolean {
    const date = new Date(dateStr);
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }

}
