import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})

export class ConfirmationServiceService {

  public type$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public value$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public toggleModal$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendAgain$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }


  sendVerificationCode(form:any , isMobile:boolean = false){
    const endPoint: string = isMobile ? 'user-send-mobile-confirmation-code' : 'user-send-email-confirmation-code';
    return this.fetchCore(endPoint, form, HttpMethod.POST)
  }  

  updateEmail(email:any, code:any){
    const formData = new FormData();
    formData.append('email', email)
    formData.append('confirmation_code', code)
    return this.fetchCore('user-email', formData, HttpMethod.POST)
  }

  updateMobile(phone:any, code:any){
    const formData = new FormData();
    formData.append('mobileNumber', phone)
    formData.append('confirmation_code', code)
    return this.fetchCore('update-user-mobile', formData, HttpMethod.POST)
  }



}
