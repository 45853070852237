<main class="container">
    <div class="row justify-content-center">
        <div class="col-md-4 tr-book-lesson-details-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-8 right10 tr-book-lesson-details-dropdown-label">
                            Subject name
                        </div>
                        <div class="col-md-12 tr-book-lesson-details-select">
                            <div class="row top10">
                                <select class="tr-subject-select tr-book-lesson-details-input tr-select-place-holder bottom10" 
                                    [(ngModel)]="selectedSubject"
                                    (ngModelChange)="onSubjectSelect($event)">
                                  <option [disabled]="true" hidden selected>{{selectedSubject}}</option>
                                  <option *ngFor="let subject of subjects$ | async" [ngValue]="subject">{{subject.name}}</option>
                                </select>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row ">
                        <div class="col-md-8 right10 tr-book-lesson-details-dropdown-label ">
                            Speciality
                        </div>
                        <div class="col-md-12 tr-book-lesson-details-select">
                            <div class="row top10">
                                <select class="tr-speciality-select tr-book-lesson-details-input bottom10" 
                                [class.tr-select-disabled]="selectedSubject =='Select your Subject'" 
                                [disabled]="selectedSubject =='Select your Subject'" 
                                [(ngModel)]="selectedSpecialty" 
                                (ngModelChange)="onSpecialtySelect($event)">
                                <option [disabled]="true" hidden selected>{{selectedSpecialty}}</option>
                          <option *ngFor="let specialty of specialties$ | async" [ngValue]="specialty">{{specialty.name}}</option>
                        </select>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <ng-container *ngIf="selectedSpecialty !== 'Select your Specialty'">
                        <div class="col-md-8 right10 tr-book-lesson-details-dropdown-label ">
                            Your age group
                        </div>

                        <div class="col-md-12 px-0">
                            <div class="row tr-book-lesson-details-inputs-wrapper top10">
                                <div class="d-flex flex-column align-items-center gap-2">
                                  <ng-container *ngFor="let group of ageGroups$ | async">
                                    <label  (click)="checkInput(group.id)" class="col-md-12 tr-book-lesson-details-input top5 w-100" [ngClass]="{'tr-book-lesson-details-selected-group': selectedGroup == group.id}" >

                                    <input type="radio" [(ngModel)]="selectedAgeGroup" [value]="group.id" />
                                            {{group.name}}
                                    </label>
                                </ng-container>
                               </div>

                            </div>
                        </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</main>