<div
  #toastElement
  class="toast tr-toast fade toast-width row"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>

  <div class="tr-toast-body col-md-12 col-lg-12 d-flex align-items-center justify-content-between cursor-pointer" (click)="hide()" data-bs-toggle="tooltip" data-bs-placement="bottom" title="click to close">
    <img class="tr-toast-icon col-md-2 col-lg-2" src="assets/icons/toast-icons/{{icon}}.svg" alt="">
    <div class="row d-flex justify-content-start w-100">
        <p class="tr-toast-title col-md-11 col-lg-11" [ngClass]="type">{{ title }} </p>
        <p class="tr-toast-body col-md-11 col-lg-11 mb-0 "><span>{{ message }}</span> </p>
    </div>
  </div>
  
</div>