import { Component, OnInit, Input } from '@angular/core';
import { TutorProfileService } from '@components/student-components/tutor-profile/tutor-profile.service';
import { finalize } from "rxjs/operators";
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";

@Component({
  selector: 'tr-tutor-profile-reviews',
  templateUrl: './tutor-profile-reviews.component.html',
  styleUrls: ['./tutor-profile-reviews.component.css']
})
export class TutorProfileReviewsComponent implements OnInit {
  @Input() tutorId:any;
  isLoading:boolean = false;
  perPage:any = 5;
  page:any = 1;
  lastPage:any;
  reviews:any;
  constructor(private tutorProfileService:TutorProfileService, 
    private responseHandlerService: ResponseHandlerService) { }

  ngOnInit(): void {
    this.getTutorReviews()
  }

  getTutorReviews(load?:any){
    const formData = new FormData();
    formData.append('tutorId', this.tutorId);
    formData.append('perPage', this.perPage);
    formData.append('page', this.page);
    this.tutorProfileService.getTutorReviews(formData)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          const reviews = res['data'];
          if (load && reviews.length > 0) {
            this.reviews = [...this.reviews, ...reviews];
          } else {
            this.reviews = reviews;
          }
          this.lastPage = res['meta']['last_page'];
          this.page = res['meta']['current_page']
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  loadMoreReviews(){
    if(this.page < this.lastPage){
       this.page = this.page + 1; 
       this.getTutorReviews(true);
    }
  }

}
