import { Injectable } from '@angular/core';
import { ToastService } from "@components/shared-components/toast.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {

  constructor(private toastService: ToastService, private router: Router) {}

  handleSuccessRessponse(res: any) {
    this.toastService.showSuccessToast("Success !", res);
  }

  handleFailureResponse(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 400 || 
          error.status === 422 || 
          error.status === 404 || 
          error.status === 500) {
        this.toastService.showErrorToast("Error!", error["error"].message);
      }
      // else if(error.status === 401){
      //    this.router.navigate(['/login']);
      // }
    } 
    else {
      this.toastService.showErrorToast("Error!", error.message);
    }
  }

  showErrorMessage(message:any){
    this.toastService.showErrorToast("Error!", message);
  }
}
