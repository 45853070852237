import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { FormDataService } from './form-data.service';

@Injectable()
export class FormDataResolver implements Resolve<Observable<any>> {
  constructor(private formDataService: FormDataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (route.url[0].path === 'find-tutor') {
      return this.formDataService.getFindTutorFilters(route.data);
    } else {
      return this.formDataService.getFormData(route.data);
    }
  }
}
