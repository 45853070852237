import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { favSavedTutorIcon } from 'src/app/shared/models/svgIcons';
import { FindTutorService } from '@studentComponents/student-tutors/find-tutor/find-tutor.service';
import { TutorTileComponent } from '../tutor-tile/tutor-tile.component';
import { TRObject } from '@shared/models/shared';
import { takeUntil } from 'rxjs/operators';
import * as bootstrap from 'bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tr-saved-tutors',
  templateUrl: './saved-tutors.component.html',
  styleUrls: ['./saved-tutors.component.css']
})

export class SavedTutorsComponent extends TRObject implements OnInit {
  @ViewChildren(TutorTileComponent) tutorTiles!: QueryList<any>;
  saveSvgPath: string = favSavedTutorIcon;
  savedTutors: any;
  savedTutorsLength: number = 0;
  isListView: any;
  isLoading:boolean = true;
  targetedTutor:any;
  showPlayer:boolean = false;

  constructor(public findTutorService: FindTutorService) {
    super();
    this.isListView = this.findTutorService.isListView$
    this.getSavedTutorsList();
  }

  ngOnInit(): void {
    this.findTutorService.unfavedTutorId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (id: any) => {
        this.removeTutorById(id)
      }
    )
  }

  getSavedTutorsList() {
    this.findTutorService.getSavedTutors().pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res) {
          this.savedTutors = res['data'];
          this.targetedTutor = this.savedTutors[0];
          this.findTutorService.targetedTutorTile$.next(this.targetedTutor)
          this.savedTutorsLength = this.savedTutors.length;
        }
      },
      (error: any) => {
      }
    )
  }

  removeTutorById(id: number) {
    this.savedTutors = this.savedTutors.filter((tutor: any) => tutor.id !== id);
    this.savedTutorsLength = this.savedTutors.length;
  }

  getTutorOnHover(tutor: any) {
    this.targetedTutor = tutor;
    this.findTutorService.targetedTutorTile$.next(this.targetedTutor)
  }

  playVideo(){
    const modalElement = document.getElementById('videoModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
    this.showPlayer = true;
  }


}