import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { data } from 'jquery';

@Component({
  selector: 'tr-grid-paginator',
  templateUrl: './grid-paginator.component.html',
  styleUrls: ['./grid-paginator.component.css']
})

export class GridPaginatorComponent implements OnInit {
  totalPages!: number[];
  itemPerPage: number = 2;
  currentPage: number = 1;
  _itemsPerPage: number = 1;
  _totalRecords: number = 1;
  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  @Output() previous: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemPerPageChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() set itemsPerPage(value: any) {
    this._itemsPerPage = value;
    this.totalPages = this.generatePageNumbers();
  }

  @Input() set totalRecords(value: any) {
    this._totalRecords = value;
    this.totalPages = this.generatePageNumbers();
  }

  constructor() { }
  
  ngOnInit(): void {
    this.totalPages = this.generatePageNumbers();
    this.itemPerPageChange.subscribe(
      (data: any) => {
        this.itemPerPage = data;
        this.totalPages = this.generatePageNumbers();
      }
    )
  }

  generatePageNumbers() {
    const totalPages = Math.ceil(this._totalRecords / this._itemsPerPage);
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  nextPage() {
    if (this.currentPage < this.totalPages.length) {
      this.currentPage += 1;
      this.next.emit(this.currentPage);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.previous.emit(this.currentPage);
    }
  }

  selectPage(selectedPage: number) {
    this.currentPage = selectedPage
  }

  getSelectedItemsPerPage(e: any) {
    const itemPerPage: any = e.target.value;
    this.itemPerPageChange.emit(itemPerPage);
  }

}

