<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none border-0" data-bs-toggle="modal" data-bs-target="#deleteModal" #openCodeConfirmationModal></button>
<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content p-4">
      <div class="modal-body mt-2 d-flex flex-column align-items-center justify-content-center px-4">
        <img src="assets/images/delete-subject.svg" alt="delete-subject" class="tr-delete-icon">
        <div class="d-flex flex-column align-items-center justify-content-center gap-2 mt-3">
          <span class="tr-sub-title-dark-text">Cancel this lesson ?</span>
          <span class="tr-sub-title-lite-text tr-font-14">Are you sure you want to cancel lesson?</span>
        </div>
        <div class="d-flex align-items-center gap-4 mt-4 w-75">
          <button class="tr-btn-outline-primary w-100 tr-btn" data-bs-dismiss="modal" aria-label="Close" #closeCodeConfirmationModal>Discard</button>
          <button class="tr-btn-danger w-100" (click)="confirmDelete()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>