<div class="modal fade modal-lg" id="addCertificateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addCertificateModal" aria-hidden="true">
   <div class="modal-dialog">
      <div class="modal-content tr-bd-6">
         <div class="modal-body tr-padding-2">
         	<div>
            	<img src="assets/icons/close-icon.svg" alt="close-icon" class="float-end d-md-none" data-bs-dismiss="modal">
            	<h6 class="tr-reg-popup-title mb-4 mb-md-3">Add certificate</h6>
         	</div>
            <p class="tr-reg-popup-desc"> Do you have teaching certificates? If so, describe them to enhance your profile credibility and get more students. </p>
            <form [formGroup]="addCertificateForm">
               <div class="row p-0 m-0 tr-row-gap-2">
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study">Years of study</label>
                        <div class="d-flex mt-2 w-100">
                           <div class="d-flex align-items-center flex-grow-1">
                              <input type="text" id="from" placeholder="from" formControlName="yearsOfStudyFrom" class="br-none tr-year-input" onfocus="(this.type='date')">
                           </div>
                           <span class="tr-form-divider m-auto"></span>
                           <div class="d-flex align-items-center flex-grow-1">
                              <input type="text" id="to" formControlName="yearsOfStudyTo" placeholder="to" class="bl-none tr-year-input" onfocus="(this.type='date')">
                           </div>
                        </div>
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.yearsOfStudyFrom">
                           <ng-container *ngIf="addCertificateForm.controls['yearsOfStudyFrom'].hasError(error.type) && (addCertificateForm.controls['yearsOfStudyFrom'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>                        
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.yearsOfStudyTo">
                           <ng-container *ngIf="addCertificateForm.controls['yearsOfStudyTo'].hasError(error.type) && (addCertificateForm.controls['yearsOfStudyTo'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study" class="mb-2">Subject</label>
                        <select class="form-select" (change)="onSelectSubject($event)">
                           <option hidden >Select subject</option>
                           <option *ngFor="let subject of subjects | async" [value]="subject.id">
                              {{subject.name}}
                           </option>
                        </select>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="rear-of-study" class="mb-2">Certificate</label>
                        <select class="form-select" formControlName="certificateId">
                           <option hidden >Select certificate</option>
                           <option *ngFor="let certificate of certificates | async" [value]="certificate.id">
                              {{certificate.name}}
                           </option>
                        </select>
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.certificateId">
                           <ng-container *ngIf="addCertificateForm.controls['certificateId'].hasError(error.type) && (addCertificateForm.controls['certificateId'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="year-of-study" class="mb-2">Issued by</label>
                        <input type="text" placeholder="Issued by" id="issued_by" formControlName="issuedBy" class="form-control">
                        <div class="col-12 top5" *ngFor= "let error of errorMessages.issuedBy">
                           <ng-container *ngIf="addCertificateForm.controls['issuedBy'].hasError(error.type) && (addCertificateForm.controls['issuedBy'].touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="form-group d-flex flex-column align-items-start">
                        <label for="Attachment" CLASS="mb-2">Attachment</label>
                        <ngx-dropzone (change)="certificateImage($event)" style="flex-direction: column-reverse;height: auto !important; min-height: 180px;width: 100%; justify-content: center; border:1px dashed #BEBEBE" accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" [maxFileSize]=" 2000000" [multiple]="false">
                           <div class="row">
                              <div class="col-md-12 text-center">
                                 <div class="row">
                                    <div class="col-md-12 col-lg-12" *ngIf="!file || file == ''">
                                       <div class="d-flex align-items-center flex-column mb-3">
                                          <img src="../../assets/icons/upload-file.svg" class="tr-drop-zone-upload-file mb-3" alt="upload-logo" >
                                          <span class="tr-drop-zone-info-label mb-1">Drag attachment here</span>
                                          <ngx-dropzone-label class="tr-drop-zone-file-types m-0" >(PNG, JPG, PDF max. size 2MB)
                                          </ngx-dropzone-label>
                                       </div>
                                       <div class="tr-drop-zone-browse-shape m-auto d-flex align-items-center justify-content-center top10">
                                          <span>Browse</span>
                                       </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                       <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of file" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                       <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                       </ngx-dropzone-image-preview>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ngx-dropzone>
                     </div>
                  </div>
                  <div class="col-12">
                      <div class="d-flex align-items-center justify-content-center gap-3">
                          <button class="btn-discard tr-btn" data-bs-dismiss="modal" (click)="clearForm()" #closeModal>Discard</button>
                          <button class="btn-add tr-btn" (click)="addCertificate()" [disabled]="addCertificateForm.invalid || !file || file == '' || disable">Add Certificate</button>
                      </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>