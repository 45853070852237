import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tr-tutor-registration-form-header',
  templateUrl: './tutor-registration-form-header.component.html',
  styleUrls: ['./tutor-registration-form-header.component.css']
})
export class TutorRegistrationFormHeaderComponent implements OnInit {
  @Input() tabClasses: any = []
  @Input() title: any = ''
  constructor() { }

  ngOnInit(): void {
  }

}
