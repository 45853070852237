<main class="student-booking-checkout-wrapper ">
  <section class="tr-px-2 tr-pb-2 tr-px-mb-0">
    <div class="row p-0 m-0">
      <div class="col-md-8 py-0 ps-0 pe-md-3 m-0 d-flex flex-column tr-flex-gap-2">
        <section class="tr-wallet-sec tr-bg-highlight p-4">
          <h2 class="tr-main-title">My wallet balance</h2>
          <div class="tr-checkout-my-wallet-balance row top10 p-2">
            <div class="">
              <span class="tr-checkout-my-balance-label ">My Balance</span> | <span class="tr-checkout-my-balance-total-amount"><span trCurrency></span> {{walletBalance$ |async}}</span>
            </div>
            <div class="">
              <img src="assets/icons/wallet-balance-warning.svg" alt="">
              <span class="left5 tr-checkout-my-balance-warning-label">The lesson cost will be deducted from your current balance</span>
            </div>
          </div>
        </section>
        <section class="tr-wallet-sec tr-bg-highlight p-4" #paymentOptionsSection>
          <h2 class="tr-main-title mb-0 p-0">Saved Card</h2>
          <tr-saved-cards [savedCards]="savedPaymentMethods$ |async" (cardDetails)="getSelectedCard($event)"></tr-saved-cards>
        </section>
        <section class="p-4 tr-bg-highlight" #paymentOptionsSection>
          <h2 class="tr-main-title mb-0 p-0">Select Payment method</h2>
          <div class="tr-pt-2">
            <tr-form-notification></tr-form-notification>
          </div>
          <ng-container *ngFor="let method of paymentGateways$ | async">
            <section (click)="paymentMethod = method;createPaymentIntent();" class="tr-pt-2">
              <div class="py-3 px-4 tr-bd-highlight d-flex align-items-center cursor-pointer justify-content-between bottom5">
                <div class="tr-paymnt-mthd">
                  <div class="d-flex align-items-center justify-content-start">
                    <label class="checkbox mt-2">
                      <input type="radio" [checked]="method" [(ngModel)]="paymentMethod" [value]="method" class="tr_checkbox__input" />
                      <span class="tr_checkbox__inner"></span>
                    </label>
                    <div class="tr-radio-label ms-4">
                      <p class="mb-0">{{method.name}}
                        <img src="assets/images/visa-saved-card.svg" alt="" class="ms-4">
                      </p>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <ng-container *ngIf="selectedPaymentOption?.name === GATEWAYS.STRIPE && method.name === GATEWAYS.STRIPE">
                <tr-stripe-payment [clientSecret]="checkoutClientKey$ | async" [isSubmitButtonClicked]="checkoutPay$ |async" (isSaveCard)="getCardSaveOption($event)" [isForSaveCard]="false" (isSubmissionSucceeded)="isPaymentProcessed($event)"></tr-stripe-payment>
              </ng-container>
            </section>
          </ng-container>
        </section>
        <section #paymentOptionsSection>
          <ul class="m-0 ps-3">
            <li>Tutor Reach uses Open Exchange Rates services for our currency conversion estimation. Actual exchange rates are subject to your bank and may vary. </li>
            <li>Your final payment will be made in UK.</li>
          </ul>
        </section>
      </div>
      <div class="col-md-4 p-0 m-0 ps-md-3 pe-0">
        <div class="tr-bg-highlight">
          <section class="p-4 d-flex tr-bdb">
            <div class="tr-profile-img me-3">
              <img [src]="selectedTutor?.profilePicture" alt="">
            </div>
            <div class="tr-profile-info w-100">
              <div class="d-flex align-items-center gap-3">
                <h4 class="tr-tutr-name mb-0 p-0">{{ selectedTutor?.firstName}}.</h4>
                <span class="fi" [ngClass]="'fi-' + selectedTutor?.flag"></span>
              </div>
              <span class="tr-lite-text">Certified tutor</span>
              <p class="tr-rate d-flex align-items-center mb-3">
                <img src="assets/icons/rate.svg" alt="rate-icon" class="me-1">
                {{ selectedTutor?.rate}}
              </p>
              <div class="tr-lesson-type mb-3 d-flex align-items-start gap-3">
                <span> subject</span>
                <p class="mb-0">  {{bookLessonService.bookLessonTypeDetails$.value.lessonSubject.name}}</p>
              </div>
              <div class="tr-lesson-type mb-3 d-flex align-items-start gap-3">
                <span>Lesson type</span>
                <p class="mb-0"> {{bookLessonService.selectedLessonType$.getValue()}} </p>
              </div>
            </div>
          </section>
          <section class="p-4 tr-bdb">
            <h2 class="tr-main-title mb-4 p-0">Booking summary</h2>
            <section class="p-0">
              <h2 class="tr-main-title mb-4 p-0">Order Cost</h2>
              <p class="tr-booking-checkout-cost-title">Lesson cost</p>
              <div class="tr-booking-checkout-cost-list">
                <ng-container *ngFor="let lesson of lessonsTimeSummary$ | async">
                  <div class="tr-booking-checkout-lits-item d-flex justify-content-between">
                    <div>
                      <p class="tr-booking-checkout-duration">
                        {{lesson.period}} mins lesson
                      </p>
                      <p class="tr-booking-checkout-time">
                         {{ lesson.lessonDateTime | date : "EEE dd MMM" }} ,
                        {{ lesson.lessonDateTime | date : "hh:mm a" | lowercase }}
                      </p>
                    </div>
                    <div class="tr-booking-checkout-lesson-price-item top10"><span trCurrency></span> {{lesson.price}}</div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="discount$.getValue() != 0" class="tr-booking-checkout-bottom-labels mb-0 d-flex justify-content-between mt-3">
                <span>Discount</span>
                <p class="mb-0 tr-txt-green">{{ (discount$ | async) * 100 | number:'1.0-0' }}%</p>

              </div>
              <div class="tr-booking-checkout-bottom-labels mb-0 d-flex justify-content-between mt-3">
                <span>Cancellation</span>
                <p class="mb-0 tr-txt-green"> Free </p>
              </div>
            </section>
          </section>
          <section class="p-4 tr-bdb">
            <div class="tr-booking-checkout-bottom-labels mb-0 d-flex justify-content-between">
              <span>Sub-total</span>
              <p class="mb-0">
                <span *ngIf="(discount$ | async) > 0" class="tr-striketrough me-2"> 
                    <span trCurrency></span>{{totalPrice | async}}
                </span>
                <span>
                   <span trCurrency></span> {{ totalPriceWithDiscount | async  }}
                </span>
             </p>
            </div>
            <div class="tr-booking-checkout-bottom-labels d-flex justify-content-between mt-4">
              <span>Processing fee</span>
              <p class="mb-0">{{processingFees$ | async}}</p>
            </div>
          </section>
          <section class="p-4">
            <div class="tr-lesson-total mb-4 d-flex justify-content-between">
              <span class="tr-booking-checkout-bottom-labels">Total</span>
              <p class="mb-0 tr-total-price"><span trCurrency></span>  {{ ((totalPriceWithDiscount | async) + (processingFees$ | async)) | number:'1.2-2' }}</p>
            </div>
            <button (click)="pay()" class="px-3 py-2" [disabled]="!validatePayButton()" [ngClass]="{
                'tr-checkout-btn': validatePayButton(),
                'tr-checkout-btn-disabled': !validatePayButton()
              }">
               <span *ngIf="!isLoading">Pay {{ ((totalPriceWithDiscount | async) + (processingFees$ | async)) | number:'1.2-2' }} </span>
                <tr-spinner *ngIf="isLoading"></tr-spinner>
             </button>
          </section>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="(isPaymentSucceeded$ | async)">
    <div class="tr-student-payment-success">
      <tr-student-booking-payment-success (isClosed)="isPaymentSuccessPopupClosed($event)" [paymentDetails]="succeededPaymentDetails$|async"></tr-student-booking-payment-success>
    </div>
  </ng-container>
</main>