<div class="container-fluid mx-0 p-0 d-flex">
    <div class="tr-side-panel-col" id="tr-sidepanel-col">
         <tr-side-panel sidePanelTitle="Lessons" [componentName]="sideNavItemNames.TUTORLESSONS"></tr-side-panel>
    </div>
    <div class="w-100 mx-lg-100">
        <div class="row p-0 m-0 d-flex tr-px-lg-4">
            <div class="col-md-12 p-0">
                <router-outlet></router-outlet> 
            </div>
         </div>
    </div>
</div>

<!-- Button trigger modal -->
<button class="border-0 p-0 m-0 d-none" data-bs-toggle="modal" data-bs-target="#lessonReschedulerModal" #openReschedulerModal></button>

<!-- Modal -->
<ng-container *ngIf="(
    (userLessonsService.lessonAction$ | async) ===  LESSON_PERMISSIONS.RESCHEDULE) 
    || (userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.REQ_RESCHEDULE || ((userLessonsService.lessonAction$ | async) === LESSON_PERMISSIONS.GIVE_NEW_TIME)">
<div class="modal fade" id="lessonReschedulerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="reschedulerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen border-0 p-0 m-0">
    <div class="modal-content">
        <div class="tr-lessons-rescheduler-components">
         <tr-lesson-rescheduler></tr-lesson-rescheduler>
       </div>
    </div>
  </div>
</div>
</ng-container>
<tr-confirm-delete-popup></tr-confirm-delete-popup>
<tr-send-message-popup></tr-send-message-popup>