import { Injectable, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})
export class StudentDashboardService {

  constructor(private trHttp: TrHttpService) {}

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

   filterLessons(params: any) {
    return this.fetchCore('user-lessons-requests', params)
  }

  getUserChats(){
    const formData = new FormData();
    formData.append('perPage', '3');
    formData.append('page', '1');
    return this.fetchCore('get-user-chats' , formData);
  } 

  getCurrentTutors() {
    const formData = new FormData();
    formData.append('perPage', '3');
    return this.fetchCore('student-tutors', formData)
  }  

  getUserStats() {
    return this.fetchCore('get-user-stats')
  }

}
