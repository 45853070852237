<div class="tr-padding-2 tr-bg-highlight">
	<h3 class="tr-sub-title-dark-text tr-font-18 mb-0 p-0"> Reviews </h3>
	<section>
		<div class="tr-review-row d-flex gap-2 py-3" [ngClass]="{'tr-bdb': !last}" *ngFor="let review of reviews; let last = last">
			<img [src]="review?.studentImage" alt="user-image" class="tr-user-img" *ngIf="review.studentImage">
			<img src="assets/images/profile-placeholder.png" alt="placeholder-image" class="tr-user-img" *ngIf="!review.studentImage">
			<div>
				<div class="d-flex gap-3 mb-2">
					<h4 class="tr-bold-text mb-0"> {{ review.studentName }} </h4>
					<div class="tr-bold-text d-flex align-items-center">
						<img src="assets/icons/rate.svg" alt="rate-svg" class="me-1">
						 {{ review.rating  | number: '1.0-0' }}
					</div>
				</div>
				<p class="tr-review-text mb-0"> {{ review.comment }} </p>
				<span class="tr-time">{{ review.reviewDate | date : "EEE dd MMM" }}</span>
			</div>
		</div>
		<div *ngIf="reviews && reviews.length == 0" class="mt-3 tr-sub-title-lite-text tr-font-14">
			<p>No reviews found... </p>
		</div>	
		<div *ngIf="page < lastPage">	
			<button (click)="loadMoreReviews()" class="border-0 bg-transparent w-100 text-center tr-sub-title-lite-text tr-font-12">Load more reviews ...</button>
		</div>			
   </section>
</div>