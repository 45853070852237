import { Component, OnInit, SkipSelf } from '@angular/core';
import { ReschedulerService } from '../rescheduler.service';
import { SLOT_DURATIONS, extractLessonDateTime } from '@shared/models/scheduler';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';
import { BehaviorSubject } from 'rxjs';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from "@angular/forms";
import { formValidationMessages } from 'src/app/shared/models/validation';
import { SchedulerNextService } from '@components/scheduler-next/scheduler-next.service';
@Component({
  selector: 'tr-lesson-rescheduler-right-panel',
  templateUrl: './lesson-rescheduler-right-panel.component.html',
  styleUrls: ['./lesson-rescheduler-right-panel.component.css']
})
export class LessonReschedulerRightPanelComponent extends TRObject implements OnInit {
  selectedReason:any = null; 
  constructor(@SkipSelf() public reschedulerService: ReschedulerService, 
    public schedulerNextService : SchedulerNextService,
    private fb: UntypedFormBuilder) {
    super()
  }

  public requestRescheduleForm = this.fb.group({
         reasonId: ["", Validators.required],
         note: ["", Validators.required],
  });

    public errorMessages = {
      reasonId: formValidationMessages['reasonId'],
      note: formValidationMessages['note'],
  }

  extractLessonDateTime: Function = extractLessonDateTime;
  rescheduleLesson$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;

  ngOnInit(): void {
    this.schedulerNextService.selectedSlots$.pipe(takeUntil(this.ngUnsubscribe),).subscribe(
        (selectedSlots: any) => {
          console.log(selectedSlots);
          this.rescheduleLesson$.next(this.mergeSlotsByLessonKey(selectedSlots));
        }
      );
  }

  mergeSlotsByLessonKey(slots: any) {
    const mergedSlots: any = [];
    const mergedSlotMap: any = {};
    slots.forEach((slot: any) => {
      const { lessonKey, duration, ...rest } = slot;

      if (!lessonKey || !mergedSlotMap[lessonKey]) {
        mergedSlots.push({ lessonKey, duration: SLOT_DURATIONS.THIRTY_MINS, ...rest });
        mergedSlotMap[lessonKey] = mergedSlots[mergedSlots.length - 1];
      } else {
        mergedSlotMap[lessonKey].duration += SLOT_DURATIONS.THIRTY_MINS;
      }
    });

    return mergedSlots;
  }

 checkFormStatus(){
   let status = this.requestRescheduleForm.valid;
   this.reschedulerService.reschedulerFormStatus$.next(status);
   this.reschedulerService.requestRescheduleData$.next(this.requestRescheduleForm.value);
 }

 removeLesson(date:any){
      this.schedulerNextService.removeSlots$.next(date);
 }

}
