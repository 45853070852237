import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-tutor-reg-under-review',
  templateUrl: './tutor-reg-under-review.component.html',
  styleUrls: ['./tutor-reg-under-review.component.css']
})
export class TutorRegUnderReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
