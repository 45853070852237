<div class="edit-slot-modal modal fade show d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <section class="">
        <div class=" tr-scheduler-prompt">
          <div class="row justify-content-center">
            <div class="tr-extend-lesson-title tr-extend-lesson-prompt-text col-md-12 col-lg-12 top25"> you selected 30 min lesson, do you want to extend lesson duration <br>
            </div>
            <div class="tr-prompt-btns d-flex justify-content-around col-md-6 col-lg-6 top25">
              <ng-container *ngIf="Extend60Mins">
                <div class="d-flex justify-content-between cursor-pointer" (click)="checkButton('radio-60')">
                  <input [(ngModel)]="extendPeriod" class="tr-scheduler-prompt-option-btn" id="radio-60" type="radio" value="60" />
                  <span class="tr-extend-lesson-prompt-text">60 mins</span>
                </div>
              </ng-container>
              <ng-container *ngIf="Extend90Mins">
                <div class="d-flex justify-content-between cursor-pointer" (click)="checkButton('radio-90')">
                  <input [(ngModel)]="extendPeriod" class="tr-scheduler-prompt-option-btn" id="radio-90" type="radio" value="90" />
                  <span class="tr-extend-lesson-prompt-text">90 mins</span>
                </div>
              </ng-container>
            </div>
            <div class="tr-prompt-btns d-flex justify-content-around col-md-8 col-lg-8 top25 bottom20">
                <input (click)="cancelExtendClass()" type="button" value="No, keep 30 mins" class="tr-extend-lesson-cancel"/>
                <input (click)="submitExtend()"  [disabled]="_extendPeriod == null" type="button" value="Extend" class="tr-extend-lesson-extend"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>