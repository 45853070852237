import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({ name: 'safe',  standalone: true })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(url: any) {
    const urlWithAutoplay = url + '?autoplay=1&showinfo=0&controls=0&rel=0&modestbranding=1';
    return this.domSanitizer.bypassSecurityTrustResourceUrl(urlWithAutoplay);
  }
}
