import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,  Subject} from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod, TRObject, generateUniqueKeyFromDateTime } from '@shared/models/shared';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { getRecurringScheduleSlots } from '@shared/models/scheduler';


@Injectable({
  providedIn: 'root'
})

export class TutorProfileService {
  suggestedTutors$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectedTutorIdId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  openBookingWindow$: Subject<any | null> = new Subject<any | null>();


  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getTutorProfile(formData:any){
    return this.fetchCore('tutor-profile-data', formData);
  }  

  getTutorReviews(formData:any){
    return this.fetchCore('get-tutor-all-reviews', formData);
  }

  toggleTutorFavorite(id: any, isFav: boolean) {
    return isFav === undefined || isFav === true ? this.unfavorite(id, isFav) : this.favTutor(id);
  }

  unfavorite(id: any, isFav: any) {
    return this.fetchCore('user-favourite/' + id, '', HttpMethod.DELETE)
  }

  favTutor(id: any) {
    const formData = new FormData();
    formData.append('favId', id);
    return this.fetchCore('user/favourites', formData, HttpMethod.POST)
  }

  getTutorsFirstLoad(timezone?:any) {
    const formData = new FormData();
    formData.append('perPage', '8');
      if(timezone){
         formData.append('timezoneId', timezone);
      }
      this.fetchCore('find-tutors', formData).subscribe(
        (response: any) => {
         // Get the currently selected tutor ID
          const selectedTutorId = this.selectedTutorIdId$.getValue();
          const filteredData = response['data'].filter((tutor: any) => tutor.id != selectedTutorId);
          this.suggestedTutors$.next(filteredData);
        },
        (error: any) => {
          console.error(error);
        }
      );
  }

}
