import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@components/app-components/login-registration/login/login.component';
import { LandingPageComponent } from '@components/landing-page/landing-page.component';
import { TutorRegisterComponent } from '@components/app-components/login-registration/tutor-registration/tutor-register.component'
import { StudentRegisterComponent } from '@components/app-components/login-registration/student-registration/student-register.component';
import { LogoutComponent } from '@components/app-components/login-registration/logout/logout.component';
import { FormDataResolver } from '../core/form-data-service.resolver';
import { NotLoggedInGuard } from '@auth/not-logged-in.guard';
import { ClearTokenGuard } from '@auth/clear-token.guard';
import { ForgotPasswordComponent } from '@components/app-components/login-registration/forgot-password/forgot-password.component';
import { ResetpasswordGuard } from '@components/app-components/login-registration/resetpassword.guard';
import { ResetPasswordComponent } from '@components/app-components/login-registration/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {
    path: 'home', component: LoginComponent
  },  
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },  
{
    path: 'reset-password',
    component: ResetPasswordComponent,
    pathMatch: 'full',
    canActivate: [ResetpasswordGuard], // Use the route guard
  },
  {
    path: 'login', component: LoginComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'logout', component: LogoutComponent,
    canActivate: [ClearTokenGuard]
  },
  {
    path: 'register-tutor/:ref', component: TutorRegisterComponent,
    data:{"tutorRoute":true},
    resolve: { formData: FormDataResolver },
  },  
  {
    path: 'register-tutor', component: TutorRegisterComponent,
    data:{"tutorRoute":true},
    resolve: { formData: FormDataResolver },
  },
  {
    path: 'register-student', component: StudentRegisterComponent,
    resolve: { formData: FormDataResolver },
    data:{"studentRoute":true},
  },  
  {
    path: 'register-student/:ref', component: StudentRegisterComponent,
    resolve: { formData: FormDataResolver },
    data:{"studentRoute":true},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [FormDataResolver]
})
export class AppRoutingModule { }
