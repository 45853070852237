import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { searchDropdownIcon } from '@shared/models/svgIcons';
import { ChatService } from '@components/shared-components/chat/chat.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';
import { AuthService } from '@core/auth/auth.service';
import { chatSendIcon } from '@shared/models/svgIcons';
import { UntypedFormBuilder, UntypedFormArray, FormGroup, Validators } from "@angular/forms";
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { PusherService } from 'src/app/shared/services/pusher.service';

@Component({
  selector: 'tr-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.css'],
})
export class ChatConversationComponent extends TRObject implements OnInit {
  @Output() toggleMenu = new EventEmitter<any>();
  @ViewChild('chatScrollContainer', { static: false })
  chatScrollContainer?: ElementRef;
  hideMenu: boolean = false;
  isLoading: boolean = false;
  isSideMenuLoading:any;
  searchDropdownIcon: string = searchDropdownIcon;
  userConverstaion: any;
  files: File[] = [];
  uploadImageStatus: any;
  searchTextId: any;
  userId: any;
  hideTutorProfileMenu: boolean = false;
  showConversation: boolean = false;
  selectedUser:any;
  message:string ='';
  currentPage: any = 1;
  lastPage: any;
  selectedChatId: any;
  textareaHeight = 50;
  chatSendIcon:string = chatSendIcon;

  constructor(
    private responseHandlerService: ResponseHandlerService,
    private chatService: ChatService,
    private fb: UntypedFormBuilder,
    private pusherService: PusherService,
    private authService: AuthService
  ) {
    super();
    this.notifyNewMessage();
    this.isSideMenuLoading = this.chatService.sideMenuLoading$;
    this.detectUserChanges();
    this.detectChatIdChanges();
    this.userId = this.authService.userId$.value;
  }

  public chatForm = this.fb.group({
         chat_id: ["", Validators.required],
         body: ["", Validators.required],
         attachment : [""]
  });

  ngOnInit(): void {}

  ngOnDestroy(){
    this.chatService.clearChatData();
  }

  addDocument(event: any) {
    event.rejectedFiles.forEach((file: any) => {
    if (file.reason === 'type') {
        this.uploadImageStatus =
       'File has wrong format ("image/jpeg,image/jpg,image/png,image/gif,application/pdf,application/msword,application")';
    } else if (file.reason === 'size') {
        this.uploadImageStatus = 'File too big (max file size is 2mb)';
    } else {
        this.uploadImageStatus = 'File not added';
    }
        this.responseHandlerService.showErrorMessage(this.uploadImageStatus);
    });
        this.files.push(...event.addedFiles);
        this.chatForm.patchValue({
          attachment : this.files
        });
        this.uploadImageStatus = '';
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  hideConversation() {
    this.toggleMenu.emit(false);
  }

  detectChatIdChanges(){
    this.chatService.chatId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((id: any) => {
        if (id) {
          this.isLoading = true;
          this.selectedChatId = id;
          this.chatForm.patchValue({
               chat_id : this.selectedChatId
          })
          this.getConversation(this.selectedChatId);
        }
    });
  }

  detectUserChanges(){
    this.chatService.selectedChatUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: any) => {
        if (user) {
           this.selectedUser = user;
        }
      });
  }

  adjustTextareaHeight() {
    const lineHeight = 50;
    const lines = this.message.split('\n').length;
    this.textareaHeight = lines * lineHeight;
  }

  getConversation(id: any) {
    this.chatService
      .getConversationById(id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.setResponseData(res);
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }


  goToSelectedChat(id: any) {
    this.searchTextId = id;
    if (this.chatScrollContainer) {
      const section = this.chatScrollContainer.nativeElement;
      const id = document.getElementById(this.searchTextId);
      if (section && id) {
        section.scrollTop = id.offsetTop - 32;
        id.style.background = '#E1FEE0';
        setTimeout(() => {
          id.style.background = 'transparent';
        }, 5000);
      }
    }
  }

  scrollToBottom() {
   setTimeout(() => {
      if (this.chatScrollContainer) {
          const element: HTMLElement = this.chatScrollContainer.nativeElement;
          element.scrollTop = element.scrollHeight;
      }
    }, 100);

  }

  public removeAllFiles() {
    this.files = [];
    this.chatForm.patchValue({
       attachment : ''
    });
  }
  
  setResponseData(res:any){
      if (res['data']) {
        this.userConverstaion = res['data'];
        setTimeout(() => {
          this.scrollToBottom();
      }, 100);
      this.currentPage = res['current_page'];
      this.lastPage = res['last_page'];
    }
  }

  sendMessage(){
    this.chatService
      .sendMessage(this.chatForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.userConverstaion.unshift(res);
          this.scrollToBottom();
          this.message = '';

        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

  loadMoreMessages(page:any){
    this.chatService
      .getConversationById(this.selectedChatId , page)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.currentPage = res['current_page'];
          this.lastPage = res['last_page'];
          if (res['data']) {
              const messages = res['data']; 
              this.userConverstaion.push(...messages);
          }
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
    );
  }

  notifyNewMessage(){
    const channelName = `chat-${ this.chatService.chatId$.value}`;
    this.pusherService.subscribeToChannel(channelName, 'new-message', (message:any) => {
      if(message){
        this.userConverstaion.unshift(message);
        this.scrollToBottom();
      }
    });
  }

}