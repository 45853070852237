import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-find-tutor-guest',
  templateUrl: './find-tutor-guest.component.html',
  styleUrls: ['./find-tutor-guest.component.css']
})
export class FindTutorGuestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
