<section class="tr-transaction-table py-4">
    <table datatable [dtOptions]="dtOptions"  class="table table-bordered mt-0">
         <thead>
           <tr>
             <th [attr.data-orderable]="column.sortable" *ngFor= "let column of columnNames">{{column.title}}</th>
             <th data-orderable="false" data-priority="1">  </th>
             <th data-orderable="false">  </th>
           </tr>
         </thead>
         <tbody>
           <tr *ngFor= "let data of tableData">
             <td data-label="#">{{ data.id }}</td>
             <td data-label="Invoice no.">{{data.invoice_num}}</td>
             <td data-label="From">{{data.from}}</td>
             <td data-label="To">{{data.to}}</td>
             <td data-label="Subject">{{data.subject}}</td>
             <td data-label="Item">{{data.item}}</td>
             <td data-label="Transaction">{{data.transaction_no}}</td>
             <td data-label="Payment">{{data.payment_method}}</td>
             <td data-label="Amount">{{data.amount}} </td>
             <td  data-label="Date">{{data.date | date: ' EEE dd MMM, y'}} <br> {{data.date | date: 'HH:mm' }} </td>
             <td data-label="Status">                        
                <span class="d-flex align-items-center gap-2" [class]="data.status">
                   <img src="assets/icons/table/{{data.status}}.svg" alt="block-img">{{data.status}}
                </span>
             </td>
             <td  data-label="Type" >
                {{data.type}}
             </td>
             <td> <img src="assets/icons/vertical-menu.svg" alt=""> </td>
             <td></td>
          </tr>             
       </tbody>
    </table>
 </section>