import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var $: any;
export const BASE_URL = environment.baseURL;
export const Stripe_Publishable_Key = environment.stripePublishableKey
export const Google_Client_ID = environment.googleClientId

export interface Tutor {
    id: string,
    name: string,
    country: string,
    isCertified: any,
    tutorRate: number,
    teaches: any,
    speaks: any,
    about: string,
    lessonCompleted: any,
    pricePerHour: number,
    isTopTutor: any,
    isOnline: any,
    img: any,
    videoLink: string
}

export enum ROLES {
    STUDENT = 'student',
    TUTOR = 'tutor',
    ADMIN = 'admin'
}
export enum BOOKING_PHASES {
    LESSON_TYPE = 'lessonType',
    LESSON_TYPE_DETAILS = 'bookLessonTypeDetails',
    LESSON_Communication_TOOLS = 'lessonCommunicationTools',
    BOOKING_SUMMARY = 'bookingSummary',
    BOOKING_CHECKOUT = 'bookingCheckout',
    TIME_SLOTS = 'timeSlots',
    INCOMPLETE = 'incompleteProfile',

}
export enum LESSON_TYPE {
    REGULAR = 'regular',
    SUBSCRIPTION = 'subscription',
    TRIAL = 'trial'
}

export enum Phases {
    PHASE_ONE = 'one',
    CODE_CONFIRMATION = 'codeConfirmation',
    PHASE_TWO = 'two',
    PHASE_THREE = 'three',
    PHASE_FOUR = 'four',
    PHASE_FIVE = 'five',
    PHASE_SIX = 'six',
    PHASE_REVIEW = 'Under-Review',
    PHASE_COMPLETED = 'completed',
}

export enum Days {
    Monday = 'Mon',
    Tuesday = 'Tue',
    Wednesday = 'Wed',
    Thursday = 'Thu',
    Friday = 'Fri',
    Saturday = 'Sat',
    Sunday = 'Sun'
}

export enum HttpMethod {
    GET,
    POST,
    DELETE
}

export class ParamBuilder {
    params: any = {};

    // TODO: Fix all this...
    add(key: string, value: any) {
        if (value != null) {
            if (value.constructor === Array) {
                let values: any = [];
                for (let val of value) {
                    if (typeof value === 'object') {
                        val = JSON.stringify(val);
                    }

                    values.push(val);
                }

                this.params[key] = values;
                return;
            } else if (typeof value === 'object') {
                value = JSON.stringify(value);
            }

            this.params[key] = value;
        }
    }

    getParams() {
        return this.params //$.param(this.params).replace(/%5B%5D/g, '').replace(/%5B0%5D/g, ''); // TODO: Figure out where %5B0%5D came from
    }
}

@Injectable()
export abstract class TRObject implements OnDestroy {
    ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy() {
        // Simple way to unsubscribe from all subscriptions
        // https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

export function scrollTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export enum sideNavItemNames {
    DASHBOARD = 'Dashboard',
    TUTORS = 'Tutors',
    SETTINGS = 'Settings',
    LESSONS = 'Lessons',
    MYWALLET = 'My-Wallet',
    TUTORINGSETTINGS = 'Tutoring-Settings',
    CHAT = 'Chat',
    TUTORMYWALLET = 'Tutor-My-Wallet',
    TUTORLESSONS = 'Tutor-Lessons',

}

export const lessonStatuses: Array<any> = [
    { id: 0, name: "Pending", target: "lessonStatus" },
    { id: 1, name: "Approved", target: "lessonStatus" },
    // { id: 2, name: "Rejected", target: "lessonStatus" },
    { id: 3, name: "Completed", target: "lessonStatus" },
    { id: 4, name: "Canceled", target: "lessonStatus" },
    { id: 5, name: "Overdue", target: "lessonStatus" },
];

export const lessonRequestStatus: Array<any> = [
    { id: 0, name: "Pending", target: "requestType" },
    { id: 1, name: "Approved", target: "requestType" },
    // { id: 2, name: "Rejected", target: "requestType" },
    { id: 3, name: "Closed", target: "requestType" },
];

export const lessonRequestTypes: Array<any> = [
    { id: 0, name: "TUTOR_APPROVAL", target: "requestStatus" },
    { id: 1, name: "RESCHEDULE", target: "requestStatus" },
    { id: 3, name: "CANCEL", target: "requestStatus" },
];

export const lessonSortBy: Array<any> = [
    { id: 1, name: "Date ASC", target: "sortByType", icon: 'desc' },
    { id: 2, name: "Date DESC", target: "sortByType", icon: 'asc' },
];

export const nameSortBy: Array<any> = [
    { id: 1, name: "Name ASC", target: "sortByType", icon: 'desc' },
    { id: 2, name: "Name DESC", target: "sortByType", icon: 'asc' },
];

export function generateUniqueId(): string {
    const timestamp = new Date().getTime().toString(16);
    const randomValues = new Array(8).fill(null).map(() => Math.floor(Math.random() * 16).toString(16));
    const uniqueId = `${timestamp}-${randomValues.join('')}`;
    return uniqueId;
}

export function createHoverCSSClass(className: string, rules: string, hoverRules: string = ''): void {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .${className} { ${rules} }
      .${className}.hovered { ${hoverRules} }
    `;

    document.getElementsByTagName('head')[0].appendChild(style);

    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mouseenter', () => {
            for (let j = 0; j < elements.length; j++) {
                elements[j].classList.add('hovered');
            }
        });

        elements[i].addEventListener('mouseleave', () => {
            for (let j = 0; j < elements.length; j++) {
                elements[j].classList.remove('hovered');
            }
        });
    }
}

export function addClassToElement(elementId: string, className: string): void {
    const element = document.getElementById(elementId);
    if (element) {
        element.classList.add(className);
    }
}

export function addId(el: any, id: string) {
    el.id = id;
}

export function addCssClass(el: any, cssClass: string) {
    el.classList.add(cssClass);
}

// Update the DOM by assigning the lessonKey to ensure that deselecting one slot of a lesson will also deselect all other slots of that lesson.
export function updateDOMElementWithLessonKey(slotId: string, lessonKey: string) {
    const divElement: any = document.getElementById(slotId);
    if (divElement) {
        divElement.setAttribute('data-lesson', lessonKey);
    }
}

export function generateUniqueKeyFromDateTime(value: string): string {
    const [date, time] = value.split(' ');
    const [year, month, day] = date.split('-');
    const formattedValue = `${year}${month}${day}${time.replace(':', '')}`;

    return formattedValue;
}

export function parseJson(paymentMethodDetails: string) {
    return JSON.parse(paymentMethodDetails);
}

export function formatTimestamp(timestamp: any) {
    const dateObj = moment(timestamp);
    const formattedDate = dateObj.format('ddd DD MMM YYYY');
    const formattedTime = dateObj.format('hh:mm A');

    return { date: formattedDate, time: formattedTime };
}


export function delayedExecution(callback: () => void, delayTime: number) {
    timer(delayTime)
        .pipe(take(1))
        .subscribe(() => {
            callback();
        });
}