import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})

export class ConfirmDeletePopupService {

  private isOpenSubject: Subject<boolean> = new Subject<boolean>();
  public isOpen$: Observable<boolean> = this.isOpenSubject.asObservable();
  actionName:any;
  formData:any;

  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  public closeModal(): void {
    this.isOpenSubject.next(false);
  }
  
  public setData(actionName:any , formData:any){
    this.actionName = actionName;
    this.formData = formData
    this.isOpenSubject.next(true);
  }

  public clearData(){

  }

  confirmDelete(){
    if(this.actionName && this.formData){
      let endpoint;
      if (this.actionName === 'cancel') {
        endpoint = '/user-direct-cancel';
      } else {
        endpoint = '/user-cancel-request';
      }
      return this.fetchCore(endpoint, this.formData, HttpMethod.POST);
    }
  }

}
