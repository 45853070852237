import { Component, OnInit, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BookLessonService } from '../book-lesson.service';
import { SchedulerService } from '@components/scheduler/scheduler.service';
import { AuthService } from '@core/auth/auth.service';
@Component({
  selector: 'tr-lesson-type',
  templateUrl: './lesson-type.component.html',
  styleUrls: ['./lesson-type.component.css']
})
export class LessonTypeComponent implements OnInit {
  selectedTutor:any;

  lessonTypes: any = [
    { type: 'regular', title: '30 - 60 mins Regular Lesson', hint: 'you can select multiple lessons and get discount 20%.', price: '£ 25' },
    { type: 'subscription', title: 'Subscription', hint: 'Monthly or yearly subscription.', price: '£ 11' },
  ]

  constructor(@SkipSelf() public bookLessonService: BookLessonService, 
    @SkipSelf() private schedulerService: SchedulerService,
    private auth:AuthService
    ) { }

  ngOnInit(): void {
    let tutor = this.bookLessonService.selectedTutor$.value;
    this.bookLessonService.selectedLessonType$;
    this.resetBooking();
    let user = this.auth.user$.value;
    if (user['isTrialAvailable'] && !tutor['hadLesson']) {
        this.lessonTypes.unshift({
          type: 'trial',
          title: '30 mins Trial Lesson',
          hint: 'you can extend this period by extra fees.',
          price: '£ 9',
        });
      }
  }

  selectLessonType(type: any) {
    if(type != 'subscription'){
      this.bookLessonService.selectedLessonType$.next(type);
    }
  }

  resetBooking() {
    this.schedulerService.selectedSlots$.next([]); // reset slot selections when Lesson Type change
  }
}