<div class="tr-py-2 tr-bg-highlight tr-mh-100">
	<h2 class="tr-title mb-0 tr-px-2">Recent reviews</h2>

	<section class="d-flex flex-column">
		<div class="tr-review-row d-flex align-items-center justify-content-between gap-4 py-4 tr-px-2 tr-hover" routerLink="/tutor/students"[ngClass]="{'tr-bdr': !last}" *ngFor="let review of reviews; let last = last">
			<div class="tr-review-img d-flex align-items-start gap-2">
				<img src="assets/images/profile-placeholder.png" alt="profile-placeholder" *ngIf="!review.tutorImage">
				<img [src]="review.tutorImage" alt="tutor-image" *ngIf="review.tutorImage">
				<div class="tr-review-details d-flex flex-column">
					<p class="mb-1">{{ review.tutorName }}</p>
					<span> {{ review.comment }}  </span>
				</div>
			</div>
			<div class="tr-review d-flex flex-column gap-2">
				<div class="tr-rating d-flex align-items-center gap-1">
					<img src="assets/icons/rate.svg" alt="rate-icon">
					 {{ review.rating  | number: '1.0-0' }}
				</div>
				<span class="tr-review-date"> {{ review.reviewDate | date : "EEE dd MMM" }} </span>
			</div>
		</div>
		<div *ngIf="reviews && reviews.length == 0" class="mt-3 tr-px-2 tr-sub-title-lite-text tr-font-14">
			<p>No reviews found... </p>
		</div>	
	</section>
</div>