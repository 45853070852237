import { Component, OnInit, SkipSelf, ViewChild } from '@angular/core';
import {
  validatePassword,
  passRules,
  formValidationMessages,
} from 'src/app/shared/models/validation';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '@components/shared-components/toast.service';
import { FormDataService } from 'src/app/core/form-data.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'tr-student-register',
  templateUrl: './student-register.component.html',
  styleUrls: ['./student-register.component.css'],
})
export class StudentRegisterComponent implements OnInit {
  isLoading: boolean = false;
  referralCode:any;
  public errorMessages = {
    email: formValidationMessages['email'],
  };

  constructor(
    @SkipSelf() private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formDataService: FormDataService
  ) {
    this.authService.clearData();
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['ref'];
      if (code) {
        this.referralCode = code;
        sessionStorage.setItem('refCode', code);
      }
    });
  }

  @ViewChild('password') password: any;
  passRules: any = passRules;
  isPassValid: boolean = false;
  passValidationStatuses: any;
  showPassword: boolean = false;
  studentRegisterForm!: UntypedFormGroup;

  ngOnInit(): void {
    this.authService.clearData();
    this.initForm();
    validatePassword(''); // reset validation by passing empty param
  }

  private initForm() {
    this.studentRegisterForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', Validators.required),
      timezone_id: new UntypedFormControl(''),
      referral_code: new UntypedFormControl(''),
    });
  }

  getPass(password: string) {
    this.isPassValid = validatePassword(password)['isValid'];
    this.passValidationStatuses =
      validatePassword(password)['validationStatuses'];
  }

  registerStudent() {
    this.isLoading = true;
    this.formDataService
      .fetchDataAndUseTimezone()
      .then((data) => {
        let timezoneID = data['id'];
        this.studentRegisterForm.patchValue({ timezone_id: timezoneID });
        if(this.referralCode){
         this.studentRegisterForm.patchValue({ referral_code: this.referralCode }); 
        }
        this.authService
          .registerStudent(this.studentRegisterForm.value)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            (res: any) => {
              this.handleLoginSuccess(res);
            },
            (error: any) => {
              this.handleLoginFailure(error);
            }
          );
      })
      .catch((error) => {
        this.toastService.showErrorToast('Error!', 'Timezone not found !!');
      });
  }

  handleLoginSuccess(res: any) {
    this.toastService.showSuccessToast('Success !', res['message']);
    this.router.navigate(['/student']);
  }

  handleLoginFailure(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (
        error.status === 422 ||
        error.status === 404 ||
        error.status === 500
      ) {
        this.toastService.showErrorToast('Error!', error['error'].message);
      }
    } else {
      this.toastService.showErrorToast('Error!', error.message);
    }
  }
}
