import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tr-tutor-profile-resume',
  templateUrl: './tutor-profile-resume.component.html',
  styleUrls: ['./tutor-profile-resume.component.css']
})
export class TutorProfileResumeComponent implements OnInit {
  selectedTab:string = 'experience';
  @Input() educations:any;
  @Input() experiences:any;
  @Input() certificates:any;
  constructor() { }

  ngOnInit(): void {
  }

}
