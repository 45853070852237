<div class="p-0" *ngIf="lessons && lessons.length > 0">
	<section class="d-flex align-items-center justify-content-between tr-pb-2">
		<h4 class="tr-tile mb-0">Waiting my action</h4>
		<div class="d-flex align-items-center gap-3 cursor-pointer tr-navigate-link" routerLink="/student/lessons/waiting-my-action">
			<span class="tr-view-more"> View more</span>
			<img src="assets/icons/arrow-right-black.svg" alt="back-btn-img">
		</div>
	</section>
		<section *ngIf="!isLoading">
		  <div class="d-flex flex-column gap-3" >
		    <ng-container *ngFor="let lesson of lessons">
		     <tr-user-lesson-card [lesson]="lesson"></tr-user-lesson-card>
		    </ng-container>
		  </div>
	   </section>
	   	<section class="d-flex flex-column gap-4" *ngIf="isLoading">
			  <tr-lesson-card-loader></tr-lesson-card-loader>
			  <tr-lesson-card-loader></tr-lesson-card-loader>
	    </section>
</div>