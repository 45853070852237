import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormNotificationComponent } from '@components/shared-components/form-notification/form-notification.component';



@NgModule({
  declarations: [FormNotificationComponent],
  imports: [
    CommonModule
  ],
  exports:[
    FormNotificationComponent
  ]
})
export class FormNotificationModule { }
