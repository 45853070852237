<section class="row p-0 m-0 tr-book-lesson-footer-wrapper">
    <div class="tr-footer-booking-section col-md-12 col-lg-12 d-flex justify-content-between align-items-center bottom-0 px-0">
        <div class="d-flex justify-content-start">
            <div class="tr-book-lesson-img d-flex align-items-center justify-content-center flex-gap-12">
               <img [src]="(this.reschedulerService.tutorData$ | async)?.tutorImage || 'assets/images/profile-placeholder.png'" alt="booking-tutor-image">

               <div>
                  <p class="mb-0 tr-booking-tutor-name">{{reschedulerService.tutorData$.value.tutorName}}</p>
                  <p class="mb-0 tr-booking-tutor-spec" *ngIf="(this.auth.currentRole$ | async) === 'student'">Certified Tutor</p>
               </div>
            </div>
         </div>
         <div class="tr-action-bar d-flex align-items-center">
            <!-- <input type="button" value="Back" class="back-btn px-3 py-2 me-2"/> -->
            <!-- <input type="button" value="Next" class="next-btn px-3 py-2"  (click)="handleNextBtnClick()"/> -->
            <button class="px-3 py-2 tr-next-btn tr-btn" (click)="handleNextBtnClick()" [disabled]="checkValidity()">
              <span *ngIf="(reschedulerService.phase$ | async) !== 'confirm_resch_phase'">
                 Next
              </span>  
              <span *ngIf="(reschedulerService.phase$ | async) == 'confirm_resch_phase'"> Confirm</span>
            </button>
         </div>
    </div>
 </section>
<div class="tr-lesson-rescheduler-header-close-btn d-none border-0" type="button" data-bs-dismiss="modal" aria-label="Close" #closeModalBtn> </div>