import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})


export class ChatService {
  public chatId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public selectedChatUser$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  sideMenuLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private trHttp: TrHttpService) { }

  fetchCore(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET): any {
    return this.trHttp.fetch(endPoint, params, method);
  }

  getUserChats(isUnread:boolean = false){
    const endPoint: string = isUnread ? 'get-user-chats-unread' : 'get-user-chats';
    return this.fetchCore(endPoint);
  }  

  getConversationById(id:any, page?:any){
    if(page){
       const formData = new FormData();
       formData.append('page', page);
       return this.fetchCore('get-chat-messages/'+ id , formData);
     }
     else{
       return this.fetchCore('get-chat-messages/'+ id);
     }
  }

  startSingleChat(user_id: any) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    return this.fetchCore('start-single-chat', formData, HttpMethod.POST)
  }

  sendMessage(form:any){
    return this.fetchCore('send-chat-message', form, HttpMethod.POST)
  }

  clearChatData() {
    this.chatId$.next(null);
    this.selectedChatUser$.next(null);
  }

  
}
