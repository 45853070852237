import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TrHttpService } from '@core/tr-http.service';
import { HttpMethod } from '@shared/models/shared';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Phases } from '@shared/models/shared';

@Injectable({
  providedIn: 'root'
})

export class TutorRegPhasesService {
  phases$: BehaviorSubject<any> = new BehaviorSubject(Phases.PHASE_ONE);
  phaseTwoFormData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  phaseThreeFormData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  phaseFourFormData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  phaseFiveFormData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  constructor(private trHttp: TrHttpService, private router: Router) {
  }

  fetch(endPoint: any, params?: any, method: HttpMethod = HttpMethod.GET) {
    return this.trHttp.fetch(endPoint, params, method).pipe(
      tap(
        (data: any) => {},
        (error: any) => {
          console.log(error);
          if (error.status == 401) { // 401 -> unauthenticated
            this.router.navigate(['/login'])
            // add notification here 
          }
        }
      )
    )
  }

  resendCode() {
    return this.fetch('send-confirmation-code-email', {}, HttpMethod.POST)
  }

  setPhase(phase: Phases) {
    this.phases$.next(phase)
  }

  confirmCode(email:any, code: any) {
    const formData = new FormData();
    formData.append('email', email)
    formData.append('confirmation_code', code)
    return this.fetch('tutor-register-confirmation-email', formData, HttpMethod.POST)
  }

  subjectById(id:any) {
    const formData = new FormData();
    formData.append('subjectId', id)
    return this.fetch('specialities-list-subject', formData, HttpMethod.GET)
  }

  registerPhaseTwo(phaseForm: any) {
    phaseForm.spokenLanguages = JSON.stringify(phaseForm.spokenLanguages)

    const formData = new FormData();
    for (var key in phaseForm) {
      if (phaseForm.hasOwnProperty(key)) {
        let val = phaseForm[key];
        formData.append(key, val)
      }
    }
    return this.fetch('tutor-register-phase-two', formData, HttpMethod.POST)
  }


  registerPhaseThree(phaseForm: any) {
    const formData = new FormData();
    for (var key in phaseForm) {
      if (phaseForm.hasOwnProperty(key)) {
        let val = phaseForm[key];
        formData.append(key, val)
      }
    }
    return this.fetch('tutor-register-phase-three', formData, HttpMethod.POST)
  }

  registerPhaseSix(phaseFourForm: any) {
    const isAccepted: any = 1;// phaseFourForm.firstName && phaseFourForm.lastName ? 1 : 0
    const formData = new FormData();
    formData.append("accept_terms", isAccepted)
    return this.fetch('tutor-register-phase-six', formData, HttpMethod.POST)
  }

  registerPhaseFour(phaseFourForm:any){
     const payload = { tutorPrices: JSON.stringify(phaseFourForm) };
     return this.fetch('tutor-register-phase-four', payload, HttpMethod.POST);
  }
   
  registerPhaseFive(){
    const formData = new FormData();
    formData.append('saveLater', 'true');
    return this.fetch('tutor-register-phase-five', formData, HttpMethod.POST)
  }
  
  registerCertificate(certificateForm:any){
    let formData = this.getFormFields(certificateForm);
    return this.fetch('user-certificate', formData, HttpMethod.POST)
  }

  registerEducation(educationForm:any){
    let formData = this.getFormFields(educationForm);
    return this.fetch('user-education', formData, HttpMethod.POST)
  }

  registerWorkExperience(experienceForm:any){
    let formData = this.getFormFields(experienceForm);
    return this.fetch('user-work-experience', formData, HttpMethod.POST)
  }

  getFormFields(form:any){
    const formData = new FormData();
      for (var key in form) {
        if (form.hasOwnProperty(key)) {
            let val = form[key];
            formData.append(key, val)
      }
    }
    return formData;
  }

  deleteRecordById(id:number, type:string){
    switch (type) {
      case "certificate":
            return this.fetch('user-certificate/'+id, '', HttpMethod.DELETE)
            break;
      case "education":
            return this.fetch('user-education/'+id, '', HttpMethod.DELETE)
            break;
      case "experience":
            return this.fetch('user-work-experience/'+id, '', HttpMethod.DELETE)
            break;
      default:
            return;
            break;
      }
  }

  checkUserName(userName: any) {
    const formData = new FormData();
    formData.append('userName', userName)
    return this.fetch('user-name-validation', formData, HttpMethod.POST)
  }

  getPhaseTwoFormData() {
    return this.fetch('get-tutor-phase-two-data')
  }

  getPhaseThreeFormData() {
    return this.fetch('get-tutor-phase-three-data')
  }  

  getPhaseFourFormData() {
    return this.fetch('get-tutor-phase-four-data')
  }  

  getPhaseFiveFormData() {
    return this.fetch('get-tutor-phase-five-data')
  }

  savePhaseTwo(phaseForm: any) {
    return this.fetch('tutor-register-phase-two', this.getPhaseTwoValidFields(phaseForm), HttpMethod.POST)
  }

  getPhaseTwoValidFields(phaseForm: any) {
    phaseForm.spokenLanguages = JSON.stringify(phaseForm.spokenLanguages);
    const formData = new FormData();
    formData.append('saveLater', 'true');
    //TODO: remove the check on the null values when the API is modified to take null values
    for (var key in phaseForm) {

      if (phaseForm.hasOwnProperty(key)) {
        let val: any = phaseForm[key];

        if (!_.isEmpty(val) && key != 'spokenLanguages') {
          formData.append(key, val)
        }
        else if (key == 'spokenLanguages') {
          let spokenLangs: any = []
          val = JSON.parse(val)
          val.forEach((el: any) => {
            if (!_.isEmpty(el.langId) && !_.isEmpty(el.profId)) {
              spokenLangs.push(el)
            }
          });
          if (spokenLangs.length > 0) {
            formData.append(key, JSON.stringify(spokenLangs));
          }
        }
      }
    }
    return formData
  }

  savePhaseThree(phaseForm: any) {
    return this.fetch('tutor-register-phase-three', this.getPhaseThreeValidFields(phaseForm), HttpMethod.POST)
  }

  getPhaseThreeValidFields(phaseForm: any) {
    phaseForm.subjects = JSON.stringify(phaseForm.subjects)
    const formData = new FormData();
    formData.append('saveLater', 'true');

    //TODO: remove the check on the null values when the API is modified to take null values
    for (var key in phaseForm) {
      if (phaseForm.hasOwnProperty(key)) {
        let val = phaseForm[key];
        if (!_.isEmpty(val) && key != 'subjects') {
          formData.append(key, val)
        }
      }
    }

    return formData
  }
}
