<main>
 <section class="tr-padding-2">
    <div class="d-flex flex-column mb-4 tr-mb-header">
      <h2 class="tr-title mb-0 ps-4 ps-md-5 ps-xl-0">My students</h2>
    </div>
      <div class="mb-4">
        <tr-lessons-action-header (lessonFilters)="getFilters($event)" [hideGridView]="true"></tr-lessons-action-header>
      </div>
      <section *ngIf="students && students.length > 0">
      <section class="mt-4 d-flex flex-wrap justify-content-between justify-content-xl-start gap-4">
        <div class="tr-grid-view tr-flex-item" *ngFor="let student of students; let i = index">
          <div class="tr-grid-details d-flex flex-wrap  gap-4 align-items-center p-4">
            <img [src]="student.profileImage" alt="student-image" *ngIf="student.profileImage">
            <img
              src="assets/images/profile-placeholder.png"
              alt="profile-img"
              class="tr-tutor-profile-img"
              *ngIf="!student.profileImage"
            />
            <div class="tr-grid-tutor-name">
              <span class="tr-bold-text mb-0">{{ student.name}}.</span>
              <div class="pt-2">
                <span class="tr-text-lite">Date of last lesson</span>
                <div class="tr-grid-date d-flex align-items-center gap-2">
                  <img src="assets/icons/calendar_today.svg" alt="calendar-icon">
                  <span class="tr-bold-text tr-fs-75">{{student.dateOfLastLesson | date: 'EEE dd MMM yyyy, hh:mm a'}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tr-grid-middle-sec p-3">
            <div class="tr-grid-lesson-number px-3 py-2">
              <div class="d-flex align-items-center gap-2 pb-2 tr-bdr-b">
                <span class="tr-lesson-count d-flex align-items-center justify-content-center"> {{student.numberOfLessons}} </span>
                <span class="tr-bold-text tr-fs-75">Number of lessons</span>
              </div>
              <div class="pt-2 d-flex align-items-center gap-2 flex-wrap justify-content-between">
                <div class="tr-lessons-list d-flex align-items-center gap-2">
                  <span>Upcoming</span> {{student.numberOfUpComing}}
                </div>
                <div class="tr-lessons-list d-flex align-items-center gap-2">
                  <span>Pending</span> {{student.numberOfPending}}
                </div>
                <div class="tr-lessons-list d-flex align-items-center gap-2">
                  <span>Overdue</span> {{student.numberOfOverdue}}
                </div>
                <div class="tr-lessons-list d-flex align-items-center gap-2">
                  <span>Completed</span> {{student.numberOfCompleted}}
                </div>
              </div>
            </div>
          </div>
          <div class="tr-bottom-sec">
            <div class="d-flex gap-3 w-100">
              <button class="tr-grid-btn tr-grid-view-lessons-btn d-flex align-items-center justify-content-center flex-grow-1 tr-btn" routerLink="/tutor/lessons/my-lessons"> View lessons</button>
              <button class="tr-grid-btn tr-view-req-btn d-flex align-items-center justify-content-center flex-grow-1 tr-btn"  routerLink="/tutor/lessons/my-requests">View requests</button>
            </div>
            <div class="w-100 mt-62">
              <button class="tr-grid-btn tr-grid-msg-btn d-flex align-items-center justify-content-center flex-grow-1 tr-btn"  (click)="sendMessage(student.id, student.name, student.profileImage)">Message</button>
            </div>
          </div>
          <div></div>
        </div>
      </section>
      <section>
        <tr-custom-pagination [currentPage]="currentPage" [lastPage]="lastPage" [totalRows]="totalRows" (onChangeEmit)="changePageSize($event)"></tr-custom-pagination>
      </section>
    </section>
    <section class="tr-min-70 d-flex align-items-center justify-content-center" *ngIf="students && students.length == 0">
      <div class="d-flex flex-column gap-2 w-100 align-items-center justify-content-center">
        <img src="assets/images/no-tutors-search.svg" alt="no-tutors" class="tr-no-students-img">
        <p class="tr-font-12 tr-dark-text-lg">No students found.</p>
      </div>
    </section>
  </section>
</main>
<tr-send-message-popup></tr-send-message-popup>