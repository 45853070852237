<section class="tr-mh-100 d-flex align-items-center justify-content-center">
  <div class="tr-login-component container-fluid d-flex justify-content-center">
    <div class="tr-login-wrapper col-md-8 col-lg-6">
      <div class="row top30 p-0 m-0">
        <section class="px-0 m-0"  [formGroup]="resetPasswordForm">
        	        <section >
          <div class="d-flex flex-column align-items-center justify-content-center tr-pb-2">
           <h4 class="tr-title mb-0 ">Reset password</h4>
          </div>
          <div class="d-flex flex-column tr-gap-2">
            <div class="form-group">  
              <label class="mb-2 tr-dark-text-md tr-font-16"> New password</label>
              <div class="d-flex align-items-center justify-content-between position-relative">
                <input type="{{ showPassword ? 'text' : 'password' }}" (input)="getPass(password.value)" #password class="form-control" placeholder="Enter password" formControlName="password">
                <img  src="assets/images/password_icon.svg" alt="password" class="tr-view-pass-img cursor-pointer" (click)="this.showPassword = !this.showPassword">
              </div>
            </div>            
            <div class="form-group">  
              <label class="mb-2 tr-dark-text-md tr-font-16"> Repeat new password</label>
               <div class="d-flex align-items-center justify-content-between position-relative">
                <input type="{{ showConfirmPassword ? 'text' : 'password' }}" class="form-control" placeholder="Repeat password" formControlName="confirmPassword">
                <img  src="assets/images/password_icon.svg" alt="password" class="tr-view-pass-img cursor-pointer" (click)="this.showConfirmPassword = !this.showConfirmPassword">
              </div>
                <div class="error-message mt-1" *ngIf="resetPasswordForm.get('confirmPassword')?.hasError('passwordMismatch')">
			      Passwords do not match.
			    </div>
            </div>
          </div>
          <div class="p-0 mx-0 d-flex justify-content-start mt-4">
            <div class="col-md-11 col-lg-11 col-11 d-flex justify-content-start">
                <label class="tr-reg-password-criteria col-md-12 col-lg-12">
                    Password must:
                </label>
            </div>
        </div>

        <div class="p-0 m-0 d-flex justify-content-start">
            <div class="col-md-11 col-lg-11 col-11 top10 tr-reg-password-criteria-items">
                <ng-container *ngFor="let rule of passRules">
                    <label class="col-md-12 col-lg-12"> <img src="/assets/icons/{{rule.icon}}" alt="validate-img" class="me-2"> {{rule.text}}</label><br>
                </ng-container>
            </div>
        </div>

        <div class="mt-4">
          <button class="w-100 tr-btn tr-btn-primary" [disabled]="resetPasswordForm.invalid" (click)="resetPassword()"> 
            <span *ngIf="!isLoading"> Reset password </span>
            <tr-spinner *ngIf="isLoading"></tr-spinner>
          </button>
        </div>
        </section>
        </section>
      </div>
    </div>
  </div>
</section>