<div class="tr-landing-page-body container">
    
    <section *ngIf="sectionName == 'sectionOne'" class="tr-section-one top30 row">

        <div class="tr-left-section col-xs-12 col-sm-12 col-md-7 col-lg-7 order-sm-first order-xs-first order-last">
            <div class="row">
                <div class="tr-left-section-title col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    Explore the world of any language
                </div>
            </div>

            <div class="row">
                <div class="top20 col-xs-12 col-sm-12 col-md-7 col-lg-7">
                    All in one tutoring solution, one to one online private tuition and high quality Tutors and carefully selected <span class="text-decoration-underline" style="color:#046E78">know more<img class="left5" src="../../../assets/images/landing-page-images/play.svg" alt=""> </span>
                </div>
            </div>
    
            <div class="tr-section-one-actoin-btns col-xs-12 col-sm-12 top20">
                <div class="row">
                    <input class="tr-start-learning-btn col-xs-12 col-sm-12 col-md-12 col-lg-3" type="button" value="Start Learning"/>
                    <input class="tr-become-tutor-btn col-xs-12 col-sm-12 col-md-12 col-lg-3" type="button" value="Become a tutor"/>
                </div>
            </div>
        </div>

        <div class="tr-right-section col-xs-12 col-sm-12 col-md-5 col-lg-5 d-flex justify-content-sm-center justify-content-xs-center justify-content-md-center justify-content-lg-center">
            <img src="../../../assets/images/landing-page-images/landing-page-sec1-right.png" alt="">
        </div>

    </section>


    <section *ngIf="sectionName == 'sectionTwo'" >
        <p> section two goes here</p>
    </section>
    
</div>
