<section class="d-flex align-items-center justify-content-center w-100">
  <section class="p-4 tr-resch-conf-wrapper">
    <div class="d-flex align-items-start gap-3">
      <div class="tr-book-lesson-img">
        <img [src]="reschedulerService.tutorData$.value?.tutorImage || 'assets/images/profile-placeholder.png'" alt="img">
      </div>
      <div class="d-flex flex-column gap-3">
        <div>
          <p class="mb-0 tr-booking-tutor-name tr-truncate-text">{{reschedulerService.tutorData$.value.tutorName}}</p>
       <!--    <p class="mb-0 tr-booking-tutor-spec">Certified Tutor</p> -->
        </div>
        <div class="d-flex align-items-center">
          <span class="tr-conf-labels">Subject</span>
          <span class="tr-conf-values">{{reschedulerService.tutorData$.value.subjectName}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="tr-conf-labels">Request type</span>
          <span class="tr-conf-values">{{ mapPermission(reschedulerService.lessonAction$.value)}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="tr-conf-labels">Old time slot</span>
          <span class="tr-conf-values">
            {{formatDateTimeAndDuration(reschedulerService.tutorData$.value.lessontime, reschedulerService.tutorData$.value.lessonPeriod) }}
           </span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-3">
          <span class="tr-conf-labels">New time slot</span>
          <span class="tr-conf-values">
            <ng-container *ngFor="let lesson of rescheduleLesson$ | async">
              {{ extractLessonDateTime(lesson.dateTime) }}, {{ lesson.duration }} mins
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </section>
</section>