import { Injectable } from '@angular/core';
import { HttpMethod, TRObject } from '../shared/models/shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../shared/models/shared';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Token } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})

export class TrHttpService extends TRObject {
  baseURL!: string;
  private paypalClientId = 'AUNyASr1J0_Tx4KVoa53XzuSE_ZFTFQAIHEhpBddNTtmd56oWTNAwcJUoY588lfFBM8CsgLsbsz1AsF9'; //To be handled in the backend
  private paypalClientSecret = 'EHVmpPpJgyOlXuZj_JVceClHNt7ATfQ43ICEXqzEaaxzd4u8KMg_JDZkQUNxEgxV64bEH8QJuu5pP_x1'; //To be handled in the backend
  private paypalAuthToken!: string;

  constructor(private http: HttpClient) {
    super();
  }

  fetch(path: string, options?: any, method: HttpMethod = HttpMethod.GET): Observable<any> {
    this.baseURL = BASE_URL;
    switch (method) {
      case HttpMethod.GET:
        return this.get(this.getUrlParams(path, options));
      case HttpMethod.POST:
        return this.post(path, options);
      case HttpMethod.DELETE:
        return this.delete(path, options);
      default:
        return of([''])
      // TODO: ADD put method 
    }
  }

  fetchThirdParty(url: string, options?: any, method: HttpMethod = HttpMethod.GET): Observable<any> {
    this.baseURL = url;
    switch (method) {
      case HttpMethod.GET:
        return this.get(this.getUrlParams(url, options));
      case HttpMethod.POST:
        return this.post(url, options);
      case HttpMethod.DELETE:
        return this.delete(url, options);
      default:
        return of([''])
      // TODO: ADD put method 
    }
  }

  private get(path: string) {
    return this.http.get(this.baseURL + path).pipe(takeUntil(this.ngUnsubscribe),
      tap(
        (response: any) => {
          return response.body;
        }, (error: any) => {
          if (error.status == 401) { //TODO: Check what error code to use to kick out the user
            // force logout
          }
        }
      )
    );
  }

  private post(path: string, body: any) {
    return this.http.post(this.baseURL + path, body).pipe(
      tap((response: any) => {
        return response.body;
        // if (response.status === 200 || response.status === 202) {
        //   return of(response.body);
        // }

        // return throwError(new Error(response.message));
      },
        (error: any) => {
          if (error.status == 401) {
            //logout
          }
          return error
        }
      ), takeUntil(this.ngUnsubscribe),
    );
  }

  private delete(path: string, body: any) {
    return this.http.delete(this.baseURL + path).pipe(tap((response: any) => {
      if (response.status || response.status === 200 || response.status === 202) {
        console.log(response);
        return of(response);
      }
      return throwError(new Error(response));
    }), takeUntil(this.ngUnsubscribe),
    );
  }

  getUrlParams(path: string, options: any) {
    if (options !== null && options) {
      const queryString = new URLSearchParams(options).toString();
      return path + '?' + queryString;
    }
    return path
  }

}