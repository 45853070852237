import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private pusher: Pusher;

  constructor() {

  this.pusher = new Pusher(environment.pusher.appKey, {
      cluster: environment.pusher.cluster,
    });

   }

  subscribeToChannel(channelName: string, eventName: string, callback: (data: any) => void) {
    const channel = this.pusher.subscribe(channelName);
    channel.bind(eventName, callback);
  }

  unsubscribeFromChannel(channelName: string) {
    this.pusher.unsubscribe(channelName);
  }
}
