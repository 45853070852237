import { Component, OnInit, QueryList, SkipSelf, ViewChild, ViewChildren } from '@angular/core';
import { FindTutorService } from './find-tutor.service';
import { ToggleDisplayComponent } from '@studentComponents/student-tutors/toggle-display/toggle-display.component';
import { TutorTileComponent } from '../tutor-tile/tutor-tile.component';
import { HostListener } from '@angular/core';
import { findTutorTitle } from '@shared/models/pageTitles';
import { ActivatedRoute } from '@angular/router';
import { validateObjKey } from '@shared/models/validation';
import * as bootstrap from 'bootstrap';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { finalize } from "rxjs/operators";
import { AuthService } from '@core/auth/auth.service';
import { FormDataService } from '@core/form-data.service';
import { BookLessonService } from '../book-lesson/book-lesson.service';
import { BOOKING_PHASES } from '@shared/models/shared';

@Component({
  selector: 'tr-find-tutor',
  templateUrl: './find-tutor.component.html',
  styleUrls: ['./find-tutor.component.css']
})
export class FindTutorComponent implements OnInit {
  currentPosition = window.pageYOffset;
  bottomGap: number = 60;
  isLoading: boolean = false;
  itemsPerPage: any = 10;
  page: any = 1;
  allTutorsCount: any;
  lastPage:any;
  searchQuery: any = '';
  filters: any;
  role = this.authService.currentRole$.getValue();
  showPlayer:boolean = false;
  isPaginated: boolean = false;
  showLoader: boolean = false;
  targetedTutor:any;
  isFilterLoading:boolean = false;
  userRole = this.authService.currentRole$.getValue();
  user = this.authService.user$.value;
  
  @HostListener('window:scroll', ['$event.target']) // for window scroll events
  scroll(e: any) {
    this.paginateTutors()
    this.handleDisplayOnScroll(e);
  }

  @ViewChild(ToggleDisplayComponent) toggleDisplayChild!: ToggleDisplayComponent;
  @ViewChildren(TutorTileComponent) tutorTiles!: QueryList<any>;

  tutors: any;
  isListView: any;
  tutorName: any;
  title: string = findTutorTitle;
  timezoneID:any;
  constructor(private route: ActivatedRoute, 
    private formDataService: FormDataService,
    private authService: AuthService,
    private bookLessonService: BookLessonService,
    private responseHandlerService: ResponseHandlerService,
    public findTutorService: FindTutorService) {
    this.isListView = this.findTutorService.isListView$
  }

  ngOnInit(): void {
    if(this.userRole === 'guest'){
       this.isLoading = true;
       this.formDataService.fetchDataAndUseTimezone()
       .then((data) => {
         this.timezoneID = data['id'];
         if(this.timezoneID){
           this.getTutorsForTheFirstLoad(this.timezoneID);
         }
         else{
           this.timezoneID = 305;
           this.getTutorsForTheFirstLoad(this.timezoneID);
         }
       
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
    else{
      this.getTutorsForTheFirstLoad();
    }
  }

  openBookingWindow(tutor: any) {
    this.user = this.authService.user$.value;
    if(this.user && this.user['id']){
        if (
          this.user.fname && 
          this.user.lname &&
          this.user.isEmailVerified === true &&
          this.user.isPhoneVerified === true ) 
        {
          this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.LESSON_TYPE_DETAILS);
        } 
      else{
          this.bookLessonService.bookingPhase$.next(BOOKING_PHASES.INCOMPLETE);
          const modalElement = document.getElementById('openCompleteInformationModal');
          if(modalElement){
            const modal = new bootstrap.Modal(modalElement);
            modal.show();
          }
      }
      this.bookLessonService.openBookingWindow$.next(true);
      this.bookLessonService.enableDisablePageScroll('hidden');
      this.bookLessonService.selectedTutor$.next(tutor);

    }
  }

  getTutorsForTheFirstLoad(timezoneId?:any) {
    this.isLoading = true;
    this.findTutorService.getTutorsFirstLoad(timezoneId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          this.tutors = res['data'];
          this.allTutorsCount = res.meta.total;
          this.lastPage = res.meta.last_page;
          this.setDefaultTutorDisplay();
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }

  paginateTutors() {
    if (this.isScrolledBottom() && !this.isLoading && !this.showLoader) {
      console.log(this.page, this.lastPage)
        if (this.page < this.lastPage) {
            this.showLoader = true;
            this.page = this.page += 1;
            this.isPaginated = true;
            this.findTutorService.findTutors(this.getParams(this.filters)).pipe(finalize(() => (this.showLoader = false))).subscribe(
            (res: any) => {
              this.tutors = [...this.tutors, ...res.data];
              this.allTutorsCount = this.tutors.length;
              this.lastPage = res.meta.last_page;
              this.allTutorsCount = res.meta.total;
            }
          )
        }
    }
  }

  getFilters(filters: any) {
    this.filters = filters;
    this.isPaginated = false;
    this.filterTutors(this.getParams(filters))
  }

  setDefaultTutorDisplay(){
    if(this.tutors && this.tutors.length > 0){
      this.targetedTutor = this.tutors[0];
      this.findTutorService.targetedTutorTile$.next(this.targetedTutor);
    }
  }

  filterTutors(params: any) {
    this.isFilterLoading = true;
    this.findTutorService.findTutors(params).subscribe(
      (res: any) => {
        this.tutors = res.data;
        this.allTutorsCount = res.meta.total;
        this.lastPage = res.meta.last_page;
        this.setDefaultTutorDisplay();
        this.isFilterLoading = false;
       }
    )
  }

  getParams(params?: any) {
    return params = {
      perPage: this.itemsPerPage,
      page: this.page,
      timezoneId: this.timezoneID,
      topTutor: params?.topTutors,
      subjectIds: params?.subjectId,
      nativeLanguagesIds: this.extractParams(params?.proficiency),
      // levelIds: this.extractParams(params?.proficiency),
      countryIds: this.extractParams(params?.countries),
      languageIds: this.extractParams(params?.languages),
      ageIds: this.extractParams(params?.ageGroups),
      hourlyRateFrom: params?.hourlyRateFrom,
      hourlyRateTo: params?.hourlyRateTo > 0 ? params?.hourlyRateTo : '',
      tutorTypeId: '',
      sortBy: '',
      searchBy: params?.searchBy
    }
  }

  extractParams(arr: any) {
    if (arr?.length > 0) {
      let ids: Array<any> = [];
      arr.forEach((element: any) => {
        ids.push(element);
      });
      return ids;
    }
    return ''
  }

  getPageNumber() {
    let totalPages = this.allTutorsCount  // total number of pages
    if (this.isPaginated) {
      if (this.page < totalPages) {
        return this.page += 1;
      }
    }
    return this.page;
  }

  isScrolledBottom() {
    return (window.innerHeight + window.scrollY > (document.body.scrollHeight - this.bottomGap))
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setToggleDisplayValues();
      const tutorData = sessionStorage.getItem('tutorData');
      if (tutorData) {
        let tutor = JSON.parse(tutorData);
        this.openBookingWindow(tutor);
        sessionStorage.removeItem('tutorData');
      }
    });
    this.displayOverLappedTutor();
  }

  setToggleDisplayValues() {
    this.toggleDisplayChild.title = this.title;
  }

  getOverLappedTutorTile(midScreenEl: any, tileEl: any) {
    const domRect1 = midScreenEl.getBoundingClientRect();
    const domRect2 = tileEl.getBoundingClientRect();
    return false
  }

  getTutorOnHover(tutor: any, index?:any) {
    this.targetedTutor = tutor;
    this.findTutorService.targetedTutorTile$.next(this.targetedTutor);
    this.displayTutorScrollCard(tutor['id']);
  }

  displayTutorScrollCard(id:any){
    // const tutorScrollId = document.getElementById('tr-scroll-display');
    // const tutorId = document.getElementById('trTutorTile_' + id);
    // var screenWidth = window.innerWidth;
    // var heightDifference = 0;
    //  if (screenWidth >= 1200 && screenWidth <= 1400) {
    //     heightDifference = 77.6;
    //   } else if (screenWidth > 1400) {
    //     heightDifference = 33.6;
    //   }
    // if(tutorId && tutorScrollId){
    //   let tutorcarddetails = tutorId.getBoundingClientRect();
    //   let cardHeight = tutorcarddetails.height;
    //   let diff = (tutorId.offsetTop - cardHeight) - heightDifference;
    //   tutorScrollId.style.transform = ('translateY(' + diff + 'px)');
    // }
  }



  displayOverLappedTutor() {
    const midScreenEl = document.getElementById('trFindTutorCrossScreen');
    this.tutorTiles.forEach(tutorTile => {
      let tutor = tutorTile.tutor
      let tutorTileId = 'trTutorTile_' + tutor.id
      let tutorTileEl = document.getElementById(tutorTileId)

      if (this.getOverLappedTutorTile(midScreenEl, tutorTileEl)) {
        setTimeout(() => {
          this.targetedTutor = tutor;
          this.findTutorService.targetedTutorTile$.next(this.targetedTutor)
        });
      }
    });
  }

  playVideo(){
    const modalElement = document.getElementById('videoModal');
    if(modalElement){
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
    this.showPlayer = true;
  }

  handleDisplayOnScroll(e: any) {
    let scroll = e.scrollingElement.scrollTop;
    const tutorId = document.getElementById('tutorTile');
    this.displayOverLappedTutor()
    // this.currentPosition = scroll;
    // const tutorScrollId = document.getElementById('tr-scroll-display');
    // const tutorTileId = document.getElementById('headerWrapper');
    // if (tutorScrollId && tutorTileId && tutorId) {
    //   let rightDiv = tutorId.offsetTop;
    //   if (rightDiv) {
    //     let top = tutorTileId.offsetHeight + 20;
    //     tutorScrollId.classList.add("position-absolute");
    //     tutorScrollId.style.setProperty("top", top + "px");
    //   }
    //   else {
    //     if (tutorScrollId) {
    //       tutorScrollId.classList.remove("position-absolute");
    //       tutorScrollId.style.setProperty("top", "0");
    //     }
    //   }
    // }

    if (scroll > this.currentPosition) {
      // console.log("scrollDown");
    } else {
      // console.log("scrollUp");
    }
  }
}
