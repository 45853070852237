import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastEventIcons, ToastEventTypes } from 'src/app/shared/models/notifications';
import { TutorDashboardService } from '@components/tutor-components/tutor-dashboard/tutor-dashboard.service';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { UserLessonsService } from '@components/shared-components/user-lesson-card/user-lessons.service';
import { ReschedulerService } from '@components/lesson-rescheduler/rescheduler.service';
import { LESSON_PERMISSIONS } from '@shared/models/lesson';

@Component({
  selector: 'tr-tutor-dashboard',
  templateUrl: './tutor-dashboard.component.html',
  styleUrls: ['./tutor-dashboard.component.css'],
})

export class TutorDashboardComponent implements OnInit {
  loading:boolean = false;
  @ViewChild('openReschedulerModal') openReschedulerModal?: ElementRef;
  notifyMessage = 'Upload your image You still have not uploaded your image due on 4 May 2023. Upload image';
  notifyMessageType = ToastEventTypes.Warning;
  notifyMessageIcon = ToastEventIcons.Warning;
  userData!:any;
  isLoading:boolean = true;
  LESSON_PERMISSIONS: any = LESSON_PERMISSIONS;
  constructor(private service:TutorDashboardService, 
    public userLessonsService: UserLessonsService,
    private responseHandlerService: ResponseHandlerService) { }
  
  ngOnInit(): void {
    this.getUserStats();
    this.userLessonsService.lessonAction$.subscribe(action => {
      if (action === LESSON_PERMISSIONS.RESCHEDULE || action === LESSON_PERMISSIONS.REQ_RESCHEDULE || action === LESSON_PERMISSIONS.GIVE_NEW_TIME) {
        setTimeout(() => {this.openReschedulerModal?.nativeElement.click()},100);
      }
    });
  }

  getUserStats() {
    this.isLoading = true;
    this.service
      .getUserStats()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: any) => {
          if(res){
            this.userData = res;
          } 
        },
        (error: any) => {
          this.responseHandlerService.handleFailureResponse(error);
        }
      );
  }
}

