import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgModule } from './svg.module';
import { RouterModule } from '@angular/router';
import { SchedulerNextModule } from '@components/scheduler-next/scheduler-next.module';
import { SafePipe } from '../shared/pipes/safe-url.pipe';
import { RaiseComplaintPopupComponent } from '@components/shared-components/raise-complaint-popup/raise-complaint-popup.component';
import { SendMessagePopupComponent } from '@components/shared-components/send-message-popup/send-message-popup.component';
import { StudentTutorsModule } from '@modules/student-tutor.module';
import { TutorProfileComponent } from '@components/student-components/tutor-profile/tutor-profile.component';
import { TutorIntroComponent } from '@components/student-components/tutor-profile/tutor-intro/tutor-intro.component';
import { TutorProfileVideoComponent } from '@components/student-components/tutor-profile/tutor-profile-video/tutor-profile-video.component';
import { SuggestedTutorsComponent } from '@components/student-components/tutor-profile/suggested-tutors/suggested-tutors.component';
import { TutorProfileDiscountsComponent } from '@components/student-components/tutor-profile/tutor-profile-discounts/tutor-profile-discounts.component';
import { TutorProfileHeaderComponent } from '@components/student-components/tutor-profile/tutor-profile-header/tutor-profile-header.component';
import { TutorProfileReviewsComponent } from '@components/student-components/tutor-profile/tutor-profile-header/tutor-profile-reviews/tutor-profile-reviews.component';
import { TutorProfileResumeComponent } from '@components/student-components/tutor-profile/tutor-profile-header/tutor-profile-resume/tutor-profile-resume.component';
import { VideoPlayerComponent } from '@components/shared-components/video-player/video-player.component';
import { StudentCompleteInformationPopupComponent } from '@components/shared-components/student-complete-information-popup/student-complete-information-popup.component';
import { CurrencyDirective } from '@core/currency.directive';

@NgModule({
  declarations: [
  TutorProfileComponent,
  TutorIntroComponent,
  TutorProfileHeaderComponent,
  TutorProfileDiscountsComponent,
  TutorProfileReviewsComponent,
  TutorProfileVideoComponent,
  TutorProfileResumeComponent,
  SuggestedTutorsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    StudentTutorsModule,
    CurrencyDirective,
    FormsModule,
    StudentCompleteInformationPopupComponent,
    SendMessagePopupComponent,
    RaiseComplaintPopupComponent,
    SchedulerNextModule,
    SafePipe,
    SvgModule,
    VideoPlayerComponent,
  ],
  exports: [
    TutorProfileComponent,
    TutorIntroComponent,
    TutorProfileHeaderComponent,
    TutorProfileDiscountsComponent,
    TutorProfileReviewsComponent,
    TutorProfileVideoComponent,
    TutorProfileResumeComponent,
    SuggestedTutorsComponent,
  ],
})
export class TutorProfileModule { }
