import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { SchedulerNextService } from './scheduler-next.service';
import * as moment from 'moment';
import { BehaviorSubject, from, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ResponseHandlerService } from "@components/shared-components/response-handler.service";
import { AuthService } from 'src/app/core/auth/auth.service';
import { FormDataService } from '@core/form-data.service';
import { takeUntil } from 'rxjs/operators';
import { TRObject } from '@shared/models/shared';

export type viewType =
  | 'viewOnly'
  | 'editAvailabilty'
  | 'booking'
  | 'editBooking'
  | 'reschedule';

@Component({
  selector: 'tr-scheduler-next',
  templateUrl: './scheduler-next.component.html',
  styleUrls: ['./scheduler-next.component.css'],
})
export class SchedulerNextComponent extends TRObject implements OnInit {
  @Input() viewType: viewType = 'viewOnly';
  @Input() tutorId: string | undefined;
  @Input() isTrial: boolean = false;
  @Input() isSubscription: boolean = false;
  @Input() lessonId?: string | undefined;
  @Input() bookingId?: string | undefined;
  isLoading:boolean = false;
  userRole = this.authService.currentRole$.getValue();
  selectedSlot:any;
  timezoneID:any;
  isLessonExtendable$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  nextFollowingSlotsExtensions$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedTimeSlot: string = 'all'; 
  viewDate = new Date();
  daysInWeek = 7;
  events: CalendarEvent[] = [];

  availabilty: any[] = [];
  availabiltyChanges: any[] = [];

  rescheduleData: any = {};
  editBookingData: any = {};

  blocked: any[] = [];
  booked: any[] = [];
  timeOff: any[] = [];
  selected: any[] = [];

  showTimeOff = true;

  // Edit modal props
  editModalOpen = false;
  promtModalOpen = false;
  editModalSlot: any;

  openEditModal(slot: any): void {
    this.editModalOpen = true;
    this.editModalSlot = slot;
  }

  closeEditModal(): void {
    this.editModalOpen = false;
    this.editModalSlot = null;
  }

  constructor(
    private schedulerNextService: SchedulerNextService,
    private authService: AuthService,
     private formDataService: FormDataService,
    private responseHandlerService: ResponseHandlerService,
    private cdr: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    if(this.userRole === 'guest' && this.viewType === 'viewOnly'){
         this.formDataService.fetchDataAndUseTimezone()
         .then((data) => {
           this.timezoneID = data['id'];
           if(this.timezoneID){
              this.getAvailability(this.timezoneID);
            }      
       
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
    else{
      this.getAvailability();
    }

    this.detectSlotsRemoved();
  }


  getAvailability(timezoneID?:any){
    if (this.tutorId) {
      this.isLoading = true;
      this.schedulerNextService
        .getAvailability(this.tutorId, this.viewType, this.isSubscription, timezoneID)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((res: any) => {
          this.availabilty = res.availableSlots;
          this.booked = res.bookedSlots;
          this.timeOff = res.timeOffSlots;

          if (this.viewType === 'editAvailabilty') {
            this.mapAvailabiltyToSelected();
          }

          if (this.viewType === 'reschedule' && this.lessonId) {
            this.schedulerNextService
              .getLessonData(this.lessonId)
              .subscribe((lessonData: any) => {
                if (!lessonData) {
                  // TODO: show error if there is an error with getting the data
                  return;
                }

                this.mapRescheduleData(lessonData);
              });
          }

          // TODO: add edit booking

          this.updateEvents();
        });
    }
  }

  getSlotActions() {
    const editAction = {
      label: 'Edit', // TODO: maybe replace with icon
      cssClass: 'btn-edit-action',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        const currentSlot = this.selected.find((slot) => slot.id === event.id);

        if (!currentSlot) {
          return;
        }

        this.openEditModal({ ...currentSlot });
      },
    };

    return [editAction];
  }

  mapAvailabiltyToSelected() {
    const selectedSlots: any[] = [];

    this.availabilty.forEach((slot) => {
      const selectedSlot = {
        ...slot,
        actions: this.getSlotActions(),
        slotType: 'selected',
      };

      selectedSlots.push(selectedSlot);
    });

    this.selected = [...selectedSlots];
  }

  mapRescheduleData(data: any) {
    this.rescheduleData = data;
    this.blocked = this.schedulerNextService.mapLessonSlots(data, 'blocked');
    this.updateEvents();
    this.goToLessonWeek();
  }

  handleEventClick(event: any) {
    if (this.viewType === 'viewOnly') {
      return;
    }

    if (event.event.slotType === 'selected') {
      const currentSlot = this.selected.find(
        (slot) => slot.id === event.event.id
      );

      this.removeSlot(currentSlot);
    }
  }

  handleHourSegmentClick = (event: any) => {
    if (this.viewType === 'viewOnly') {
      return;
    }

    switch (this.viewType) {
      case 'editAvailabilty':
        this.handleEditAvailabilty(event);
        break;
      case 'booking':
        this.handleBooking(event);
        break;
      case 'reschedule':
        this.handleReschedule(event);
        break;
      // case 'editBooking':
      //   this.handleEditBooking(event);
      //   break;
      default:
        break;
    }

    return;
  };

  handleEditAvailabilty = (event: any) => {
    console.log(event);
    const currentDate = moment(event.date).format('YYYY-MM-DD HH:mm');
    const availableSlot = this.availabilty.find(
      (slot) => slot.id === currentDate
    );

    if (availableSlot) {
      const selectedSlot = {
        ...availableSlot,
        actions: this.getSlotActions(),
        slotType: 'selected',
      };

      if (this.viewType === 'editAvailabilty') {
        this.availabiltyChanges = [
          ...this.availabiltyChanges.filter(
            (slot) => slot.recordId !== selectedSlot.meta.slots[0].recordId
          ),
        ];
      }

      this.selectSlot(selectedSlot);
      return;
    }

    const selectedSlot = {
      id: currentDate,
      start: new Date(currentDate),
      cssClass: `slot-selected`,
      isRepeated: true,
      slotType: 'selected',
      actions: this.getSlotActions(),
      meta: {
        slots: [
          {
            recordId: null,
            dateTime: currentDate,
            state: 'available',
            isRepeated: true,
          },
        ],
      },
    };

    if (this.viewType === 'editAvailabilty') {
      this.availabiltyChanges = [
        ...this.availabiltyChanges.filter(
          (slot) => slot.dateTime !== selectedSlot.id
        ),
      ];
      this.availabiltyChanges = [
        ...this.availabiltyChanges,
        {
          action: 'add',
          recordId: null,
          dateTime: selectedSlot.id,
          isRepeated: true,
        },
      ];
    }

    this.selectSlot(selectedSlot);
  };

  handleBooking = (event: any) => {
    if (this.isTrial && this.selected.length > 0) {
      this.selected = [];
    }

    const isAvailableSlot =
      event.sourceEvent.target.classList.contains('available-slot');

    if (isAvailableSlot) {
      const id = event.sourceEvent.target.getAttribute('data-slot-id');
      const availableSlot = this.availabilty.find((slot) => slot.id === id);
      this.selectedSlot = {
        ...availableSlot,
        slotType: 'selected',
        cssClass: `slot-selected`,
        meta: {
          ...availableSlot.meta,
          extendable: this.isExtandableSlot(id),
        },
      };

      if(this.isTrial){
        this.selectSlot(this.selectedSlot);
        return;
      }
      let selectStatus = false;
      let next30 = this.selectedSlot.meta.extendable.next30.available;
      let next60 = this.selectedSlot.meta.extendable.next60.available;
      if(next30){
        let output: any = { Extend60Mins: next30, Extend90Mins: next60};
        this.nextFollowingSlotsExtensions$.next(output);
        this.promtModalOpen = true;
      }
      else{
        this.selectSlot(this.selectedSlot);
        return;
      }

      // handle trial: if isTrial is true, only allow to select one slot
      // TODO: show modal to ask if user wants to extend the slot
      // this.extendAndSelectSlot(selectedSlot, 60);
      // this.extendAndSelectSlot(selectedSlot, 90);
      
    }
  };

  handleReschedule = (event: any) => {
    if (this.selected.length > 0) {
      this.selected = [];
    }

    const isAvailableSlot =
      event.sourceEvent.target.classList.contains('available-slot');

    if (isAvailableSlot) {
      const id = event.sourceEvent.target.getAttribute('data-slot-id');
      const availableSlot = this.availabilty.find((slot) => slot.id === id);
      const selectedSlot = {
        ...availableSlot,
        slotType: 'selected',
        cssClass: `slot-selected`,
        meta: {
          ...availableSlot.meta,
          extendable: this.isExtandableSlot(id),
        },
      };
      let selectStatus = false;

      switch (this.rescheduleData.period) {
        case 30:
          selectStatus = this.selectSlot(selectedSlot);
          break;
        case 60:
          selectStatus = this.extendAndSelectSlot(selectedSlot, 60);
          break;
        case 90:
          selectStatus = this.extendAndSelectSlot(selectedSlot, 90);
          break;
      }

      if (!selectStatus) {
        console.log('Failed to select slot');
      }
    }
  };

  extendAndSelectSlot(slot: any, period: number) {
    const selectedSlot = { ...slot };

    if (selectedSlot.meta.extendable.next30.available && period === 60) {
      selectedSlot.meta.slots.push(
        selectedSlot.meta.extendable.next30.slot.meta.slots[0]
      );
      selectedSlot.end = selectedSlot.meta.extendable.next30.end;
      return this.selectSlot(selectedSlot);
    }

    if (selectedSlot.meta.extendable.next60.available && period === 90) {
      selectedSlot.meta.slots.push(
        selectedSlot.meta.extendable.next30.slot.meta.slots[0]
      );
      selectedSlot.meta.slots.push(
        selectedSlot.meta.extendable.next60.slot.meta.slots[0]
      );
      selectedSlot.end = selectedSlot.meta.extendable.next60.end;
      return this.selectSlot(selectedSlot);
    }

    return false;
  }

  selectSlot(slot: any) {
    this.selected = [...this.selected, slot];
    this.updateEvents();
    return true;
  }

  removeSlot(slot: any) {
    if (this.viewType === 'editAvailabilty') {
      // remove from changes if exists for the same slot
      this.availabiltyChanges = [
        ...this.availabiltyChanges.filter((s) => s.dateTime !== slot.id),
      ];

      if (slot.meta.slots[0].recordId) {
        // add the new change
        this.availabiltyChanges = [
          ...this.availabiltyChanges,
          {
            action: 'remove',
            recordId: slot.meta.slots[0].recordId,
            dateTime: slot.meta.slots[0].dateTime,
            isRepeated: slot.meta.slots[0].isRepeated,
          },
        ];
      }
    }
    this.selected = [...this.selected.filter((e) => e.id !== slot.id)];
    this.updateEvents();
    this.selectedSlot = null;
  }

  removeSelectedSlots(id:any){
      this.selected = [...this.selected.filter((e) => e.id !== id)];
      this.updateEvents();
  }

  toggleRepeated(slot: any) {
    slot.isRepeated = !slot.isRepeated;
    slot.meta.slots[0].isRepeated = !slot.meta.slots[0].isRepeated;

    if (this.editModalSlot) {
      this.editModalSlot = slot;
    }

    const currentSelectedSlot = this.selected.findIndex(
      (s) => s.id === slot.id
    );
    this.selected[currentSelectedSlot] = slot;

    // update changes --- fix this
    this.availabiltyChanges = [
      ...this.availabiltyChanges.filter(
        (s) => s.recordId !== slot.meta.slots[0].recordId
      ),
    ];

    const orignalSlot = this.availabilty.find((s) => s.id === slot.id);
    console.log(orignalSlot);
    if (orignalSlot && orignalSlot.isRepeated !== slot.isRepeated) {
      this.availabiltyChanges = [
        ...this.availabiltyChanges,
        {
          action: 'update',
          recordId: slot.meta.slots[0].recordId,
          dateTime: slot.meta.slots[0].dateTime,
          isRepeated: slot.meta.slots[0].isRepeated,
        },
      ];
    }

    if (!orignalSlot) {
      this.availabiltyChanges = [
        ...this.availabiltyChanges,
        {
          action: 'add',
          recordId: null,
          dateTime: slot.id,
          isRepeated: slot.isRepeated,
        },
      ];
    }
    // end update changes

    this.updateEvents();
  }

  updateEvents() {
    // if (this.selected && this.selected.length > 0) {
    //   const combinedSlots: any = [];
    //   const idSet = new Set();

    //   this.selected.forEach(item => {
    //     const slots = item.meta.slots;

    //     // Create a unique lessonKey for each item
    //     const lessonKey = this.generateUniqueKey();

    //     slots.forEach((slot: any) => {
    //       if (!idSet.has(slot.recordId)) {
    //         slot.lessonPeriod = 30;
    //         slot.lessonKey = lessonKey; // Add unique lessonKey to the slot
    //         idSet.add(slot.recordId);
    //         combinedSlots.push(slot);
    //       } else {
    //         // If a slot with the same recordId already exists in combinedSlots, create a new unique lessonKey
    //         const newLessonKey = this.generateUniqueKey();
    //         slot.lessonKey = newLessonKey;
    //         combinedSlots.push(slot);
    //       }
    //     });
    //   });

    //   this.schedulerNextService.selectedSlots$.next(combinedSlots);
    //   console.log('uniquw', this.selected, combinedSlots);
    // } else {
    //   this.schedulerNextService.selectedSlots$.next([]);
    // }

   if (this.selected && this.selected.length > 0) {
      const combinedSlots: any = [];
      const uniqueSlotKeys = new Set();

      this.selected.forEach(item => {
        const slots = item.meta.slots;

        // Create a unique lessonKey for each item
        const lessonKey = this.generateUniqueKey();

        slots.forEach((slot: any) => {
          // Create a composite key using recordId and date
          const slotKey = `${slot.recordId}-${slot.dateTime}`;

          if (!uniqueSlotKeys.has(slotKey)) {
            slot.lessonPeriod = 30;
            slot.lessonKey = lessonKey;
            uniqueSlotKeys.add(slotKey);
            combinedSlots.push(slot);
          } else {
            // If a slot with the same recordId and date already exists in combinedSlots, create a new unique lessonKey
            const newLessonKey = this.generateUniqueKey();
            slot.lessonKey = newLessonKey;
            combinedSlots.push(slot);
          }
        });
      });

      this.schedulerNextService.selectedSlots$.next(combinedSlots);
    } else {
      this.schedulerNextService.selectedSlots$.next([]);
    }



    this.events = [
      ...this.booked,
      ...this.selected,
      ...(this.showTimeOff ? this.timeOff : []),
      ...(this.viewType === 'reschedule' ? this.blocked : []),
    ];
    this.cdr.detectChanges();
    console.log(this.availabiltyChanges);
  }

  generateUniqueKey() {
     return Math.random().toString(36).substr(2, 9); // Generate a random alphanumeric string
  }


  isAvailableSlot(segment: any) {
    const slot = this.availabilty.find(
      (slot) => slot.start.getTime() === segment.date.getTime()
    );

    return { available: !!slot, slotId: slot?.id };
  }

  isExtandableSlot(selectedSlotTime: string) {
    const selectedDate = new Date(selectedSlotTime);
    const next30SlotTime = moment(selectedDate)
      .add(30, 'minutes')
      .format('YYYY-MM-DD HH:mm');
    const next60SlotTime = moment(selectedDate)
      .add(60, 'minutes')
      .format('YYYY-MM-DD HH:mm');

    let next30Slot = this.availabilty.find(
      (slot) => slot.id === next30SlotTime
    );

    let next60Slot = this.availabilty.find(
      (slot) => slot.id === next60SlotTime
    );

    if (next30Slot && this.selected.find((slot) => slot.id === next30Slot.id)) {
      next30Slot = undefined;
    }

    if (
      !next30Slot ||
      (next60Slot && this.selected.find((slot) => slot.id === next60Slot.id))
    ) {
      next60Slot = undefined;
    }

    return {
      next30: {
        available: !!next30Slot,
        end: moment(next30SlotTime).add(30, 'minutes').toDate(),
        slot: next30Slot,
      },
      next60: {
        available: !!next30Slot && !!next60Slot,
        end: moment(next60SlotTime).add(30, 'minutes').toDate(),
        slot: next60Slot,
      },
    };
  }

  today(): void {
    this.viewDate = new Date();
    this.cdr.detectChanges();
  }

  prevWeek(): void {
    const date = new Date(this.viewDate);
    date.setDate(date.getDate() - this.daysInWeek);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date < today) {
      return;
    }

    this.viewDate = date;
    this.cdr.detectChanges();
  }

  nextWeek(): void {
    const date = new Date(this.viewDate);
    date.setDate(date.getDate() + this.daysInWeek);
    this.viewDate = date;
    this.cdr.detectChanges();
  }

  goToLessonWeek(): void {
    if (this.rescheduleData.dateTime) {
      this.setWeekOfEvent(new Date(this.rescheduleData.dateTime));
    }
  }

  setWeekOfEvent(eventDate: Date): void {
    const diffDays = Math.ceil(
      (eventDate.getTime() - this.viewDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const diffWeeks = Math.floor(diffDays / this.daysInWeek);
    const date = new Date(this.viewDate);
    date.setDate(date.getDate() + diffWeeks * this.daysInWeek);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Adjust the date to make sure it's the same day of the week as today
    const dayOfWeekToday = today.getDay();
    const dayOfWeekDate = date.getDay();
    const daysDifference = dayOfWeekDate - dayOfWeekToday;
    date.setDate(date.getDate() - daysDifference);

    if (date < today) {
      this.viewDate = new Date(today);
    } else {
      this.viewDate = date;
    }

    this.cdr.detectChanges();
  }

  keepCurrentSelection(isClosed: boolean) {
    this.isLessonExtendable$.next(!isClosed);
    this.promtModalOpen = false;
    this.selectedSlot.meta.slots = [this.selectedSlot.meta.slots[0]];
    this.selectSlot(this.selectedSlot);
  }

  extendLessonSlots(ExtensionPeriod: number) {
    this.promtModalOpen = false;
    if(ExtensionPeriod == 60){
       this.extendAndSelectSlot(this.selectedSlot, 60);
    }
    else if(ExtensionPeriod == 90){
       this.extendAndSelectSlot(this.selectedSlot, 90);
    }
  }

  discardAvailability(){
     this.getAvailability();
     this.availabiltyChanges = [];
  }

  updateAvailability() {
    this.isLoading = true;
    this.schedulerNextService.updateTutorAvailability(this.availabiltyChanges).pipe(finalize(() => (this.isLoading = false)))
    .subscribe(
      (res: any) => {
        if(res['status']){
          this.getAvailability();
          this.availabiltyChanges = [];
          this.responseHandlerService.handleSuccessRessponse(res.message);

        }
      },
      (error: any) => {
        this.responseHandlerService.handleFailureResponse(error);
      }
    )
  }

  detectSlotsRemoved(){
    this.schedulerNextService.removeSlots$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((id: any) => {
        if (id) {
          this.removeSelectedSlots(id);
          console.log(id);
        }
    });
  }

  scroll(e: any) {
    console.log(e);
    const header = document.getElementById('header'); // Get the element by its ID
    if (header) {
      const scrollOffset = window.scrollY;
      if (scrollOffset > 60) { // Adjust the value according to your needs
        header.style.top = '0';
      } else {
        header.style.top = `-${scrollOffset}px`;
      }
    }
  }

  isMorningAfternoonEveningSlot(segment: any): boolean {
    const calEvents = document.querySelector('.cal-time-events') as HTMLElement;
    const scheduler= document.getElementById('schedulerNext');
    const hour = segment.displayDate.getHours();
    if(calEvents && scheduler){
        if (this.selectedTimeSlot === 'morning') {
          scheduler.style.height = '780px';
          scheduler.style.overflowY = 'hidden';
          calEvents.style.marginTop = 'auto';
          return hour >= 0 && hour < 12;
        } 
        else if (this.selectedTimeSlot === 'afternoon') {
           calEvents.style.marginTop = '-708px';
           scheduler.style.height = '420px';
           scheduler.style.overflowY = 'hidden';
           return hour >= 12 && hour < 18;
        } 
        else if (this.selectedTimeSlot === 'evening') {
          calEvents.style.marginTop = '-1070px';
          scheduler.style.height = '440px';
          return hour >= 18 || hour < 0;
        }        
        else if (this.selectedTimeSlot === 'all') {
          calEvents.style.marginTop = 'auto';
          scheduler.style.height = 'auto';
        }
         return true;
      }
       return true;
    }
}

