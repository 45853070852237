import { Component, ElementRef, Renderer2, HostListener, OnInit, Input} from '@angular/core';

@Component({
  selector: 'tr-tutor-profile-header',
  templateUrl: './tutor-profile-header.component.html',
  styleUrls: ['./tutor-profile-header.component.css']
})

export class TutorProfileHeaderComponent implements OnInit {
  activeSectionId: string = 'tr-about-sec';
  @Input() tutor:any;
  isSticky = false;
  stickyDivOffsetTop = 0;


  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    // Calculate the offset top of the sticky div
    this.stickyDivOffsetTop = this.el.nativeElement.querySelector('.tr-nav-div').offsetTop;
  }

  goToSection(id:any){
    const targetSection = this.el.nativeElement.querySelector(id);
  
  if (targetSection) {
    const offset =  140 // Convert 5rem to pixels
    const targetOffsetTop = targetSection.getBoundingClientRect().top + window.scrollY - offset;
    
    window.scrollTo({ top: targetOffsetTop, behavior: 'smooth' });
  }

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const currentScrollPosition = window.pageYOffset;
    const sections = this.el.nativeElement.querySelectorAll('.tr-target');
      if (currentScrollPosition >= this.stickyDivOffsetTop) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }

      for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      const rect = section.getBoundingClientRect();
      
      // Adjust the offset (100px in this case)
      const offset = 180;
      
      if (rect.top + offset <= window.innerHeight / 2) {
        this.activeSectionId = section.id;
        break;
      }
    }

  }

  removePTags(html: string): string {
    // Remove the <p> tags using regular expressions
    return html.replace(/<\/?p>/g, '');
  }

}
